<template lang="pug">
modal(@click="$emit('close', false)")
  modal-body-no-footer.rounded-2xl.shadow-2xl.fade-in-bottom(class="w-4/5")
    template(v-slot:header)
      .flex
        .flex.gap-4
          h4.text-2xl.text-gray-500.font-semibold.font-inter Detalhes da chamada
        .material-icons.notranslate.text-gray-500.ml-auto.cursor-pointer(class="transition-all hover:text-red-500 active:scale-90" @click="$emit('close', false)" v-tooltip="'Fechar'") close
    template(v-slot:body)
      loading-ring(v-if="!loaded")
      .flex.flex-col(v-else)
        activity-card(:data="activityCardData")
        .mt-6.history-contact-box
          contact-box(@openCallback="openCallback" :user-data="contactData")
          call-event-tracking(:call="activity" :contact="contactData")
  callback-modal(
    @close-modal="actualModal = ''",
    v-if="actualModal === 'assignCallback'",
    :contact="callbackContact"
    :linkedId="callLinkedId"
  )
</template>

<script>
import Modal from '@/components/Modals/Modal.vue';
import ModalBodyNoFooter from '../Modals/ModalBodyNoFooter.vue';
import ActivityCard from '@/pages/Callbacks/Callbacks.History.ActivityCard.vue';
import ContactBox from '@/pages/Callbacks/Callbacks.History.ContactBox.vue';
import CallEventTracking from '@/components/CallEventTracking';
import LoadingRing from '../LoadingRing.vue';
import CallbackModal from '@/pages/Contacts/Contact.CallbackModal.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  props: ['callLinkedId'],
  mixins: [UserOrContactMixin],
  components: {
    Modal,
    ModalBodyNoFooter,
    ActivityCard,
    ContactBox,
    CallEventTracking,
    LoadingRing,
    CallbackModal,
  },
  data() {
    return {
      loaded: false,
      userData: null,
      activity: null,
      actualModal: '',
      callbackContact: {},
    };
  },
  computed: {
    contactNumber() {
      const fromId = this.activity?.fromId || this.activity?.from_id;
      const isMineCall = fromId === this.user.id || this.activity.from === this.user.endpoint;
      return isMineCall ? this.activity.to : this.activity.from;
    },
    contactData() {
      return { ...this.identityContact, number: this.contactNumber };
    },
    activityCardData() {
      return { ...this.activity, contact: this.contactData };
    },
  },
  methods: {
    async getContact() {
      const fromId = this.activity?.fromId || this.activity?.from_id;
      const toId = this.activity?.toId || this.activity?.to_id;
      const isMineCall = fromId === this.user.id || this.activity.from === this.user.endpoint;
      const callerId = isMineCall ? toId : fromId;
      const callerNumber = isMineCall ? this.activity.to : this.activity.from;

      await this.setContactOrOperator({
        id: callerId,
        number: callerNumber,
      });

      this.loaded = true;
    },
    openCallback(contact) {
      this.callbackContact = contact;
      this.actualModal = 'assignCallback';
      this.$store.commit('SET_ITEMID', contact.id);
    },
    async fetchData() {
      try {
        const res = await this.$http.get(this.requestLinks.callreport + '/call/id/' + this.callLinkedId);
        this.activity = res.data;
      } catch (e) {
        this.$toast.e(e);
      } finally {
        await this.getContact();
      }
    },
    sendToDetail() {
      this.$router.push({
        name: 'Detalhe Histórico',
        params: {
          id: this.callLinkedId,
        },
      });
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2022-7-21 0:39:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
