<template lang="pug">
.min-w-min.bg-zinc-50.px-6.overflow-auto.scroll-style.relative(
  class="w-full shadow-[15px_5px_40px_-10px_rgba(0,0,0,0.2)] md:px-12"
)
  ContactHistoryContent(:contact-id="context.id || ''" :contact-phone="context.phonenumber || ''", :is-overlay="true")
</template>

<script>
import { Dropdown } from 'floating-vue';
import ContactHistoryContent from '@/pages/Contacts/Contact.History.Content.vue';

export default {
  components: {
    FDropdown: Dropdown,
    ContactHistoryContent,
  },
  data() {
    return {};
  },
  computed: {
    context() {
      return this.$store.getters['overlay/getContext'];
    },
  },
  methods: {
    close() {
      this.$store.commit('overlay/setActiveOverlay', null);
    },
  },
};
</script>

<style></style>
