<template lang="pug">
.side
  .side-wrapper
    .box-logo
      .menu.relative.h-full
        i.material-icons.notranslate.absolute.top-7(@click="() => (active = !active)") menu
      img.w-16(src="../../assets/images/opens-app-logo.png")
    .pt-3.bg-white(
      class="dark:bg-gray-50 flex justify-center items-start"
      :class="[active ? 'navigation active' : 'navigation', sidebarWidthClass]"
    )
      slot(name="content")
        ul.my-2.nav.nav-links.transition-all.duration-200.ease-in-out(
          class='!mb-40 flex justify-start items-start flex-col'
          :class="sidebarMarginClass"
        )
          slot(@click="active = false")
            sidebar-link(v-for="(link, index) in sidebarLinks", :key="link.name + index", :to="link.path", @click="closeNavbar", :link="link")
              .box-menu(class='dark:bg-gray-200')
              i(:class="link.icon")
              p {{ link.name }}
        ul.nav.nav-bottom(v-if="$slots['bottom-links']")
          slot(name="bottom-links")
</template>
<script>
import SidebarLink from './SidebarLink.vue';

export default {
  components: {
    SidebarLink,
  },
  props: {
    expandSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sidebarLinks: [],
      active: false,
      sidebarMarginClass: 'ml-0',
      sidebarWidthClass: 'w-28',
    };
  },
  watch: {
    active(isActive) {
      if (!isActive) this.$emit('close', isActive);
    },
    expandSidebar() {
      this.handleResize();
    },
  },
  computed: {
    darkMode() {
      return this.$store.getters['user/GET_DARKMODE'];
    },
  },
  methods: {
    handleResize() {
      const windowWidthMobile = window.innerWidth <= 992;

      if (!windowWidthMobile && this.expandSidebar) {
        this.sidebarMarginClass = 'ml-6';
        this.sidebarWidthClass = 'w-44';
      } else if (windowWidthMobile && this.expandSidebar) {
        this.sidebarMarginClass = '-ml-10';
        this.sidebarWidthClass = 'w-56';
      } else {
        this.sidebarMarginClass = 'ml-0';
        this.sidebarWidthClass = 'w-28';
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style lang="scss">
.nav-link {
  padding: 0.2rem 0.6rem !important;
}
.menu {
  display: none;
}
.navigation {
  height: calc(100vh - 79px);
  position: fixed;
  top: 79px;
  left: 0;
  width: 101px;
}
.side {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 101px;
  z-index: 20;
  color: #fff;
}
.side-wrapper {
  position: relative;
  max-height: calc(100vh - 75px);
  min-height: 100%;
  overflow: hidden;
  width: 101px;

  z-index: 4;
  padding-bottom: 20px;
}

.nav {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-left: 15px;
}

.logo-menu {
  position: relative;
  width: 20px !important;
  margin-left: 10px;
  margin-top: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.box-logo {
  position: absolute;
  width: 100%;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  text-align: center;
}
.nav-links {
  text-align: center;
}
.box-menu {
  position: absolute;
  width: 48px;
  height: 48px;
  left: 38px;
  top: 100px;
  background: #dee2e6;
  border-radius: 8px;
  z-index: 999;
  text-align: center;
  margin-bottom: 30px;
}
.nav-links li {
  padding-top: 10px;
  color: #868e96;

  display: flex;
  align-items: start !important;
  justify-content: start !important;
}

@media screen and (max-width: 991px) {
  .navigation {
    position: fixed;
    left: -100%;
    z-index: 99;
    transition: all 0.15s ease-in-out 0s, visibility 0s linear 0s, z-index 0s;
    -webkit-box-shadow: 24px 27px 32px 1px rgba(0, 0, 0, 0.11);
    -moz-box-shadow: 24px 27px 32px 1px rgba(0, 0, 0, 0.11);
    box-shadow: 24px 27px 32px 1px rgba(0, 0, 0, 0.11);
  }
  .active {
    left: 0;
    z-index: 99;
    transition: all 0.15s ease-in-out 0s, visibility 0s linear 0s, z-index 0s;
  }
  .menu {
    color: #000;
    display: flex;
    padding-left: 10px;
    cursor: pointer;
  }
}
</style>
