<template lang="pug">
.flex.items-center.justify-between.select-box-device.cursor-default.cursor-pointer
  div.relative.font-inter
    .flex(:class="{'opacity-20': !isWorking && webphone}")
      i.flex.items-center.justify-center.mr-3.text-brand-600.bg-brand-200.rounded.w-9.h-9.material-icons.notranslate
        .text-brand {{ webphone ? "rss_feed" : "phone" }}
      div.flex(v-if="user.endpoint")
        p.mr-2.text-sm.device-text.text-marker Ramal
        p.text-sm.font-bold.text-marker {{ user.endpoint }}
      div(v-else)
        p.text-sm.text-zinc-500 Sem dispositivo
  div.flex.justify-center.items-center
    f-tooltip(:triggers='["hover"]' :auto-hide='false' v-if="!isWorking && user.endpoint")
      v-button-secondary.text-xs(@click="synchronize") sincronizar
      template(#popper)
        device-status-info(:is-updating='isUpdating')
    f-tooltip(placement='left' v-if="user.endpoint")
      .relative.flex.justify-center.items-center
        .material-icons.notranslate.w-6.h-6.rounded-full.flex.justify-center.items-center(:class="errorIconClass") &#xe88e
        .w-6.h-6.rounded-full.absolute.animate-ping.bg-red-500.z-10(v-if="!isWorking")
      template(#popper)
        device-status-info
</template>

<script>
import { Tooltip } from 'floating-vue';
import useGetDeviceRegistryMixin from '@/mixins/cstate/use-get-device-registry';
import browserNotificationMixin from '@/mixins/browser-notification';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import DeviceStatusInfo from './DeviceStatusInfo.vue';
import EventBus from '@/main-event-bus';
export default {
  mixins: [useGetDeviceRegistryMixin, browserNotificationMixin],
  components: {
    FTooltip: Tooltip,
    vButtonSecondary,
    DeviceStatusInfo,
  },
  data() {
    return {
      isUpdating: false,
      wasInactive: false,
      idleTimer: null,
      isIdle: false,
      reconnectionAttemptInterval: null,
    };
  },
  watch: {
    isWorking(value) {
      if (value) return;
      this.reconnectionAttemptInterval = setInterval(() => {
        if (this.isWorking) {
          return clearInterval(this.reconnectionAttemptInterval);
        }
        this.resetWebphone();
      }, 1000 * 30);
    },
  },
  methods: {
    async resetWebphone() {
      if (!this.isWebRTC) return;
      this.$store.commit('sip/destroyUserAgent');
      this.$store.dispatch('sip/createUserAgent', {
        username: this.user.username,
        endpoint: this.user.endpoint,
      });
    },
    async synchronize() {
      this.isUpdating = true;
      try {
        await this.resetWebphone();
        await this.getDeviceRegistry(this.user.endpoint).catch((e) => {
          this.$toast.error('Falha ao buscar registro do ramal...');
        });
      } catch (error) {
      } finally {
        this.isUpdating = false;
      }
    },
    resetIdleTimer() {
      if (this.isIdle && !this.isWorking) this.resetWebphone();
      clearTimeout(this.idleTimer);
      this.isIdle = false;
      const timeout = process.env.VUE_APP_ACTIVITY_TIMEOUT || 20;
      this.idleTimer = setTimeout(this.setIdleState, 1000 * 60 * timeout); // 20 minutos
    },
    setIdleState() {
      this.isIdle = true;

      EventBus.$emit('UX-event', { eventName: 'idle' });

      this.$mixpanel.track('idle');

      const showInactivityNotification = localStorage.getItem("@yunique:show-inactivity-notification");
      if (showInactivityNotification === 'false') return;

      this.notify('Você ainda esta por ai?');
    },
  },
  computed: {
    isWebRTC() {
      return this.configuration.transport === 'transport-wss';
    },
    configuration() {
      return this.$store.getters['sip/configuration'];
    },
    webphone() {
      return this.$store.getters['sip/agent'];
    },
    webphoneStatus() {
      return this.$store.getters['sip/webphoneStatus'];
    },
    isConnected() {
      return this.$store.getters['cstate/isConnected'];
    },
    isRegistered() {
      return this.$store.getters['cstate/IS_DEVICE_CONNECTED'];
    },
    errorIconClass() {
      if (!this.isWorking) return 'text-red-500';
      return 'text-brand';
    },
    isWorking() {
      if (this.isWebRTC) return this.isConnected && this.isTransportStateOk && this.isAgentConnected;
      return this.isRegistered;
    },
    isAgentConnected() {
      if (this.webphoneStatus.agentConnected) return true;
      return false;
    },
    agentState() {
      if (this.webphoneStatus.agentState === 'Started') return 'Inicializado';
      else return 'Ocioso';
    },
    isAgentStateOk() {
      if (this.webphoneStatus.agentState === 'Started') return true;
      return false;
    },
    transportState() {
      if (this.webphoneStatus.transportState === 'Connected') return 'Conectado';
      if (this.webphoneStatus.transportState === 'Connecting') return 'Conectando';
      if (this.webphoneStatus.transportState === 'Disconnecting') return 'Desconectando';
      if (this.webphoneStatus.transportState === 'Disconnected') return 'Desconectado';
      return 'Desconectado';
    },
    isTransportStateOk() {
      if (this.webphoneStatus.transportState === 'Connected') return true;
      return false;
    },
    transportMethod() {
      if (this.configuration.transport === 'transport-wss') return 'WebRTC';
      if (this.configuration.transport === 'transport-tls') return 'SIP';
      return 'Indefinido';
    },
  },
  mounted() {
    document.addEventListener('mousemove', this.resetIdleTimer);
    document.addEventListener('keydown', this.resetIdleTimer);
    this.resetIdleTimer();
  },
};
</script>
