<template>
  <table class="callback-table" aria-label="Dessert calories">
    <thead class="callback-thead">
      <tr>
        <th class="callback-table-starter" role="columnheader" scope="col">Tipo de atividade</th>
        <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">
          Data
        </th>
        <th class="mdc-data-table__header-cell mdc-data-table__header-cell--numeric" role="columnheader" scope="col">
          Contato
        </th>
        <th
          class="mdc-data-table__header-cell"
          role="columnheader"
          scope="col"
          v-if="calls?.length > 0 || emails?.length > 0"
        >
          Fila
        </th>
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col" v-if="chats?.length > 0">Tag</th>
        <th class="mdc-data-table__header-cell" role="columnheader" scope="col">Duração</th>
      </tr>
    </thead>
    <TransitionGroup tag="tbody" name="slide-fade" :duration="200" mode="out-in" class="mdc-data-table__content">
      <ActivityRow
        class="text-inside-brand"
        v-if="calls.length > 0"
        v-for="(call, index) in calls"
        :key="call.linkedId"
        :call="call"
        :index="index"
        @click.native="sendToDetail(call)"
      ></ActivityRow>
    </TransitionGroup>
    <TransitionGroup tag="tbody" name="slide-fade" :duration="200" mode="out-in" class="mdc-data-table__content">
      <ChatRow
        v-for="(data, index) in chats"
        v-if="chats.length > 0"
        :key="data.chatRoomId"
        :data="data"
        :index="index"
      ></ChatRow>
    </TransitionGroup>
    <TransitionGroup tag="tbody" name="slide-fade" :duration="200" mode="out-in" class="mdc-data-table__content">
      <ChatRow
        v-for="(data, index) in emails"
        v-if="emails.length > 0"
        :key="data.chatRoomId"
        :data="data"
        :index="index"
      ></ChatRow>
    </TransitionGroup>
    <callDetailsOverlay
      v-if="showCallDetailsModal"
      @close="showCallDetailsModal = false"
      :callLinkedId="callLinkedId"
    ></callDetailsOverlay>
  </table>
</template>

<script>
import ActivityRow from './Callbacks.HistoryActivityRow';
import ChatRow from './Callbacks.HistoryChatRow.vue';
import callDetailsOverlay from '@/components/Overlay/call-details.overlay.vue';

export default {
  props: ['calls', 'chats', 'emails'],
  components: {
    ActivityRow,
    ChatRow,
    callDetailsOverlay,
  },
  data() {
    return {
      showCallDetailsModal: false,
      callLinkedId: '',
    };
  },
  methods: {
    sendToDetail(call) {
      this.callLinkedId = call.linkedId;
      this.showCallDetailsModal = true;
    },
  },
};
</script>

<style>
.history-type-icon {
  display: flex;
}
.history-type-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 8px;
}
.history-table-row {
  padding: 16px;
  cursor: pointer;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  height: 80px;
}
.history-table-row:hover {
  background-color: #f8f8f8;
}
.md-table-cell-container {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
</style>
