<template lang="pug">
Onboarding
  template(v-slot:header) 
    h4.text-2xl.text-gray-400.font-extrabold Instale o Softphone
    i.material-icons.notranslate.cursor-pointer(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    p.my-8.text-gray-400 Ao iniciar o softphone Opens, a caixa de configuração da conta abrirá automaticamente ou a partir do menu de Opções &gt; "Adicionar Conta". No softphone, adicione os dados de autenticação abaixo e clique em salvar
    .divide-y.divide-gray-200
      SimpleLabelDisplay.mb-2(:label="'Servidor'", :value="server")
      SimpleLabelDisplay.mb-2(:label="'Usuário'", :value="device")
      SimpleLabelDisplay.mb-1(:label="'Senha'", :value="password")
    p.my-8.text-gray-400(v-if="!noFooter") Após concluir a configuração, avance para o próximo passo
  template(v-slot:footer v-if="!noFooter")
    .flex.justify-evenly.w-full
      button.w-32.text-brand(@click="askForHelp()" class="dark:invert") Preciso de Ajuda
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'LinkDevice')"
      ) AVANÇAR
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import SimpleLabelDisplay from "@/components/Labels/SimpleLabelDisplay.vue";
import services from "@/services";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
import OnboardingMixin from "@/mixins/onboarding.js";
export default {
  mixins: [OpenLinkMixin, OnboardingMixin],
  data() {
    return {
      password: "",
      server: "",
      device: "",
      noFooter: false,
    };
  },
  components: {
    Onboarding,
    SimpleLabelDisplay,
  },
  computed:{
    context(){
      return this.$store.getters['modal/getContext']
    }
  },
  async created() {
    try {
      const {peer, noFooter} = this.context
      this.noFooter = noFooter
      const res = await services.customerService.getDeviceConfig(peer);
      this.device = res.name;
      this.server = res.server;
      this.password = res.password || res.secret;
    } catch (e) {
    }
  },
};
</script>