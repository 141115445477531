<template lang="pug">
overlay
  .overlay-content.bg-neutral-50.h-full.ml-4.p-0(ref="overlay-content")
    .container-fluid.mt-0
      .pagearea.mt-4.overflow-auto.p-0.pl-4.pr-6
        .mb-8.flex.items-center.justify-between
          .title.mb-0 Adicionar contato
          i.cursor-pointer.material-icons.notranslate.rounded-full.transition-colors(
            v-tooltip="'Fechar'",
            class="hover:bg-neutral-100 hover:text-neutral-500"
            @click="$emit('close-overlay')"
          ) &#xe5cd
        add-contact-content(:openedByContext="openedByContext" :is-overlay="true", @close-overlay="$emit('close-overlay')")
</template>

<script>
import AddContactContent from '../Contacts/AddContactContent.vue';
import overlay from '@/components/Overlay/overlay.vue';

export default {
  title: 'Opens - Adicionar contato',
  props: {
    openedByContext: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddContactContent,
    overlay,
  },
};
</script>
