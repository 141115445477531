export default {
  SET_FORMAT(state, format) {
    state.format = format.format;
    state.formatDiff = format.formatDiff || state.formatDiff;
  },
  IS_EDITOR: (state, value) => {
    state.isEditor = value;
  },
  IS_SUPER: (state, value) => {
    state.isSuper = value;
  },
  RESET_PERFORMANCE: (state) => {
    state.sent_to_evaluation = 0;
    state.agent_hangup = 0;
    state.client_hangup = 0;
    state.event_total = 0;
    state.abandons = 0;
    state.answers = 0;
    state.timeouts = 0;
    state.tma = 0;
    state.tme = 0;
    state.unique = 0;
    state.csatSatisfaction = 0;
    state.csatScores = 0;
    state.map = {};
    state.map_week = {};
    state.map_hour = {};
    state.callbackComplete = 0;
    state.callbackIncomplete = 0;
    state.occupationRate = {};
    state.evaluated = 0;
    state.transfers = 0;
    state.sla_targets = 0;
    state.sla_target_abandon = 0;
  },
  RESET_FILTERS: (state) => {
    state.filter_received_answered = false;
    state.filter_received_not_answered = false;
    state.filter_made_answered = false;
    state.filter_made_not_answered = false;
    state.filter_answered = false;
    state.filter_timeout = false;
    state.filter_agent_hangup = false;
    state.filter_client_hangup = false;
    state.filter_transferred = false;
    state.filter_abandoned = false;
    state.filter_sent_to_evaluation = false;
    state.filter_protocol = false;
    state.filter_tags = [];
    state.filter_csat = {
      0: true,
      1: true,
      2: true,
      3: true,
      4: true,
      5: true,
    };
    state.filter_number = "";
    state.filter_contact = [];
    state.filter_category = [];
    state.filter_tma_range = null;
    state.filter_tme_range = null;
  },
  SET_PERFORMANCE: (state, payload) => {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key];
    }
  },
  SET_CALLS(state, payload) {
    state.calls = payload.calls || state.calls;
    state.callbacks = payload.callbacks || state.callbacks;
    state.calls_list = payload.calls_list || state.calls_list;
  },
  SET_QUEUES: (state, payload) => {
    state.queues = payload;
  },
  SET_QUEUE_SELECT: (state, { queue, value }) => {
    state.queues[queue] = value;
  },
  SET_PERIOD: (state, payload) => {
    state.start_time = payload.start;
    state.end_time = payload.end;
  },
  SET_SELECTED_QUEUES: (state, payload) => {
    state.selectedQueues = payload;
  },
  SET_PROGRESS: (state, payload) => {
    state.inProgress = payload.inProgress;
    state.progress = payload.progress;
  },
  SET_CONFIG: (state, payload) => {
    state.config = payload;
  },
  SET_FILTERS: (state, payload) => {
    for (const key of Object.keys(payload)) {
      state[key] = payload[key];
    }
  },
  SET_WORK_GROUPS: (state, workGroups) => {
    state.user.workGroups = workGroups;
  },
  SET_USERS: (state, users) => {
    state.user.users = users;
  },
  SET_SELECTED_USERS: (state, users) => {
    const userSet = new Set();
    users.forEach((u) => userSet.add(u));
    state.user.selectedUsers = userSet;
  },
  SET_USER_PERFORMANCE: (state, payload) => {
    state.user.performance = payload;
  },
  SET_USER_AVAILABILITY_LIST: (state, payload) => {
    state.user.availabilityList = payload;
  },
  RESET_SET_USER_AVAILABILITY_LIST: (state) => {
    state.user.availabilityList = {};
  },
  RESET_USER_PERFORMANCE: (state) => {
    state.user = {
      workGroups: [],
      users: [],
      selectedUsers: new Set(),
      performance: {
        uniqueDevices: {},
        uniqueDevicesSent: {},
        uniqueDevicesRec: {},
      },
      availabilityList: {},
    };
    state.calls = [];
    state.calls_list = [];
    state.callbacks = [];
  },
};
