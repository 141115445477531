<template lang="pug">
.min-w-200.bg-zinc-50.px-12.overflow-auto(
  class="w-3/4 shadow-[15px_5px_40px_-10px_rgba(0,0,0,0.2)]"
) 
  .w-full.h-12.pt-8.flex.justify-between.items-center.mb-2
    h2.text-lg.font-extrabold.text-zinc-700.flex.justify-start.items-center Atividades Pendentes
    .flex
      span.material-icons.notranslate.text-zinc-400.text-2xl.cursor-pointer(@click="close") &#xe5cd
  .overflow-auto(class="max-h-[75%]")
    table.mt-4.w-full.table-fixed.divide-y
      thead.callback-thead
        tr
          th.callback-table-starter.flex.justify-start.gap-2(
            role="columnheader",
            scope="col"
          ) Origem
          th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric.align-middle(
            role="columnheader",
            scope="col"
          ) Observações
          th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric.truncate(
            role="columnheader",
            scope="col"
          ) Adicionado
          th.mdc-data-table__header-cell.truncate(role="columnheader", scope="col") Ações
      tr.h-20(v-for="item in callbacks", :key="item.id")
        td.pl-4 
          .flex.justify-start
            .flex.flex-col.justify-start.items-start
              p.text-xs.text-zinc-400 Criado por
              p {{ colleague(item.assignedBy) }}
        td(v-if="item.note") {{ item.note }}
        td(v-else-if="item.type.toLowerCase() == 'abandon'") {{ `Chamada abandonada na fila ${item.description}` }}
        td.text-sm.text-zinc-400(v-else) Sem nenhuma observação
        td {{ $moment(item.createdAt).fromNow() }}
        td
          .flex.justify-start.items-center
            button.text-xl.w-8.h-8.bg-emerald-400.text-white.rounded-full.material-icons.notranslate.mr-2.flex.justify-center.items-center(
              @click="openCompleteCallbackModal(item)"
              class="hover:brightness-95"
            ) &#xe876
            button.text-xl.w-8.h-8.bg-zinc-400.text-white.rounded-full.material-icons.notranslate.flex.justify-center.items-center(
              @click="openCancelCallbackModal(item)"
              class="hover:brightness-90"
            ) &#xe5cd
</template>

<script>
import { getAllContactCallbacks } from "@/services/cblist.js";
export default {
  data() {
    return {
      callbacks: [],
    };
  },
  methods: {
    colleague(id) {
      const colleague = this.$store.getters["COLLEAGUE_LOOKUP_TABLE"](id);
      if (!colleague) return "-";
      return colleague.username;
    },
    close() {
      this.$store.commit("overlay/setActiveOverlay", null);
    },
    openCompleteCallbackModal(callback) {
      this.$store.commit("modal/setActiveModal", {
        name: "CompleteCallback",
        context: {
          callback: callback,
          onSuccess: async () => {
            this.callbacks = await getAllContactCallbacks({
              companyId: this.user.company_id,
              contactNumber: this.context,
              assignedTo: this.user.id,
              status: 101,
            });
          },
        },
      });
    },
    openCancelCallbackModal(callback) {
      this.$store.commit("modal/setActiveModal", {
        name: "CancelCallback",
        context: {
          callback: callback,
          onSuccess: async () => {
            this.callbacks = await getAllContactCallbacks({
              companyId: this.user.company_id,
              contactNumber: this.context,
              assignedTo: this.user.id,
              status: 101,
            });
          },
        },
      });
    },
  },
  computed: {
    context() {
      return this.$store.getters["overlay/getContext"];
    },
  },
  async created() {
    const locale = window.navigator.userLanguage || window.navigator.language;
    this.$moment.locale(locale);
    if (!this.context) return;
    this.callbacks = await getAllContactCallbacks({
      companyId: this.user.company_id,
      contactNumber: this.context.replace(/\D/g,''),
      assignedTo: this.user.id,
      status: 101,
    });
  },
};
</script>
