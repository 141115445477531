<template>
  <div ref="overlay-content" class="overlay-content bg-neutral-50">
    <div class="container-fluid">
      <div class="overlay-pagearea">
        <div style="width: 100%; display: flex; justify-content: center">
          <InvalidInfo v-if="this.$store.getters['GET_MODAL'] === 'InvalidInfo'"></InvalidInfo>
        </div>
        <div style="align-items: flex-end; margin-bottom: 32px" class="header">
          <div class="incall-left-aligner" style="flex-direction: column; display: flex">
            <div class="overlay-title-text">Histórico de atividades do contato</div>
          </div>
          <div style="width: 44%" class="incall-righzt-aligner">
            <span @click="closeOverlay" class="close-overlay-icon notranslate material-icons">close</span>
          </div>
        </div>
        <div class="calls-content">
          <ContactHistoryBox
            v-for="(data, index) in callsHistory"
            :key="index"
            :call="data._source"
          ></ContactHistoryBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getContactByPhone, getContactById } from '@/services/contact-list.js';
import { getCallsByContact } from '@/services/call-report';
import { getAllChatsOfMember } from '@/services/chat';
import InvalidInfo from '@/components/Modals/InvalidInfo.vue';
import OrganizationInput from '@/pages/Contacts/Contact.DropdownInput';
import CategoryInput from '@/pages/Contacts/Category.DropdownInput';
import PhoneInput from '@/pages/Contacts/Contact.PhoneInput';
import VuePhoneNumberInput from 'vue-phone-number-input';
import ContactPhoneInput from '@/pages/Contacts/Contact.PhoneInput.vue';
import ContactHistoryBox from '@/components/ContactHistoryComponent';
import '../../assets/css/vue-phone-number-input.css';

export default {
  components: {
    OrganizationInput,
    CategoryInput,
    VuePhoneNumberInput,
    InvalidInfo,
    PhoneInput,
    ContactPhoneInput,
    ContactHistoryBox,
  },
  data() {
    return {
      callsHistory: [],
    };
  },
  computed: {
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
  },
  methods: {
    closeOverlay() {
      let overlay = this.$refs['overlay-content'];
      overlay.style.opacity = 0;
      setTimeout(() => this.$emit('close-overlay'), 300);
    },
    async getTags(events) {
      if (!events.length) return;
      let newEvents = events;
      const eventsJoin = events.map((event) => (event.linkedid ? event.linkedid : event.chatRoomId)).join(',');
      try {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/event/${
            events[0].company_id ? events[0].company_id : events[0].companyId
          }/calltags/${eventsJoin}`,
        );
        for (let j = 0; j < newEvents.length; j++) {
          if (res.data[newEvents[j].linkedid]) {
            newEvents[j].tags = res.data[newEvents[j].linkedid];
          }
        }
        return newEvents;
      } catch (e) {
        console.error(e);
      }
    },
    async getCallbacks(events) {
      if (!events.length) return;
      let newEvents = events;
      const eventsJoin = events.map((event) => (event.linkedid ? event.linkedid : event.chatRoomId)).join(',');
      try {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/event/${
            events[0].company_id ? events[0].company_id : events[0].companyId
          }/callback/${eventsJoin}`,
        );
        for (let j = 0; j < newEvents.length; j++) {
          if (res.data[newEvents[j].linkedid]) {
            newEvents[j].callbacks = res.data[newEvents[j].linkedid];
          }
        }
        return newEvents;
      } catch (e) {
        console.error(e);
      }
    },
  },
  async created() {
    try {
      const contact = await getContactById(this.$route.params.id);
      this.userData = contact;

      const resCalls = await getCallsByContact({
        contactId: contact.id,
        limit: 5,
        offset: 0,
      });
      const resChats = await getAllChatsOfMember({
        recipientId: contact.id,
        companyId: this.user.company_id,
      });

      const chats = resChats.map((chat) => {
        const newChat = chat;
        newChat.componentType = 'chat';
        return newChat;
      });
      const calls = resCalls.map((call) => {
        const newCall = call._source;
        newCall.createdAt = newCall.calldate;
        newCall.componentType = 'call';
        return newCall;
      });
      const activities = calls.concat(chats);

      this.activities = (await this.getTags(activities)) || [];
      this.activities = (await this.getCallbacks(this.activities)) || [];
      this.activities.sort(function (a, b) {
        if (a && b) {
          return a.createdAt < b.createdAt ? 1 : a.createdAt > b.createdAt ? -1 : 0;
        }
      });
    } catch (e) {
      this.$log.error(e);
    }
  },
  async created() {
    if (!this.call) return;
    const numberToSearch = this.call.from == this.user.endpoint ? this.call.to : this.call.from;
    try {
      let callreportRequest = '';
      // é preciso consultar o Contact-List antes por que as vezes a fila registra um numero de forma diferente
      // dai essa consulta diz se o numero é reconhecido ou não e assim a consulta de histório correta é feita
      await getContactByPhone({
        number: numberToSearch,
        companyId: this.user.company_id,
      })
        .then((contact) => {
          callreportRequest = `${this.requestLinks.callreport}/call/u/${contact.data.id}/10/0`;
        })
        .catch((e) => {
          callreportRequest = `${this.requestLinks.callreport}/call/d/${numberToSearch}/10/0`;
        });
      // consulta de histórico no callreport
      const res = await this.$http.get(callreportRequest);
      this.callsHistory = await this.getTags(res.data);
      this.callsHistory = await this.getCallbacks(this.callsHistory);
      this.callsHistory.sort(function (a, b) {
        if (a._source && b._source) {
          return a._source.calldate < b._source.calldate ? 1 : a._source.calldate > b._source.calldate ? -1 : 0;
        }
      });
    } catch (e) {
      this.$log.info(e);
    }
  },
};
</script>
<style>
.calls-content {
  max-height: 90%;
  overflow-y: auto;
}
.history-left-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
}
.history-right-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 25%;
}
.expand-activity-icon {
  color: #adb5bd;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
}
.expand-activity-icon:hover {
  color: var(--brand-color);
}
.history-center-info {
  display: flex;
  width: 45%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.history-info-side {
  width: 95%;
  display: flex;
  align-items: center;
}
.history-icon-side {
  width: 3%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.history-type-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-left: 10px;
  transition: 0.3s;
  color: #495057;
}
.contact-history-box {
  width: 100%;
  height: 56px;
  padding-left: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}
.history-call-indicator-icon {
  font-size: 15px !important;
}
.history-track-icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  min-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
</style>
