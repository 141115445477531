<template>
  <div class="page-content">
    <link
      href="https://fonts.googleapis.com/css2?family=Inter&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap"
      rel="stylesheet"
    />
    <div class="step-header">
      <div class="step-title">Vincular Device SIP</div>
      <div class="step-counter-passed"></div>
      <div class="step-counter-passed"></div>
    </div>
    <div class="line-separator"></div>
    <div class="center-aligner">
      <div class="confirm-circle">
        <div class="confirm-inside-circle">
          <i class="check-icon fa fa-check fa-2x"></i>
        </div>
      </div>
    </div>
    <div class="center-aligner">
      <div class="success-text">Seu device foi vinculado com sucesso!</div>
    </div>
    <div class="cancel-button-aligner">
      <button @click="close" class="buttonsave">CONCLUIR</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    
  },
};
</script>

<style>
.success-text {
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 32px;
  width: 414px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #343a40;
  line-height: 130%;
}
.check-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  background: none;
  color: #fff;
  font-size: 16px !important;
  box-shadow: none;
}
.confirm-circle {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--brand-color-op-1);
}
.confirm-inside-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background: var(--brand-color);
}
</style>
