<template lang="pug">
.w-full
    //- Loading esqueleto
    .flex.w-80.h-10.animate-pulse.items-center.select-none.px-4.rounded.justify-center.h-12.gap-2(
      v-if="loadingAttachment", class="dark:bg-zinc-700"
    )
      i.material-icons.notranslate.text-gray-400.bg-gray-300.rounded-full play_arrow
      .flex.bg-gray-300.h-2.grow.rounded-full
      .bg-gray-300.w-6.h-6.rounded-full.items-center.justify-center.flex.text-xs 2x
      i.material-icons.notranslate.text-gray-400.bg-gray-300.rounded-full volume_off

    //- Audio
    .flex(v-else-if="audioUrl")
      audio-player.w-80(:source="audioUrl", :bg="'bg-transparent'")

    //- Button to load audio
    .flex.w-max.items-center.select-none.rounded.justify-center.h-12.gap-2.cursor-pointer(
      v-else-if="!audioUrl", class="dark:bg-zinc-700"
      @click="loadAudio"
    )
      i.material-icons.notranslate.text-md.text-zinc-600.bg-white.rounded-full.w-8.h-8.items-center.flex.transition-all(
        v-tooltip="'Clique para carregar o audio'"
        class='hover:scale-105 active:scale-95 ease-in-out'
      ) download
      .flex.flex-col.gap-1.w-60.items-start
        .flex.bg-gray-300.h-2.grow.w-full.rounded-full
        //- Error
        .flex.items-center.justify-center.gap-1(v-if="attachment?.error")
          .material-icons.notranslate.text-red-400.text-base error
          p.font-semibold.text-xs.mix-blend-difference.font-plus(v-tooltip="attachment?.error") Erro ao carregar, tente novamente
      .bg-gray-300.w-6.h-6.rounded-full.items-center.justify-center.flex.text-xs 2x
      i.material-icons.notranslate.text-gray-400.bg-gray-300.rounded-full volume_off
</template>

<script>
import AudioPlayer from '@/components/Player/audio-player.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
import { getAttachmentById } from '@/services/records';

export default {
  components: {
    FDropdown: Dropdown,
    DropdownItem,
    AudioPlayer,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      audioUrl: null,
      loadingAttachment: false,
    };
  },
  methods: {
    async loadAudio() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.provider);
        this.audioUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar áudio';
      } finally {
        this.loadingAttachment = false;
      }
    },
  },
};
</script>
