<template lang="pug">
.flex.flex-col.gap-2.items-start.justify-center
  //- Loading
  .flex.w-72.h-72.bg-gray-300.rounded-md.animate-pulse(v-if="loadingAttachment", class='dark:bg-gray-700')
    i.material-icons.notranslate.text-6xl.text-zinc-400.opacity-50 movie

  .flex.w-72.h-72.bg-gray-300.rounded-md.flex-col.items-center.justify-center.relative( v-else-if="!videoUrl", class='dark:bg-gray-700')
    //- Error
    .flex.py-2.gap-2.absolute.top-0.left-2.items-center.justify-center.cursor-help(v-if="attachment?.error")
      .material-icons.notranslate.text-red-400 error
      p.font-semibold.text-xs.font-plus(v-tooltip="attachment?.error") Erro ao carregar vídeo, tente novamente

    //- //- Button to load video
    i.material-icons.notranslate.text-9xl.text-zinc-400.opacity-50.absolute.blur-sm movie
    .flex.flex-col.items-center.gap-2.justify-center.z-10.cursor-pointer
      i.material-icons.notranslate.text-4xl.text-zinc-600.bg-white.rounded-full.w-16.h-16.items-center.flex.transition-all(
        @click="loadVideo"
        v-tooltip="'Clique para carregar o vídeo'"
        class='hover:scale-105 active:scale-95 ease-in-out'
      ) download
      p.font-plus(@click="loadVideo") Clique aqui para ver o video

  //- Success
  .flex.justify-center.items-start.relative.group(class="dark:invert", v-else)
    video.aspect-video.rounded-sm.w-96(
      ref="video"
      :src="videoUrl"
      controls
      controlslist="nodownload"
    )
</template>

<script>
import { getAttachmentById } from '@/services/records';
import { defineComponent } from 'vue';
import ButtonPrimary from '@/components/Buttons/v-button.vue';

export default defineComponent({
  name: 'attachment-video',

  data() {
    return {
      videoUrl: null,
      loadingAttachment: false,
    };
  },

  components: {
    ButtonPrimary,
  },

  props: {
    attachment: {
      type: Object,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  methods: {
    async loadVideo() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.provider);
        this.videoUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar video';
      } finally {
        this.loadingAttachment = false;
      }
    },
  },

  mounted() {
    const userAudioVolume = localStorage.getItem('audioVolume');
    this.$nextTick(() => {
      if (!this.$refs?.video) return;
      this.$refs.video.volume = userAudioVolume || 0.5;
    });
  },
});
</script>
