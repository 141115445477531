import { getUserById, getContactFromCservice } from '../services/cservice';
export default {
  namespaced: true,
  state: {
    usersById: {},
    usersByEndpoint: {},
  },
  mutations: {
    setUserById(state, payload) {
      const userObj = { [payload.id]: payload, ...state.usersById };
      state.usersById = Object.assign({}, userObj);
    },
    setUserByEndpoint(state, payload) {
      const userObj = { [payload?.device]: payload, ...state.usersByEndpoint };
      state.usersByEndpoint = Object.assign({}, userObj);
    },
  },
  getters: {
    userById(state) {
      return (id) => state.usersById[id];
    },
    userByEndpoint(state) {
      return (endpoint) => state.usersByEndpoint[endpoint];
    },
  },
  actions: {
    async fetchUserById(context, userId) {
      try {
        const user = await getUserById(userId);
        context.commit('setUserById', user);
        return user;
      } catch (error) {}
    },

    async fetchUserByEndpoint(context, { peer, company_id }) {
      try {
        const user = await getContactFromCservice({ peer, company_id });
        context.commit('setUserByEndpoint', user);
        return user;
      } catch (error) {}
    },
  },
};
