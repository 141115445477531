<template>
  <div class="modal-aligner">
    <div :ref="'modalActivityId'" style="display:flex;" class="activity-modal">
      <div class="modal-left-aligner">
        <div
          style="margin-left: 16px; margin-right: 20px"
          class="modal-icon"
        >
          <span id="modalIcon" style="font-size: 26px" class="material-icons notranslate"
            >check</span
          >
        </div>
        <a id="modalTextId" class="activity-modal-text"
          >{{modalText}}</a
        >
      </div>
      <div class="modal-right-aligner">
        <span
          style="font-size: 26px; color: #adb5bd; margin-right: 14px"
          class="material-icons notranslate"
          >close</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default{
  props: [
    "modalText",
  ],
  methods:{
    fadeOut(){
      this.$refs['modalActivityId'].style.opacity = 0;
      setTimeout(this.resetActivity, 800)
    },
    resetActivity(){
      this.$refs['modalActivityId'].style.display = 'none';
      this.$refs['modalActivityId'].style.opacity = 1;
      this.$store.commit("SET_MODAL", {name: null})
    },
  },
  created(){
    setTimeout(this.fadeOut,1500);
  }
}
</script>
<style>
</style>
