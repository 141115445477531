<template>
  <Overlay>
    <template v-slot:content>
      <div class="movidesk-modal movidesk-modal--center">
        <div class="movidesk-modal__header">
          <slot name="header"></slot>
        </div>
        <div class="movidesk-modal__content">
          <slot name="content"></slot>
        </div>
        <div class="movidesk-modal__footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </template>
  </Overlay>
</template>


<script>
import Overlay from "@/components/Modals/Overlay.vue";
export default {
  components: { Overlay },
};
</script>

<style lang="scss" scoped>
.movidesk-modal {
  padding: 10px;
  width: 55rem;
  height: 45rem;
  background-color: #fafafa;
  font-family: "Inter", sans-serif;
  &--center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 15%;
    width: 90%;
    border-bottom: 1px solid rgb(218, 218, 218);
  }

  &__content {
    height: 85%;
    width: 90%;
    margin-bottom: 20px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10%;
    width: 90%;
  }
}
</style>

