export default {
  calls: [],
  calls_list: [],
  callbacks: [],
  format: "DD/MM/yyyy",
  formatDiff: "days",
  isEditor: false,
  isSuper: false,
  queues: [],
  occupationRate: {},
  callbackIncomplete: 0,
  evaluated: 0,
  callbackComplete: 0,
  event_total: 0,
  unique: 0,
  abandons: 0,
  answers: 0,
  timeouts: 0,
  tma: null,
  tme: null,
  sla_target_tme: 0,
  sla_target_tma: 0,
  sla_target_abandon: 0,
  sent_to_evaluation: 0,
  agent_hangup: 0,
  client_hangup: 0,
  csatSatisfaction: 0,
  transfers: 0,
  csatScores: 0,
  selectedQueues: [],
  start_time: null,
  end_time: null,
  inProgress: false,
  progress: 0,
  map: {},
  map_week: {},
  map_hour: {},
  filter_received_answered: false,
  filter_received_not_answered: false,
  filter_made_answered: false,
  filter_made_not_answered: false,
  filter_answered: false,
  filter_timeout: false,
  filter_agent_hangup: false,
  filter_client_hangup: false,
  filter_transferred: false,
  filter_abandoned: false,
  filter_tme_range: null,
  filter_tma_range: null,
  filter_sent_to_evaluation: false,
  filter_protocol: false,
  filter_tags: [],
  filter_csat: {
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
  },
  filter_number: "",
  filter_contact: [],
  filter_category: [],
  config: {
    target_tme: 0,
    target_tme_checked: 1,
    target_tma: 0,
    target_tma_checked: 1,
    target_abandon: 0,
    target_csat: 1,
    target_sla: 10,
    target_abandon_remove: 10,
    target_abandon_remove_checked: 1,
  },
  user: {
    workGroups: [],
    users: [],
    selectedUsers: new Set(),
    performance: {
      uniqueDevices: {},
      uniqueDevicesSent: {},
      uniqueDevicesRec: {},
    },
    availabilityList: {},
  },
};
