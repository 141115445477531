<template lang="pug">
table.callback-table(aria-label="Cases")
  thead.callback-thead
    tr
      th.callback-table-starter.flex.justify-start.gap-2(
        role="columnheader",
        scope="col"
      ) Título
      th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric.align-middle(
        role="columnheader",
        scope="col"
      ) Cliente
      th.mdc-data-table__header-cell.mdc-data-table__header-cell--numeric.truncate(
        role="columnheader",
        scope="col"
      ) Responsável
      th.mdc-data-table__header-cell.truncate(role="columnheader", scope="col") Data de abertura
      th.mdc-data-table__header-cell(role="columnheader", scope="col") Status
      th.mdc-data-table__header-cell(role="columnheader", scope="col") Ações
  TransitionGroup(tag="tbody" name="slide-fade" :duration="200" mode="out-in")
    CaseRow(
      v-for="(data, index) in cases",
      v-bind:key="data.id + key",
      @click="$store.commit('overlay/setActiveOverlay', { name: 'DetailCase', context: data.id })",
      @openEditOverlay="$store.commit('overlay/setActiveOverlay', { name: 'EditCase', context: data.id })",
      :caso="data"
    )
</template>

<script>
import CaseRow from "@/pages/Cases/Cases.CaseRow.vue";
export default {
  props: ["cases"],
  components: {
    CaseRow,
  },
  data() {
    return {
      key: 1,
      createdAtOrder: false,
      responsibleOrder: false,
      clientOrder: false,
      titleOrder: false,
    };
  },
  methods: {
    sortByAlphabet(key, key2) {
      this[`${key}Order`] = !this[`${key}Order`];
      const order = this[`${key}Order`];
      if (key2) {
        this.cases = this.cases.sort((a, b) =>
          order ? a[key][key2].localeCompare(b[key][key2]) : b[key][key2].localeCompare(a[key][key2])
        );
        this.key++;
        return;
      }
      this.cases = this.cases.sort((a, b) => order ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]));
      this.key++;
    },
    sortByDate(key) {
      this[`${key}Order`] = !this[`${key}Order`];
      const order = this[`${key}Order`];
      this.cases.sort(function (a, b) {
        return order ? new Date(b[key]) - new Date(a[key]) : new Date(a[key]) - new Date(b[key]);
      });
      this.key++;
    },
  },
};
</script>

<style>
</style>