<template lang="pug">
div.contents
  tr.w-full.border-b.h-20.font-plus(v-if="loaded")
    td.align-middle.pl-6
      .flex.items-center.justify-start.gap-2
        img.w-8.mr-2(v-if="chat?.provider === 'whatsapp'" src="@/assets/images/whatsapplogo.png")
        img.w-8.mr-4(v-else-if="chat?.provider === 'yunique'" src="@/assets/images/opens-app-logo.png")
        img.w-6.mr-4(v-else-if="chat?.provider === 'outlook'" src="@/assets/images/outlooklogo.png")
        img.w-6.mr-4(v-else-if="chat?.provider === 'gmail'" src="@/assets/images/provider-gmail-logo.png")
        img.w-8.mr-2(v-else-if="chat?.provider === 'messenger'" src="@/assets/images/messenger-logo.png")
        img.w-6.mr-4(v-else-if="chat?.provider === 'direct'" src="@/assets/images/direct-logo.png")

        i.text-white.rounded-full.cursor-default.material-icons.notranslate.text-base.w-6.h-6.grid.place-items-center(
          :class="bgClass",
          class="dark:text-black"
        ) chat
        span.font-inter.text-zinc-700 {{ textForCallType[chat.room.status] }}
    td.align-middle
      span.font-inter.text-zinc-700 {{ $moment(chat.createdAt).format('DD/MM/yyyy - HH:mm') }}
    td.align-middle
      .flex.gap-2.justify-start
        .flex.w-10.h-10.bg-brand-50.rounded(v-if="failedImg") 
          .firstLetter {{ identityContact?.name[0]?.toUpperCase() || 'D' }}
        img.w-10.h-10.rounded(
          :src="identityContact?.avatarUrl"
          v-if="!failedImg"
          @error="failedImg = true"
        )
        .flex.flex-col.justify-start.items-start
          p.font-semibold.font-inter.text-zinc-700 {{ identityContact?.name }}
          p.text-zinc-500(v-if="identityContact?.organization") {{ identityContact.organization?.name }}
          p.text-zinc-500(v-else-if="identityContact?.number?.replace(/\D/g, '')") {{ identityContact?.number?.replace(/\D/g, '') }}
          p.text-zinc-500(v-else-if="identityContact?.emails") {{ identityContact.emails[0].address }}
    td.align-middle
      .w-fit
        .tag {{ chat.room.tags && chat.room.tags.length ? chat.room.tags[0].tagName : 'Sem Tag' }}
        .aditional-tag-margin.tag(
          v-if="chat.room.tags && chat.room.tags?.length > 1",
          @mouseover="showAllTags",
          @mouseleave="hideAllTags"
        ) +{{ chat.room.tags?.length - 1 }}
      .tags-component-aligner
        .tags-component-content(
          v-if="chat.room.tags?.length",
          ref="tags-component"
        )
          p.tags-text Tags
          .tags-box
            .tag.multiple-tags-margin(
              v-for="(data, index) in chat.room.tags",
              :key="index"
            )
              | {{ data.tagName }}
    td.align-middle
      span.font-inter.text-zinc-700 {{ $moment.utc(chat.room.attendanceTime * 1000).format('HH:mm:ss') }}
    td.align-middle(:width="80", v-if="!hideExpand")
      i.material-icons.notranslate.text-zinc-400.cursor-pointer(
          v-tooltip="'Expandir atividade'"
          class="hover:text-zinc-600"
          @click="isOpened = !isOpened",
          :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'"
        ) keyboard_arrow_down
      i.material-icons.notranslate.text-zinc-400.cursor-pointer.ml-2(
          v-tooltip="'Vincular a um caso'"
          class="hover:text-zinc-600"
          @click="openCaseModal"
        ) assignment
  tr(v-if="isOpened && loaded")
    chat-event-detail(:chat-data="chat" :openedByCallback="true")
  tr.w-full.h-20(v-if="!loaded")
    td.align-middle.px-4(v-for="n in 6" :key="n")
      content-placeholders(:rounded="true")
        content-placeholders-img.w-full.h-14
</template>

<script>
import ChatEventDetail from '@/components/ChatEventDetail.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  mixins: [UserOrContactMixin],
  props: ['chat', 'hide-expand'],
  components: {
    ChatEventDetail,
  },
  data() {
    return {
      loaded: false,
      textForCallType: {
        terminated: 'Conversa finalizada pelo operador',
        expired: 'Conversa finalizada pelo sistema',
        established: 'Conversa em andamento',
        routing: 'Conversa em espera',
        pending: 'Conversa pendente',
        completed: 'Conversa finalizada',
      },
      hideTagsTimeout: null,
      showTagsTimeout: null,
      failedImg: false,
      isOpened: false,
    };
  },
  computed: {
    bgClass() {
      const colors = {
        terminated: 'bg-green-400 dark:invert',
        expired: 'bg-red-400 dark:invert',
        established: 'bg-yellow-400 dark:invert',
        routing: 'bg-yellow-400 dark:invert',
        pending: 'bg-yellow-400 dark:invert',
        completed: 'bg-green-400 dark:invert',
      };
      return colors[this.chat.room.status];
    },
  },
  methods: {
    openCaseModal() {
      this.$store.commit('modal/setActiveModal', {
        name: 'linkCase',
        context: { contact: this.chat.origin, type: 'chat', callId: this.chat.room.id },
      });
    },
    showAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.hideTagsTimeout);
      this.$refs['tags-component'].style.display = 'flex';
      this.showTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.opacity = 1), 1);
    },
    hideAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.showTagsTimeout);
      this.$refs['tags-component'].style.opacity = 0;
      this.hideTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.display = 'none'), 300);
    },
    async getContact() {
      await this.setContactOrOperator({
        id: this.chat.room.createdBy,
        number: null,
      });
    },
  },

  async created() {
    try {
      await this.getContact();
    } catch (error) {
    } finally {
      this.loaded = true;
    }
  },
};
</script>
