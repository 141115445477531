<template lang="pug">
.w-72.overflow-hidden(class="max-w-[280px]")
  unknown-mate-card(
    v-if="!loading && isInputOnlyNumbers",
    :inputValue="search",
    ref="unknown-contact"
  )
  .divide-y
    dialpad-contacts-card(
      v-if="!loading && !!contacts.length",
      v-for="(contact, index) in contacts",
      :key="index",
      :contact="contact"
    )
    .divide-y.relative.py-3.px-4.h-24.w-full.skeleton-card.mb-2(
      v-if="loading",
      v-for="i in 5",
      :key="i + 'loading'"
    )

  .flex.flex-col.w-full.gap-2.relative(v-if="paginationOptions.hasNextPage && !loading")
    observer.w-full.h-48.absolute(
      v-if="!loading",
      key="observer-search-contacts",
      @intersect="intersectedContactsObserver"
    )
    .divide-y.divide-y-2.relative.py-3.px-4.h-24.skeleton-card.w-full(
      v-if="!loading",
      v-for="i in ['observer-loading-1', 'observer-loading-2']",
      :key="i"
    )
</template>

<script>
import { getContactsByIdentifier } from '@/services/contact-list.js';
import { debounce } from '@/helpers';
import dialpadContactsCard from '@/components/Cards/dialpad-contacts-card.vue';
import Observer from '@/components/Observer.vue';
import UnknownMateCard from '@/components/Cards/UnknownMateCard.vue';

export default {
  components: {
    dialpadContactsCard,
    Observer,
    UnknownMateCard,
  },

  props: {
    search: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      contacts: [],
      total: 0,
      loading: false,
      paginationOptions: {
        limit: 8,
        offset: 0,
      },
    };
  },

  watch: {
    search: {
      handler: async function (val) {
        this.loading = true;
        this.contacts = [];
        this.getContacts(val);
      },
      immediate: true,
    },
  },

  computed: {
    mates() {
      return this.$store.getters['GET_COMPANYMATES'];
    },
    isInputOnlyNumbers() {
      return /^\d+$/.test(this.search);
    },
  },

  async created() {
    try {
      this.loading = true;
      this.getContacts(this.search);
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    intersectedContactsObserver() {
      this.paginationOptions.offset += this.paginationOptions.limit;
      this.getContacts(this.search, this.paginationOptions);
    },
    getContacts: debounce(async function (filterString, { limit = 8, offset = 0 } = {}) {
      try {
        const { contacts, hasNextPage, hasPreviousPage, totalItems, total_count } = await getContactsByIdentifier({
          filter: filterString,
          limit,
          offset,
        });

        const filteredMates = this?.mates
          ?.filter((mate) => {
            return (
              mate?.username?.toLowerCase()?.includes(filterString?.toLowerCase()) ||
              mate?.endpoint?.toLowerCase()?.includes(filterString?.toLowerCase())
            );
          })
          ?.map((mate) => {
            return {
              name: mate?.username,
              phones: [{ number: mate?.endpoint }],
              isMate: true,
            };
          });

        const concatenatedContacts = [...(filteredMates || []), ...(contacts || [])];
        this.contacts = [...this.contacts, ...concatenatedContacts];
        this.paginationOptions = {
          ...this.paginationOptions,
          hasNextPage,
          hasPreviousPage,
          totalItems,
          total_count,
        };
      } catch (error) {
        console.error(error);
        this.$toast.error('Erro ao buscar contatos');
      } finally {
        this.loading = false;
      }
    }, 500),
  },
};
</script>
