export default {
  namespaced: true,
  state: {
    audioVolume: parseFloat(localStorage.getItem('audioVolume')) || 0.5,
  },
  mutations: {
    setAudioVolume(state, volume) {
      localStorage.setItem('audioVolume', volume)
      state.audioVolume = volume;
    },
  },
  getters: {
    getAudioVolume(state) {
      return state.audioVolume;
    },
  },
};
