<template lang="pug">
div.font-plus(:class="{'w-full flex space-x-4': variant === 'horizontal'}")
  ul.flex.items-center(
    class="border-bottom w-full overflow-auto list-none",
    :class="{'flex justify-end items-end': variant === 'vertical'}"
  )
    li.transition-all.px-4.py-2(
      v-for="(tab, index) in tabList",
      :class="{'border-bottom border-b-2 border-brand-500': index + 1 === activeTab,'text-white': index + 1 !== activeTab}"
    )
      label.transition-all.p-0.m-0(:for="`${_uid}${index}`" v-text="tab" class="block cursor-pointer" :class="{'text-brand-500 font-bold': index + 1 === activeTab}")
      input(:id="`${_uid}${index}`" type="radio" :name="`${_uid}-tab`" :value="index + 1" v-model="activeTab" class="hidden")

  template(v-for="(tab, index) in tabList")
    div.w-full.h-full(:key="index" v-if="index + 1 === activeTab")
      slot(:name="`tabPanel-${index + 1}`")
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: () => 'vertical',
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
  },
  data() {
    return {
      activeTab: 1,
    };
  },

  watch: {
    activeTab() {
      this.$emit('tabChanged', this.activeTab);
    },
  },
};
</script>
