<template lang="pug">
Onboarding
  template(v-slot:header) 
    h4.text-2xl.text-gray-400.font-extrabold Instale o Softphone
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd

  template(v-slot:content)
    p.text-gray-400.my-6 Antes avançar, existem algumas configuração adicionais que precisam ser realizadas no seu softphone.
    iframe.my-6.h-60(
      width="450",
      src="https://www.youtube.com/embed/j0QyjeQd404",
      title="YouTube video player",
      frameborder="0",
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen
    )
    p.text-gray-400.my-6.text-sm Após concluir as configurações, avance para o próximo passo.

  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-32.text-brand(@click="askForHelp()" class="dark:invert") Preciso de Ajuda
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'LinkDevice')"
      ) AVANÇAR
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
import OnboardingMixin from "@/mixins/onboarding.js";
export default {
  mixins: [OpenLinkMixin, OnboardingMixin],
  components: {
    Onboarding,
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>