<template lang="pug">
.flex.flex-col.justify-start.items-start.w-full.w-full.gap-4.py-4
  .flex.flex-col.gap-1.w-full.items-start
    span.font-inter.text-sm.text-gray-400.font-medium {{ 'Escolha um template de mensagem' + (context.liveChat ? '' : ' para iniciar a conversa') }}
    //- template dropdown
    dropdown.w-full(placement="bottom", auto-size="inner")
      span
        .flex.items-center.justify-between.form-control.input(
          @click="search = !search"
        )
          div.w-full.flex.justify-between.cursor-pointer(v-if="!selectedTemplate")
            span.font-plus Selecione um template
            i.material-icons-round.notranslate.dropdown-icon.text-gray-300 keyboard_arrow_down
          div.w-full.flex.justify-between.cursor-pointer(v-else)
            span {{ formatSnakeCaseName(selectedTemplate.name) }}
            i.material-icons-round.notranslate.dropdown-icon.text-gray-300(@click.stop="resetTemplate()") cancel
      template(#popper)
        .flex.flex-col.w-full.items-start.justify-start.p-2
          IconInput.mb-2.font-plus(
            :auto-focus="true",
            v-model="searchTemplateValue",
            :label="'Pesquisar template'",
            @input="() => { handleSearchInput(searchTemplateValue); loadingSearchTemplate = true}"
          )
          .flex.flex-col.w-full.gap-1(v-if="isEmptyTemplates && !loadingSearchTemplate")
            nothing-here-icon.w-10
            p.text-gray-400.font-inter Nenhum template encontrado.
          div.w-full.max-h-60.overflow-auto
            loading-ring.py-6(v-if="loadingSearchTemplate")
            .flex.justify-between.items-center.p-3.cursor-pointer.text-zinc-500.font-inter.w-full(
              v-if="!loadingSearchTemplate"
              v-for="template in templates",
              :key="template.id",
              class="hover:bg-zinc-200",
              v-close-popper
              @click="selectTemplate(template)"
            )
              p.flex.gap-4.justify-between.mr-auto.items-center.w-full.font-plus {{ formatSnakeCaseName(template.name) }}
            .flex.w-full.flex-col(v-if="nextPages.length > 0")
              Observer(@intersect="fetchNextTemplatesPage()")
              loading-component.w-6.h-6.pointer-events-none.py-6(class="dark:invert")

  .w-full.flex.flex-col.gap-4(v-if="selectedTemplate !== null && !loadingConfig")
    //- template preview
    .flex.items-center.w-full.justify-start.gap-2(v-if="!isSupportedTemplate")
      i.material-icons-round.notranslate.text-red-500.cursor-help(
        class="dark:invert",
        v-tooltip="{ content: 'O template selecionado ainda não é suportado pela nossa plataforma' }"
      ) error
      p.font-semibold.text-red-500.font-inter Template não suportado!
    .flex.items-center.w-full.justify-start.gap-1(v-if="templateNeedSync")
      i.material-icons-round.notranslate.text-red-500.cursor-help(
        class="dark:invert",
        v-tooltip="{ content: 'O modelo selecionado precisa ser sincronizado com nossa plataforma' }"
      ) error
      p.font-semibold.text-red-500.font-inter Modelo desatualizado!
    .form-control.input.h-fit.p-2(:class="{ 'borde-2 border-red-500': !isSupportedTemplate || templateNeedSync }")
      .bg-zinc-200.p-2.flex.flex-col.gap-1.max-w-sm.rounded.ml-auto.items-start(:class="isMediaHeader ? 'w-min' : ''")
        //- Header
        div(v-if="isMediaHeader")
          input#headerFileInput.hidden(type="file", :accept="headerFileInputAccept", @change="handleSelectHeaderMedia", ref="headerFileInput", :key="headerFileKey ")
          //- preview document
          div(v-if="headerComponent?.format?.toLowerCase() === 'document'")
            v-button-secondary.flex.w-full.rounded(
              v-if="isRequiredHeaderMedia && !headerFile && !loadingHeaderFileUpload",
              @click="clickOnHeaderFileInput()"
            )
              i.material-icons-outlined.notranslate.text-3xl.text-brand(class="dark:invert") file_upload
              span.font-plus.text-brand.text-xs.whitespace-nowrap(class="dark:invert") Selecione um documento

            v-button-secondary.flex.w-full.rounded(
              class="!min-w-full",
              v-else-if="isRequiredHeaderMedia && loadingHeaderFileUpload",
            )
              loading-ring

            .flex.rounded.px-3.py-2.bg-opacity-10.bg-zinc-600.w-full.justify-start.group(
              class="hover:bg-zinc-300 min-w-[16rem]",
              v-if="isRequiredHeaderMedia ? headerFile : templateConfig?.headerContent"
            )
              i.material-icons.notranslate.text-3xl.text-brand(class="dark:invert") insert_drive_file
              p.font-plus.text-brand.text-xs.whitespace-nowrap.font-bold.flex-1.truncate(class="dark:invert") {{ headerFile?.name || templateConfig?.headerContent }}
              i.material-icons.notranslate.invisible.self-end.cursor-pointer.text-red-500.border.shadow.bg-red-100.w-8.h-8.rounded-full.indent-0.p-2.flex.items-center.justify-center.transition-all(
                class="dark:invert hover:brightness-95 group-hover:visible",
                v-tooltip="{ content: 'Remover documento' }"
                @click="removeHeaderMedia()"
                v-if="isRequiredHeaderMedia"
              ) delete

          //- preview image
          div(v-if="headerComponent?.format?.toLowerCase() === 'image'")
            v-button-secondary.w-full.py-4.aspect-square.flex.items-center.justify-center.flex-col.gap-2.cursor-pointer(
              v-if="isRequiredHeaderMedia && !headerImageUrl && !loadingHeaderFileUpload",
              class="min-w-[16rem] hover:brightness-90",
              @click="clickOnHeaderFileInput()"
            )
              i.material-icons.notranslate.text-6xl.w-fit.text-brand(class="dark:invert ") file_upload
              span.font-plus.text-brand.flex.items-center.gap-1 Selecione uma imagem

            v-button-secondary.w-full.py-4.aspect-square.flex.items-center.justify-center.flex-col.gap-2.cursor-pointer(
              v-else-if="isRequiredHeaderMedia && loadingHeaderFileUpload",
              class="min-w-[16rem] hover:brightness-90",
            )
              loading-ring

            .flex.relative.group(v-else)
              img.object-cover.aspect-square.rounded(class="min-w-[16rem]", :src="headerImageUrl" alt="imagem do cabeçalho")
              i.material-icons.notranslate.invisible.top-2.right-2.cursor-pointer.absolute.text-red-500.border.shadow.bg-red-100.w-8.h-8.rounded-full.indent-0.p-2.flex.items-center.justify-center.transition-all(
                class="dark:invert hover:brightness-95 group-hover:visible",
                v-tooltip="{ content: 'Remover imagem' }"
                @click="removeHeaderMedia()"
                v-if="isRequiredHeaderMedia"
              ) delete
        p.text-gray-700.text-left.indent-0.font-bold(
          v-if="headerComponent?.format?.toLowerCase() === 'text'"
        ) {{ replaceVariablesWithValue(headerComponent?.text, headerVariables, headerVariablesValues)}}
        //- Body
        p.text-gray-700.text-left.indent-0.whitespace-pre-line {{ replaceVariablesWithValue(bodyComponent?.text, bodyVariables, bodyVariablesValues) }}
        //- Footer
        p.text-gray-400.text-left.indent-0.text-xs.mt-2(v-if="footerComponent?.text") {{ footerComponent.text }}
        //- Buttons
        .flex.flex-col.gap-2.w-full.border(v-if="buttonsComponent?.buttons?.length")
          .flex.w-full(v-for="(button, index) in buttonsComponent.buttons", :key="index")
            v-button.flex.items-center.gap-2.pointer-events-none(size="w-full")
              i.material-icons-round.notranslate {{ buttonIcon[button?.type] }}
              span.text-sm.font-semibold {{ button.text }}

    //- template variables
    .overflow-auto.max-h-64.w-full.flex.flex-col.gap-4.m-auto.justify-start.items-start(v-if="hasVariables && isSupportedTemplate && !templateNeedSync")
      section-expander.w-full(
        v-if="headerVariables.length",
        title="Variáveis do cabeçalho",
        :opened="true",
        :error="hasExceededHeaderLimit && 'O cabeçalho da mensagem não pode exceder 60 caracteres'"
      )
        v-table.table-fixed
          template(v-slot:header)
            v-table-head
              tr.font-inter.text-sm.font-semibold.text-zinc-400.border-b(class="px-4 child:py-3 child:px-4")
                th.font-inter.font-semibold.w-16 Campo
                th.font-inter.font-semibold Valor
                th.font-inter.font-semibold.w-32 Valores do contato
          template(v-slot:body)
            v-table-row(v-for="(variable, index) in headerVariables", :key="index")
              td.font-plus.text-brand.font-bold {{ variable }}
              td.font-plus
                v-dropdown.text-sm.min-w-fit.h-12.w-fit(
                  v-if="switchHeaderValues[index]"
                  useAllHeight
                  class="transition-all active:scale-90"
                  :placeholder="getSelectableValuePlaceholder(headerVariablesValues[index])"
                )
                  ul.divide-y(v-close-popper.all)
                    dropdown-item(
                      hide-icon
                      v-for="([key, value]) in Object.entries(contactValuesToUse)" :key="key"
                      class="transition-all active:scale-90"
                      :label="value",
                      @click="headerVariablesValues.splice(index, 1, `$${key}$`)",
                      :selected="headerVariablesValues[index] === `$${key}$`"
                    )
                icon-input.w-full(
                  v-else
                  icon="text_fields",
                  v-model="headerVariablesValues[index]",
                  :label="`Digite um valor para a variável ${variable}`"
                )
              td.font-plus
                .flex.items-center.justify-start.gap-2
                  switch-box.m-0(v-model="switchHeaderValues[index]", @input="headerVariablesValues[index] = ''")

      section-expander.w-full(
        v-if="bodyVariables.length",
        title="Variáveis do corpo",
        :opened="true",
        :error="hasExceededBodyLimit && 'O corpo da mensagem não pode exceder 1024 caracteres'"
      )
        v-table.table-fixed
          template(v-slot:header)
            v-table-head
              tr.font-inter.text-sm.font-semibold.text-zinc-400.border-b(class="px-4 child:py-3 child:px-4")
                th.font-inter.font-semibold.w-16 Campo
                th.font-inter.font-semibold Valor
                th.font-inter.font-semibold.w-32 Valores do contato
          template(v-slot:body)
            v-table-row(v-for="(variable, index) in bodyVariables", :key="index")
              td.font-plus.text-brand.font-bold {{ variable }}
              td.font-plus
                v-dropdown.text-sm.min-w-fit.h-12.w-fit(
                  v-if="switchBodyValues[index]"
                  useAllHeight
                  class="transition-all active:scale-90"
                  :placeholder="getSelectableValuePlaceholder(bodyVariablesValues[index])"
                )
                  ul.divide-y(v-close-popper.all)
                    dropdown-item(
                      hide-icon
                      v-for="([key, value]) in Object.entries(contactValuesToUse)" :key="key"
                      class="transition-all active:scale-90"
                      :label="value",
                      @click="bodyVariablesValues.splice(index, 1, `$${key}$`)",
                      :selected="bodyVariablesValues[index] === `$${key}$`"
                    )
                icon-input.w-full(
                  v-else
                  icon="text_fields",
                  v-model="bodyVariablesValues[index]",
                  :label="`Digite um valor para a variável ${variable}`"
                )
              td.font-plus
                .flex.items-center.justify-start.gap-2
                  switch-box.m-0(v-model="switchBodyValues[index]", @input="bodyVariablesValues[index] = ''")

      //- Buttons variables
      section-expander.w-full(
        v-if="buttonsVariables?.some((buttonVariable) => buttonVariable)",
        title="Variáveis dos botoes",
        :opened="true",
      )
        v-table.table-fixed
          template(v-slot:header)
            v-table-head
              tr.font-inter.text-sm.font-semibold.text-zinc-400.border-b(class="px-4 child:py-3 child:px-4")
                th.font-inter.font-semibold.w-20 Campo
                th.font-inter.font-semibold.w-max Valor
          template(v-slot:body)
            tbody
              v-table-row(v-for="(variable, index) in buttonsVariables", :key="index", v-if="variable?.length")
                td.font-plus.text-brand.font-bold {{ index + 1 }}
                td.font-plus.flex.flex-col.items-start
                  icon-input.w-full(
                    icon="text_fields",
                    v-model="buttonsVariablesValues[index]",
                    :label="buttonsComponent?.buttons[index]?.text"
                    :caract="28"
                  )
                  p.text-xs.font-plus.text-gray-500
                    | {{ concatUrlWithVariable(buttonsComponent?.buttons[index]?.url, buttonsVariablesValues[index]) }}

  .w-full.flex.justify-center.items-center(v-if="!loadingConfig && templateNeedSync")
    v-button.relative.flex.items-center.text-base.gap-1(
      size="w-full"
      @click="syncTemplate()",
      :class="{ 'cursor-not-allowed': loadingTemplateSync }"
    )
      i.material-icons-round.notranslate(:class="{ 'animate-spin': loadingTemplateSync }") sync
      span {{ loadingTemplateSync ? 'Sincronizando...' : 'Sincronizar' }}
  .w-full.flex.justify-center.items-center.py-4(v-if="loadingConfig")
    loading-ring
</template>

<script>
import ChatAdapter from '@/services/adapter';
import ChatConfig from '@/services/chat-config';
import Overlay from '@/components/Modals/Overlay.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import LoadingRing from '../LoadingRing.vue';
import { defineComponent } from 'vue';
import IconInput from '../Inputs/IconInput.vue';
import { debounce } from '@/helpers';
import { Dropdown } from 'floating-vue';
import Observer from '../Observer.vue';
import LoadingComponent from '../LoadingComponent.vue';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import SwitchBox from '@/components/Inputs/SwitchCheckbox.vue';
import vDropdown from '@/components/Dropdown/v-dropdown.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import NothingHereIcon from '../Icons/NothingHereIcon.vue';
import vTable from '../Tables/v-table.vue';
import vTableHead from '../Tables/v-table-head.vue';
import vTableRow from '../Tables/v-table-row.vue';
import SectionExpander from '../SectionExpander.vue';
import contactList from '@/services/contact-list';
import contactListMixin from '@/mixins/contact-list';

export default defineComponent({
  mixins: [contactListMixin],
  name: 'MountWhatsappTemplateForm',
  props: {
    providerConfigId: {
      type: String,
      required: false,
    },
    recipientId: {
      type: String,
      required: false,
    },
  },
  components: {
    NothingHereIcon,
    VButtonSecondary,
    Overlay,
    VButton,
    LoadingRing,
    IconInput,
    Observer,
    LoadingComponent,
    SwitchBox,
    Dropdown,
    vDropdown,
    DropdownItem,
    vTable,
    vTableHead,
    vTableRow,
    SectionExpander,
  },
  data() {
    return {
      loadingTemplateSync: false,
      switchBodyValues: [],
      switchButtonsValues: [],
      switchHeaderValues: [],
      loadingHeaderFileUpload: false,
      isEmptyTemplates: false,
      nextPages: [],
      search: false,
      searchTemplateValue: '',
      templates: [],
      selectedTemplate: null,
      headerComponent: null,
      bodyComponent: null,
      footerComponent: null,
      buttonsComponent: null,
      templateConfig: null,
      loadingConfig: false,
      loadingSearchTemplate: false,
      headerFile: null,
      headerImageUrl: null,
      hasVariables: false,
      headerVariables: [],
      headerVariablesValues: [],
      bodyVariables: [],
      bodyVariablesValues: [],
      buttonsVariables: [],
      buttonsVariablesValues: [],
      headerFileInfos: {
        fileUrl: '',
        filename: '',
        parsedFilename: '',
      },
      buttonIcon: {
        URL: 'open_in_new',
        PHONE_NUMBER: 'call',
        QUICK_REPLY: 'chat',
      },
      cancelToken: axios.CancelToken.source(),
      headerFileKey: 0,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
    hasExceededBodyLimit() {
      const limit = 1024;
      const bodyText = this.bodyComponent?.text;
      const bodyWithVariables = this.replaceVariablesWithValue(bodyText, this.bodyVariables, this.bodyVariablesValues);
      const fullBody = this.replaceYuniqueContactVariables(bodyWithVariables);
      if (!fullBody) return false;
      return fullBody.length > limit;
    },
    hasExceededHeaderLimit() {
      const limit = 60;
      const headerText = this.headerComponent?.text;
      const headerWithVariables = this.replaceVariablesWithValue(
        headerText,
        this.headerVariables,
        this.headerVariablesValues,
      );
      const fullHeader = this.replaceYuniqueContactVariables(headerWithVariables);

      if (!fullHeader) return false;
      return fullHeader.length > limit;
    },
    isSupportedTemplate() {
      const headerIsOk = ['text', 'image', 'document'].includes(this.headerComponent?.format?.toLowerCase());
      const buttonsIsOk = this.buttonsComponent?.buttons?.every((button) =>
        ['url', 'phone_number', 'quick_reply'].includes(button?.type?.toLowerCase()),
      );

      if (this.headerComponent && this.buttonsComponent?.buttons?.length > 0) {
        return headerIsOk && buttonsIsOk;
      }
      if (this.headerComponent && !this.buttonsComponent?.buttons?.length) {
        return headerIsOk;
      }
      if (!this.headerComponent && this.buttonsComponent?.buttons?.length > 0) {
        return buttonsIsOk;
      }
      return true;
    },
    isMediaHeader() {
      return ['image', 'document'].includes(this.headerComponent?.format?.toLowerCase());
    },
    isRequiredHeaderMedia() {
      if (this.templateConfig) {
        return this.templateConfig?.isDynamicHeader;
      }
      return this.isMediaHeader;
    },
    headerFileInputAccept() {
      return this.headerComponent?.format?.toLowerCase() === 'image' ? 'image/*' : '.pdf';
    },
    activeRoom() {
      return this.$store.getters['chat/activeRoom'].room;
    },
    wabaConfigId() {
      if (this.providerConfigId) return this.providerConfigId;
      return this.$store.getters['chat/activeRoom'].room.chatConfigId;
    },
    hasSelectedMediaTemplate() {
      return (
        this.headerComponent?.format?.toLowerCase() === 'image' ||
        this.headerComponent?.format?.toLowerCase() === 'document'
      );
    },
    templateNeedSync() {
      if (!this.selectedTemplate) return false;
      if (!this.templateConfig) return true;

      const componentsMap = this.selectedTemplate.components.reduce((acc, component) => {
        acc[component.type] = component;
        return acc;
      }, {});

      const headerComponent = componentsMap['HEADER'];
      const bodyComponent = componentsMap['BODY'];
      const footerComponent = componentsMap['FOOTER'];
      const headerFormat = headerComponent?.format?.toLowerCase() || null;
      const hasChangedHeaderType = headerFormat !== this.templateConfig?.headerType;

      if (hasChangedHeaderType) return true;

      const footerNeedSync = this.footerNeedSync({ footerComponent });
      const bodyNeedSync = this.bodyNeedSync({ bodyComponent });
      const headerNeedSync = this.headerNeedSync({ headerComponent, headerFormat });

      return footerNeedSync || bodyNeedSync || headerNeedSync;
    },
    contactValuesToUse() {
      const values = {
        contactFullName: 'Nome completo',
        contactFirstName: 'Primeiro nome',
      };

      if (!this?.contact) return values;

      const email = this.contact?.email?.length ? this.contact.email[0]?.address : null;
      const category = this.contact?.category?.name || null;
      const organization = this.contact?.organization?.name || null;
      const cpf = this.contact?.cpf?.length;
      const phoneNumber = this.contact?.phones?.length ? this.contact.phones[0]?.number : null;

      if (phoneNumber) values.contactPhone = 'Numero';
      if (email) values.contactEmail = 'Email';
      if (category) values.contactCategory = 'Categoria';
      if (organization) values.contactOrganization = 'Organização';
      if (cpf) values.contactCPF = 'CPF';

      return values;
    },
  },
  watch: {
    selectedTemplate() {
      this.emitUpdatedTemplateData();
    },
    templateConfig() {
      this.emitUpdatedTemplateData();
    },
    headerVariablesValues() {
      this.emitUpdatedTemplateData();
    },
    bodyVariablesValues() {
      this.emitUpdatedTemplateData();
    },
    buttonsVariablesValues() {
      for (let i = 0; i < this.buttonsVariablesValues.length; i++) {
        if (typeof this.buttonsVariablesValues[i] === 'undefined') {
          this.buttonsVariablesValues[i] = null;
        }
      }
      this.emitUpdatedTemplateData();
    },
    headerFile() {
      this.emitUpdatedTemplateData();
    },
    isRequiredHeaderMedia() {
      this.emitUpdatedTemplateData();
    },
    isMediaHeader() {
      this.emitUpdatedTemplateData();
    },
    hasVariables() {
      this.emitUpdatedTemplateData();
    },
    buttonsVariables() {
      this.emitUpdatedTemplateData();
    },
    bodyVariables() {
      this.emitUpdatedTemplateData();
    },
    headerVariables() {
      this.emitUpdatedTemplateData();
    },
    headerFileInfos() {
      this.emitUpdatedTemplateData();
    },
    templateNeedSync() {
      this.emitUpdatedTemplateData();
    },
    isSupportedTemplate() {
      this.emitUpdatedTemplateData();
    },
  },
  methods: {
    replaceYuniqueContactVariables(text) {
      if (!text) return '';
      let replacedText = text;
      const contactVariables = {
        contactFullName: this.contact?.name,
        contactFirstName: this.contact?.name?.split(' ')[0],
        contactPhone: this.contact?.phones?.length ? this.contact.phones[0]?.number : null,
        contactEmail: this.contact?.email?.length ? this.contact.email[0]?.address : null,
        contactCategory: this.contact?.category?.name || null,
        contactOrganization: this.contact?.organization?.name || null,
        contactCPF: this.contact?.cpf?.length,
      };

      Object.entries(contactVariables).forEach(([key, value]) => {
        if (value) {
          replacedText = replacedText.replace(`$${key}$`, value);
        }
      });
      return replacedText;
    },
    async syncTemplate() {
      this.loadingTemplateSync = true;
      try {
        const templateId = this.selectedTemplate.id;
        const configId = this.wabaConfigId;
        await ChatConfig.syncOneWhatsappTemplate({ templateId, configId });
        this.templateConfig = await ChatConfig.getMetaTemplateById(templateId).catch(() => null);
        this.$toast.success('Sincronização concluída!');
      } catch (error) {
        this.$toast.error(error?.message);
      } finally {
        this.loadingTemplateSync = false;
      }
    },
    headerNeedSync({ headerFormat, headerComponent }) {
      if (
        headerFormat === 'text' &&
        headerComponent?.text?.replace(/\u00A0/g, ' ') !== this.templateConfig?.headerContent?.replace(/\u00A0/g, ' ')
      ) {
        return true;
      }
      return false;
    },
    footerNeedSync({ footerComponent }) {
      if (
        footerComponent?.text &&
        footerComponent.text?.replace(/\u00A0/g, ' ') !== this.templateConfig?.footerContent?.replace(/\u00A0/g, ' ')
      ) {
        return true;
      }
      return false;
    },
    bodyNeedSync({ bodyComponent }) {
      if (bodyComponent?.text?.replace(/\u00A0/g, ' ') !== this.templateConfig?.bodyContent?.replace(/\u00A0/g, ' ')) {
        return true;
      }
      return false;
    },
    getSelectableValuePlaceholder(keyFormatted) {
      if (!keyFormatted) return 'Selecione um valor';
      const key = keyFormatted.replaceAll('$', '');
      return this.contactValuesToUse[key] || 'Selecione um valor';
    },
    emitUpdatedTemplateData() {
      this.areAllTemplateVariablesFilled();
      this.$emit('updated', {
        selectedTemplate: this.selectedTemplate,
        headerVariablesValues: this.headerVariablesValues,
        bodyVariablesValues: this.bodyVariablesValues,
        buttonsVariablesValues: this.buttonsVariablesValues,
        isSupportedTemplate: this.isSupportedTemplate,
        isMediaHeader: this.isMediaHeader,
        isRequiredHeaderMedia: this.isRequiredHeaderMedia,
        headerFile: this.headerFile,
        hasVariables: this.hasVariables,
        buttonsVariables: this.buttonsVariables,
        bodyVariables: this.bodyVariables,
        headerVariables: this.headerVariables,
        templateConfig: this.templateConfig,
        headerFileInfos: this.headerFileInfos,
        templateNeedSync: this.templateNeedSync,
      });
    },
    areAllTemplateVariablesFilled() {
      if (!this.selectedTemplate?.name) {
        return this.$emit('error', 'Selecione um modelo de mensagem para continuar.');
      }

      if (!this.isSupportedTemplate) {
        return this.$emit('error', 'Modelo de mensagem não suportado!');
      }

      if (this.isRequiredHeaderMedia && !this.headerFile) {
        return this.$emit('error', 'Selecione uma mídia para continuar.');
      }

      if (this.hasVariables) {
        const filteredHeaderVariablesValues = this.headerVariablesValues?.filter((value) => value !== '');
        const filteredBodyVariablesValues = this.bodyVariablesValues?.filter((value) => value !== '');

        if (this.buttonsVariables.length !== this.buttonsVariablesValues.length) {
          return this.$emit('error', 'Preencha as variáveis para continuar.');
        }

        if (this.buttonsVariablesValues.some((value, index) => this.buttonsVariables[index] && value === '')) {
          return this.$emit('error', 'Preencha as variáveis para continuar.');
        }

        if (
          this.headerVariables?.length !== filteredHeaderVariablesValues?.length ||
          this.bodyVariables?.length !== filteredBodyVariablesValues?.length
        ) {
          return this.$emit('error', 'Preencha as variáveis para continuar.');
        }
      }

      if (this.hasExceededBodyLimit) {
        return this.$emit('error', 'O corpo da mensagem não pode exceder 1024 caracteres');
      }

      if (this.hasExceededHeaderLimit) {
        return this.$emit('error', 'O cabeçalho da mensagem não pode exceder 60 caracteres');
      }

      return this.$emit('error', null);
    },
    concatUrlWithVariable(url, value) {
      if (!url) return;
      const truncateUrl = this.truncateMiddleString(url);
      if (!value) return truncateUrl;
      return truncateUrl?.replace('{{1}}', value);
    },
    truncateMiddleString(str, maxLength = 60) {
      if (str.length <= maxLength) {
        return str;
      }

      const truncationLength = maxLength - 3; // Leave space for "..."
      const firstHalfLength = Math.ceil(truncationLength / 2);
      const secondHalfLength = Math.floor(truncationLength / 2);

      const firstHalf = str.substr(0, firstHalfLength);
      const secondHalf = str.substr(-secondHalfLength);

      return `${firstHalf}...${secondHalf}`;
    },
    clickOnHeaderFileInput() {
      this.$refs.headerFileInput.click();
    },

    removeHeaderMedia() {
      this.headerFile = null;
      this.headerImageUrl = null;
      this.headerFileInfos = null;
    },
    async handleSelectHeaderMedia(event) {
      try {
        this.loadingHeaderFileUpload = true;
        this.headerFileKey++;
        const file = event.target.files[0];
        const sizeInMB = file.size / 1024 / 1024;

        if (this.headerComponent?.format?.toLowerCase() === 'image') {
          if (sizeInMB > 5) {
            this.$toast.error('O tamanho máximo da imagem é de 5MB');
            return;
          }
        }
        if (this.headerComponent?.format?.toLowerCase() === 'document' && sizeInMB > 64) {
          this.$toast.error('O tamanho máximo do documento é de 64MB');
          return;
        }

        const fileFormData = new FormData();
        const companyId = this.user.company_id;
        const fileId = uuidv4();
        const filename = file.name;
        const parsedFilename = `${companyId}.${fileId}.${filename}`;
        fileFormData.append('file', file, parsedFilename);
        const response = await ChatAdapter.saveFileInAdapter({ fileFormData: fileFormData });
        this.headerFile = file;
        this.headerFileInfos = {
          filename,
          parsedFilename: response.filename,
          fileUrl: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${response.filename}`,
        };
        if (this.headerComponent?.format?.toLowerCase() === 'image') {
          this.headerImageUrl = this.headerFileInfos.fileUrl;
        }
      } catch (error) {
        this.$toast.error('Error ao adicionar arquivo');
      } finally {
        this.loadingHeaderFileUpload = false;
      }
    },
    handleSearchInput: debounce(async function (value) {
      this.templates = [];
      this.nextPages = [];
      this.resetTemplate();
      if (!value.trim()) {
        this.cancelToken.cancel();
        this.cancelToken = axios.CancelToken.source();
        await this.getTemplates();
        this.loadingSearchTemplate = false;
      } else {
        await this.searchTemplate();
      }
    }, 500),
    async searchTemplate() {
      try {
        const { data, paging } = await ChatAdapter.getTemplatesByConfigId({
          configId: this.wabaConfigId,
          templateName: this.searchTemplateValue,
          cancelToken: this.cancelToken.token,
        });

        if (paging?.next) {
          this.nextPages.push(paging.next);
        }

        this.templates = this.templates
          .concat(data.filter((template) => template.status === 'APPROVED'))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.isEmptyTemplates = !this.templates.length;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingSearchTemplate = false;
      }
    },
    replaceVariablesWithValue(text, variables, values) {
      if (!text || !variables || !values) return '';
      let replacedText = text;

      variables.forEach((variable, index) => {
        const variableValue = values[index];
        replacedText = variableValue ? replacedText.replace(`{{${variable}}}`, variableValue) : replacedText;
      });

      return replacedText;
    },
    getVariables(text) {
      if (!text) return [];
      const variables = text?.match(/{{(.*?)}}/g);
      if (variables) {
        return variables.map((variable) => variable.replace(/{{|}}/g, ''));
      }
      return [];
    },
    formatSnakeCaseName(name) {
      let uppercase = name[0].toUpperCase() + name.substring(1, name.length);
      return uppercase.split('_').join(' ');
    },
    async getTemplates() {
      try {
        const { data: templates, paging } = await ChatAdapter.getTemplatesByConfigId({ configId: this.wabaConfigId });

        if (paging?.next) {
          this.nextPages.push(paging.next);
        }

        this.templates = this.templates
          .concat(templates.filter((template) => template.status === 'APPROVED'))
          .sort((a, b) => a.name.localeCompare(b.name));
        this.isEmptyTemplates = !this.templates.length;
      } catch (error) {
        this.$toast.error(error);
      }
    },
    async fetchNextTemplatesPage() {
      try {
        this.nextPages.forEach(async (nextPage) => {
          this.nextPages = [];
          const { data: templates, paging } = await ChatAdapter.getTemplatesByConfigId({
            next: nextPage,
            configId: this.wabaConfigId,
          });

          if (paging?.next) {
            this.nextPages.push(paging.next);
          }

          this.templates = this.templates.concat(templates.filter((template) => template.status === 'APPROVED'));
        });
      } catch (error) {}
    },
    async getFileMedia() {
      try {
        if (this?.templateConfig?.headerType === 'image') {
          const url = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${this.templateConfig.companyId}:${this.templateConfig.headerContent}`;
          this.headerImageUrl = url;
          return url;
        }
      } catch (error) {
        this.$toast.error(error);
      }
    },
    resetTemplate() {
      this.headerVariables = [];
      this.bodyVariables = [];
      this.headerVariablesValues = [];
      this.bodyVariablesValues = [];
      this.buttonsVariables = [];
      this.buttonsVariablesValues = [];
      this.headerImageUrl = null;
      this.selectedTemplate = null;
      this.templateConfig = null;
      this.hasVariables = false;
      this.headerFile = null;
      this.headerComponent = null;
      this.bodyComponent = null;
      this.footerComponent = null;
      this.buttonsComponent = null;
      this.headerFileInfos = null;
      this.switchBodyValues = [];
      this.switchButtonsValues = [];
      this.switchHeaderValues = [];
    },
    async selectTemplate(template) {
      try {
        this.resetTemplate();
        this.loadingConfig = true;
        this.selectedTemplate = template;
        const componentsMap = template.components.reduce((acc, component) => {
          acc[component.type] = component;
          return acc;
        }, {});

        this.headerComponent = componentsMap['HEADER'];
        this.bodyComponent = componentsMap['BODY'];
        this.footerComponent = componentsMap['FOOTER'];
        this.buttonsComponent = componentsMap['BUTTONS'];

        this.templateConfig = await ChatConfig.getMetaTemplateById(template.id).catch(() => null);

        this.extractComponentsVariables();
        if (
          !this.isRequiredHeaderMedia &&
          ['image', 'document'].includes(this.templateConfig?.headerType) &&
          this.templateConfig?.headerContent
        ) {
          const url = await this.getFileMedia(this.templateConfig.headerContent);
          this.headerFileInfos = {
            fileUrl: url,
            filename: this.templateConfig.headerContent,
            parsedFilename: `${this.user.company_id}.${this.templateConfig.headerContent}`,
          };
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingConfig = false;
      }
    },
    extractComponentsVariables() {
      if (this.headerComponent?.format?.toLowerCase() === 'text' && this?.headerComponent?.text) {
        this.headerVariables = this.getVariables(this.headerComponent.text);
      }
      this.buttonsComponent?.buttons?.forEach((button) => {
        if (button?.type?.toLowerCase() === 'url' && button?.url) {
          this.buttonsVariables = [...this.buttonsVariables, ...this.getVariables(button.url)];
        } else {
          this.buttonsVariables = [];
        }
      });

      this.bodyVariables = this.getVariables(this.bodyComponent.text);

      this.hasVariables =
        this.headerVariables.length > 0 || this.bodyVariables.length > 0 || this.buttonsVariables.length > 0;
    },
    async getContact() {
      if (!this.recipientId) return;
      this.activeContactId = this.recipientId;
    },
  },
  async created() {
    try {
      await this.getContact();
      await this.getTemplates();
    } catch (error) {
      this.$toast.error(error);
    }
  },
});
</script>
