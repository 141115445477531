<template lang="pug">
.w-80
  .flex.flex-col.items-center.w-full(v-if="!loading && !error")
    img.rounded.transition.cursor-pointer.object-cover.aspect-square(
      :src="imageUrl",
      @click="displayImage"
      @load="showImage = true"
      :class="{ 'opacity-0': loading }"
      class="hover:brightness-90 dark:invert"
      ref="image"
    )

  //- Loading esqueleto
  .flex.items-center.justify-center.aspect-square.bg-gray-300.rounded.animate-pulse(v-else-if="loading && !error", class='dark:bg-gray-700')
    .material-icons.notranslate.text-6xl.text-zinc-400.opacity-50 landscape
    
  //- Error
  p.text-xs.text-red-500.flex.rounded.px-4.py-2.cursor-pointer.mb-1.bg-opacity-10.font-plus(
    class="hover:bg-gray-400 hover:bg-opacity-20"
    :class="[!isMine ? 'bg-zinc-600' : 'bg-gray-500']"
    v-else
  ) {{ error }}
    i.material-icons-round.notranslate.text-xl.text-red-500.transition-all(
      @click="retryGetFile",
      class="hover:text-red-600 hover:rotate-[360deg] duration-300"
      v-tooltip="'Tente novamente'"
    ) refresh
</template>

<script>
import * as AdapterService from '@/services/adapter';

export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      imageUrl: null,
      imageName: null,
      showImage: false,
      loading: true,
      errorTimer: null,
      retryInterval: 1000, // intervalo de 1 segundos
      error: null,
      intervalId: null,
    };
  },

  computed: {
    isMine() {
      return this.message.recipientId === this.user.id;
    },
  },

  methods: {
    async displayImage() {
      await this.$store.commit('modal/setActiveModal', {
        name: 'FullImage',
        context: `${this.imageUrl}`,
      });
    },
    async retryGetFile() {
      this.loading = true;
      this.error = null;
      try {
        await this.waitForFileAvailability();
      } catch (error) {
        this.error = error?.message;
      } finally {
        this.loading = false;
      }
    },
    async waitForFileAvailability() {
      return new Promise(async (resolve, reject) => {
        let retries = 0;
        const intervalId = setInterval(async () => {
          try {
            let url;
            const urlNew = await AdapterService.checkStaticFileAvailability(this.imageName);
            const urlOld = await AdapterService.checkStaticFileAvailability(
              this.imageName.replace(`${this.companyId}.`, `${this.companyId}:`),
            );

            url = urlNew || urlOld || null;
            if (url) {
              this.imageUrl = url;
              clearInterval(intervalId);
              resolve();
            } else {
              retries += 1;
              if (retries > 3) {
                clearInterval(intervalId);
                reject(new Error('Não foi possível carregar o arquivo.'));
              }
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(new Error('Não foi possível carregar a imagem.'));
          }
        }, 1500);
      });
    },
  },
  async created() {
    try {
      if (this.message.messageHeader.startsWith('https://') || this.message.messageHeader.startsWith('http://')) {
        this.imageUrl = this.message.messageHeader;
        return;
      }
      this.imageName = this.message.messageHeader;
      await this.waitForFileAvailability();
    } catch (error) {
      this.error = error?.message;
    } finally {
      this.loading = false;
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    URL.revokeObjectURL(this.imageUrl);
  },
};
</script>
