<template lang="pug">
div(v-if="activity")
  .contact-history-box
    .history-info-side
      .history-left-info
        div(:class="'history-track-icon ' + mapCallType[activity.status]")
          span.text-white.history-call-indicator-icon.material-icons.notranslate {{  &quot;call_&quot; + translateCallType()  }}
        .history-type-text.text-marker(
          :style="isOpened ? 'font-weight:bold' : 'font-weight:normal'"
        )
          | {{ textForCallType[translateCallType()] }}
        span.got-callback-icon.material-icons(v-if="activity.callbacks") assignment_late
      .history-center-info.text-marker
        .history-type-text {{ activityDateFormat() }}
        .history-type-text
          | {{  &quot;Dura&ccedil;&atilde;o &quot; + formatDuration(activity.answerdate, activity.hangupdate)  }}
      .history-right-info
        .tag
          | {{  tags &amp;&amp; tags?.length ? getCompleteTag(tags[0]).text : &quot;Sem Tag&quot;  }}
        .aditional-tag-margin.tag(
          v-if="tags && tags?.length > 1",
          @mouseover="showAllTags",
          @mouseleave="hideAllTags"
        )
          | +{{ tags?.length - 1 }}
    .history-icon-side.gap-1
      span.expand-activity-icon.material-icons.notranslate.transition-none(
        v-tooltip="'Expandir atividade'"
        @click="isOpened = !isOpened",
        :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'"
      ) keyboard_arrow_down
      span.expand-activity-icon.material-icons.notranslate.text-lg.transition-none(
        v-tooltip="'Vincular a um caso'"
        @click="linkCase"
        v-if="!isFeed"
      ) assignment
  .tags-component-aligner
    .tags-component-content(
      v-if="tags && tags?.length > 1",
      ref="tags-component"
    )
      p.tags-text Tags
      .tags-box
        .tag.multiple-tags-margin(
          v-for="(data, index) in tags",
          :key="index"
        )
          | {{ getCompleteTag(data).text }}
  event-detail(:callData="activity", v-if="isOpened")
.no-device(v-else="", style="width: 100%")
  span.info-orange-icon.material-icons.notranslate error_outline
  | Esse contato n&atilde;o possui atividades.
</template>

<script>
import EventDetail from "@/pages/EventDetail.vue";
import { getAllCallTags } from "@/services/call-report";
import rship from "@/services/rship";

export default {
  props: ["activity", "number", "contact", "isFeed"],
  data() {
    return {
      isOpened: false,
      mapCallType: {
        ANSWERED: "received",
        NOANSWER: "missed",
      },
      textForCallType: {
        made: "Chamada efetuada atendida",
        received: "Chamada recebida atendida",
        missed: "Chamada recebida não atendida",
        missed_outgoing: "Chamada efetuada não atendida",
      },
      tags: [],
      tagsInfo: [],
      hideTagsTimeout: null,
      showTagsTimeout: null,
    };
  },
  components: {
    EventDetail,
  },
  methods: {
    getCompleteTag(tag){
      return {...tag, text: this.tagsInfo.find(tagInfo => tagInfo.id === tag.tagId).text, callId: this.activity.uniqueid || this.activity.linkedid}
    },
    linkCase() {
      const id = this.activity.uniqueid || this.activity.linkedid;
      this.$store.commit("modal/setActiveModal", {
        name: "linkCase",
        context: { contact: this.contact, callId: id },
      });
    },
    translateCallType() {
      const userId = this.user.id;
      const returnIcon = {
        ANSWERED: function (activity, id) {
          if (activity.from_ids.includes(id)) {
            return "received";
          }
          return "made";
        },
        NOANSWER: function (activity, id) {
          if (activity.from_ids.includes(id)) {
            return "missed";
          }
          return "missed_outgoing";
        },
      };
      let type = returnIcon[this.activity.status];
      return type(this.activity, userId);
    },
    hideAllTags() {
      if (!this.$refs["tags-component"]) return;
      clearTimeout(this.showTagsTimeout);
      this.$refs["tags-component"].style.opacity = 0;
      this.hideTagsTimeout = setTimeout(
        () => (this.$refs["tags-component"].style.display = "none"),
        300
      );
    },
    showAllTags() {
      if (!this.$refs["tags-component"]) return;
      clearTimeout(this.hideTagsTimeout);
      this.$refs["tags-component"].style.display = "flex";
      this.showTagsTimeout = setTimeout(
        () => (this.$refs["tags-component"].style.opacity = 1),
        1
      );
    },
    formatDuration(start, end) {
      const endTime = this.$moment(end);
      const startTime = this.$moment(start);
      const duration =  this.$moment.utc(this.$moment(endTime, "HH:mm:ss").diff(this.$moment(startTime, "HH:mm:ss"))).format("HH:mm:ss");
      return duration;
    },
    activityDateFormat() {
      const date = this.$moment.tz(this.activity.calldate, this.user.timezone);
      return date.format("DD/MM/yyyy [-] HH:mm");
    },
    async populateTags() {
      this.tags = await getAllCallTags({
        companyId: this.user.company_id,
        linkedId: this.activity.linkedid || this.activity.uniqueid,
      });
    },
  },
  async created() {
    this.tagsInfo = await rship.getTags(this.user.company_id);
    await this.populateTags();
  }
};
</script>

<style>
.got-callback-icon {
  font-size: 18px !important;
  color: var(--brand-orange);
  margin-left: 12px;
  user-select: none;
}
.tags-component-aligner {
  width: 90%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.aditional-tag-margin {
  margin-left: 10px;
}
.tags-box {
  display: flex;
  max-width: 326px;
  flex-wrap: wrap;
}
.multiple-tags-margin {
  margin-right: 10px;
  margin-top: 10px;
}
.tags-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
  color: #495057;
}
.tags-component-content {
  width: 368px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  display: none;
  flex-direction: column;
  padding: 20px;
  z-index: 9;
  position: absolute;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  transition: 0.3s;
}
</style>
