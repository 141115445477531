import { Inviter, Registerer, SessionState, UserAgent } from "sip.js";
import eventBus from "@/main-event-bus.js";

let sipServer;
// Number of times to attempt reconnection before giving up
const reconnectionAttempts = 40;
// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 10;

// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;
// If false, reconnection attempts will be discontinued or otherwise prevented
let shouldBeConnected = true;

let userAgent = null;
export const createAgent = function ({ endpoint, server, password, username }) {
  sipServer = server;
  const uri = UserAgent.makeURI(`sip:${endpoint}@${server}`);
  if (!uri) {
    throw new Error("Failed to create URI");
  }
  const userAgentOptions = {
    uri,
    transportOptions: {
      server: `wss://${server}:${8089}/ws`,
      traceSip: false,
      connectionTimeout: 15, // The timeout in seconds for the initial connection to make on the web socket port
      keepAliveInterval: 59,
    },
    sessionDescriptionHandlerFactoryOptions: {
      peerConnectionConfiguration: {
        // bundlePolicy: "balanced",
        // certificates: undefined,
        // iceCandidatePoolSize: 0,
        // iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        // iceTransportPolicy: "all",
        // peerIdentity: undefined,
        // rtcpMuxPolicy: "require",
      },
      iceGatheringTimeout: 500, // Set amount of time in milliseconds to wait for the ICE/STUN server
    },
    displayName: username,
    authorizationUsername: endpoint,
    authorizationPassword: password,
    contactParams: {
      transport: "wss",
    },
    hackIpInContact: true, // Set a random IP address as the host value in the Contact header field and Via sent-by parameter. (Suggested for Asterisk)
    userAgentString: username, // can be whatever
    autoStart: false,
    autoStop: true,
    register: false,
    noAnswerTimeout: 120,
    logBuiltinEnabled: true,
  };

  userAgent = new UserAgent(userAgentOptions);
  /*
   * Setup handling for incoming INVITE requests
   */
  /*
   * Create a Registerer to register user agent
   */
  const registererOptions = {
    expires: 30
  };
  const registerer = new Registerer(userAgent, registererOptions);

  /*
   * Start the user agent
   */
  // Handle connection with server established
  return {
    userAgent,
    registerer,
  };
};

export const makeCall = function ({ userAgent, callNum }) {
  // Send an outgoing INVITE request
  callNum = callNum.replace(/\D/g, "");
  const target = UserAgent.makeURI(`sip:${callNum}@${sipServer}`);
  if (!target) {
    throw new Error("Failed to create target URI.");
  }

  // Create a new Inviter
  const inviterOptions = {
    /* ... */
    earlyMedia: true,
  };
  const inviter = new Inviter(userAgent, target, inviterOptions);
  // An Inviter is a Session
  const outgoingSession = inviter;
  // Send the INVITE request
  inviter
    .invite({
      requestDelegate: {
        onReject(reject) {
          eventBus.$emit("invite-failed", reject.message.statusCode);
        },
        onAccept(accept) {
        },
        onProgress(progress) {
        },
        onTrying(response) {
        },
        onRedirect(redirect) {
        },
      },
    })
    .then((session) => {
      // INVITE sent
      eventBus.$emit("invite-sent");
    })
    .catch((error) => {
      // INVITE did not send
      eventBus.$emit("invite-failed");
    });
  return {
    outgoingSession,
    outgoingSessionOptions: inviterOptions,
  };
};

export const attendTransfer = function ({ outgoingSession, transferNum }) {
  // Send an outgoing REFER request

  const target = UserAgent.makeURI(`sip:${transferNum}@${sipServer}`);
  if (!target) {
    throw new Error("Failed to create transfer target URI.");
  }
  const inviterOptions = {
    /* ... */
    activeAfterTransfer: false,
    inviteWithoutSdp: true,
  };
  const transferTarget = new Inviter(outgoingSession.userAgent, target, inviterOptions);

  transferTarget.stateChange.addListener((newState) => {
    switch (newState) {
      case SessionState.Establishing:
        // Session is establishing.
        break;
      case SessionState.Established:
        {
          outgoingSession.refer(transferTarget);
          // Session has been established.
        }
        break;
      case SessionState.Terminated:
        {
        }
        // Session has terminated.
        break;
      default:
        break;
    }
  });
  transferTarget.invite();
};

// Function which recursively attempts reconnection
const attemptReconnection = (reconnectionAttempt = 1) => {
  // If not intentionally connected, don't reconnect.
  if (!shouldBeConnected) {
    return;
  }

  // Reconnection attempt already in progress
  if (attemptingReconnection) {
    return;
  }

  // Reconnection maximum attempts reached
  if (reconnectionAttempt > reconnectionAttempts) {
    return;
  }

  // We're attempting a reconnection
  attemptingReconnection = true;

  setTimeout(
    () => {
      // If not intentionally connected, don't reconnect.
      if (!shouldBeConnected) {
        attemptingReconnection = false;
        return;
      }
      // Attempt reconnect
      userAgent
        .reconnect()
        .then(() => {
          // Reconnect attempt succeeded
          attemptingReconnection = false;
        })
        .catch((error) => {
          // Reconnect attempt failed
          attemptingReconnection = false;
          attemptReconnection(++reconnectionAttempt);
        });
    },
    reconnectionAttempt === 1 ? 0 : reconnectionDelay * 1000
  );
};
