<template>
<div>
  <div class="queue-card" v-for="queue in queues" :key="queue.name">
      <div class="horizontal-aligner">
        <div class="dial-left-aligner">
        <div class="queue-card-text">{{queue.name}}</div>
        </div>
        <div class="dial-right-aligner">
          <span class="fa arrow-icon notranslate material-icons">
            arrow_forward
          </span>
        </div>
      </div>
      <div 
        style="color: #DC6058;background: rgba(220, 96, 88, 0.1);padding:5px;width:fit-content" 
        class="client-text">
        08 clientes aguardando
      </div>
      <div class="card-info-text">Usuários na fila:</div>
      <div class="status-horizontal-aligner">
        <div class="status-left-aligner">
        <div class="card-status-disabled green"></div><span class="card-info-text">000 disponíveis</span>
        </div>
       <div class="status-right-aligner">
        <div class="card-status-disabled"></div><span class="card-info-text">000 indisponível</span>
       </div>
      </div>
      <div class="status-horizontal-aligner">
        <div class="status-left-aligner">
        <div class="card-status-disabled red"></div><span class="card-info-text">000 em chamadas</span>
        </div>
        <div class="status-right-aligner">
        <div class="card-status-disabled yellow"></div><span class="card-info-text">000 em pausa</span>
        </div>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data(){
    return{
      queues:[
        {name:'Comercial'},
        {name:'Suporte'}
      ]
    }
  }
}
</script>

<style>
.status-right-aligner{
    display:flex;
    align-items:center;
    width:50%;
    justify-content:left;
  }
  .status-left-aligner{
    display:flex;
    align-items:center;
    width:50%;
    justify-content:left;
  }
  .status-horizontal-aligner{
    display: flex;
    width: 100%;
    align-items:center;
  }
  .yellow{
    background-color:#F8A354 !important;
  }
  .red{
    background-color:var(--secondary-color) !important;
  }
  .green{
    background-color:var(--green-default) !important;
  }
  .card-status-disabled{
    width: 8px;
    height: 8px;
    margin-right:5px;
    border-radius: 8px;
    background-color: #ADB5BD;
  }
  .card-info-text{
    font-family: 'Inter',sans-serif;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    color: #868E96 !important;
  }
  .client-text{
    width: fit-content;
    height: 15px;
    padding: 15px;
    font-family: 'Inter',sans-serif;
    font-weight: 500;
    font-size: 10px;
    align-items: center;
    line-height: 15px;
    color: #495057;
    border: 1px solid rgba(73, 80, 87, 0.1);
    border-radius: 25px;
    background: white;
    display:flex;
    justify-content:center;
  }
  .arrow-icon{
    background:none !important;
    border:none !important ;
    box-shadow:none !important;
    color: var(--brand-color) !important;
    font-size:24px !important;
  }
  .queue-card-text{
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .queue-card{
    border-bottom:1px solid #F8F9FA;
    padding:16px;
    width: 280px;
    height: 192px;
    background: #FFFFFF;
  }
</style>
