<template lang="pug">
.mb-4.relative.flex.justify-center.items-center.bottom-0.px-4.pointer-events-none(class="dark:invert")
  .px-5.h-16.bg-zinc-800.rounded-full.flex.justify-center.items-center.cursor-pointer.pointer-events-auto(@click="$router.push({ name: 'Emails'})")
    p.text-white Você possui novas mensagens de email, 
      span.text-blue-400(class="dark:font-semibold dark:text-blue-300") clique aqui para acessar seus emails!
    .relative
      span.material-icons.notranslate.ml-6.p-1.bg-blue-400.text-blue-400.rounded-full.absolute.animate-ping &#xe0be
      span.material-icons.notranslate.ml-6.p-1.bg-blue-400.text-blue-800.rounded-full &#xe0be
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
