<template lang="pug">
.w-full
  //- Loading
  .flex.gap-1.w-full.justify-center.items-center.animate-pulse(v-if="loadingAttachment", class='dark:bg-gray-700')
    .material-icons.notranslate.text-2xl.text-zinc-400.opacity-50 attach_file
    p.font-semibold.font-plus Carregando documento...

  //- Error
  .flex.w-full.py-2.flex-col.gap-2(v-else-if="attachment?.error")
    .flex.gap-1.cursor-help
      .material-icons.notranslate.text-red-400(v-tooltip="attachment?.error") error
      p.font-semibold.font-plus(v-tooltip="attachment?.error") Erro ao carregar documento
      .material-icons.notranslate.text-sm.text-brand-300.cursor-pointer(@click="loadDocument" v-tooltip="'Tentar novamente'") refresh

  //- Success
  .flex.gap-1.w-full.justify-center.items-center(v-else)
    .flex.gap-1.cursor-pointer(@click="downloadDocument")
      .material-icons.notranslate.text-2xl.text-brand-300 attach_file
      p.font-semibold.font-plus {{ attachment.filename }}
</template>

<script>
import { getAttachmentById } from '@/services/records';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loadingAttachment: false,
      fileUrl: null,
    };
  },
  methods: {
    async loadDocument() {
      try {
        this.loadingAttachment = true;
        const data = await getAttachmentById(this.attachment.id, this.provider);
        this.fileUrl = data.link;
      } catch (error) {
        this.attachment.error = error?.message ?? 'Erro ao carregar documento';
      } finally {
        this.loadingAttachment = false;
      }
    },

    async downloadDocument() {
      if (!this.fileUrl) await this.loadDocument();
      const link = document.createElement('a');
      link.href = this.fileUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
});
</script>
