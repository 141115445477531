<template lang="pug">
div(:class="{'!w-80': message?.messageHeaderType?.toUpperCase() === 'IMAGE'}")
  .flex.justify-start.items-center.max-w-md(v-if="!isAutomatic")
    .flex.flex-col.items-start.w-fit
      link-preview(v-if="showPreview" :url="urls[0].href" @error="previewError = true" @close="previewClose = true")
      .w-full.font-plus.font-medium.break-anywhere.whitespace-pre-line(
        class="!max-w-xl"
        :style="{'overflowWrap':'anywhere'}"
        :class="[ isAutomatic ? '!text-xs': '!text-sm', isRecipient ? 'text-zinc-100' : 'text-gray-900' ]"
        v-html="linkifyHtml(message.messageBody, options)"
      )
    span.material-icons.notranslate.mb-5.ml-2.cursor-pointer.transition(
      v-if="!previewError && previewClose",
      :url="urls[0].href",
      @click="previewClose = false",
      class="hover:text-brand-500 dark:invert"
    ) preview

  .flex.justify-center.items-center.w-full(v-if="isAutomatic")
    p.text-zinc-400.text-xs(v-html="linkifyHtml(message.messageBody, options)")
</template>

<script>
import LinkPreview from '@/components/Labels/LinkPreview.vue';
import linkifyHtml from 'linkify-html';
import * as linkify from 'linkifyjs';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
    isAutomatic: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LinkPreview,
  },
  data() {
    return {
      urls: [],
      previewError: false,
      previewClose: false,
    };
  },
  computed: {
    showPreview() {
      return this.urls.length > 0 && !this.previewError && !this.previewClose;
    },
    isRecipient() {
      return this.message?.recipientId !== this.user.id;
    },
    options() {
      return {
        defaultProtocol: 'https',
        className: `yu-link ${this.isRecipient ? 'text-blue-300' : 'text-blue-700'}`,
        target: '_blank',
      };
    },
  },
  methods: {
    linkifyHtml,
  },
  created() {
    this.urls = linkify.find(this.message.messageBody);
  },
});
</script>
