<template lang="pug">
.content.flex.flex-col
  .container-fluid
    .pt-8.m-0.pagearea
      .flex.items-start.gap-2.w-full.justify-center.flex-col
        .flex.justify-center.gap-2(
          @click="$router.push({ name: 'Atividades' })",
          role="button"
        )
          span.pl-0.min-w-0.w-5.arrow-icon.notranslate.material-icons arrow_backward
          a.mt-1.back-text Voltar
        .m-0.title Detalhe da atividade
  .flex.w-full.flex-col.items-center.justify-center.gap-2.p-10(v-if="loading")
    p.text-sm.font-plus Carregando...
    loading-ring
  .flex.w-full.flex-col.items-center.justify-center.gap-2.p-10(v-if="error")
    p.text-sm.font-plus Ocorreu algum erro ao carregar esta chamada, <b class="font-plus text-brand-500 cursor-pointer" @click="getCallInfo">tentar novamente</b>
    .material-icons.notranslate.text-red-500 error
  .flex.w-full.flex-col.pl-5.gap-2(v-if="!loading && !error")
    activity-card.w-full.border-y(:data="{ ...activity, contact: identityContact }")
    contact-box.w-full.rounded-t-md(:user-data="identityContact", @openCallback="callbackModal = true")
    call-event-tracking.w-full(:call="activity", :contact="identityContact")
  callback-modal(
    v-if="callbackModal",
    @close-modal="callbackModal = false",
    :contact="identityContact",
    :linkedId="activityId"
  )
</template>
<script>
import ActivityCard from '@/pages/Callbacks/Callbacks.History.ActivityCard.vue';
import ContactBox from '@/pages/Callbacks/Callbacks.History.ContactBox.vue';
import CallbackModal from '@/pages/Contacts/Contact.CallbackModal.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import CallEventTracking from '@/components/CallEventTracking';
import LoadingRing from '@/components/LoadingRing.vue';
import CallReport from '@/services/call-report';

export default {
  title: 'Opens - Detalhes da atividade',
  mixins: [UserOrContactMixin],
  components: {
    CallEventTracking,
    CallbackModal,
    ActivityCard,
    LoadingRing,
    ContactBox,
  },
  data() {
    return {
      callbackModal: false,
      loading: true,
      error: false,
      activity: null,
    };
  },
  computed: {
    activityId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getCallInfo() {
      const { getCallById } = CallReport;
      this.loading = true;
      this.error = false;
      try {
        const res = await getCallById(this.activityId);

        const fromId = res?.fromId;
        const isMineCall = fromId === this.user.id || res.from === this.user.endpoint;
        const callerId = isMineCall ? res.toId : fromId;
        const callerNumber = isMineCall ? res.toId : res.from;

        await this.setContactOrOperator({
          id: callerId,
          number: callerNumber,
        });

        this.activity = res;
      } catch (e) {
        console.error(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getCallInfo();
  },
};
</script>
