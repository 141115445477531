<template lang="pug">
.w-full.font-plus.break-words.text-sm.font-bold(
  :class="[isRecipient ? 'text-zinc-700 dark:text-black' : 'text-zinc-100 dark:text-white']"  
) {{ message.messageHeader }}
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    isRecipient() {
      return this.message?.recipientId === this.user.id;
    },
  },
});
</script>
