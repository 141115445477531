<template lang="pug">
.px-4.py-2.border.rounded-full.truncate.flex.items-center.justify-center
  p.mr-2 {{ tagName }}
  .material-icons.notranslate.text-sm.text-zinc-400.cursor-pointer(
    v-if="!preventTagEdit"
    @click="deleteTag"
    class="hover:text-red-500"
    v-tooltip="'Remover tag'"
  ) close
</template>

<script>
import rhService from '@/services/rship.js';
import { deleteCallTag } from '@/services/call-report.js';
import { deleteChatTag } from '@/services/chat.js';
export default {
  props: ['callTag', 'type'],
  data() {
    return {
      loaded: false,
      tagName: '',
    };
  },
  methods: {
    async deleteTag() {
      try {
        if (this.type === 'chat') {
          await deleteChatTag(this.callTag.id);
          await rhService.createTag({
            eventType: 'tag-removed',
            companyId: this.user.company_id,
            identifier: this.user.username,
            callId: this.callTag.chatRoomId,
            text: this.tagName,
            createdBy: this.user.id,
          });
        } else {
          await deleteCallTag(this.callTag.id);
          await rhService.createTag({
            eventType: 'tag-removed',
            companyId: this.user.company_id,
            identifier: this.user.username,
            callId: this.callTag.callId,
            text: this.tagName,
            createdBy: this.user.id,
          });
        }
        this.$toast.success('Tag removida com sucesso!');
        this.$emit('tag-removed', this.callTag.id);
      } catch (error) {
        console.error(error);
        this.$toast.error('Não foi possivel remover essa tag...');
      }
    },
  },
  computed: {
    preventTagEdit() {
      return this.$store.getters['callTagsState/preventTagEdit'];
    },
  },
  created() {
    this.tagName = this.callTag.tagName || this.callTag.text;
  },
};
</script>
