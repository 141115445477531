<template>
  <div v-click-outside="checkDisplay" class="history-dropdown-content">
    <div @click="openDropdown" class="dropdown-box">
      <input
        @input="filterData"
        @focus="filterData"
        placeholder="Fila"
        v-model="filterValue"
        class="mask-dropdown-input"
      />
      <span
        v-if="activitySelected === 'Fila'"
        class="material-icons notranslate dropdown-icon"
      >
        keyboard_arrow_down</span
      >
      <span
        v-else
        @click.stop="cancelSearch"
        class="material-icons notranslate dropdown-icon"
      >
        cancel</span
      >
    </div>
    <ul v-if="searching" class="dropdown-list">
      <li
        class="dropdown-option"
        v-for="(data, index) in filteredOptions"
        :key="index"
        @click="selectType(data)"
      >
        {{ data.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["options"],
  data() {
    return {
      searching: false,
      activitySelected: "Fila",
      filteredOptions: [],
      filterValue: "",
    };
  },
  methods: {
    filterData() {
      this.filteredOptions = this.options.filter((element) => {
        return element.name.toLowerCase().startsWith(this.filterValue.toLowerCase());
      });
    },
    cancelSearch() {
      this.activitySelected = "Fila";
      this.filterValue = "";
      this.$emit("filter", "");
    },
    checkDisplay(e) {
      if (e.target.className != "dropdown-option") {
        this.searching = false;
      }
    },
    selectType(data) {
      this.filterValue = data.name;
      this.activitySelected = data;
      this.searching = false;
      this.$emit("filter", data.name);
    },
    openDropdown() {
      this.searching = !this.searching;
    },
  },
  created() {
    this.filteredOptions = this.options;
  },
};
</script>

<style lang="scss" scoped>
.mask-dropdown-input {
  background: none;
  width: 90%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 16px;
  color: #868e96 !important;
  border: none;
  outline: none;
  height: 100%;
}
.history-dropdown-content {
  width: 18%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
:hover {
  color: var(--brand-color);
}
.dropdown-list {
  margin-top: 56px;
  padding-top: 16px;
  width: max-content;
  position: absolute;
  padding-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-left: 0px !important;
}
.dropdown-option {
  height: 40px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: 0.1s;
}
.dropdown-option:hover {
  background: #f8f9fa;
}
.dropdown-box {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.dropdown-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  width: 80%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #868e96;
}
</style>