<template lang="pug">
tr.callback-table-row.text-inside-brand(@click="$emit('openOverlay', callback)")
  th.callback-origin-content(scope='row')
    .callback-origin-text {{data.type == 'Chat' ? 'Abandono em chat \npara' : 'Abandono em ligação \npara'}}
    .callback-origin-tag Fila {{ data.description }}
  td.callback-callto-content
    .callback-callto-content-aligner
      .callback-callto-user-img(v-if='failedImg') {{ identityContact?.name[0]?.toUpperCase() }}
      img.callback-callto-user-img(
        v-else-if='!failedImg',
        :src='`${requestLinks.clist}/uploads/${identityContact?.avatar}.jpg`',
        @error='failedImg = true'
      )
      .callback-callto-info-aligner
        p.callback-callto-name-text
          | {{ identityContact?.name }}
        p.callback-callto-number-text(v-if="data.type == 'Chat' && identityContact?.phones")
          | {{ formatInputValue({ number: identityContact?.phones[0]?.number, country: 'BR' }) }}
        p.callback-callto-number-text(v-if="data.type != 'Chat' && identityContact?.phones")
          | {{ formatInputValue({ number: identityContact?.phones[0]?.number, country: 'BR' }) }}
      .callback-callto-tag-margin.user-tag(v-if='identityContact.category')
        | {{ identityContact?.category?.name }}
      .unknown-text.no-tag-margin(v-else) Sem categoria
  td.callback-obs-content
    p Abandonado em <b>{{ data.description }}</b> após {{ abandonTime(data.type === 'Chat' ? callback.payload.waitTime : callback.payload.date) }}
  td.callback-add-content.font-inter {{ timeAdded(callback.payload.createdAt) }}
  td.callback-actions-content.pointer-events-none(@click.stop="")
    v-dropdown.pointer-events-auto.mx-4(placement="bottom-start")
      button.flex.items-center.cursor-pointer.select-none.mr-1.border.transition.px-3.py-1.pr-1.rounded-md.group(
        class="hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10"
      )
        .font-plus.font-semibold.text-xs.text-zinc-500(
          class="group-hover:text-brand"
        ) Ações
        .material-icons.notranslate.text-gray-400(class="group-hover:text-brand") keyboard_arrow_down
      template(#popper)
        ul.divide-y
          dropdown-item(
            v-if="identityContact?.categoryId"
            @click="startChat",
            label="Iniciar conversa",
            icon="e0b7"
          )
          dropdown-item(
            @click="$emit('calling', { payload: getPayload(data.contactId) , index: index })",
            :label="'Ligar para ' + identityContact?.name || 'Contato desconhecido'",
            icon="e0b0"
          )
          dropdown-item(
            @click="$root.$emit('action', { actualModal: 'FinishActivity', actualActivity: callback })"
            label="Concluir atividade",
            icon="e876"
          )
          dropdown-item-alert(
            @click="$root.$emit('action', { actualModal: 'CancelActivity', actualActivity: callback })"
            label="Cancelar atividade",
            icon="E5CD"
          )
</template>

<script>
import { getContactByPhone, getContactById } from '@/services/contact-list.js';
import { Dropdown } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import DropdownItemAlert from '@/components/Dropdown/dropdown-item-alert.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default {
  mixins: [UserOrContactMixin],
  props: ['data', 'index'],
  components: {
    VDropdown: Dropdown,
    DropdownItem,
    DropdownItemAlert,
  },
  data() {
    return {
      failedImg: false,
      callback: {
        payload: {},
        assignedBy: {},
        assignedTo: {},
        contact: {
          username: '??',
        },
      },
    };
  },
  methods: {
    getPayload(data) {
      if (data) return { ...this.callback.payload, contactNumber: this.identityContact.phones[0].number };
      return this.callback.payload;
    },
    async startChat() {
      await this.setResponsible(this.callback.payload.id);
      this.$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: this.identityContact } });
    },
    async setResponsible(id) {
      try {
        const res = await this.$http.post(`${this.requestLinks.cblist}/callback/assign`, {
          user: this.user.id,
          callbackId: id,
        });
        this.$log.info(res.data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async getAssignedBy() {
      if (this.data.assignedBy === 'AUTO') return;
      try {
        const userLookUp = this.$store.getters['USER_LOOKUP_TABLE'](this.data.assignedBy);
        if (userLookUp) {
          this.callback.assignedBy = userLookUp;
          return;
        }
        const assignedBy = await this.$http.get(
          `${this.requestLinks.cservice}/companies/users/search/${this.data.assignedBy}`,
        );
        this.$store.commit('ADD_TO_LOOK_UP_TABLE', {
          key: this.data.assignedBy,
          user: assignedBy.data.user,
        });
        this.callback.assignedBy = assignedBy.data.user;
      } catch (e) {}
    },
    async getContactById() {
      await this.setContactOrOperator({
        id: this.data.contactId,
      });
    },
    async getContact() {
      try {
        const userLookUp = this.$store.getters['USER_LOOKUP_TABLE'](this.data.contactNumber.replace(/[^\d]+/g, ''));
        if (userLookUp) {
          this.identityContact = userLookUp;
          return;
        }
        const res = await getContactByPhone({
          number: this.data.contactNumber.replace(/[^\d]+/g, ''),
          companyId: this.user.company_id,
        });
        const userFound = {
          ...res.data.contact,
          username: res.data.contact.name,
          avatar: res.data.contact.id,
        };
        this.$store.commit('ADD_TO_LOOK_UP_TABLE', {
          key: this.data.contactNumber.replace(/[^\d]+/g, ''),
          user: userFound,
        });
        this.identityContact = userFound;
      } catch (e) {
        this.getContactFromCservice().catch(
          () =>
            (this.identityContact = {
              username: 'Contato desconhecido',
              avatar: '?',
            }),
        );
      }
    },
    async getContactFromCservice() {
      try {
        const userLookUp = this.$store.getters['USER_LOOKUP_TABLE'](this.data.contactNumber.replace(/[^\d]+/g, ''));
        if (userLookUp) {
          this.identityContact = userLookUp;
          return;
        }
        const contact = await this.$http.post(`${this.requestLinks.cservice}/companies/users/devices/search`, {
          company_id: this.data.companyId,
          peer: this.data.contactNumber.replace(/[^\d]+/g, ''),
        });
        const userFound = contact.data.users_devices;
        this.$store.commit('ADD_TO_LOOK_UP_TABLE', {
          key: this.data.contactNumber.replace(/[^\d]+/g, ''),
          user: userFound,
        });
        this.identityContact = userFound;
      } catch (e) {
        return Promise.reject(e);
      }
    },

    abandonTime(holdtime) {
      if (!holdtime) return '0 segundos';
      if (holdtime > 59 && holdtime < 3600) {
        return parseInt(holdtime / 60) + ' minutos';
      } else if (holdtime < 60) {
        return parseInt(holdtime) + ' segundos';
      } else if (holdtime > 3599 && holdtime < 3600 * 24) {
        if (parseInt(holdtime / 3600) === 1) {
          return '1 hora';
        }
        return parseInt(holdtime / 3600) + ' horas';
      } else if (holdtime > 3600 * 24) {
        return parseInt(holdtime / 3600 / 24) + ' dias';
      }
    },
    formatInputValue(number) {
      try {
        if (!number.number) return;
        if (number.number.length > 4) {
          return this.phoneUtil.format(
            this.phoneUtil.parseAndKeepRawInput(number.number, 'BR'),
            this.PNF.INTERNATIONAL,
          );
        } else if (number.number.length === 4) {
          return 'SIP ' + number.number;
        }
        return number.number;
      } catch (e) {
        return number.number;
      }
    },

    timeAdded(date) {
      let dateNow = new Date();
      let addedTime = (dateNow - new Date(date)) / 1000;
      if (addedTime > 59 && addedTime < 3600) {
        return 'Há ' + parseInt(addedTime / 60) + ' min';
      } else if (addedTime < 60) {
        return 'Há ' + parseInt(addedTime) + ' seg';
      } else if (addedTime > 3599 && addedTime < 3600 * 24) {
        if (parseInt(addedTime / 3600) === 1) {
          return 'Há 1 hora';
        }
        return 'Há ' + parseInt(addedTime / 3600) + ' horas';
      } else if (addedTime > 3600 * 24) {
        return 'Há ' + parseInt(addedTime / 3600 / 24) + ' dias';
      }
    },
  },
  async created() {
    this.callback.payload = this.data;
    this.getAssignedBy();
    if (this.data.contactId) await this.getContactById();
    else this.getContact();
  },
};
</script>
