<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.px-6.w-96
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter Transferir Conversa
        i.cancel.material-icons.notranslate.cursor-pointer(
          @click="$store.commit('modal/setActiveModal', '')"
        ) &#xe5cd
      p.text-xs.text-zinc-400.text-left.w-full.mt-4 Selecione um colega para transferir esta conversa.
      icon-input.m-4(
        icon="search" v-model="userName" label="Nome do colega"
      )
      div.max-h-56.w-full.overflow-x-hidden.overflow-y-auto
        TransitionGroup(name="slide-fade" :duration="200" mode="out-in")
          transfer-user-card(
            v-for="user in filteredUsers",
            :userName="user.username",
            :userAvatar="user.avatar",
            :userId="user.id",
            :key="user.id",
            :selected="selectedUser == user.id",
            @select="select(user.id)"
          )
      div.w-full.flex.gap-4.mt-4
        v-button-secondary(
          @click="$store.commit('modal/setActiveModal', '')"
          ) Fechar
        v-button(
          @click="transfer()"
          ) Transferir
  </template>

<script>
import Overlay from '@/components/Modals/Overlay.vue';
import TagInputChat from '@/components/Inputs/TagInputChat.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import IconInput from '../Inputs/IconInput.vue';
import csService from '@/services/cservice';
import chatService from '@/services/chat.js';
import TransferUserCard from '../../components/Cards/TransferUserCard.vue';
export default {
  components: {
    Overlay,
    TagInputChat,
    VButtonSecondary,
    VButton,
    IconInput,
    TransferUserCard,
  },
  data() {
    return {
      userName: '',
      userList: [],
      selectedUser: '',
      csAddress: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CSERVICE}`,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
    chatRoomId() {
      const { id } = this.$store.getters['email/activeEmail'];
      return id;
    },
    activeEmail() {
      return this.$store.getters['email/activeEmail'];
    },
    filteredUsers() {
      return this.userList.filter((user) => {
        const memberAlreadyJoined = this.activeEmail?.members?.find(
          (member) => member.recipientId === user.id && member.exited === false,
        );
        if (memberAlreadyJoined) return false;
        return !!user.username.toLowerCase().includes(this.userName.toLowerCase());
      });
    },
  },
  methods: {
    select(id) {
      if (this.selectedUser == id) {
        this.selectedUser = '';
        return;
      }
      this.selectedUser = id;
    },
    async transfer() {
      try {
        console.log(this.activeEmail);
        await chatService.createTransferenceInvitation({
          companyId: this.user.company_id,
          opensUserId: this.selectedUser,
          chatRoomId: this.chatRoomId,
          delegatorId: this.user.id,
          provider: this.activeEmail.creatorProvider,
          type: 'transference',
        });
        this.$store.commit('chat/addTransference', this.chatRoomId);
        this.$store.commit('modal/setActiveModal', '');
        this.$toast.success('Transferência iniciada com sucesso');
      } catch (error) {
        console.error(error);
        const errorMessage =
          error?.response?.data?.message || 'Não foi possível iniciar transferência, tente novamente mais tarde';
        this.$toast.error(errorMessage);
      }
    },
  },
  async created() {
    try {
      const data = await csService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
      data.forEach((element) => {
        if (element.id !== this.user.id) {
          this.userList.push(element);
        }
      });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
