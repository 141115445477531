import services from '@/services';

export default {
  namespaced: true,
  state: {
    user: null,
    darkMode: false,
    devices: {
      sip: [],
      mobile: [],
    },
    schedule: [],
    actions: {
      linked_first_device: true,
    },
    newActivity: false,
  },
  mutations: {
    SET_DARKMODE: function (state, payload) {
      state.darkMode = payload;
    },
    SET_NEWACTIVITY: function (state, payload) {
      state.newActivity = payload;
    },
    SET_ACTIONS: function (state, payload) {
      state.actions = payload;
    },
    SET_USER: function (state, payload) {
      if (!state.user) return (state.user = Object.assign({}, { ...payload }));
      state.user = { ...state.user, ...payload };
    },
    SET_DEVICES: function (state, payload) {
      state.devices.sip = payload.sip;
      state.devices.mobile = payload.mobile;
    },
    SET_SCHEDULE: function (state, payload) {
      state.schedule = payload;
    },
  },
  getters: {
    GET_USER: function (state) {
      if (state.user) return state.user;
      return {};
    },
    GET_NEWACTIVITY: function (state) {
      return state.newActivity;
    },
    GET_DARKMODE: function (state) {
      return state.darkMode;
    },
    GET_DEVICES: function (state) {
      return { ...state.devices };
    },
    GET_SCHEDULE: function (state) {
      return { ...state.schedule };
    },
    HAS_ACTIVE_DEVICE: function (state) {
      for (const device in state.devices.sip) {
        if (device.status === 'enabled') return true;
      }
      for (const device in state.devices.mobile) {
        if (device.status === 'enabled') return true;
      }

      return false;
    },
    GET_ACTIONS: function (state) {
      return state.actions;
    },
  },
  actions: {
    async requestDevices({ commit }, $http) {
      try {
        const schema = process.env.VUE_APP_SCHEMA;
        const cservice = process.env.VUE_APP_CSERVICE;

        const r = await $http.get(`${schema}://${cservice}/companies/users/devices`);
        const devices = r.data.users_devices.filter((d) => {
          return d.type == 'peer' && (d.status === 'linked' || d.status === 'enabled');
        });
        const mobiles = r.data.users_devices.filter((d) => {
          return d.type == 'mobile' && (d.status === 'linked' || d.status === 'enabled');
        });

        commit('SET_DEVICES', {
          sip: devices,
          mobile: mobiles,
        });
        return;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async requestUser({ commit }, $http) {
      const schema = process.env.VUE_APP_SCHEMA;
      const cservice = process.env.VUE_APP_CSERVICE;
      try {
        const user = await $http.get(`${schema}://${cservice}/companies/users/search`);
        commit('SET_USER', user.data.user);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async requestUserActions({ commit, getters }, $http) {
      try {
        const actions = await services.customerService.getUserActions($http, {
          user_id: getters.GET_USER.id,
        });
        commit('SET_ACTIONS', actions);
      } catch (e) {}
    },
    async updateUser({ commit }, { $http, payload, endpoint }) {
      const schema = process.env.VUE_APP_SCHEMA;
      const cservice = process.env.VUE_APP_CSERVICE;

      try {
        const res = await $http.put(`${schema}://${cservice}/${endpoint}`, payload);
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
};

function sortedIndex(arr, val) {
  var low = 0,
    high = arr.length;

  while (low < high) {
    var mid = (low + high) >>> 1;
    if (arr[mid] < val) low = mid + 1;
    else high = mid;
  }
  return low;
}
