<template lang="pug">
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Adicione um Device
    i.cancel.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd

  template(v-slot:content)
    p.content Selecione o tipo de device que deseja adicionar
    .content__options
      .content__options__item(
        @click="$store.commit('modal/setActiveModal', 'SoftphoneInstallOption')"
      )
        .content__options__item__icon
          IconCircularBg.w-12.h-12(:icon="'devices'")
        p.content__options__item__text Adicionar &nbsp;
          b Softphone
        .content__options__item__icon
          IconCircularBg.w-8.h-8(:icon="'arrow_forward'")

      .content__options__item(
        @click="$store.commit('modal/setActiveModal', 'LinkTableDevice')"
      )
        .content__options__item__icon
          IconCircularBg.w-12.h-12(:icon="'phone'")
        p.content__options__item__text Adicionar &nbsp;<b>Telefone SIP de mesa</b>
        .content__options__item__icon
          IconCircularBg.w-8.h-8(:icon="'arrow_forward'")
  template(v-slot:footer)
    p.footer__cancel(@click="$store.commit('modal/setActiveModal', '')") Cancelar
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
};
</script>

<style lang='scss' scoped>
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #868e96;
}

.cancel {
  border-radius: 100%;
  margin-top: 20px;
  width: 30px;
  height: 30px;
  color: rgb(197, 197, 197);
  cursor: pointer;
}

.content {
  display: block;
  margin-top: 20px;
  text-align: left;
  color: #868e96;

  &__options {
    width: 100%;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      margin: 25px 0 20px 0;
      height: 5.5rem;
      width: 100%;
      border: 1px solid #747b8149;
      cursor: pointer;

      &:hover {
        border: 1px solid #ff480049;
      }

      &__text {
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        flex: 2;
        margin: 0;
        color: #3d3d3d;
      }
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1;
      }
    }
  }
}

.footer {
  &__cancel {
    cursor: pointer;
    font-size: 14px;
    color: rgb(252, 105, 52);
    font-weight: 600;
  }
}
</style>