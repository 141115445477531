<template lang="pug">
v-dropdown
  .dropdown-box
    span.dropdown-text {{ activitySelected }}
    span.material-icons.notranslate.dropdown-icon(v-if="activitySelected === 'Período'")
      | keyboard_arrow_down
    span.material-icons.notranslate.dropdown-icon(v-else='' @click.stop='cancelSearch')
      | cancel
  template(#popper)
    v-date-picker.calendar-box(color='orange' :columns='$screens({ lg: 2 }, 1)' v-model='range' is-range='')
</template>

<script>
import { Dropdown } from "floating-vue";
export default {
  props: ["options"],
  components: {
    VDropdown: Dropdown,
  },
  data() {
    return {
      range: null,
      activitySelected: "Período",
    };
  },
  methods: {
    cancelSearch() {
      this.activitySelected = "Período";
      this.$emit("filter", {
        start: new Date("2000-05-06T17:05:47.796Z"),
        end: new Date("3000-05-06T17:05:47.796Z"),
      });
    },
    checkDisplay(e) {
      if (e.target.className != "dropdown-option") {
        this.searching = false;
      }
    },
    selectType(data) {
      this.searching = false;
      this.$log.info(new Date(data.start).toISOString());
      const start = new Date(data.start).setHours(0, 0, 0, 0);
      const end = new Date(data.end).setHours(23, 59, 59);
      let date = { start, end };
      this.activitySelected =
        this.activityDateFormat(new Date(data.start).toISOString()) +
        " - " +
        this.activityDateFormat(new Date(data.end).toISOString());
      this.$emit("filter", date);
    },
    activityDateFormat(item) {
      let day = item.split("T")[0];
      let separe = day.split("-");
      this.$log.info(item);
      let formattedDay = `${separe[2]}/${separe[1]}/${separe[0]}`;

      let hour = item.split("T")[1].split(".")[0];

      let formattedDate = `${formattedDay}`;

      return formattedDate;
    },
  },
  watch: {
    range: function (value) {
      this.selectType(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.history-dropdown-content {
  width: 22%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
:hover {
  color: var(--brand-color);
}
.dropdown-box {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.dropdown-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  width: 80%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #868e96;
}
</style>