import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_QUEUE_PREFERENCES_SERVICE}`;
/**
 *
 * @param {object} payload
 * @param {string} payload.companyId
 * @param {string} payload.queueId
 *
 */
async function getQueuePreferences(payload) {
  try {
    const response = await $http.get(
      `${service}/queue-preferences?companyId=${payload.companyId}&queueId=${payload.queueId}`,
    );
    return response.data?.data || [];
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 *
 * @param {object} payload
 * @param {string} payload.companyId
 * @param {string} payload.groupId
 */
async function getGroupPreferences(payload) {
  try {
    const response = await $http.get(
      `${service}/queue-preferences?companyId=${payload.companyId}&groupId=${payload.groupId}`,
    );
    return response.data?.data || [];
  } catch (e) {
    return Promise.reject(e);
  }
}
export default {
  getQueuePreferences,
  getGroupPreferences,
};
