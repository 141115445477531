export default {
  IS_SUPER(state) {
    return state.isSuper;
  },
  IS_EDITOR(state) {
    return state.isEditor;
  },
  PERFORMANCE_REPORT(state) {
    return {
      event_total: state.event_total,
      abandons: state.abandons,
      answers: state.answers,
      timeouts: state.timeouts,
      transfers: state.transfers,
      tma: state.tma,
      tme: state.tme,
      unique: state.unique,
      map: state.map,
      map_week: state.map_week,
      map_hour: state.map_hour,
      csatSatisfaction: state.csatSatisfaction,
      csatScores: state.csatScores,
      callbackComplete: state.callbackComplete,
      callbackIncomplete: state.callbackIncomplete,
      agent_hangup: state.agent_hangup,
      client_hangup: state.client_hangup,
      sent_to_evaluation: state.sent_to_evaluation,
      evaluated: state.evaluated,
      occupationRate: state.occupationRate,
      sla_targets: state.sla_targets,
      sla_target_abandon: state.sla_target_abandon,
    };
  },
  CALLS(state) {
    return {
      calls: state.calls,
      callbacks: state.callbacks,
      calls_list: state.calls_list,
    };
  },
  QUEUES(state) {
    return state.queues;
  },
  PERIOD(state) {
    return {
      start: state.start_time,
      end: state.end_time,
    };
  },
  SELECTED_QUEUES(state) {
    return state.selectedQueues;
  },
  PROGRESS(state) {
    return {
      inProgress: state.inProgress,
      progress: state.progress,
    };
  },
  CONFIG(state) {
    return state.config;
  },
  FILTERS(state) {
    return {
      filter_received_answered: state.filter_received_answered,
      filter_received_not_answered: state.filter_received_not_answered,
      filter_made_answered: state.filter_made_answered,
      filter_made_not_answered: state.filter_made_not_answered,
      filter_answered: state.filter_answered,
      filter_timeout: state.filter_timeout,
      filter_client_hangup: state.filter_client_hangup,
      filter_transferred: state.filter_transferred,
      filter_abandoned: state.filter_abandoned,
      filter_sent_to_evaluation: state.filter_sent_to_evaluation,
      filter_csat: state.filter_csat,
      filter_protocol: state.filter_protocol,
      filter_tags: state.filter_tags,
      filter_contact: state.filter_contact,
      filter_category: state.filter_category,
      filter_agent_hangup: state.filter_agent_hangup,
      filter_number: state.filter_number,
      filter_tme_range: state.filter_tme_range,
      filter_tma_range: state.filter_tma_range,
    };
  },
  FORMAT(state) {
    return {
      format: state.format,
      formatDiff: state.formatDiff,
    };
  },
  WORK_GROUPS(state) {
    return state.user.workGroups;
  },
  USERS(state) {
    return state.user.users;
  },
  SELECTED_USERS(state) {
    return state.user.selectedUsers;
  },
  USER_PERFORMANCE(state) {
    return state.user.performance || {};
  },
  USER_AVAILABILITY_LIST(state) {
    return state.user.availabilityList;
  },
};
