<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.pb-8.px-10(class="w-2/5")
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter Criar organização
      .flex.flex-col.items-start.w-full.gap-2
        span.font-inter.text-sm.text-gray-400.font-medium.my-2 Preencha os campos para criar uma organização
        icon-input.w-full(label="Nome*" icon="badge" v-model="organization.name")
        .flex.gap-2.w-full
          icon-input.w-full(label="Endereço" :key="cepFormatted + 'street'" icon="apartment" v-model="organization.street")
          icon-input.w-full(label="CEP" v-model="organization.cep" icon="apartment" :key="cepFormatted" isCep)
        .flex.gap-2.w-full 
          v-dropdown.w-full(
              placement="bottom-start",
              :triggers="[]",
              :shown="showStateList",
              :autoHide="false"
            )
              icon-input.w-full(
                v-model="organization.state" 
                label="Estado" 
                @focus="showStates",
                icon="apartment"
                :key="stateChange"
                @blur="showStateList = false"
                ) 
              template(#popper)
                .max-h-72.overflow-auto.divide-y
                  li.flex.justify-start.items-center.w-96.h-10.bg-zinc-50.cursor-pointer.px-4.transition.text-gray-500(
                    v-for="state in states.filter((o) => { return o.nome.toLowerCase().startsWith(organization.state.toLowerCase()); })",
                    :key="state.sigla"
                    class="hover:bg-zinc-100 hover:text-gray-600"
                  ) 
                    p.w-full(@mousedown="selectState(state)"
                    ) {{ state.nome }}
                    span.ml-auto.text-brand-400.font-semibold {{state.sigla}}
          v-dropdown.w-full(
              placement="bottom-start",
              :triggers="[]",
              :shown="showCityList",
              :autoHide="false"
            )
              icon-input.w-full(
                v-model="organization.city" 
                label="Cidade" 
                icon="apartment"
                @focus="showCities",
                :key="cityChange"
                @blur="showCityList = false"
                ) 
              template(#popper)
                .max-h-72.overflow-auto.divide-y
                  li.flex.justify-start.items-center.w-96.h-10.bg-zinc-50.cursor-pointer.px-4.transition.text-gray-500(
                    v-for="city in cities.filter((o) => { return o.nome.toLowerCase().startsWith(organization.city.toLowerCase()); })",
                    :key="city.nome"
                    class="hover:bg-zinc-100 hover:text-gray-600"
                  ) 
                    p.w-full.capitalize(@mousedown="selectCity(city)"
                    ) {{ city.nome.toLowerCase() }}
        icon-input.w-full(label="Site" icon="public" v-model="organization.site")
        icon-input.w-full(label="Número de telefone principal" isPhoneNumber icon="phone" v-model="organization.phone")
        .flex.gap-2.w-full
          icon-input.w-full(label="E-mail" icon="mail" v-model="organization.mail")
          icon-input.w-full(label="CNPJ" icon="apartment" isCnpj v-model="organization.cnpj")
      .flex.w-full.items-start.justify-center.mt-6.gap-2
          v-button-secondary(
            @click="$store.commit('modal/setActiveModal', '');"
          ) Cancelar
          v-button(@click="createOrganization()") Criar organização
</template>
<script>
import Overlay from '@/components/Modals/Overlay.vue';
import IconInput from '../Inputs/IconInput.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import { Dropdown } from 'floating-vue';
import contactList from '@/services/contact-list';
import brasilapi from '@/services/brasilapi';

export default {
  components: {
    Overlay,
    IconInput,
    VButton,
    VButtonSecondary,
    VDropdown: Dropdown,
  },
  data() {
    return {
      organization: {
        name: '',
        site: '',
        state: '',
        city: '',
        street: '',
        cep: '',
        mail: '',
        cnpj: '',
        phone: '',
      },
      states: [
        {
          id: 11,
          sigla: 'RO',
          nome: 'Rondônia',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 12,
          sigla: 'AC',
          nome: 'Acre',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 13,
          sigla: 'AM',
          nome: 'Amazonas',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 14,
          sigla: 'RR',
          nome: 'Roraima',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 15,
          sigla: 'PA',
          nome: 'Pará',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 16,
          sigla: 'AP',
          nome: 'Amapá',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 17,
          sigla: 'TO',
          nome: 'Tocantins',
          regiao: { id: 1, sigla: 'N', nome: 'Norte' },
        },
        {
          id: 21,
          sigla: 'MA',
          nome: 'Maranhão',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 22,
          sigla: 'PI',
          nome: 'Piauí',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 23,
          sigla: 'CE',
          nome: 'Ceará',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 24,
          sigla: 'RN',
          nome: 'Rio Grande do Norte',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 25,
          sigla: 'PB',
          nome: 'Paraíba',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 26,
          sigla: 'PE',
          nome: 'Pernambuco',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 27,
          sigla: 'AL',
          nome: 'Alagoas',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 28,
          sigla: 'SE',
          nome: 'Sergipe',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 29,
          sigla: 'BA',
          nome: 'Bahia',
          regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' },
        },
        {
          id: 31,
          sigla: 'MG',
          nome: 'Minas Gerais',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
        {
          id: 32,
          sigla: 'ES',
          nome: 'Espírito Santo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
        {
          id: 33,
          sigla: 'RJ',
          nome: 'Rio de Janeiro',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
        {
          id: 35,
          sigla: 'SP',
          nome: 'São Paulo',
          regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' },
        },
        {
          id: 41,
          sigla: 'PR',
          nome: 'Paraná',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
        {
          id: 42,
          sigla: 'SC',
          nome: 'Santa Catarina',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
        {
          id: 43,
          sigla: 'RS',
          nome: 'Rio Grande do Sul',
          regiao: { id: 4, sigla: 'S', nome: 'Sul' },
        },
        {
          id: 50,
          sigla: 'MS',
          nome: 'Mato Grosso do Sul',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
        {
          id: 51,
          sigla: 'MT',
          nome: 'Mato Grosso',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
        {
          id: 52,
          sigla: 'GO',
          nome: 'Goiás',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
        {
          id: 53,
          sigla: 'DF',
          nome: 'Distrito Federal',
          regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' },
        },
      ],
      nameError: false,
      showStateList: false,
      showCityList: false,
      stateChange: 0,
      cityChange: 0,
      cepFormatted: false,
      cities: [],
    };
  },
  computed: {
    cep() {
      return this.organization.cep;
    },
  },
  watch: {
    async cepFormatted(value) {
      if (value) {
        await this.searchDataByCep();
        this.cepFormatted = false;
      }
    },
    cep(val) {
      var re = /([\d]{5})-*([\d]{3})/;

      if (re.test(val)) {
        this.cepFormatted = true;
        this.organization.cep = val.replace(re, '$1-$2');
        return val.replace(re, '$1-$2');
      }
      return (this.cepFormatted = false);
    },
  },
  methods: {
    async searchDataByCep() {
      const { getInfoByCep } = brasilapi;
      try {
        const data = await getInfoByCep(this.organization.cep);
        this.organization.state = data.state;
        this.organization.city = data.city;
        let street = '';
        let neighborhood = '';
        if (data.street) street = data.street.includes(' - até') ? data.street.replace(' - até', '') : data.street;
        if (data.neighborhood) neighborhood = data.neighborhood;
        street && neighborhood
          ? (this.organization.street = `${street}, ${neighborhood}`)
          : (this.organization.street = '');
        this.stateChange++;
        this.cityChange++;
      } catch (error) {
        this.$toast.error('Falha ao resgatar cidades!');
      }
    },
    showCities() {
      this.showCityList = true;
    },
    showStates() {
      this.showStateList = true;
    },
    selectCity(city) {
      this.organization.city = city.nome;
      this.showCityList = false;
      this.cityChange++;
    },
    async selectState(state) {
      this.organization.state = state.sigla;
      this.showStateList = false;
      this.stateChange++;
      await this.getCities(state.sigla);
    },
    async getCities(sigla) {
      const { getCitiesByState } = brasilapi;
      try {
        this.cities = await getCitiesByState(sigla);
      } catch (error) {
        this.$toast.error('Falha ao resgatar cidades!');
      }
    },
    verifyInputs() {
      if (
        (this.organization.phone != '' && this.organization.phone.length < 14) ||
        this.organization.name == '' ||
        (this.organization.cnpj != '' && this.organization.cnpj.length != 18)
      )
        return false;
      return true;
    },
    async createOrganization() {
      if (!this.verifyInputs())
        return this.$toast.error(
          'Verifique se todos os campos estão corretamente preenchidos antes de criar esta organização',
        );
      const { createOrganization } = contactList;
      try {
        const data = await createOrganization(this.organization);
        this.$store.commit('modal/setActiveModal', '');
        this.$toast.success('Organização criada com sucesso!');
      } catch (error) {
        this.$toast.error(error);
      }
    },
  },
};
</script>
