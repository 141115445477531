<template lang="pug">
.org.flex.flex-col.items-start.justify-start.relative
  slot(name="input")
  .absolute.z-10.bg-gray-100.max-h-48.overflow-y-auto.w-96.rounded.inset-x-0.mt-28(
    v-if="visible",
  )
    slot(name="options")
</template>

<script>
export default {
  props: ["visible"],
};
</script>
<style lang='scss' scoped>
.up-placeholder {
  font-family: "Inter", sans-serif;
}
.org {
  display: inline-block;
  position: relative;
  width: 100%;

  li {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    padding-left: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #868e96;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
  }
  li:hover {
    background: #f8f9fa;
  }
}
</style>
