<template lang="pug">
Onboarding
  template(v-slot:header) 
    h4.text-2xl.text-gray-400.font-extrabold Instale o Softphone
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    p.my-8.text-gray-400 Ao iniciar o softphone Telephone, a caixa de configuração da conta abrirá automaticamente ou a partir do menu Telephone > Preferências > Contas. No softphone, adicione os dados de autenticação abaixo e clique em “Concluir”.
    .divide-y.divide-gray-200
      SimpleLabelDisplay.mb-2(:label="'Servidor / Domain'", :value="server")
      SimpleLabelDisplay.mb-2(:label="'Usuário / Username'", :value="user")
      SimpleLabelDisplay.mb-1(:label="'Senha / Password'", :value="password")
    p.my-8.text-gray-400 Após concluir a configuração, avance para o próximo passo
  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-32.text-brand(@click="askForHelp()" class="dark:invert") Preciso de Ajuda
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'InstallMacInstructions')"
      ) AVANÇAR
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import SimpleLabelDisplay from "@/components/Labels/SimpleLabelDisplay.vue";
import services from "@/services";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
import OnboardingMixin from "@/mixins/onboarding.js";
export default {
  mixins: [OpenLinkMixin, OnboardingMixin],
  data() {
    return {
      password: "",
      server: "",
      user: "",
    };
  },
  components: {
    Onboarding,
    SimpleLabelDisplay,
  },
  async created() {
    try {
      const res = await services.customerService.getDeviceConfig();
      this.user = res.name;
      this.server = res.server;
      this.password = res.password;
    } catch (e) {
    }
  },
};
</script>