<template lang="pug">
.w-full
    //- Audio
    .flex(v-if="!loading && !error")
      audio-player.w-80(
        :source="audioFile", :bg="'bg-transparent'")
      f-dropdown.mr-1(placement="bottom-start")
        button.flex.items-center.cursor-pointer.select-none.mr-1.transition.w-8.h-8.rounded-full.group.bg-gray-100(
          class="active:scale-95"
        )
          .material-icons.notranslate.text-gray-900(class="group-hover:text-brand dark:invert") more_vert
        template(#popper)
          ul.divide-y
            dropdown-item(
              @click="downloadAudio",
              label="Baixar áudio",
              icon="e2c4"
            )
      
    //- Loading esqueleto
    .flex.w-80.h-10.animate-pulse.items-center.select-none.px-4.rounded.justify-center.h-12(
      v-else-if="loading && !error", class="dark:bg-zinc-700"
    )
      .material-icons.notranslate.text-gray-400.bg-gray-300.rounded-full play_arrow
      div.bg-gray-300.h-2.w-full.rounded-full.m-2
    
    //- Error
    p.text-xs.text-red-500.flex.rounded.px-4.py-2.cursor-pointer.mb-1.bg-opacity-10(
      class="hover:bg-gray-400 hover:bg-opacity-20"
      :class="['bg-zinc-600']"
      v-else
    ) {{ error }}
      i.material-icons.notranslate.text-xl.text-red-500.transition-all(
        @click="retryGetFile",
        class="hover:text-red-600 hover:rotate-[360deg] duration-300"
        v-tooltip="'Tente novamente'"
      ) refresh
</template>

<script>
import * as AdapterService from '@/services/adapter';
import AudioPlayer from '@/components/Player/audio-player.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
import rhService from "@/services/rship.js";
export default {
  components: {
    FDropdown: Dropdown,
    DropdownItem,
    AudioPlayer,
  },
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      audioFile: undefined,
      loading: true,
      error: null,
      intervalId: null,
    };
  },

  computed: {
    isRecipient() {
      return this.message.recipientId !== this.user.id;
    },
  },

  methods: {
    async retryGetFile() {
      this.loading = true;
      this.error = null;
      try {
        await this.waitForFileAvailability();
      } catch (error) {
        this.error = error?.message;
      } finally {
        this.loading = false;
      }
    },
    async waitForFileAvailability() {
      return new Promise(async (resolve, reject) => {
        let retries = 0;
        const intervalId = setInterval(async () => {
          try {
            const url = await AdapterService.checkStaticFileAvailability(this.message.messageBody);
            if (url) {
              this.audioFile = url;
              clearInterval(intervalId);
              resolve();
            } else {
              retries += 1;
              if (retries > 3) {
                clearInterval(intervalId);
                reject(new Error('Não foi possível carregar o arquivo.'));
              }
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(new Error('Não foi possível carregar o arquivo.'));
          }
        }, 1500);
      });
    },
    async addTrackingOfDownload() {
      const {createTag} = rhService;
      try {
        const payload = {
          eventType: "record",
          companyId: this.user.company_id,
          identifier: this.user.username,
          callId: this.message.chatRoomId,
          text: `Áudio baixado pelo usuário ${this.user.username}.`,
          eventBody: this.message.messageBody,
        }
        await createTag(payload);
        return {ok: true};
      } catch (error) {
        console.log(error)
        this.$toast.error('Erro ao salvar registro de download');
        return false;
      }
    },
    async downloadAudio() {
      try {
        // Fetch the audio file from the external URL
        const response = await fetch(this.audioFile);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const tracking = await this.addTrackingOfDownload();
        if(!tracking.ok) {
          throw new Error(`Falha ao salvar registro ${response.status}`);
        }

        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.message.messageBody; // Set your desired file name and extension

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // Clean up the URL object
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        this.$toast.error('Erro ao baixar o áudio, tente novamente mais tarde');
      }
    },
  },

  async created() {
    try {
      await this.waitForFileAvailability();
    } catch (error) {
      this.error = error?.message;
    } finally {
      this.loading = false;
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
