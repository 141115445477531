<template>
   <div>
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap" rel="stylesheet">
    <div class="login-container">
    <div class="form-container">
      <div class="inputs-group"> 
        <img class="logo" src="../../assets/images/logo.png" />
        <div class="p-1 subtitle-login"><b>LOGIN</b></div>     
        <h2 class="title">Defina uma nova senha</h2>
        <div class="subtitle-info">Para iniciar a utilização recurso, defina sua senha para um acesso seguro.</div>
        <label class="up-placeholder">Senha</label>
        <input type="password" name="email" class="form-control input" placeholder="">

       <label class="up-placeholder">Confirmar nova senha</label>
        
        <input type="password" name="password" class="form-control input" placeholder=""><br><br>

        <button type="button" class="btn btn-secondary btn-fill btn-lg button">ATIVAR CONTA</button>
      </div>
    </div>
    <div class="img-container">
      <div class="img-background">
        <img src="../../assets/images/snepseven.png" />
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>