<template lang="pug">
.movidesk-area-identify.p-0
    .movidesk-text.text-xs Relacione essa chamada à um cliente da base Movidesk para criar e editar tickets em tempo real.

    .movidesk-integration
      #movidesk-option
        MovideskDropdown(
          style="margin-bottom: 16px",
          @data-selected="dataSelected",
          :movideskToken="movideskToken"
        )
        span.movidesk-right(v-if="confirmBtn")
          a.movidesk-button(@click="confirm") Confirmar
</template>
<script>
import MovideskDropdown from "@/components/Movidesk/DropDownSelectComponent.vue";

export default {
  props: ["movideskToken"],
  components: {
    MovideskDropdown,
  },
  data: () => ({
    confirmBtn: false,
    movideskUser: {
      user: "",
      assignedTo: "",
    },
  }),
  methods: {
    confirm() {
      this.$emit("movideskConfirmClient", this.movideskUser);
    },
    dataSelected(selected) {
      this.confirmBtn = true;
      this.movideskUser = selected;
    },
  },
};
</script>