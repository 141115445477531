<template lang="pug">
overlay 
  template(v-slot:content)
    iframe.h-full(class="w-2/3" :src='context' frameborder="0" scrolling="auto")
</template>

<script>
import Overlay from "@/components/Modals/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  computed: {
    context() {
      return this.$store.getters["modal/getContext"];
    },
  },
};
</script>
