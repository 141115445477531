<template lang="pug">
.mt-0.content
  .container-fluid.pb-6
    .pt-8.m-0.pagearea
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          a.title(style="margin: 0px") Editar organização
      div(v-if="!loading").w-full.flex.flex-col.gap-2
        icon-input(label="Nome da organização*" icon="badge" v-model="organization.name")
        .flex.w-full.gap-2 
          icon-input(label="Endereço (Rua, número)" :key="cepFormatted + 'street'" icon="apartment" v-model="organization.street")
          icon-input(label="CEP" icon="apartment" isCep :key="cepFormatted" v-model="organization.cep")
        .flex.w-full.gap-2 
          icon-input(label="Estado" icon="apartment" :key="cepFormatted + 'state'" v-model="organization.state")
          icon-input(label="Cidade" icon="apartment" :key="cepFormatted + 'city'" v-model="organization.city")
        icon-input(label="Site" icon="public" v-model="organization.site")
        icon-input(label="Número principal de telefone" isPhoneNumber icon="phone" v-model="organization.phone")
        .flex.w-full.gap-2 
          icon-input(label="Email" icon="mail" v-model="organization.mail")
          icon-input(label="CNPJ" icon="apartment" isCnpj v-model="organization.cnpj")
        section-expander.w-full.mt-5(
          :title="'Contatos'"
        )
          icon-input.mt-2(label="Nome do contato" v-model="contactSearchValue")
          md-table.tableBox.w-full.mt-2(
            v-if="contactsList?.length > 0"
            :value="contactsList?.slice(currentPage * limitItems - limitItems, currentPage * limitItems)", 
          )
            md-field.md-toolbar-section-end(md-clearable)
            md-table-row.contact-clickable.cursor-pointer.rounded-md(
              slot="md-table-row",
              slot-scope="{ item }",
              class="text-inside-brand" 
            )
              md-table-cell(role="button", md-label="Nome do contato")
                .flex.gap-4
                  avatar2.w-10.h-10(
                    :src="`${requestLinks.clist}/uploads/${item.id}.jpg`"
                    :avatarId="item.id"
                  )
                  p {{ item.name }}
          pagination.ml-auto.py-2(
            v-if="contactsList?.length > 0"
            :hasMorePages="hasMorePages"
            :perPage="limitItems"
            @limit="setLimit"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
            :totalItems="contactsList?.length"
          )
          p.font-plus.p-2(v-else) Nenhum contato encontrado
        .flex.mt-10.gap-2
          v-button-secondary(@click="$router.push({ name: 'Organizations' })") Cancelar
          v-button(@click="updateOrganization") Salvar
      loading-ring.mt-10(v-else)
</template>
<script>
import VButton from '@/components/Buttons/v-button.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import Pagination from '@/components/Pagination.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import vTable from '@/components/Tables/v-table.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import cListService from '@/services/contact-list.js';
import brasilapi from '@/services/brasilapi';
import SectionExpander from '@/components/SectionExpander.vue';
import avatar2 from '@/components/Avatars/avatar2.vue';

export default {
  title: 'Opens - Organizações',
  components: {
    VButton,
    Pagination,
    IconInput,
    vTable,
    LoadingRing,
    vButtonSecondary,
    SectionExpander,
    avatar2,
  },
  data: () => ({
    organization: {
      name: '',
      site: '',
      state: '',
      city: '',
      street: '',
      cep: '',
      mail: '',
      cnpj: '',
      phone: '',
    },
    limitItems: 10,
    currentPage: 1,
    loading: true,
    cepFormatted: false,
    contactSearchValue: '',
  }),
  computed: {
    hasMorePages() {
      const totalPages = Math.ceil(this.contactsList?.length / this.limitItems);
      return this.currentPage < totalPages;
    },
    contactsList() {
      return this.organization.contacts.filter((organization) => {
        if (organization.name.toLowerCase().includes(this.contactSearchValue.toLowerCase())) return true;
        return false;
      });
    },
    cep() {
      return this.organization.cep;
    },
  },
  watch: {
    organizationSearchValue() {
      this.currentPage = 1;
    },
    async cepFormatted(value) {
      if (value) {
        await this.searchDataByCep();
        this.cepFormatted = false;
      }
    },
    cep(val) {
      var re = /([\d]{5})-*([\d]{3})/;

      if (re.test(val)) {
        this.cepFormatted = true;
        this.organization.cep = val.replace(re, '$1-$2');
        return val.replace(re, '$1-$2');
      }
      return (this.cepFormatted = false);
    },
  },
  methods: {
    verifyInputs() {
      if (
        (this.organization.phone != '' && this.organization.phone?.length < 14) ||
        this.organization.name == '' ||
        (this.organization.cnpj != '' && this.organization.cnpj?.length != 18)
      )
        return false;
      return true;
    },
    async getOrganization() {
      try {
        this.organization = await cListService.getOrganizationById(this.$route.params.id);
      } catch (e) {
        this.$toast.error('Não foi possível carregar as informações desta organização');
      }
    },
    async updateOrganization() {
      if (!this.verifyInputs())
        return this.$toast.error(
          'Verifique se todos os campos estão corretamente preenchidos antes de criar esta organização',
        );
      try {
        await cListService.updateOrganization(this.$route.params.id, this.organization);
        this.$toast.success('Organização editada com sucesso!');
        this.$router.push({ name: 'Organizations' });
      } catch (e) {
        this.$toast.error('Não foi possível editar esta organização');
      }
    },
    async searchDataByCep() {
      const { getInfoByCep } = brasilapi;
      try {
        const data = await getInfoByCep(this.organization.cep);
        this.organization.state = data.state;
        this.organization.city = data.city;
        let street = '';
        let neighborhood = '';
        if (data.street) street = data.street.includes(' - até') ? data.street.replace(' - até', '') : data.street;
        if (data.neighborhood) neighborhood = data.neighborhood;
        street && neighborhood
          ? (this.organization.street = `${street}, ${neighborhood}`)
          : (this.organization.street = '');
        this.stateChange++;
        this.cityChange++;
      } catch (error) {
        this.$toast.error('Falha ao resgatar cidades!');
      }
    },
    async onPageChange(page) {
      this.currentPage = page;
    },
    setLimit(value) {
      this.limitItems = value;
      this.resetPagination();
    },
    resetPagination() {
      this.currentPage = 1;
    },
  },
  async created() {
    await this.getOrganization();
    this.loading = false;
  },
};
</script>
