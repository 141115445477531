<template lang="pug">
.event-box
  .upside
    tooltip
      .flex.justify-start.items-center.cursor-pointer.gap-1.transition-all(
        @click="copyProtocol(data.identifier)",
        v-tooltip="{ content: 'Copiado!', shown: shownCopiedTooltip, triggers: [], delay: 200 }"
      )
        span.material-icons.notranslate.protocol.mr-4 flag
        span.event-text Protocolo gerado
      template(#popper)
        .flex.flex-col.items-center.gap-1
          p.text-white.font-medium.font-plus Clique para copiar!
          span.text-sm.text-zinc-200.font-plus {{ data.identifier }}
    span.date-text(v-tooltip="formatTimestamp(data.createdAt)") {{ formatTimeDiff(data.createdAt, calldate) }}
  .downside
</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    Tooltip,
  },
  mixins: [dateFormatMixin],
  props: ['data', 'calldate'],
  data() {
    return {
      shownCopiedTooltip: false,
    };
  },
  methods: {
    copyProtocol(protocol) {
      const clipboardData =
        event?.clipboardData || window?.clipboardData || event?.originalEvent?.clipboardData || navigator?.clipboard;
      clipboardData.writeText(protocol);
      this.shownCopiedTooltip = true;
      setTimeout(() => {
        this.shownCopiedTooltip = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss" scoped>
.event-box {
  width: 100%;
  height: 48px;
  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  .protocol {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #868e96;
    color: #fff;
    font-size: 12.5px !important;
  }
  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    height: 22px;
  }
  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
