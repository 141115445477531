<template lang="pug">
tooltip
  .relative.cursor-help
    .material-icons.notranslate.text-2xl.cursor-pointer.absolute(:class='[textColor]') {{ materialIcon }}
    .material-icons.notranslate.text-2xl.cursor-pointer(:class='[isPinging, textColor]') {{materialIcon}}
  template(#popper)
    .text-sm.max-w-44
      slot
</template>

<script>
import { Tooltip } from 'floating-vue';
export default {
  components: {
    Tooltip,
  },
  props: {
    error: Boolean,
    warning: Boolean,
    info: Boolean,
    success: Boolean,
    ping: Boolean,
    finished: Boolean,
  },
  computed: {
    isPinging() {
      if (this.ping) return 'animate-ping';
      return '';
    },
    materialIcon() {
      if (this.error) return 'error';
      if (this.warning) return 'warning';
      if (this.info) return 'info';
      if (this.success) return 'check_circle';
      if (this.finished) return 'cancel';
      return 'info';
    },
    textColor() {
      if (this.error) return 'text-red-400';
      if (this.warning) return 'text-amber-400';
      if (this.info) return 'text-zinc-400';
      if (this.success) return 'text-green-400';
      if (this.finished) return 'text-red-400';
      return 'text-zinc-400';
    },
  },
};
</script>
