<template lang="pug">
.w-full.bg-neutral-200.bg-opacity-70.py-5.px-8.flex.justify-between.items-center.gap-4
  .flex.gap-4
    .flex.bg-brand-200.rounded-md.w-16.h-16
      .firsLetter.text-brand.text-3xl(:key="userData.invalid" class="dark:invert" v-if="userData.invalid") {{ userData.name[0].toUpperCase() }}
      img(
        v-else,
        style="border-radius: 4px",
        :src="`${requestLinks.clist}/uploads/${userData.id}.jpg`",
        @error="userData.invalid = true;$forceUpdate()"
      )
    .flex.flex-col.items-start.py-1.justify-between
      .font-inter.text-base.text-gray-900.text-marker {{ userData.name }}
      .font-inter.text-sm.text-gray-500.opacity-90.font-regular(class="dark:text-black dark:font-semibold") {{ userData.organization?.name || 'Sem organização' }}
    .mb-auto.mt-2.py-1.px-3.rounded-full.flex.gap-2.bg-white.border.font-inter.border-grey.text-gray-700.text-xs(
      v-if="userData.category?.name"
    ) {{ userData.category.name }}
  .flex.flex-wrap.gap-2.justify-start
    .font-inter.text-sm.text-gray-500.text-marker(v-for="(email, index) in userData.emails" :key='index') {{ email.address }}
    .font-inter.text-sm.text-gray-500.text-marker(v-for="(phone, index) in userData.phones" :key='index') {{ formatInputValue(phone) }}
  .flex.gap-4
    v-dropdown(placement="bottom")
      .rounded-full.bg-gray-400.w-7.material-icons.notranslate.text-white.cursor-pointer.transition(
        class="p-0.5 hover:bg-gray-600"
      ) more_horizontal
      template(#popper)
        ul.divide-y(v-close-popper.all)
          dropdown-item(
            @click="$router.push({ name: 'EditContact', params: { id: userData.id } })",
            label="Editar usuário",
            icon="e3c9"
          )
          dropdown-item(
            @click="$emit('opencallback', userData)",
            label="Atribuir retorno",
            icon="e85d"
          )
          dropdown-item(
            label="Iniciar conversa",
            icon="e0b7",
            @click="$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: userData } })"
          )
    v-dropdown(placement="bottom")
      .rounded-full.bg-green-300.material-icons.notranslate.text-white.cursor-pointer.transition.text-xl.h-8.w-8.grid.place-items-center(
        class="hover:bg-green-400 dark:invert dark:bg-green-400"
      ) phone
      template(#popper)
        ul.divide-y
          dropdown-item(
            v-for="(phone, index) in userData.phones",
            icon="e0cd",
            :key='index'
            :label="`${formatInputValue(phone)}`",
            @click="makeCall(phone.number)"
          )
</template>

<script>
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
export default {
  props: ['userData'],
  components: {
    DropdownItem,
  },
  data() {
    return {
      loaded: false,
    };
  },
  methods: {
    openCallback() {
      this.$emit('open-callback', this.userData);
    },
    checkNumber(number) {
      if (number != 'Não identificado') {
        return number;
      } else {
        return number;
      }
    },
    saveActualItem(itemId) {
      this.$store.commit('SET_ITEMID', itemId);
    },
    formatInputValue(number) {
      if (number.number.length > 4) {
        return number.country != 'BR'
          ? `+${number.ddi} ${number.number}`
          : this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number.number, 'BR'), this.PNF.INTERNATIONAL);
      } else if (number.number.length === 4) {
        return 'SIP ' + number.number;
      }
      return number.number;
    },
    makeCall(num) {
      if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
      else this.callNumber(num);
    },
  },
  mounted() {
    this.loaded = true;
  },
};
</script>

<style>
.contact-box-right-absolute {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  width: 93%;
}
</style>
