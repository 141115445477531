<template>
  <li
    class="dropdown nav-item"
    :is="tag"
    :class="{ show: isOpen }"
    aria-haspopup="true"
    :aria-expanded="isOpen"
    @click="toggleDropDown"
    v-click-outside="closeDropDown"
  >
    <a class="nav-link dropdown-toggle" data-toggle="dropdown">
      <slot name="title">
        <i :class="icon"></i>
        <div class="title-aligner">
          <span class="no-icon">{{ title }}</span>
          <span class="user-sub-title">{{ realSubtitle }}</span>
        </div>
        <b class="orange-arrow caret"></b>
      </slot>
    </a>
    <div class="dropdown-menu show" v-show="isOpen">
      <div style="display: flex; flex-direction: row">
        <div
          style="
            width: 40px;
            height: 40px;
            margin-left: 10%;
            margin-top: 10px;
            margin-bottom: 0px;
          "
          class="profile-box"
        >
          <img
            v-if="!user.invalid && user.avatar != null"
            style="border-radius: 4px; width: 40px; height: 40px"
            :src="`${this.requestLinks.cservice}/avatar/${user.id}.png`"
            @error="
              user.invalid = true;
              $forceUpdate();
            "
          />
          <div class="firstLetter" v-else>
            {{ user.username[0].toUpperCase() }}
          </div>
        </div>
        <div class="name-org-box">
          <a
            style="font-family: 'Inter', sans-serif !important"
            class="history-text-name"
            >{{ user.username }}</a
          >
          <a class="call-duration-text" style="color: #adb5bd">{{
            user.email
          }}</a>
        </div>
      </div>
      <div style="width: 100%; display: flex; justify-content: center">
        <div style="width: 80%" class="context-separator"></div>
      </div>
      <div
        style="
          height: 192px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        "
      >
        <div
          style="display: flex; justify-content: center; flex-direction: column"
        >
          <a
            v-if="user.profile == 'p_admin' || user.profile == 'p_manager'"
            href="/"
            style="height: 48px; display: flex; width: 100%; font-weight: 500"
            class="context-option"
          >
            <span style="margin-right: 18px" class="material-icons notranslate"
              >sync_alt</span
            >
            Acessar ambiente de gestão</a
          >
          <a
            href="#/profile"
            style="height: 48px; display: flex; width: 100%; font-weight: 500"
            class="context-option"
          >
            <span style="margin-right: 18px" class="material-icons notranslate"
              >person</span
            >
            Perfil e segurança</a
          >
          <a
            @click="logout"
            style="height: 48px; display: flex; width: 100%; font-weight: 500"
            class="context-option"
          >
            <span style="margin-right: 18px" class="material-icons notranslate">
              exit_to_app</span
            >
            Sair</a
          >
          <slot></slot>
        </div>
      </div>
    </div>
  </li>
</template>
<script>
export default {
  name: "base-dropdown",
  props: {
    subtitle: String,
    title: String,
    icon: String,
    tag: {
      type: String,
      default: "li",
    },
  },
  data() {
    return {
      realSubtitle: "",
      isOpen: false,
    };
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit("change", this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit("change", this.isOpen);
    },
    async logout() {
      try {
        const res = await this.$http.get(
          `${this.requestLinks.cservice}/companies/users/logout`
        );
      } catch (e) {
      } finally {
        this.$cookies.remove("snep7");
        window.location.href = "/auth/logout";
      }
    },
  },
  created() {
    if (this.subtitle == "p_admin") {
      this.realSubtitle = "Admin";
    } else if (this.subtitle == "p_manager") {
      this.realSubtitle = "Gestor";
    } else if (this.subtitle == "p_agent") {
      this.realSubtitle = "Agente de atendimento";
    }
  },
};
</script>
<style scoped>
.orange-arrow {
  color: var(--brand-color);
}
.title-aligner {
  display: flex;
  flex-direction: column;
}
.user-sub-title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin: 0px;
  color: #adb5bd;
}
.dropdown .dropdown-toggle {
  cursor: pointer;
}
.top-user-icon {
  color: var(--secondary-color) !important;
}
.no-icon {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #495057;
}
</style>
