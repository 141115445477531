export default {
  namespaced: true,
  state: {
    isShow: JSON.parse(localStorage.getItem('@Yunique:show-newsletter')) || false,
    newsletters: [
      {
        title: "1111111111",
        body: `
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>`,
      },
      {
        title: "2222222222",
        body: `
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>`,
      },
      {
        title: "3333333333",
        body: `
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>`,
      },
      {
        title: "4444444444",
        body: `
        <div>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, <a class='text-cornflower-blue-500 hover:font-bold' href='https://www.google.com' target='_blank'>sed</a> do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>`,
      }
    ]
  },
  mutations: {
    setShow(state, isShow) {
      localStorage.setItem('@Yunique:show-newsletter', JSON.stringify(isShow));
      state.isShow = isShow;
    }
  },
  getters: {
    getShow(state) {
      return state.isShow;
    },
    getNewsletters(state) {
      return state.newsletters;
    }
  },
};
