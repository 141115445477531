<template lang="pug">
tr(
  class="text-sm child:px-4 font-inter font-base text-zinc-800 child:py-3"
  :class="{ ' child:hover:bg-zinc-100': !isFileTooLarge}"
)
  slot
</template>

<script>
export default {
  props: {
    isFileTooLarge: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
