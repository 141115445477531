import { $http, companyId } from './client';
const service = `${process.env.VUE_APP_IMAP_PUBLIC}`;
import qs from 'qs';

async function getAccounts(payload) {
  try {
    const data = await $http.get(`${service}/accounts`, {
      params: { ...payload, companyId },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function forwardEmail(payload) {
  try {
    await $http.post(`${service}/send-email`, payload, {
      headers: {
        'x-service-method': 'forward',
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export default {
  getAccounts,
  forwardEmail,
};
