<template lang="pug">
.firstLetter.text-slate-200(v-if="failedImg === true" class="dark:invert") {{ identityContact?.name[0].toUpperCase() }}
img(
  v-else-if="identityContact?.avatarUrl"
  class="dark:!invert-0"
  style="border-radius: 4px"
  :src="identityContact?.avatarUrl"
  @error="$emit('failedImg')"
)
</template>

<script>
export default {
  props: {
    failedImg: {
      type: Boolean,
      default: false,
    },
    identityContact: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
