<template lang="pug">
.w-full.relative
  label.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
  v-dropdown.w-full(placement="bottom-center" auto-size="min")
    .flex.border.border-gray-200.rounded-md(
      class="hover:bg-gray-100 cursor-pointer transition-colors"
      v-if="selectedContact?.id"
    )
      contact-email-row(
        :data="selectedContact"
      )
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter(
      v-else
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base Selecione um email
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .flex.w-full.p-1
        icon-input.w-full(
          label="Email ou nome do contato"
          v-model="search"
        )
      .flex.max-h-80.flex-col.overflow-y-scroll.justify-start.min-w-full
        contact-email-row(
          v-for="contactEmail in contactsEmailsList"
          :key="contactEmail.id"
          :data="contactEmail"
          :selected="contactEmail?.id == selectedContact?.id"
          @select-contact="selectContact"
        )
        .flex.p-2(v-if="loading")
          loading-ring
        observer(
          v-else 
          @intersect="loadMoreContacts"
        )
</template>

<script>
import ContactEmailRow from '@/components/TablesRows/contact-email-row.vue';
import { hideAllPoppers, Dropdown } from 'floating-vue';
import contactList from '@/services/contact-list';
import IconInput from '../Inputs/IconInput.vue';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';
import { debounce } from '@/helpers';
import user from '@/store/user';

export default {
  components: {
    vDropdown: Dropdown,
    ContactEmailRow,
    LoadingRing,
    IconInput,
    Observer,
  },
  props: {
    label: {
      type: String,
      default: 'Selecione o email',
    },
    placeholder: {
      type: String,
      default: 'Selecione um email',
    },
  },
  data() {
    return {
      search: '',
      lastRequestLength: 0,
      offset: 0,
      loading: false,
      contactsEmailsList: [],
      selectedContact: {},
    };
  },
  watch: {
    search() {
      this.searchContact();
    },
  },
  methods: {
    selectContact(value) {
      this.selectedContact = value;
      this.$emit('selected-contact', value);
      hideAllPoppers();
    },
    searchContact: debounce(async function () {
      this.contactsEmailsList = [];
      this.offset = 0;
      this.lastRequestLength = 0;
      await this.getContacts();
    }, 500),
    async loadMoreContacts() {
      if (this.lastRequestLength < 20) return;
      this.offset = this.offset + 20;
      await this.getContacts();
    },
    async getContacts() {
      this.loading = true;
      const { getContactsByNameOrEmail } = contactList;
      try {
        const payload = {
          limit: 20,
          offset: this.offset,
          companyId: this.user.company_id,
          orderBy: 'name',
        };
        if (this.search) {
          payload.email = this.search;
          payload.name = this.search;
        }
        const data = await getContactsByNameOrEmail(payload);
        this.lastRequestLength = data.emails.length;
        data.emails.forEach((contact) => {
          this.contactsEmailsList.push(contact);
        });
      } catch (e) {
        this.$toast.error('Ocorreu algum erro na busca');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getContacts();
  },
};
</script>
