import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CALLREPORT}`;

function connectToCallSSE($http, { device }) {
  try {
    const evtSource = new EventSource(`${service}/call/stream/device/${device}`);
    return evtSource;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCallById(id) {
  try {
    const result = await $http.get(`${service}/call/id/${id}`);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getCallsFromQueue(payload) {
  try {
    const result = await $http.post(`${service}/call/search/queue`, payload);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getUserPerformance(params) {
  try {
    const { data } = await $http.get(`${service}/call/user-performance`, {
      params,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getQueuePerformance(params) {
  try {
    const { data } = await $http.get(`${service}/call/queue-performance`, {
      params,
    });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {object} payload
 * @param {*} payload.tagId
 * @param {string} payload.companyId
 * @param {string} payload.linkedid
 * @param {number} [payload.callId]
 */
async function createCallTag(payload) {
  const { data } = await $http.post(`${service}/call-tags`, payload);
  return data;
}

export async function deleteCallTag(callTagId) {
  const { data } = await $http.delete(`${service}/call-tags/${callTagId}`);
  return data;
}

export async function getAllCallTags(params) {
  const { data } = await $http.get(`${service}/call-tags`, { params });
  return data;
}

export async function getTotalCallsCountOfMember(params) {
  const data = await $http.get(`${service}/u/${params.userId}/count`, { params });
  return data;
}

export async function getCallsByContact({ contactId, limit, offset }) {
  const { data } = await $http.get(`${service}/call/u/${contactId}/${limit}/${offset}`);
  return data;
}
export async function getCallsByPhone({ phone, limit, offset }) {
  const { data } = await $http.get(`${service}/call/u/${phone}/${limit}/${offset}`);
  return data;
}

export async function getPaginateCalls(queries) {
  const filteredQueries = {};
  Object.entries(queries).forEach(([key, value]) => {
    if (value) filteredQueries[key] = value;
  });

  const { data } = await $http.get(`${service}/call`, {
    params: filteredQueries,
  });

  return data;
}

export async function countPaginatedCalls(queries) {
  const filteredQueries = {};
  Object.entries(queries).forEach(([key, value]) => {
    if (value) filteredQueries[key] = value;
  });

  const { data } = await $http.get(`${service}/call/count`, {
    params: filteredQueries,
  });

  return data;
}

export async function updateCallbackReport(linkedid) {
  const data = await $http.put(`${service}/call/callback`, { linkedid });
  return data;
}

/** @typedef {object} json
 * @typedef QueuedCalls
 * @property {string} companyId
 * @property {string} queue
 * @property {object[]} onHold
 */
/**
 * @param {object} params
 * @param {Array} params.queues
 * @returns {Promise<Array<QueuedCalls>>}
 */
export async function getQueuedCalls(params) {
  const { data } = await $http.get(`${service}/queued-calls`, { params });
  return data;
}

export default {
  connectToCallSSE,
  getCallsFromQueue,
  getCallById,
  createCallTag,
  getCallsByContact,
  getCallsByPhone,
  getAllCallTags,
  getUserPerformance,
  getQueuePerformance,
  getPaginateCalls,
  countPaginatedCalls,
  updateCallbackReport,
  getQueuedCalls,
};
