<template lang="pug">
.flex.items-center.text-inside-brand.h-18.w-full.p-3.border-b(class="bg-brand-400 bg-opacity-5 rounded-lg")
  .flex.w-full.justify-between.items-start.gap-2
    .flex.flex-col.justify-start.mt-auto.mb-auto
      .rounded.w-8.h-8.flex.justify-center.items-center(class="bg-[color:var(--brand-color-op-1)]")
        span.material-icons.notranslate.text-brand perm_identity
    .grid.items-start.ml-2.place-items-start.h-full.leading-none.self-center(class="w-[90%]")
      span.font-semibold.font-inter.truncate(class="dark:text-black dark:font-extrabold") Discar para
      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-700") {{ formatInputValue() }}
    .flex.flex-col.justify-between.h-full.relative.items-end
      .items-center.justify-center.flex.h-full(class="dark:invert")
        i.fa.fa-phone.items-center.justify-center.flex.rounded-full.transition-all.text-2xl.w-11.h-11(
          class="hover:bg-brand-gradient hover:text-white text-[#58dca5]",
          @click.stop="checkCall()",
          role="button",
          v-tooltip="'Ligar para ' + inputValue"
        )
</template>

<script>
import callMixin from '@/mixins/invite-calls.js';
export default {
  mixins: [callMixin],
  props: {
    inputValue: {
      type: String,
      required: true,
    },
    isTransfer: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    checkCall() {
      if (this.isTransfer) {
        this.$emit('callingUnkown', this.inputValue);
      } else {
        const regex = new RegExp('^[0-9]+$');
        if (!regex.test(this.inputValue)) {
          return this.$toast.error('Não é possível realizar uma chamada com letras.');
        }
        this.makeCall(this.inputValue);
      }
    },
    formatInputValue() {
      return this.inputValue;
    },
  },
};
</script>
