<template lang="pug">
tr.text-left
  td.text-left.pl-7
    .flex.justify-start
      span.material-icons.notranslate.h-8.w-8.rounded-md.bg-brand-100.text-brand-500.grid.place-items-center.mr-2(class="dark:invert dark:bg-brand-800") phone
      p.text-sm.text-marker Ramal
      span.device-number.ml-1 {{ peer }}
  td.max-h-fit
    a(:class="`status-color-${status.class}`")
    span.device-text.text-marker {{ status.status }}
  td
    .flex
      SwitchBox.m-0(v-if="web !== null" v-model="web")
      tooltip(v-else)
        template(#popper)
          p Credencias de acesso ao Snep inválidas! Contate o administrador.
        .flex.bg-red-500.rounded.w-fit.px-2.h-5.font-medium.font-plus.text-xs.text-white(class="dark:bg-[#10bbbb] dark:text-black") Snep network error
  td.pt-5
    i.mr-2.material-icons.notranslate.cursor-pointer.text-white.w-5.h-5.flex.justify-center.items-center.bg-gray-400.rounded-full.text-sm(@click="openSettingsModal", v-if="web !== null") &#xe8b8
</template>

<script>
import SwitchBox from '@/components/Inputs/SwitchCheckbox.vue';
import csService from '@/services/cservice.js';
import { Tooltip } from 'floating-vue';

export default {
  components: {
    SwitchBox,
    Tooltip,
  },
  props: ['device'],
  data() {
    return {
      web: null,
    };
  },
  computed: {
    name() {
      if (this.device.device) {
        return this.device.device.split('/');
      } else return ['SIP', '#'];
    },
    peer() {
      return this.name.length > 1 ? this.name[1] : this.name[0];
    },
    status() {
      switch (this.device.status) {
        case 'enabled':
          return {
            status: 'Habilitado',
            class: 'enabled',
            enabled: true,
          };
        case 'new': {
          return {
            status: 'Desabilitado',
            class: 'null',
            enabled: false,
          };
        }
        default:
          return {
            status: 'Desabilitado',
            class: 'disabled',
            enabled: false,
          };
      }
    },
  },
  methods: {
    deleteDevice() {
      this.$emit('deleteDevice', this.device.id);
      if (this.user.route == 'peer') {
        this.user.route = null;
      }
      this.$store.commit('modal/setActiveModal', null);
    },
    openDeteleModal() {
      this.$store.commit('modal/setContext', {
        func: this.deleteDevice,
        device: this.device,
      });
      this.$store.commit('modal/setActiveModal', { name: 'DeleteDevice' });
    },
    openSettingsModal() {
      this.$store.commit('modal/setContext', {
        func: this.deleteDevice,
        device: this.device,
      });
      this.$store.commit('modal/setActiveModal', {
        name: 'SoftPhoneConfig',
        context: { peer: this.peer, noFooter: true },
      });
    },
    async enable() {
      this.$store.commit('SET_ADEVICE', this.device);
      this.$store.commit('SET_MODAL', { name: 'EnableConfirmModal' });
    },
    async disable() {
      this.$store.commit('SET_ADEVICE', this.device);
      this.$store.commit('SET_MODAL', { name: 'DeviceConfirmModal' });
    },
    async enableWebRtc() {
      try {
        await csService.changeTransport({ peer: this.peer, transport: 'webrtc' });
        this.$store.dispatch('sip/createUserAgent', { username: this.user.username, endpoint: this.user.endpoint });
        this.$toast.open({ message: 'Modo web habilitado', type: 'success' });
      } catch (error) {
        this.$toast.error('Não foi possível realizar essa operação');
      }
    },
    async enableSip() {
      try {
        await csService.changeTransport({ peer: this.peer, transport: 'pjsip' });
        this.$store.commit('sip/destroyUserAgent');
        this.$store.commit('sip/setConfiguration', { transport: 'transport-tls' });
        this.$toast.success('Modo web desabilitado');
      } catch (error) {
        this.$toast.error('Não foi possível realizar operação');
      }
    },
  },
  watch: {
    async web(isWeb, previousIsWebValue) {
      if (previousIsWebValue == null) return;
      if (isWeb) this.enableWebRtc();
      else this.enableSip();
    },
  },
  async created() {
    try {
      const { transport } = await csService.getDeviceConfig(this.peer);
      if (transport === 'transport-wss') this.web = true;
      else this.web = false;
    } catch (error) {
      if (error?.response.status == 401) {
        window.location.pathname = '/auth/login';
      }
      console.log(error.response);
      this.$toast.error('Falha ao buscar configurações!');
    }
  },
};
</script>

<style>
.align-device-table {
  display: flex;
  align-items: center;
}
</style>
