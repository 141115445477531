<template>
  <div :style="'height:' + this.modalSize" class="confirm-modal">
    <div class="step-header">
      <div class="step-title">{{ this.modalTitle }}</div>
    </div>
    <div style="margin-bottom: 24px" class="line-separator"></div>
    <a class="modal-info-text"
      >{{ this.modalTextPart1 }}
      <a style="font-weight: bold" class="modal-info-text">{{
        this.actualDevice.device
      }}</a>
      {{ this.modalTextPart2 }}</a
    >
    <div style="margin-top: 48px" class="cancel-button-aligner">
      <button v-on:click="cancelClose()" class="buttoncancel">Cancelar</button>
      <button
        v-on:click="confirmFunction ? confirmClose() : confirmEnableClose()"
        class="buttonsave"
      >
        CONFIRMAR
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["modalType"],
  data: () => ({
    modalSize: "",
    modalTextPart1: "",
    modalTextPart2: "",
    modalTitle: "",
    confirmFunction: true,
    actualDevice: null,
  }),
  methods: {
    cancelClose() {
      this.$store.commit("SET_MODAL", null);
    },
    async confirmClose() {
      this.$store.commit("SET_MODAL", null);
      try {
        this.$store.commit("SET_MODAL", { name: "ProgressModal" });
        await this.$http.post(
          `${this.requestLinks.cservice}/companies/users/devices/peer/disable/${this.actualDevice.id}`,
          {
            code: this.actualDevice.code,
            peer: this.actualDevice.device,
          }
        );
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
        this.$store.commit("SET_MODAL", { name: "DisableDeviceFailed" });
      }
    },
    async confirmEnableClose() {
      this.$store.commit("SET_MODAL", null);
      try {
        this.$store.commit("SET_MODAL", { name: "ProgressModal" });
        await this.$http.post(
          `${this.requestLinks.cservice}/companies/users/devices/peer/enable`,
          {
            code: this.actualDevice.code,
            peer: this.actualDevice.device,
          }
        );
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
        this.$store.commit("SET_MODAL", { name: "EnableDeviceFailed" });
      }
    },
  },
  created() {
    this.actualDevice = this.$store.getters["GET_ADEVICE"];
    if (this.modalType == "DisableConfirmModal") {
      this.modalTextPart1 = "Deseja desabilitar o Device ";
      this.modalTextPart2 = " da sua conta?";
      this.modalTitle = "Desabilitar Device SIP";
      this.modalSize = "285px";
      this.confirmFunction = true;
    } else {
      this.modalTextPart1 = "Ao autenticar com o Device ";
      this.modalTextPart2 =
        ", você perderá vínculo com seu device autenticado atualmente.";
      this.modalTitle = "Habilitar Device SIP";
      this.modalSize = "309px";
      this.confirmFunction = false;
    }
  },
};
</script>
<style>
.modal-info-text {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #868e96 !important;
}
.confirm-modal {
  display: block;
  width: 610px;
  height: 285px;
  background: #fff;
  position: absolute;
  z-index: 9999;
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.8);
  padding: 48px;
}
</style>
