<template lang="pug">
alert
  template(v-slot:body)
    .h-12.rounded.flex.justify-center.items-center.bg-emerald-500.bg-opacity-80.w-96 
      p.text-white.flex.justify-center.items-center.mr-12.text-sm Conexão estabelecida!
      span.material-icons.notranslate.text-white &#xe876
</template>

<script>
import alert from "@/components/Alerts/alert-template.vue";
export default {
  components: {
    alert,
  },
};
</script>
