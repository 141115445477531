<template lang="pug">
.w-full
  .flex.justify-center.items-center
    p.text-xs.text-zinc-500.px-4.py-2.rounded Opção "{{message.messageBody}}" selecionada
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
