<template lang="pug">
.w-full
  //- Documento
  .flex.flex-col.rounded.items-start.w-full(v-if="!loading && !error"
    :class="['hover:brightness-90', hasCaption ? 'bg-transparent' : 'bg-zinc-300 dark:bg-zinc-500']"
  )
    .flex.rounded.px-3.py-2.cursor-pointer.h-14.w-80.justify-start(
      ref="document"
      @click="viewPDF",
      :class="[hasCaption ? 'bg-zinc-300 dark:bg-zinc-500' : 'bg-transparent']"
    )
      i.mr-2.material-icons.notranslate.text-3xl.text-brand-500(class="dark:!text-zinc-200") &#xea0e
      p.text-sm.text-zinc-600.truncate(class="dark:invert dark:text-zinc-300") {{ documentFilename }}
    p.text-sm.break-words.whitespace-pre-line.mt-1(
      ref="subtitle",
      v-if="hasCaption"
      class="dark:invert-0"
      :class="[ isAutomaticMessage ? '!text-zinc-400': 'text-zinc-50' ]",
    ) {{ message.caption }}

  //- Loading esqueleto
  .flex.w-60.h-14.rounded.skeleton-card(v-if="loading")

</template>

<script>
import * as AdapterService from '@/services/adapter';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      file: undefined,
      loading: true,
      error: null,
      intervalId: null,
    };
  },

  computed: {
    isRecipient() {
      return this.message.recipientId !== this.user.id;
    },
    hasCaption() {
      return this.message?.caption;
    },
    documentUrl() {
      return `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${this.message.messageBody}`;
    },
    documentFilename() {
      return this.message.messageBody;
    },
    isAutomaticMessage() {
      return !this.message.recipientId;
    },
  },

  methods: {
    async viewPDF() {
      const separatedUrl = this.documentUrl.split('.');
      const archiveExtension = separatedUrl[separatedUrl.length - 1];
      if (archiveExtension !== 'pdf') return await this.downloadFile();
      this.$store.commit('modal/setActiveModal', {
        name: 'PdfViewer',
        context: `${this.documentUrl}`,
      });
    },

    async downloadFile() {
      const data = await AdapterService.getFile(this.message.messageBody);
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.documentFilename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },

  mounted() {
    this.loading = false;
  },
});
</script>
