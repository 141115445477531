<template lang="pug">
div
  p.font-plus.font-semibold.text-base.flex.justify-start.items-center(
  ) {{ title }}
    span.ml-5.cursor-pointer.material-icons.notranslate.w-5.h-5.bg-brand-500.text-white.rounded.flex.justify-center.items-center.select-none(
      @click="configOpen = !configOpen"
      :class="{ '!bg-red-500' : error}"
    ) {{ !configOpen ? 'expand_more' : 'expand_less' }}
    p.text-xs.font-inter.text-red-500.m-0.p-0(v-if="error") {{ error }}
    hr.bg-gray.my-2(
      v-if="!configOpen"
      class="h-[1px]"
      :class="{'bg-red-500' : error}"
    )
  .border.mt-2.border-grey-100.rounded.p-2.flex.flex-col.items-start(
    :class="{'border-red-500' : error}"
    v-if="configOpen"
  )
    slot
</template>
<script>
export default {
  props: ['title', 'opened', 'error'],
  data() {
    return {
      configOpen: false,
    };
  },
  methods: {
    initalState() {
      if (this.opened == true) {
        this.configOpen = true;
      } else {
        this.configOpen = false;
      }
    },
  },
  async created() {
    this.initalState();
  },
};
</script>
