<template lang="pug">
.min-w-200.bg-zinc-50.px-12.overflow-auto.mr-40(
  class="shadow-[15px_5px_40px_-10px_rgba(0,0,0,0.2)]"
)
  .w-full.h-12.pt-8.flex.justify-between.items-center.mb-4
    h2.text-lg.font-extrabold.text-zinc-700.flex Detalhes do caso
    .flex
      span.material-icons.notranslate.text-zinc-400.text-2xl.cursor-pointer(@click="close") &#xe5cd
  CaseInfoBox(v-if="caso.contact", :data="caso")
  .content.flex-col.min-w-max.z-0
    .header.p-5.items-center.justify-between
      .flex.gap-5
        h2.font-inter.font-normal.text-zinc-500 Feed do caso
        v-dropdown(placement="bottom-start", auto-size="min")
          .flex.items-center.cursor-pointer.select-none
            .font-inter.font-semibold.text-gray-500 {{ filter ? translateFilter[filter] : 'Filtrar por' }}
            .material-icons.notranslate keyboard_arrow_down
          template(#popper)
            ul.divide-y(v-close-popper.all)
              dropdown-item(
                @click="filter = 'chat'",
                label="Conversas",
                icon="e0b7"
              )
              dropdown-item(
                @click="filter = 'call'",
                label="Chamadas",
                icon="e0b0"
              )
              dropdown-item(@click="filter = ''", label="Tudo", icon="f06d")
    .contact-calls-content.scroll-style
      .text-zinc-900.w-full.h-full.flex.items-center.flex-col.text-brand-500.font-extrabold(
        v-if="!loaded"
      ) Carregando...
        .material-icons.notranslate.animate-spin.text-brand-200 &#xe5d5
      .no-device(style="width: 100%", v-else-if="filteredActivities.length < 1") Esse caso não possui atividades vinculadas.
        span.info-orange-icon.material-icons.notranslate &#xe000
      TransitionGroup(name="slide-fade", mode="out-in")
        component(
          v-for="(data, index) in filteredActivities",
          :is="`${data.actionType}-component`",
          :key="index",
          :activity="data",
          :isFeed="true"
        )
</template>

<script>
import CaseInfoBox from '@/pages/Cases/Cases.CaseInfoBox.vue';
import ChatHistoryComponent from '@/components/ChatHistoryComponent.vue';
import ContactHistoryComponent from '@/components/ContactHistoryComponent.vue';
import CaseTaskHistoryComponent from '@/pages/Cases/Cases.CaseTaskHistoryComponent.vue';
import { getCaseFeed } from '@/services/cases';
import chat from '@/services/chat';
import contactList from '@/services/contact-list';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';

export default {
  components: {
    CaseInfoBox,
    TaskComponent: CaseTaskHistoryComponent,
    ChatComponent: ChatHistoryComponent,
    DropdownItem,
    CallComponent: ContactHistoryComponent,
  },
  data() {
    return {
      caso: {},
      loadingNewActivities: false,
      caseActivities: [],
      activities: [],
      translateFilter: {
        chat: 'Conversas',
        call: 'Chamadas',
        all: 'Filtrar por',
      },
      loaded: false,
      filter: '',
      start: 0,
      limit: 5,
    };
  },
  computed: {
    context() {
      return this.$store.getters['overlay/getContext'];
    },
    filteredActivities() {
      return this.activities.filter((activity) => activity.actionType.startsWith(this.filter));
    },
  },
  methods: {
    close() {
      this.$store.commit('overlay/setActiveOverlay', null);
    },
    async loadNewActivities() {
      this.limit += 5;
      await this.populateActivitiesInfo(this.start, this.limit);
    },
    async getCallInfo(id) {
      try {
        const res = await this.$http.get(`${this.requestLinks.callreport}/call/id/${id}`);
        return res.data;
      } catch (error) {}
    },
    async getTaskInfo(id) {
      try {
        const res = await this.$http.get(`${this.requestLinks.cases}/tasks/${id}`);
        return res.data;
      } catch (error) {}
    },
    async getChatInfo(id) {
      const { findById } = chat;
      try {
        const data = await findById(id);
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async populateActivitiesInfo(start, limit) {
      const activitiesToBeLoaded = this.caseActivities
        .sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .splice(start, limit);

      activitiesToBeLoaded.forEach(async (activity) => {
        let activityData = {};
        if (activity.actionTypeId.name == 'call') {
          activityData = (await this.getCallInfo(activity.callId)) || {};
          activityData.actionType = 'call';
        }
        if (activity.actionTypeId.name == 'task') {
          activityData = (await this.getTaskInfo(activity.callId)) || {};
          activityData.actionType = 'task';
        }
        if (activity.actionTypeId.name == 'chat') {
          activityData = (await this.getChatInfo(activity.conversationId)) || {};
          activityData.actionType = 'chat';
        }

        this.activities.push(activityData);
        this.activities.map((act) => {
          if (act.actionType == 'call') {
            act.createdAt = act.calldate;
          }
        });
        this.activities.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      });
    },
  },
  async created() {
    const { getContactById } = contactList;
    try {
      const res = await this.$http.get(`${this.requestLinks.cases}/cases/${this.context}`);
      this.caseActivities = await getCaseFeed({ caseId: this.context });
      this.caso = res.data;

      if (this.caseActivities.length >= 1) await this.populateActivitiesInfo(this.start, this.limit);
      this.caso.contact = await getContactById(this.caso.customerId);
      this.loaded = true;
    } catch (error) {
      console.error(error);
    }
    this.loaded = true;
  },
};
</script>

<style></style>
