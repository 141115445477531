<template lang="pug">
overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.pb-8.overflow-auto.justify-start.rounded-lg(class="w-[50rem] max-h-[96%]")
      .flex.flex-col.gap-4.items-start.space-between.w-full.border-b.border-zinc-200.px-10
        h4.text-2xl.pt-4.text-gray-500.font-semibold.font-inter Enviar modelo de mensagem

        .flex
          button.px-4.text-base.font-inter.transition-all.border-b-2(
            v-for="templateProvider in templateProviders"
            v-if="templateProvider.name !== 'yunique'"
            :class="{ 'border-brand font-semibold': templateProvider.name === selectedProvider, 'cursor-not-allowed text-slate-400': checkIfTemplateProviderIsDisabled(templateProvider.name) }",
            @click="selectProvider(templateProvider.name)"
            :disabled="checkIfTemplateProviderIsDisabled(templateProvider.name)",
            v-tooltip="{ content: getDisabledProviderTooltipContent(templateProvider.name) }"
          ) {{ transformProviderName(templateProvider.name) }}

      .flex.flex-col.justify-center.items-start.w-full.gap-2.px-10
        span.flex.flex-col.gap-1.pt-4.items-start.w-full(v-if="liveChat")

        mount-whatsapp-template-form(
          v-if="selectedProvider === 'whatsapp'",
          @updated="templateUpdated",
          @error="handleTemplateValuesFilled",
          :recipientId="activeRoomRecipientMember?.recipientId"
        )
        mount-opens-template-form(
          v-else-if="selectedProvider === 'opens'",
          @updated="templateUpdated",
          @error="handleTemplateValuesFilled",
          :opens-provider-id="opensProviderId",
          :recipientId="activeRoomRecipientMember?.recipientId"
        )
      .flex.w-full.items-start.justify-center.gap-2.mt-2(v-if="!loading && templateData.isSupportedTemplate && !templateData.templateNeedSync")
        v-button-secondary(@click="finishChat()") Cancelar
        v-button(@click="sendTemplate()") Enviar modelo
      .flex.w-full.items-start.justify-center.gap-2.mt-2(v-if="loading")
        loading-ring
</template>

<script>
import Overlay from '@/components/Modals/Overlay.vue';
import chatService from 'src/services/chat.js';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import LoadingRing from '../LoadingRing.vue';
import { defineComponent } from 'vue';
import MountWhatsappTemplateForm from '@/components/Forms/mount-whatsapp-template-form.vue';
import MountOpensTemplateForm from '@/components/Forms/mount-opens-template-form.vue';
import chatFetchRoomMixin from '@/mixins/chat/fetch-rooms.mixin';
import { Dropdown } from 'floating-vue';
import chatConfig from '@/services/chat-config';

export default defineComponent({
  mixins: [chatFetchRoomMixin],
  components: {
    VButtonSecondary,
    Overlay,
    VButton,
    LoadingRing,
    MountWhatsappTemplateForm,
    MountOpensTemplateForm,
    Dropdown,
  },
  data() {
    return {
      templateData: {},
      selectedProvider: '',
      templateProviders: [],
      mountedTemplateErrorMessage: 'Selecione um modelo de mensagem para continuar.',
      loading: false,
      errorMessage: '',
    };
  },
  computed: {
    opensProviderId() {
      return this.templateProviders.find((provider) => provider.name === 'opens')?.id;
    },
    liveChat() {
      return this.context.liveChat;
    },
    activeRoom() {
      return this.$store.getters['chat/activeRoom'].room;
    },
    roomCreatorProvider() {
      return this.$store.getters['chat/activeRoom'].room.creatorProvider;
    },
    wabaConfigId() {
      return this.$store.getters['chat/activeRoom'].room.chatConfigId;
    },
    activeRoomRecipientMember() {
      return this.$store.getters['chat/activeRoomRecipientMember'];
    },
    context() {
      return this.$store.getters['modal/getContext'];
    },
    isActiveRoomExpired() {
      const lastClientMsgAt = this.activeRoom.lastClientMsgAt;
      const timeLimit = this.activeRoom.inactivityLimit;
      const then = this.$moment(lastClientMsgAt);
      const now = this.$moment();
      const timeElapsed = now.diff(then, 'seconds');
      return timeElapsed >= timeLimit;
    },
  },
  methods: {
    checkIfTemplateProviderIsDisabled(templateProviderName) {
      return (
        (templateProviderName === 'whatsapp' && this.roomCreatorProvider === 'yunique') ||
        (templateProviderName === 'opens' && (!this.liveChat || !this.isSendingAllowed(templateProviderName)))
      );
    },
    getDisabledProviderTooltipContent(templateProviderName) {
      if (templateProviderName === 'whatsapp' && this.checkIfTemplateProviderIsDisabled(templateProviderName)) {
        return 'Não é possível enviar templates do WhatsApp para um chat da Opens.';
      } else if (templateProviderName === 'opens' && this.checkIfTemplateProviderIsDisabled(templateProviderName)) {
        return 'Não é possível enviar templates da Opens para iniciar um chat ou antes do cliente responder.';
      }
    },
    selectProvider(providerName) {
      this.selectedProvider = providerName;
    },
    handleTemplateValuesFilled(error) {
      this.mountedTemplateErrorMessage = error;
    },
    templateUpdated(template) {
      this.templateData = template;
    },
    async finishChat() {
      this.$store.commit('modal/setActiveModal', '');

      if (this.context.liveChat) return;
      if (!this.activeRoom) return;

      await chatService.deleteChatById(this.activeRoom.id);
      this.$store.commit('chat/removeRoomById', this.activeRoom.id);
      this.$store.commit('chat/setActiveRoom', { roomId: null, room: null });
      this.$toast.open({ message: 'Envio cancelado!' });
    },
    isSendingAllowed(templateProviderName) {
      if (
        this.isActiveRoomExpired &&
        templateProviderName !== 'whatsapp' &&
        this.activeRoom.creatorProvider === 'whatsapp'
      ) {
        this.errorMessage = 'Esse atendimento já expirou, use um modelo de mensagem do WhatsApp';
        return;
      }
      const hasClientResponded = this.activeRoom.messages?.some((message) => message.recipientId !== this.user.id);
      if (
        !hasClientResponded &&
        this.activeRoom.creatorProvider === 'whatsapp' &&
        templateProviderName !== 'whatsapp'
      ) {
        this.errorMessage = 'Você só pode enviar mensagens após resposta do cliente!';
        return;
      }
      return true;
    },
    async sendTemplate() {
      if (!this.isSendingAllowed(this.selectedProvider)) return;

      try {
        this.loading = true;

        await this.sendTemplateStrategies();

        if (this.$router.currentRoute.name !== 'Chat') {
          this.$router.push({ name: 'Chat' });
        }
        this.$store.commit('modal/setActiveModal', '');
        await this.fetchUserChatRooms({ isOpen: true, orderMessages: 'desc' });
      } catch (error) {
        console.log(error);
        this.$toast.error('Erro ao enviar o template. Tente novamente!');
      } finally {
        this.loading = false;
      }
    },
    async sendTemplateStrategies() {
      const selectedProvider = this.selectedProvider.toLowerCase();

      if (selectedProvider === 'whatsapp') {
        await this.sendWhatsappTemplate();
      } else if (selectedProvider === 'opens') {
        await this.sendOpensTemplate();
      }
    },
    async sendWhatsappTemplate() {
      const {
        selectedTemplate,
        isRequiredHeaderMedia,
        headerFile,
        hasVariables,
        headerVariablesValues,
        bodyVariablesValues,
        buttonsVariablesValues,
        headerFileInfos,
      } = this.templateData;

      if (this.mountedTemplateErrorMessage) {
        return this.$toast.error(this.mountedTemplateErrorMessage);
      }
      this.loading = true;

      const payload = {
        phone: this.activeRoom.members.find((member) => member.provider !== 'opens')?.channel,
        chatRoomId: this.activeRoom.id,
        chatRoomMemberId: this.activeRoom.members.find((member) => member.recipientId === this.user.id)?.id,
        configId: this.activeRoom.chatConfigId,
        provider: 'opens',
        companyId: this.user.company_id,
        messageType: 'text',
        recipientId: this.user.id,
        templateId: selectedTemplate.id,
        templateOptions: {
          bodyVariablesValues: bodyVariablesValues.filter((value) => value !== ''),
          headerVariablesValues: headerVariablesValues.filter((value) => value !== ''),
          buttonsVariablesValues: buttonsVariablesValues.map((value) => value || null),
          headerFileInfos,
        },
      };

      await chatService.sendMetaMessageV2(payload);
    },
    async sendOpensTemplate() {
      const template = this.templateData.templateConfig;
      const { bodyVariablesValues } = this.templateData;

      const message = {
        recipientId: this.user.id,
        origin: this.user.id,
        channel: 'opens',
        provider: 'opens',
        chatRoomId: this.activeRoom.id,
        companyId: this.user.company_id,
        messageType: 'text',
        messageBody: template?.bodyContent,
        chatRoomMemberId: this.activeRoom.members.find((member) => member.recipientId === this.user.id)?.id,
        templateOptions: {
          bodyVariablesValues: bodyVariablesValues.filter((value) => value !== ''),
        },
      };

      await chatService.sendMessageTemplate(message);
    },
    transformProviderName(providerName) {
      const providerNameDictionary = {
        whatsapp: 'WhatsApp',
        opens: 'Opens',
      };

      return providerNameDictionary[providerName];
    },
    selectInitialProvider() {
      const selectProviderDictionary = {
        whatsapp: 'whatsapp',
        yunique: 'opens',
      };

      this.selectedProvider = selectProviderDictionary[this.activeRoom.creatorProvider];
    },
  },
  async created() {
    this.loading = true;
    const providers = await chatConfig.getAllProviders();
    this.templateProviders = providers;
    this.selectInitialProvider();
    this.loading = false;
  },
});
</script>
