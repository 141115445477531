<template lang="pug">
div(v-click-outside="checkDisplay" class="history-dropdown-content z-10")
  div(@click="openDropdown" class="dropdown-box")
    input(@input="filterData" @focus="filterData" placeholder="Tag" v-model="filterValue" class="mask-dropdown-input")
    span(v-if="tagSelected === 'Tag'" class="material-icons notranslate dropdown-icon")  keyboard_arrow_down
    span(v-else @click.stop="cancelSearch" class="material-icons notranslate dropdown-icon")  cancel
  ul(v-if="searching" class="dropdown-list max-h-96 overflow-auto")
    li.dropdown-option(v-for="(data, index) in filteredOptions" :key="index" @click="selectType(data)") {{ data.text }}
</template>

<script>
export default {
  props: ['options', 'tagSelected'],
  data() {
    return {
      searching: false,
      filteredOptions: [],
      filterValue: this.tagSelected === 'Tag' ? '' : this.tagSelected,
    };
  },
  methods: {
    filterData() {
      this.filteredOptions = this.options.filter((element) => {
        return element.text.toLowerCase().startsWith(this.filterValue.toLowerCase());
      });
    },
    cancelSearch() {
      this.filterValue = '';
      this.filteredOptions = this.options;
      this.$emit('filter', 'reset');
    },
    checkDisplay(e) {
      if (e.target.className != 'dropdown-option') {
        this.searching = false;
      }
    },
    selectType(data) {
      this.filterValue = data.text;
      this.searching = false;
      this.$emit('filter', data);
    },
    openDropdown() {
      this.searching = !this.searching;
    },
  },
  created() {
    this.filteredOptions = this.options;
  },
};
</script>

<style lang="scss" scoped>
.mask-dropdown-input {
  background: none;
  width: 90%;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 16px;
  color: #868e96 !important;
  border: none;
  outline: none;
  height: 100%;
}
.history-dropdown-content {
  z-index: 999;
  width: 18%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
:hover {
  color: var(--brand-color);
}
.dropdown-list {
  margin-top: 56px;
  padding-top: 16px;
  width: max-content;
  position: absolute;
  padding-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-left: 0px !important;
}
.dropdown-option {
  height: 40px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: 0.1s;
}
.dropdown-option:hover {
  background: #f8f9fa;
}
.dropdown-box {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.dropdown-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  width: 80%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #868e96;
}
</style>
