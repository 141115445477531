<template lang="pug">
.history-dropdown-content
  .dropdown-box(@click="openDropdown")
    span.dropdown-text(v-text="activitySelected")
    span(v-if="activitySelected === 'Tipo de atividade'")
      i.material-icons.notranslate.dropdown-icon keyboard_arrow_down
    span(v-else @click.stop="cancelSearch")
      i.material-icons.notranslate.dropdown-icon cancel
  .dropdown-list(v-if="searching")
    li.dropdown-option(v-for="(data, index) in options" :key="index" @click="selectType(data)") {{ data.value }}
</template>

<script>
export default {
  props: ['options', 'activitySelected'],
  data() {
    return {
      searching: false,
    };
  },
  methods: {
    cancelSearch() {
      this.$emit('filter', 'reset');
    },
    checkDisplay(e) {
      if (e.target.className != 'dropdown-option') {
        this.searching = false;
      }
    },
    selectType(data) {
      this.searching = false;
      this.$emit('filter', data);
    },
    openDropdown() {
      this.searching = !this.searching;
    },
  },
};
</script>

<style lang="scss" scoped>
.history-dropdown-content {
  width: 22%;
  display: flex;
  position: relative;
  flex-direction: column;
}
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
:hover {
  color: var(--brand-color);
}
.dropdown-list {
  margin-top: 56px;
  padding-top: 16px;
  width: max-content;
  position: absolute;
  padding-bottom: 16px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-left: 0px !important;
}
.dropdown-option {
  height: 40px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: 0.1s;
}
.dropdown-option:hover {
  background: #f8f9fa;
}
.dropdown-box {
  width: 100%;
  height: 48px;
  padding-left: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.dropdown-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  text-overflow: ellipsis;
  font-weight: normal;
  width: 80%;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  color: #868e96;
}
</style>
