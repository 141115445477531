<template lang="pug">
.mt-3.rounded-md.font-semibold.font-inter.text-zinc-500.text-sm.flex.flex-row.items-center.p-2.gap-4.cursor-pointer.bg-transparent.border-2.transition-all(
  class="hover:bg-gray-200",
  :class="{ 'border-brand': selected }",
  @click="$emit('select')"
)
  avatar.w-12.bg-black.h-12.ml-1(
    :src="`${this.requestLinks.cservice}/avatar/${userId}.png`",
    :avatarId="userId"
  )
  p {{ userName }}
  .p-1.grid.rounded-full.ml-auto.mr-2(
    :class="{ 'bg-brand': selected, 'border border-gray-300': !selected }"
  )
    i.material-icons(
      :class="{'text-white': selected, 'text-transparent': !selected}"
    ) check
</template>

<script>
import Avatar from '../Avatars/avatar2.vue'
export default {
  components: {
    Avatar
  },
  props: {
    userName: String,
    userAvatar: String,
    userId: String,
    selected: Boolean,
  },
  data() {
    return {};
  },
};
</script>
