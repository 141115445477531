<template lang="pug">
.org
  .w-full.relative.flex.flex-col.justify-start.items-start.border-2.border-transparent.bg-gray-200.transition.rounded-md.bg-opacity-50.h-12(
      class="focus-within:bg-white focus-within:border-brand dark:invert dark:bg-opacity-5 dark:text-white dark:focus-within:bg-black dark:placeholder:text-zinc-600"
    )
    p.text-gray-500.font-plus.font-medium.p-1.px-2.transition(:class="{'translate-y-1/4 text-sm': search, 'translate-x-0 text-xs': !search}") {{ labelTitle }}
    input.absolute.w-full.h-full.outline-none.bg-transparent.appearence-none.indent-2.pt-3.font-plus.font-semibold.text-gray-800(
      :placeholder="this.placeholder",
      @input="filterOrg",
      @focus="showOptions",
      v-click-outside="closeFilter",
      @keydown.delete="detectDelete",
      ref="dropdown",
      style="width: 100% !important",
      v-model="inputValue"
    )
  .org-dropdown(v-show="search")
    span(v-if="filtered.length > 0")
      li(
        v-for="(data, index) in filtered",
        @click="selectData(data)",
        :key="index"
      )
        .li-left-aligner
          .client-text(style="width: fit-content; margin: 0px") {{ data.name }}
    .addCategory(@click="newCategory()", v-if="canAddCategory") Adicionar categoria
      .client-text(
        style="width: fit-content; margin: 0px; margin-left: 5px !important"
      ) {{ inputValue }}
</template>

<script>
export default {
  props: [
    "labelTitle",
    "model",
    "attr",
    "dataFilter",
    "linkUrl",
    "useId",
    "displayAttr",
    "jsonData",
    "placeholder",
  ],
  data() {
    return {
      tag: null,
      search: false,
      dataList: [],
      filtered: [],
      dataJson: [],
      activeColorBox: false,
      inputColor: "",
      inputValue: "",
    };
  },
  computed: {
    canAddCategory() {
      const isRegistered = this.filtered.some((tag) => {
        if (tag.name.toLowerCase().startsWith(this.inputValue.toLowerCase())) {
          return true;
        }
      });
      if (this.inputValue.length > 0 && !isRegistered) return true;
      return false;
    },
  },
  methods: {
    upPlaceholder() {
      if(this.focus) return false;
      if(this.content) return false
      return true;
    },
    filterOrg() {
      if (this.tag != null) {
        this.removeTag();
      } else {
        this.filtered = this.dataList.filter((data) => {
          return data[this.dataFilter]
            .toLowerCase()
            .startsWith(this.inputValue.toLowerCase());
        });
        this.search = true;
      }
    },
    showOptions() {
      this.$refs["tag-input"].style.backgroundColor = "#FFFFFF";
      this.$refs["tag-input"].style.border = "1px solid #AAAAAA";
      this.filtered = this.dataList.filter((data) => {
        return data[this.dataFilter];
      });
      this.search = true;
    },
    selectData(selected) {
      this.activeColorBox = false;
      this.search = false;
      this.filtered = [];
      this.tag = selected;
      this.inputValue = "";
      this.$emit("data-selected", selected);
    },
    removeTag() {
      this.tag = null;
    },
    newCategory() {
      var newCategoryJson = {
        category: {
          name: this.inputValue,
          color: "#F9A454",
        },
      };
      this.dataList.push(newCategoryJson.category);
      this.selectData(newCategoryJson.category);
    },
    optionsClick(boxId) {
      var box = this.$refs[boxId][0];
      if (box.style.display == "flex") {
        box.style.display = "none";
        this.$refs.dropdown.click();
        this.activeColorBox = false;
      } else {
        box.style.display = "flex";
        this.activeColorBox = true;
      }
    },
    closeFilter() {
      this.$refs["tag-input"].style.backgroundColor = "#F8F9FA";
      this.$refs["tag-input"].style.border = "1px solid #E9ECEF";
      setTimeout(() => {
        if (this.activeColorBox) return;
        this.search = false;
      }, 100);
    },
    getColors(hexColor, data, index) {
      var box = this.$refs["colorBox" + index][0];
      box.style.display = "none";
      data.color = hexColor;
    },
    detectDelete() {
      if (!this.inputValue.length) {
        this.removeTag();
      }
    },
  },
  async created() {
    this.tag = this.model;
    if (this.jsonData) {
      this.dataList = this.jsonData;
    } else {
      try {
        const res = await this.$http.get(
          `${this.requestLinks.clist}/contact/category`
        );
        this.dataList = res.data;
      } catch (e) {
        this.$log.info(e);
      }
    }
  },
  watch: {
    jsonData(newData) {
      this.dataList = newData;
    },
  },
};
</script>

<style scoped lang="scss">
.tag-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  transition: 0.3s;
}
.tag-input__tag {
  height: 30px;
  float: left;
  margin-right: 10px;
  background-color: #eee;
  margin-top: 10px;
  line-height: 30px;
  padding: 0 5px;
  border-radius: 5px;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}

.tag-input__text {
  border: none;
  outline: none;
  width: 100%;
  height: 48px;
  text-indent: 12px;
  font-family: "Inter", sans-serif;
  background: none;
}
.color-box {
  width: 32px;
  height: 32px;
  margin-right: 15px;
  background: #b373f2;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
}
.change-color-box {
  z-index: 9999;
  padding: 16px 24px 16px 24px;
  position: absolute;
  margin-top: 16px;
  width: 174px;
  height: 111px;
  background: #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: none;
  justify-content: space-between;
  flex-direction: column;
}
.up-placeholder {
  font-family: "Inter", sans-serif;
}
.org {
  display: inline-block;
  position: relative;
  width: 100%;

  &-dropdown {
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    width: 100%;
    overflow: normal !important;
    color: #868e96;
    overflow: auto;
    top: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  li {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    overflow: visible;
    font-size: 14px;
    color: #868e96;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 40px;
  }
  li:hover {
    background: #f8f9fa;
  }

  .addCategory {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 600;
    overflow: visible;
    font-size: 14px;
    color: #868e96;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    height: 40px;
  }
  .addCategory:hover {
    background: #f8f9fa;
  }
}

.client-text {
  font-size: 13px;
  border: 1px solid rgba(172, 172, 172, 0.363);
  float: right;
}
</style>
