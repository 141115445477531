<template lang="pug">
div
  div.py-1.w-96
    p.font-plus.font-semibold.text-white.truncate(class="dark:text-gray-100" v-if="name") {{ name }}
    p.font-inter.text-xs.text-gray-300(v-if="address") {{ address }}
  iframe.h-64.w-96(
    :src="googleMapsEmbedUrl"
    frameborder="0"
    style="border:0;"
    referrerpolicy="no-referrer-when-downgrade"
    theme="dark"
  )
</template>

<script>
export default {
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      width: 350,
      height: 200,
      lat: 0,
      lng: 0,
      address: '',
      name: '',
    };
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    googleMapsEmbedUrl() {
      return `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.lat},${this.lng}`;
    },
  },

  async created() {
    const content = JSON.parse(this.message.messageBody || 'null');
    if (content) {
      this.lat = content.lat;
      this.lng = content.lng;
      this.address = content?.address;
      this.name = content?.name;
    }
  },
};
</script>
