<template lang="pug">
.w-full
  .flex.justify-center.items-center
    p.text-xs.text-zinc-500.rounded.font-inter Menu interativo enviado
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
