<template lang="pug">
nav.navbar.navbar-expand-lg(v-if="user")
  .flex.w-full.gap-2
    pause-alert(
      v-if="user.profile === 'p_agent' && user.pause"
      :class="{'ml-0': user.profile === 'p_manager' || user.profile === 'p_admin'}"
    )
    pause-button(
      v-else
      :class="{'ml-0': user.profile === 'p_manager' || user.profile === 'p_admin'}"
    )

    .flex.relative.h-10.bg-brand.bg-opacity-5.w-9.rounded-md.cursor-help.text-sm(
      v-tooltip="networkText"
      class="dark:invert"
    )
      .material-icons.notranslate.text-brand-400(
        :class="networkStyle"
      ) network_check
      .material-icons.notranslate.text-brand-400.animate-ping.absolute(
        :class="networkStyle"
        v-if="networkSpeed == 'low'"
      ) network_check

    .flex.relative.h-10.bg-brand.bg-opacity-5.w-9.rounded-md.cursor-help(
      class="dark:invert"
      v-tooltip="{content: `Detectamos que o horário do seu computador está diferente do seu fuso horário configurado.<br> Isso pode causar problemas em algumas funcionalidades do sistema.<br> Recomendamos ajustar o relógio do seu dispositivo para o fuso horário correto para garantir uma melhor experiência de uso.`, html: true}"
      v-if="userClockDifference >= 5"
    )
      .material-icons.notranslate.text-red-400 schedule
      .material-icons.notranslate.text-red-400.animate-ping.absolute schedule

    .flex.h-10.bg-brand.bg-opacity-5.w-9.rounded-md(
      class="dark:invert"
    )
      notification-icon

    .flex.items-center.justify-center.cursor-pointer(
      v-if="!supportWidgetId || !userIsAdminOrManager || !supportWidgetToken",
    )

    v-button-secondary.flex.items-center.justify-center.gap-2.h-10(
      v-else-if="supportWidgetId && userIsAdminOrManager",
      @click="openSupportWidget"
      ref="btn-toggle-support-widget"
    )
      img.w-5(src="@/assets/images/widget-chat-icon-purple.png")
      p Ajuda

    a(
      href="https://suporte.opens.com.br/",
      target="_blank",
      v-tooltip="'Abrir Portal do Suporte'",
      class="dark:invert"
    )
      v-button-secondary.flex.items-center.justify-center.gap-2.h-10
        .material-icons.notranslate.w-5.text-brand help

    .header-navigator
      v-dropdown(:distance="28" position="bottom-end" :skidding="-60")
        .user-box.pointer
          .relative
            .absolute.w-3.h-3.z-10.bottom-0.right-0.mb-1.rounded-full.border.border-zinc-50(
              :class="statusBallColor",
              class="dark:invert"
            )
            img.user-img.rounded.w-10.h-10.object-cover.aspect-square(
              v-if="!invalidAvatar && user.avatar != null",
              :src="`https://${csAddress}/avatar/${user.id}.png?version=${userAvatarCache}`",
              ref="img",
              :key="userAvatarCache"
              @error="invalidAvatar = true"
            )
            .user-img.firstLetter(v-else-if="user.username") {{ user.username[0].toUpperCase() }}
          .user-info
            .username.text-marker {{ user.username }}
            p.profile(class="dark:text-zinc-600") {{ translateProfile[user.profile] }}
          span.material-icons.notranslate.caret &#xe313
        template(#popper)
          .flex.flex-col.justify-start.items-start.py-6.text-inter.w-80
            .flex.flex-col.px-6.w-full.justify-start.items-start
              .flex.mb-4
                .w-10.h-10.bg-brand-gradient.rounded.mr-3.relative.flex.justify-center.items-center
                  img.user-img.rounded.w-10.h-10.object-cover.aspect-square(
                    v-if="!invalidAvatar && user.avatar != null",
                    :src="`https://${csAddress}/avatar/${user.id}.png?version=${userAvatarCache}`",
                    ref="img",
                    :key="userAvatarCache"
                    @error="invalidAvatar = true"
                  )
                  p.text-white.text-xl.absolute(v-else-if="user.username") {{ user.username[0].toUpperCase() }}
                .flex.flex-col.items-start
                  p.text-zinc-600.text-marker {{ user.username }}
                  p.text-zinc-400.text-sm(class="dark:text-zinc-800") {{ user.email }}
              .border-bottom.border-zinc-200.w-full.h-1
            .flex.flex-col.px-2.w-full.items-start
              .flex.mb-4.mt-6.ml-3.gap-4(class="hover:bg-zinc-150")
                switch-checkbox.mb-0(v-model="darkMode")
                p.text-zinc-400.user-dropdown__option(class="dark:text-zinc-800 dark:font-semibold") Tema escuro
              a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(
                target="_blank"
                href="../v1/panel"
                v-if="loadedManagerAccessPermissionInformation && hasPermissionToAccessManager"
              )
                span.mr-4.material-icons.notranslate.not-action &#xe915
                a.text-zinc-400.font-plus.user-dropdown__option(
                  class="dark:text-zinc-800 dark:font-semibold"
                ) Acessar ambiente de gestão
              a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(:href="`#/profile`")
                span.mr-4.material-icons.notranslate.not-action &#xe7fd
                a.text-zinc-400.font-plus.user-dropdown__option(
                  class="dark:text-zinc-800 dark:font-semibold"
                ) Perfil e segurança
              a.flex.p-3.w-full.justify-start.rounded-md.text-inside-brand.cursor-pointer.transition(@click="logout")
                span.mr-4.material-icons.notranslate.not-action &#xe9ba
                a.text-zinc-400.font-plus.user-dropdown__option(
                  class="dark:text-zinc-800 dark:font-semibold"
                ) Sair

  .flex.flex-col.absolute.z-10.w-80.shadow-lg.rounded-xl.bg-white.pb-4.border.select-none(
    :class="['h-[620px]', {'cursor-move': isDragging}]",
    v-if="showWidget && supportWidgetToken"
    ref="support-widget"
    @mousedown="startDragging"
    @mouseup="stopDragging"
    @mouseleave="stopDragging"
    @mousemove="drag"
  )
    .flex.justify-between.items-center.h-7.text-gray-400.p-2.px-4.w-full
      .flex.gap-2
        .material-icons.cursor-move.text-gray-400(
        ) drag_handle
        .material-icons.text-gray-400(
          @mouseover="handleShowInfoAboutDragWidget(true)",
          @mouseleave="handleShowInfoAboutDragWidget(false)"
        ) info
      .material-icons.cursor-pointer.text-gray-400(@click="handleCloseWidget") close

    .w-60.bg-white.border.rounded-lg.p-3.py-1.pb-2.absolute.-left-14.top-6(
      v-if="showInfoAboutDragWidget"
      @mouseover="handleShowInfoAboutDragWidget(true)",
      @mouseleave="handleShowInfoAboutDragWidget(false)"
    )
      h3.font-semibold.text-sm Arraste e solte!
      p.text-xs.text-gray-400 Você pode arrastar o widget para onde você quiser durante seu atendimento.
      .flex.flex-col.items-center.justify-center.h-full.rounded-xl.overflow-hidden
        video(src="https://imgs.opens.com.br/docs/yunique/widget_drag.mp4" autoplay loop)

    iframe.flex-1.w-full(
      :src="widgetUrl",
      allow="microphone;"
    )
</template>

<script>
import PauseAlert from '@/components/Modals/PauseAlert.vue';
import PauseButton from '@/components/Buttons/pause-button.vue';
import NotificationIcon from '@/components/Icons/NotificationIcon.vue';
import SwitchCheckbox from '@/components/Inputs/SwitchCheckbox.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import vButton from '@/components/Buttons/v-button.vue';
import csService from 'src/services/cservice.js';
import cstate from 'src/services/cstate.js';
import { Dropdown } from 'floating-vue';
export default {
  components: {
    PauseAlert,
    PauseButton,
    NotificationIcon,
    VDropdown: Dropdown,
    SwitchCheckbox,
    vButtonSecondary,
    vButton,
  },
  data() {
    return {
      csAddress: process.env.VUE_APP_CSERVICE,
      activeNotifications: false,
      invalidAvatar: false,
      darkMode: false,
      dropdownOpen: false,
      loadedManagerAccessPermissionInformation: false,
      hasPermissionToAccessManager: false,
      userAvatarCache: 0,
      userClockDifference: 0,
      translateProfile: {
        p_agent: 'Agente de atendimento',
        p_manager: 'Gestor',
        p_admin: 'Admin',
        p_corporative: 'Corporativo',
      },
      showWidget: false,
      isDragging: false,
      supportWidgetOffset: {
        top: 0,
        left: 0,
      },
      supportWidgetId: null,
      supportWidgetToken: null,
      supportWidgetContactId: null,
      showInfoAboutDragWidget: false,
      timeoutToHideInfoAboutDragWidget: null,
      networkSpeed: 'checking',
    };
  },
  computed: {
    networkStyle() {
      if (this.networkSpeed == 'checking') return 'animate-pulse';
      if (this.networkSpeed == 'high') return 'text-green-600';
      if (this.networkSpeed == 'medium') return 'text-yellow-600';
      if (this.networkSpeed == 'low') return 'text-red-600';
    },
    networkText() {
      if (this.networkSpeed == 'checking') return 'Testando velocidade da internet';
      if (this.networkSpeed == 'high') return 'Conexão de internet estavel';
      if (this.networkSpeed == 'medium') return 'Conexão instavel';
      if (this.networkSpeed == 'low') return 'Nível crítico de instabilidade na rede';
    },
    isDarkTheme() {
      return window.localStorage.getItem('yunique_dark_mode');
    },
    isPaused() {
      return this.$store.getters['IS_PAUSED'] || this.$store.getters['GET_PAUSE'].ispaused;
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    statusBallColor() {
      if (this.user.oncall) return 'bg-rose-500';
      if (this.user.pause || this.isPaused) return 'bg-orange-400';
      return 'bg-emerald-400';
    },
    userIsAdminOrManager() {
      return this.user?.profile === 'p_admin' || this.user?.profile === 'p_manager';
    },
    widgetUrl() {
      const baseUrl = process?.env?.VUE_APP_SCHEMA + '://' + process?.env?.VUE_APP_YUNIQUE_CHAT_APP_SERVICE;
      const page = localStorage.getItem('@support-widget:page');
      let pageParam = '';
      switch (page) {
        case 'chats':
          pageParam = '&goToChat=true';
          break;
        case 'call':
          pageParam = '&goToCall=true';
          break;
        default:
          pageParam = '';
          break;
      }
      const currentUrl = window.location.href;
      return `${baseUrl}/?yuniqueId=${this.supportWidgetId}&btYunique=${this.supportWidgetToken}&contactId=${this.supportWidgetContactId}&openYuniqueWidget=true&yuniqueFull=true${pageParam}&currentUrl=${currentUrl}`;
    },
  },
  watch: {
    user(value) {
      this.userAvatarCache++;
      this.$store.commit('SET_IS_PAUSED', !!value.pause);
    },
    darkMode(value) {
      if (!value) {
        document.querySelector('html').classList.remove('dark');
      } else {
        document.querySelector('html').classList.add('dark');
      }
      this.$store.commit('user/SET_DARKMODE', value);
      window.localStorage.setItem('yunique_dark_mode', value);
    },
    isDarkTheme(value) {
      this.darkMode = value;
    },
  },
  methods: {
    async checkUserClock() {
      const { checkTime } = cstate;
      try {
        const data = await checkTime();
        this.userClockDifference = Math.abs(data.timeDifference);
      } catch (e) {
        console.error(e);
      }
    },
    async checkHasPermissionToAccessManager() {
      if (this.user.profile == 'p_admin' || this.user.profile == 'p_manager')
        return (this.hasPermissionToAccessManager = true);
      const { getUserBond } = csService;
      try {
        const userInformationWithAllBonds = await getUserBond(this.user.id);
        if (
          userInformationWithAllBonds.users_bonds.filter((userBond) => {
            return (
              userBond.type == 'queue' && (userBond.permission == 'superviser' || userBond.permission == 'manager')
            );
          }).length >= 1
        )
          return (this.hasPermissionToAccessManager = true);
      } catch (e) {
        return (this.hasPermissionToAccessManager = false);
      }
    },
    checkDowloadSpeed() {
      this.networkSpeed = 'checking';
      const startTime = new Date().getTime();
      const testResource = new Image();
      testResource.onload = () => {
        const loadTime = new Date().getTime() - startTime;
        if (loadTime > 2000) return (this.networkSpeed = 'low');
        if (loadTime > 1000) return (this.networkSpeed = 'medium');
        this.networkSpeed = 'high';
      };
      testResource.src = 'https://imgs.opens.com.br/docs/yunique/1kb.png?' + startTime;
    },
    async logout() {
      try {
        if (this.user.profile == 'p_agent') {
          await csService.logout();
        }
      } catch (e) {
      } finally {
        this.$cookies.remove('snep7');
        window.location.href = '/auth/logout';
      }
    },
    newImage(payload) {
      this.$refs['img'].src = payload;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    async openSupportWidget() {
      localStorage.setItem('@support-widget:show', !this.showWidget);
      this.showWidget = !this.showWidget;

      const auth = await this.generateWidgetAuth();
      if (!auth) return;
      this.supportWidgetToken = auth?.token;
      this.supportWidgetContactId = auth?.contactId;

      this.$nextTick(async () => {
        if (!this.showWidget) {
          localStorage.removeItem('@support-widget:page');
          return;
        }
        const btnToggleSupportWidget = this.$refs['btn-toggle-support-widget'].$el;
        const supportWidget = this.$refs['support-widget'];

        let verticalPosition = btnToggleSupportWidget.offsetTop + 50;
        let horizontalPosition =
          btnToggleSupportWidget.offsetLeft + btnToggleSupportWidget.offsetWidth / 2 - supportWidget.offsetWidth / 2;

        supportWidget.style.top = `${verticalPosition}px`;
        supportWidget.style.left = `${horizontalPosition}px`;
      });
    },
    async generateWidgetAuth() {
      const authServiceUrl = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_AUTH}`;
      if (this.supportWidgetToken) return { token: this.supportWidgetToken, contactId: this.supportWidgetContactId };
      try {
        const payload = {
          name: this.user?.username,
          email: this.user?.email,
          companyId: this.user?.company_id,
        };
        const { data } = await this.$http.post(`${authServiceUrl}/contact-token`, payload);
        return data;
      } catch (error) {
        this.$toast.error('Erro ao gerar token de contato');
        return null;
      }
    },
    startDragging(e) {
      this.isDragging = true;
      this.supportWidgetOffset = {
        top: e.clientY - this.$refs['support-widget'].getBoundingClientRect().top,
        left: e.clientX - this.$refs['support-widget'].getBoundingClientRect().left,
      };
    },
    drag(e) {
      if (!this.isDragging) return;
      const supportWidget = this.$refs['support-widget'];
      supportWidget.style.top = `${e.clientY - this.supportWidgetOffset.top}px`;
      supportWidget.style.left = `${e.clientX - this.supportWidgetOffset.left - 100}px`;
    },
    handleShowInfoAboutDragWidget(show) {
      clearTimeout(this.timeoutToHideInfoAboutDragWidget);
      this.timeoutToHideInfoAboutDragWidget = setTimeout(() => {
        this.showInfoAboutDragWidget = show;
      }, 500);
    },
    stopDragging() {
      this.isDragging = false;
      localStorage.setItem(
        '@support-widget:position',
        JSON.stringify({
          top: this.$refs['support-widget'].style.top,
          left: this.$refs['support-widget'].style.left,
        }),
      );
    },
    handleCloseWidget() {
      this.showWidget = false;
      localStorage.removeItem('@support-widget:show');
    },
  },
  async created() {
    await this.checkUserClock();
    this.checkDowloadSpeed();
    setInterval(() => {
      this.checkDowloadSpeed();
    }, 1000 * 60 * 1.5);

    await this.checkHasPermissionToAccessManager();
    this.loadedManagerAccessPermissionInformation = true;

    const dark = window.localStorage.getItem('yunique_dark_mode');
    this.darkMode = dark == 'true' ? true : false;
    if (!this.darkMode) {
      document.querySelector('html').classList.remove('dark');
    } else {
      document.querySelector('html').classList.add('dark');
    }

    if (!this.userIsAdminOrManager) return;

    try {
      const response = await csService.getCompanySupportWidgetId();

      if (!response?.support_widget_id) {
        const defaultSupportWidgetId = process.env.VUE_APP_DEFAULT_SUPPORT_WIDGET_ID;
        if (!defaultSupportWidgetId) return;
        this.supportWidgetId = defaultSupportWidgetId;
      } else this.supportWidgetId = response.support_widget_id;

      const auth = await this.generateWidgetAuth();
      if (!auth) return;
      this.supportWidgetToken = auth?.token;
      this.supportWidgetContactId = auth?.contactId;
    } catch (error) {}

    const supportWidgetShow = localStorage.getItem('@support-widget:show');
    const supportWidgetOffset = localStorage.getItem('@support-widget:position');
    if (supportWidgetOffset) {
      const { top, left } = JSON.parse(supportWidgetOffset);
      if (!top || !left) return;
      if (supportWidgetShow) this.showWidget = JSON.parse(supportWidgetShow);
      this.$nextTick(() => {
        if (!this.$refs['support-widget']) return;
        this.$refs['support-widget'].style.top = top;
        this.$refs['support-widget'].style.left = left;

        window.addEventListener(
          'message',
          (event) => {
            if (event.origin !== process.env.VUE_APP_YUNIQUE_CHAT_APP_SERVICE) return;
            if (event.data?.closeWidget) {
              this.showWidget = false;
              localStorage.removeItem('@support-widget:show');
              return;
            }
            if (!event.data?.page && localStorage.getItem('@support-widget:page') === event.data?.page) return;
            localStorage.setItem('@support-widget:page', event.data?.page);
          },
          false,
        );
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('message', () => {});
  },
};
</script>
<style lang="scss" scoped>
.bug-report {
  font-size: 24px;
  margin-right: 30px;
  display: grid;
  place-items: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--brand-color);
}

.menu {
  min-width: fit-content;
  margin-left: auto;
}

.header-navigator {
  display: flex;
  cursor: pointer;

  .user-dropdown {
    padding: 24px 0px 24px 0px;
    width: 347px;
    background: #ffffff;
    position: absolute;
    margin-top: 28px;
    right: 130px;
    z-index: 1;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    &__option {
      background: #ffffff;
      width: 100%;
      height: 48px;
      padding-left: 31px;
      display: flex;
      align-items: center;
      gap: 16px;
      font-family: Inter;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      line-height: 17px;
      color: #868e96;
      transition: 0.2s;

      &:hover {
        background: #f8f9fa;
      }
    }

    &__header {
      padding: 8px 32px 24px 32px;

      .user-box {
        border-bottom: 1px solid #e9ecef;
        padding-bottom: 24px;
      }
    }
  }

  .header-navigator {
    position: relative;
  }

  .user-box {
    display: flex;
    gap: 12px;

    .username {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      color: #495057;
    }

    .profile {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #adb5bd;
    }

    .user-img {
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      border-radius: 4px;
      position: relative;
      top: none;
      background: var(--brand-color) 26;
      color: #fff;
    }

    .caret {
      color: var(--brand-color);
      font-size: 22px !important;
    }
  }
}

.navbar {
  z-index: 20;
}

@media screen and (max-width: 991px) {
  .navbar .navbar-nav {
    display: contents;
  }

  .header-navigator {
    .user-dropdown {
      right: 0;
    }
  }

  .menu {
    width: 100%;
  }
}
</style>
