<template lang="pug">
overlay 
  template(v-slot:content)
   img.shadow(:src="context" alt="altText" class="max-h-[80%]")
</template>

<script>
import Overlay from "@/components/Modals/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  computed: {
    context() {
      return this.$store.getters["modal/getContext"];
    },
  },
};
</script>