<template lang="pug">
.flex.bg-opacity-10.py-2.pl-4.pr-2.items-center.justify-between.rounded(:class="bg")
  audio(:src="source", type="audio/ogg", v-show="false")
  button.material-icons.notranslate.p-2.bg-brand-gradient.text-white.text-sm.rounded-full.h-6.w-6.flex.items-center.justify-center(
    @click="play",
    v-if="!playing",
    class="dark:invert",
  ) &#xe037
  .relative.grid.place-items-center
    button.material-icons.notranslate.p-2.bg-brand-gradient.text-sm.text-white.rounded-full.h-6.w-6.flex.items-center.justify-center(
      @click="pause",
      v-if="playing",
      class="dark:invert",
    ) &#xe034
    .flex.rounded-full.w-5.h-5.animate-ping.absolute.bg-brand.pointer-events-none(v-if="playing")
  .flex.flex-col.items-start.pl-2.flex-1
    .w-full.bg-brand-100.rounded-full.mt-1.cursor-pointer(
      @click="skipAudio"
      class="h-1.5 dark:invert"
    )
      .h-full.bg-brand-gradient.transition-all.flex.justify-end.rounded-full(:style="`width:${playedPercentage}%`", class="dark:invert-0")
        .ml-auto.h-3.w-3.rounded-full.bg-brand
    .text-xs.font-plus.font-semibold.tracking-tighter.mt-1.mix-blend-difference(
      :class="[isRecipient ? 'text-white' : 'text-gray-600']"
    ) {{ $moment.utc(currentTime * 1000).format(' mm:ss') }} - {{ $moment.utc(duration * 1000).format(' mm:ss') }}
  button.mx-1.text-xs.w-8.h-8.rounded-full.bg-gray-100.font-inter.text-gray-800.font-semibold(class="hover:text-brand-300" @click="increasePlaybackSpeed") {{ playBackSpeed }}x
</template>

<script>
export default {
  props: {
    source: {
      type: String,
    },
    bg: {
      type: String,
      default: 'bg-zinc-600',
    },
    isRecipient: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing: null,
      audio: null,
      duration: 0,
      currentTime: 0,
      playBackSpeed: 1,
    };
  },
  computed: {
    playedPercentage() {
      if (!this.duration) return 0;
      if (!this.currentTime) return 0;
      return (this.currentTime / this.duration) * 100;
    },
  },
  methods: {
    play() {
      if (!this.audio) return;
      this.audio.play();
      this.playing = setInterval(() => {
        if (this.audio.currentTime >= this.audio.duration) this.reset();
      }, 100);
    },
    pause() {
      if (!this.audio) return;
      this.audio.pause();
      clearInterval(this.playing);
      this.playing = null;
    },
    reset() {
      this.pause();
    },
    loadMetaData() {
      this.duration = this.audio.duration;
    },
    timeUpdate() {
      this.currentTime = this.audio.currentTime;
    },
    skipAudio(element) {
      if (!element?.target?.getBoundingClientRect) return;
      const posX = element.pageX - element.target.getBoundingClientRect().left;
      const endX = element.target.clientWidth;
      const percent = posX / endX;
      this.audio.currentTime = percent * this.audio.duration;
    },
    increasePlaybackSpeed() {
      this.playBackSpeed = Math.max(1, (this.playBackSpeed + 0.5) % 2.5);
      this.audio.playbackRate = this.playBackSpeed;
    },
  },
  mounted() {
    this.audio = new Audio(this.source);
    this.audio.addEventListener('loadedmetadata', this.loadMetaData);
    this.audio.addEventListener('timeupdate', this.timeUpdate);
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>
