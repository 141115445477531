const PageViewEnum = {
  CHAT: 0,
  CAMPAIGN: 1,
};
export default {
  namespaced: true,
  state: {
    containerView: PageViewEnum.CHAT,
    panelView: PageViewEnum.CHAT,
  },
  mutations: {
    /**
     *
     * @param {*} state
     * @param {'0'|'1'} view 0 means chat-container will be displayed, 1 means the campaign container will be visible instead
     */
    setContainerView: (state, view) => {
      state.containerView = view;
    },
    setPanelView: (state, view) => {
      state.panelView = view;
    },
    setView: (state, view) => {
      state.containerView = view;
      state.panelView = view;
    },
  },
  getters: {
    getContainerView(state) {
      return state.containerView;
    },
    getPanelView(state) {
      return state.panelView;
    },
  },
};
