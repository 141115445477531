<template lang="pug">
.flex.flex-col.justify-start.items-start.w-full.w-full.gap-4.py-4
  .flex.flex-col.gap-1.w-full.items-start
    span.font-inter.text-sm.text-gray-400.font-medium Escolha um template de mensagem
    //- template dropdown
    dropdown.w-full(placement="bottom", auto-size="inner")
      span
        .flex.items-center.justify-between.form-control.input(
          @click="search = !search"
        )
          div.w-full.flex.justify-between.cursor-pointer(v-if="!selectedTemplate")
            span.font-plus Selecione um template
            i.material-icons-round.notranslate.dropdown-icon.text-gray-300 keyboard_arrow_down
          div.w-full.flex.justify-between.cursor-pointer(v-else)
            span {{ formatSnakeCaseName(selectedTemplate.name) }}
            i.material-icons-round.notranslate.dropdown-icon.text-gray-300(@click.stop="resetTemplate()") cancel
      template(#popper)
        .flex.flex-col.w-full.items-start.justify-start.p-2
          IconInput.mb-2.font-plus(
            :auto-focus="true",
            v-model="searchTemplateValue",
            :label="'Pesquisar template'",
            @input="handleSearchInput(searchTemplateValue)"
          )
          .flex.flex-col.w-full.gap-1(v-if="isEmptyTemplates && !loadingSearchTemplate")
            nothing-here-icon.w-10
            p.text-gray-400.font-inter Nenhum template encontrado.
          div.w-full.max-h-60.overflow-auto
            loading-ring.py-6(v-if="loadingSearchTemplate")
            .flex.justify-between.items-center.p-3.cursor-pointer.text-zinc-500.font-inter.w-full(
              v-if="!loadingSearchTemplate"
              v-for="template in templates",
              :key="template.id",
              class="hover:bg-zinc-200",
              v-close-popper
              @click="selectTemplate(template)"
            )
              p.flex.gap-4.justify-between.mr-auto.items-center.w-full.font-plus {{ formatSnakeCaseName(template.name) }}
            .flex.w-full.flex-col(v-if="nextPage")
              Observer(@intersect="fetchNextTemplatesPage()")
              loading-component.w-6.h-6.pointer-events-none.py-6(class="dark:invert")

  .w-full.flex.flex-col.gap-4(v-if="selectedTemplate !== null && !loadingConfig")
    //- template preview
    .form-control.input.h-fit.p-2
      .bg-zinc-200.p-2.flex.flex-col.gap-1.max-w-sm.rounded.ml-auto.items-start
        //- Body
        p.text-gray-700.text-left.indent-0.whitespace-pre-line {{ replaceVariablesWithValue(bodyComponent?.text, bodyVariables, bodyVariablesValues) }}

    //- template variables
    .overflow-auto.max-h-64.w-full.flex.flex-col.gap-4.m-auto.justify-start.items-start(v-if="hasVariables")
      section-expander.w-full(
        v-if="bodyVariables.length",
        title="Variáveis do corpo",
        :opened="true",
        :error="hasExceededBodyLimit && 'O corpo da mensagem não pode exceder 1024 caracteres'"
      )
        v-table.table-fixed
          template(v-slot:header)
            v-table-head
              tr.font-inter.text-sm.font-semibold.text-zinc-400.border-b(class="px-4 child:py-3 child:px-4")
                th.font-inter.font-semibold.w-16 Campo
                th.font-inter.font-semibold Valor
                th.font-inter.font-semibold.w-32 Valores do contato
          template(v-slot:body)
            v-table-row(v-for="(variable, index) in bodyVariables", :key="index")
              td.font-plus.text-brand.font-bold {{ variable }}
              td.font-plus
                v-dropdown.text-sm.min-w-fit.h-12.w-fit(
                  v-if="switchBodyValues[index]"
                  useAllHeight
                  class="transition-all active:scale-90"
                  :placeholder="getSelectableValuePlaceholder(bodyVariablesValues[index])"
                )
                  ul.divide-y(v-close-popper.all)
                    dropdown-item(
                      hide-icon
                      v-for="([key, value]) in Object.entries(contactValuesToUse)" :key="key"
                      class="transition-all active:scale-90"
                      :label="value",
                      @click="bodyVariablesValues.splice(index, 1, `$${key}$`)",
                      :selected="bodyVariablesValues[index] === `$${key}$`"
                    )
                icon-input.w-full(
                  v-else
                  icon="text_fields",
                  v-model="bodyVariablesValues[index]",
                  :label="`Digite um valor para a variável ${variable}`"
                )
              td.font-plus
                .flex.items-center.justify-start.gap-2
                  switch-box.m-0(v-model="switchBodyValues[index]", @input="bodyVariablesValues[index] = ''")

  .w-full.flex.justify-center.items-center.py-4(v-if="loadingConfig")
    loading-ring
</template>

<script>
import ChatConfig from '@/services/chat-config';
import Overlay from '@/components/Modals/Overlay.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import LoadingRing from '../LoadingRing.vue';
import { defineComponent } from 'vue';
import IconInput from '../Inputs/IconInput.vue';
import { debounce } from '@/helpers';
import { Dropdown } from 'floating-vue';
import Observer from '../Observer.vue';
import LoadingComponent from '../LoadingComponent.vue';
import SwitchBox from '@/components/Inputs/SwitchCheckbox.vue';
import vDropdown from '@/components/Dropdown/v-dropdown.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import NothingHereIcon from '../Icons/NothingHereIcon.vue';
import vTable from '../Tables/v-table.vue';
import vTableHead from '../Tables/v-table-head.vue';
import vTableRow from '../Tables/v-table-row.vue';
import SectionExpander from '../SectionExpander.vue';
import contactList from '@/services/contact-list';
import contactListMixin from '@/mixins/contact-list';

export default defineComponent({
  mixins: [contactListMixin],
  name: 'MountOpensTemplateForm',
  props: {
    recipientId: {
      type: String,
      required: false,
    },
    opensProviderId: {
      type: Number,
      required: true,
    },
  },
  components: {
    NothingHereIcon,
    VButtonSecondary,
    Overlay,
    VButton,
    LoadingRing,
    IconInput,
    Observer,
    LoadingComponent,
    SwitchBox,
    Dropdown,
    vDropdown,
    DropdownItem,
    vTable,
    vTableHead,
    vTableRow,
    SectionExpander,
  },
  data() {
    return {
      nextPages: [],
      switchBodyValues: [],
      isEmptyTemplates: false,
      nextPage: false,
      search: false,
      searchTemplateValue: '',
      templates: [],
      selectedTemplate: null,
      bodyComponent: null,
      templateConfig: null,
      loadingConfig: false,
      loadingSearchTemplate: false,
      hasVariables: false,
      bodyVariables: [],
      bodyVariablesValues: [],
      size: 10,
      from: 0,
    };
  },
  computed: {
    hasExceededBodyLimit() {
      const limit = 1024;
      const bodyText = this.bodyComponent?.text;
      const bodyWithVariables = this.replaceVariablesWithValue(bodyText, this.bodyVariables, this.bodyVariablesValues);
      const fullBody = this.replaceYuniqueContactVariables(bodyWithVariables);
      if (!fullBody) return false;
      return fullBody.length > limit;
    },
    activeRoom() {
      return this.$store.getters['chat/activeRoom'].room;
    },
    wabaConfigId() {
      if (this.providerConfigId) return this.providerConfigId;
      return this.$store.getters['chat/activeRoom'].room.chatConfigId;
    },
    contactValuesToUse() {
      const values = {
        contactFullName: 'Nome completo',
        contactFirstName: 'Primeiro nome',
      };

      if (!this?.contact) return values;

      const email = this.contact?.email?.length ? this.contact.email[0]?.address : null;
      const category = this.contact?.category?.name || null;
      const organization = this.contact?.organization?.name || null;
      const cpf = this.contact?.cpf?.length;
      const phoneNumber = this.contact?.phones?.length ? this.contact.phones[0]?.number : null;

      if (phoneNumber) values.contactPhone = 'Numero';
      if (email) values.contactEmail = 'Email';
      if (category) values.contactCategory = 'Categoria';
      if (organization) values.contactOrganization = 'Organização';
      if (cpf) values.contactCPF = 'CPF';

      return values;
    },
  },
  watch: {
    selectedTemplate() {
      this.emitUpdatedTemplateData();
    },
    templateConfig() {
      this.emitUpdatedTemplateData();
    },
    bodyVariablesValues() {
      this.emitUpdatedTemplateData();
    },
    hasVariables() {
      this.emitUpdatedTemplateData();
    },
    bodyVariables() {
      this.emitUpdatedTemplateData();
    },
  },
  methods: {
    replaceYuniqueContactVariables(text) {
      if (!text) return '';
      let replacedText = text;
      const contactVariables = {
        contactFullName: this.contact?.name,
        contactFirstName: this.contact?.name?.split(' ')[0],
        contactPhone: this.contact?.phones?.length ? this.contact.phones[0]?.number : null,
        contactEmail: this.contact?.email?.length ? this.contact.email[0]?.address : null,
        contactCategory: this.contact?.category?.name || null,
        contactOrganization: this.contact?.organization?.name || null,
        contactCPF: this.contact?.cpf?.length,
      };

      Object.entries(contactVariables).forEach(([key, value]) => {
        if (value) {
          replacedText = replacedText.replace(`$${key}$`, value);
        }
      });
      return replacedText;
    },
    getSelectableValuePlaceholder(keyFormatted) {
      if (!keyFormatted) return 'Selecione um valor';
      const key = keyFormatted.replaceAll('$', '');
      return this.contactValuesToUse[key] || 'Selecione um valor';
    },
    emitUpdatedTemplateData() {
      this.areAllTemplateVariablesFilled();
      this.$emit('updated', {
        selectedTemplate: this.selectedTemplate,
        bodyVariablesValues: this.bodyVariablesValues,
        hasVariables: this.hasVariables,
        bodyVariables: this.bodyVariables,
        templateConfig: this.templateConfig,
        isSupportedTemplate: true,
        templateNeedSync: false,
      });
    },
    areAllTemplateVariablesFilled() {
      if (!this.selectedTemplate?.name) {
        return this.$emit('error', 'Selecione um modelo de mensagem para continuar.');
      }

      if (this.hasVariables) {
        const filteredBodyVariablesValues = this.bodyVariablesValues?.filter((value) => value !== '');

        if (this.bodyVariables?.length !== filteredBodyVariablesValues?.length) {
          return this.$emit('error', 'Preencha as variáveis para continuar.');
        }
      }

      if (this.hasExceededBodyLimit) {
        return this.$emit('error', 'O corpo da mensagem não pode exceder 1024 caracteres');
      }

      return this.$emit('error', null);
    },
    concatUrlWithVariable(url, value) {
      if (!url) return;
      const truncateUrl = this.truncateMiddleString(url);
      if (!value) return truncateUrl;
      return truncateUrl?.replace('{{1}}', value);
    },
    truncateMiddleString(str, maxLength = 60) {
      if (str.length <= maxLength) {
        return str;
      }

      const truncationLength = maxLength - 3; // Leave space for "..."
      const firstHalfLength = Math.ceil(truncationLength / 2);
      const secondHalfLength = Math.floor(truncationLength / 2);

      const firstHalf = str.substr(0, firstHalfLength);
      const secondHalf = str.substr(-secondHalfLength);

      return `${firstHalf}...${secondHalf}`;
    },
    handleSearchInput: debounce(async function (value) {
      this.from = 0;
      this.templates = [];
      this.nextPage = false;
      this.resetTemplate();

      this.searchTemplateValue = value.trim();

      await this.getTemplates();
    }, 500),
    replaceVariablesWithValue(text, variables, values) {
      if (!text || !variables || !values) return '';
      let replacedText = text;

      variables.forEach((variable, index) => {
        const variableValue = values[index];
        replacedText = variableValue ? replacedText.replace(`{{${variable}}}`, variableValue) : replacedText;
      });

      return replacedText;
    },
    getVariables(text) {
      if (!text) return [];
      const variables = text?.match(/{{(.*?)}}/g);
      if (variables) {
        return variables.map((variable) => variable.replace(/{{|}}/g, ''));
      }
      return [];
    },
    formatSnakeCaseName(name) {
      let uppercase = name[0].toUpperCase() + name.substring(1, name.length);
      return uppercase.split('_').join(' ');
    },
    async getTemplates() {
      try {
        const { items, total } = await ChatConfig.getOpensTemplates({
          size: this.size,
          from: this.from,
          templateName: this.searchTemplateValue,
          opensProviderId: this.opensProviderId,
        });

        this.templates = items;
        this.nextPage = this.templates.length < total;
        this.from = this.size;
        this.isEmptyTemplates = !this.templates.length;
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao buscar os templates');
      }
    },
    async fetchNextTemplatesPage() {
      try {
        const { items, total } = await ChatConfig.getOpensTemplates({
          size: this.size,
          from: this.from,
          templateName: this.searchTemplateValue,
          opensProviderId: this.opensProviderId,
        });

        this.templates.push(...items);
        this.nextPage = this.templates.length < total;
        this.from += this.size;
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao buscar mais templates');
      }
    },
    resetTemplate() {
      this.bodyVariables = [];
      this.bodyVariablesValues = [];
      this.selectedTemplate = null;
      this.templateConfig = null;
      this.hasVariables = false;
      this.bodyComponent = null;
      this.switchBodyValues = [];
    },
    async selectTemplate(template) {
      try {
        this.resetTemplate();
        this.loadingConfig = true;
        this.selectedTemplate = template;
        this.templateConfig = template;

        this.bodyComponent = {
          type: 'BODY',
          text: template.bodyContent,
        };

        this.extractComponentsVariables();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingConfig = false;
      }
    },
    extractComponentsVariables() {
      this.bodyVariables = this.getVariables(this.bodyComponent.text);

      this.hasVariables = this.bodyVariables.length > 0;
    },
    async getContact() {
      if (!this.recipientId) return;
      this.activeContactId = this.recipientId;
    },
  },
  async created() {
    try {
      await this.getContact();
      await this.getTemplates();
    } catch (error) {
      this.$toast.error(error);
    }
  },
});
</script>
