<template lang="pug">
tr.callback-table-row.text-inside-brand(@click="$emit('openOverlay', callback)")
  th.callback-origin-content(scope='row')
    .callback-origin-text.text-marker Criado por
    .callback-origin-create-text.text-marker {{ callback.assignedBy.username }}
  td.callback-callto-content
    .callback-callto-content-aligner
      .callback-callto-user-img(v-if='failedImg') {{ callback.contact.username[0].toUpperCase() }}
      img.callback-callto-user-img(
        v-else-if='!failedImg',
        @error='failedImg = true'
        :src='!callback.contact.endpoint ? `${clistUrl}/uploads/${callback.contact.avatar}.jpg` : `${clistUrl}/avatar/${callback.contact.id}.png`',
      )
      .callback-callto-info-aligner
        p.callback-callto-name-text.text-marker {{ callback.contact.username }}
        p.callback-callto-number-text(v-if="data.type == 'Chat'")
          | {{ formatNumber(callback.contact.phones[0]?.number) }}
        p.callback-callto-number-text(v-else)
          | {{ data.contactId ? callback.contact.phones[0].number : formatNumber(data.contactNumber) }}
      .callback-callto-tag-margin.user-tag(v-if='callback.contact.category') {{ callback.contact.category.name }}
      .client-text.Colega.colega-tag.p-1.ml-1(v-else-if='callback.contact.access_token') Colega
      .unknown-text.no-tag-margin(v-else='') Sem categoria
  td.callback-obs-content.text-marker(v-if='data.note')
    | {{ data.note.substring(0, 55) + (data.note.length > 55 ? "..." : "") }}
  td.callback-obs-content(v-else-if="data.Category.type == 'abandon'")
    p Abandonado em <b>{{ data.description }}</b> após {{ abandonTime(data.type === 'Chat' ? callback.payload.waitTime : callback.payload.date) }}
  td.callback-obs-content(v-else='') Sem Observações
  td.callback-add-content.text-marker.font-inter {{ timeAdded(data.createdAt) }}
  td.callback-actions-content.pointer-events-none(@click.stop="")
    v-dropdown.pointer-events-auto.mx-4(placement="bottom-start")
      button.flex.items-center.cursor-pointer.select-none.mr-1.border.transition.px-3.py-1.pr-1.rounded-md.group(
        class="hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10"
      )
        .font-plus.font-semibold.text-xs.text-zinc-500(
          class="group-hover:text-brand"
        ) Ações
        .material-icons.notranslate.text-gray-400(class="group-hover:text-brand") keyboard_arrow_down
      template(#popper)
        ul.divide-y
          dropdown-item(
            v-if="callback.contact.categoryId"
            @click="startChat",
            label="Iniciar conversa",
            icon="e0b7"
          )
          dropdown-item(
            @click="$emit('calling', { payload: getPayload(data.contactId), index: index })",
            :label="'Ligar para ' + callback?.contact?.username || 'Contato desconhecido'",
            icon="e0b0"
          )
          dropdown-item(
            @click="$root.$emit('action', { actualModal: 'FinishActivity', actualActivity: callback })"
            label="Concluir atividade",
            icon="e876"
          )
          dropdown-item-alert(
            @click="$root.$emit('action', { actualModal: 'CancelActivity', actualActivity: callback })"
            label="Cancelar atividade",
            icon="E5CD"
          )
</template>

<script>
import { getContactByPhone, getContactById } from '@/services/contact-list.js';
import { getContactFromCservice, getUserById } from '@/services/cservice';
import { Dropdown } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import DropdownItemAlert from '@/components/Dropdown/dropdown-item-alert.vue';

export default {
  props: ['data', 'index'],
  components: {
    VDropdown: Dropdown,
    DropdownItem,
    DropdownItemAlert,
  },
  data() {
    return {
      clistUrl: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CLIST}`,
      failedImg: false,
      callback: {
        payload: {},
        assignedBy: {},
        assignedTo: {},
        contact: {
          username: '???',
        },
      },
    };
  },
  methods: {
    getPayload(data) {
      if (data) return { ...this.callback.payload, contactNumber: this.callback.contact.phones[0].number };
      return this.callback.payload;
    },
    async startChat() {
      this.$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: this.callback.contact } });
    },
    timeAdded(date) {
      let dateNow = new Date();
      let addedTime = (dateNow - new Date(date)) / 1000;
      if (addedTime > 59 && addedTime < 3600) {
        return 'Há ' + parseInt(addedTime / 60) + ' min';
      } else if (addedTime < 60) {
        return 'Há ' + parseInt(addedTime) + ' seg';
      } else if (addedTime > 3599 && addedTime < 3600 * 24) {
        if (parseInt(addedTime / 3600) === 1) {
          return 'Há 1 hora';
        }
        return 'Há ' + parseInt(addedTime / 3600) + ' horas';
      } else if (addedTime > 3600 * 24) {
        return 'Há ' + parseInt(addedTime / 3600 / 24) + ' dias';
      }
    },
    formatNumber(number) {
      try {
        if (number.length > 4) {
          return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number, 'BR'), this.PNF.INTERNATIONAL);
        } else if (number.length === 4) {
          return 'SIP ' + number;
        }
        return number;
      } catch (error) {
        return number;
      }
    },
    abandonTime(holdtime) {
      if (!holdtime) return '0 segundos';
      if (holdtime > 59 && holdtime < 3600) {
        return parseInt(holdtime / 60) + ' minutos';
      } else if (holdtime < 60) {
        return parseInt(holdtime) + ' segundos';
      } else if (holdtime > 3599 && holdtime < 3600 * 24) {
        if (parseInt(holdtime / 3600) === 1) {
          return '1 hora';
        }
        return parseInt(holdtime / 3600) + ' horas';
      } else if (holdtime > 3600 * 24) {
        return parseInt(holdtime / 3600 / 24) + ' dias';
      }
    },
    async getAssignedBy() {
      try {
        const assignedBy = await getUserById(this.data.assignedBy);
        this.callback.assignedBy = assignedBy || {};
      } catch (e) {}
    },
    async getChatContact() {
      try {
        const res = await getContactById(this.data.contactNumber);
        const userFound = {
          ...res,
          username: res.name,
          avatar: res.id,
        };
        this.callback.contact = userFound;
      } catch (error) {
        console.error(error);
      }
    },
    async getContactById() {
      try {
        const contact = await getContactById(this.data.contactId);
        const userFound = {
          ...contact,
          username: contact.name,
          avatar: contact.id,
        };
        this.callback.contact = userFound;
        if (!this.contact?.phones?.length) return;
        const { number, ddi } = this.contact.phones[0];
        this.contactPhoneNumber = `${ddi}${number}`;
      } catch (error) {
        console.error(error);
      }
    },
    async getContact() {
      if (this.data.contactId) return this.getContactById();
      try {
        const res = await getContactByPhone({
          number: this.data.contactNumber.replace(/[^\d]+/g, ''),
          companyId: this.user.company_id,
        });
        this.callback.contact = {
          ...res.data.contact,
          username: res.data.contact.name,
          avatar: res.data.contact.id,
        };
      } catch (e) {
        this.getContactFromCservice().catch(() => {
          this.callback.contact = {
            username: 'Contato desconhecido',
            avatar: '?',
          };
          this.failedImg = true;
        });
      }
    },
    async getContactFromCservice() {
      try {
        const contact = await getContactFromCservice({
          peer: this.data.contactNumber.replace(/[^\d]+/g, ''),
          company_id: this.data.companyId,
        });
        this.callback.contact = contact.data.users_devices;
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async call(num) {
      if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
      else this.callNumber(num);
    },
  },
  async created() {
    await this.getAssignedBy();
    this.callback.contact = { ...this.fakeContact.contact, username: 'Desconhecido' };
    if (this.data.type === 'Chat') this.getChatContact();
    else this.getContact();
    this.callback.payload = this.data;
  },
};
</script>
