<template>
  <Onboarding>
    <template v-slot:header>
      <h4 class="text-2xl font-extrabold text-gray-400">Instale o Softphone</h4>
      <i class="cancel notranslate material-icons" @click="$store.commit('modal/setActiveModal', '')">close</i>
    </template>

    <template v-slot:content>
      <p class="content">
        Selecione em qual sistema operacional você deseja instalar e siga as
        instruções do passo a passo
      </p>
      <div class="content__options">
        <div
          @click="$store.commit('modal/setActiveModal', 'InstallWindows')"
          class="content__options__item"
        >
          <p class="content__options__item__text">
            Instalar no &nbsp;<b>Windows</b>
          </p>
          <div class="content__options__item__icon">
            <IconCircularBg class="w-8 h-8" :icon="'arrow_forward'" />
          </div>
        </div>
        <div
          @click="$store.commit('modal/setActiveModal', 'InstallMacOS')"
          class="content__options__item"
        >
          <p class="content__options__item__text">
            Instalar no &nbsp;<b>MacOS</b>
          </p>
          <div class="content__options__item__icon">
            <IconCircularBg class="w-8 h-8" :icon="'arrow_forward'" />
          </div>
        </div>
        <div
          @click="$store.commit('modal/setActiveModal', 'InstallLinux')"
          class="content__options__item"
        >
          <p class="content__options__item__text">
            Instalar no &nbsp;<b>Linux</b>
          </p>
          <div class="content__options__item__icon">
            <IconCircularBg class="w-8 h-8" :icon="'arrow_forward'" />
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <p
        class="footer__cancel"
        @click="$store.commit('modal/setActiveModal', '')"
      >
        Cancelar
      </p>
    </template>
  </Onboarding>
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
};
</script>

<style lang='scss' scoped>
.header {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #868e96;
}

.cancel {
  border-radius: 100%;
  margin-top: 20px;
  width: 30px;
  height: 30px;
  color: rgb(197, 197, 197);
  cursor: pointer;
}

.content {
  display: block;
  margin-top: 20px;
  text-align: left;
  color: #868e96;

  &__options {
    width: 100%;
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 5px;
      margin: 25px 0 20px 0;
      height: 4rem;
      width: 100%;
      border: 1px solid #747b8149;
      cursor: pointer;

      &:hover {
        border: 1px solid #ff480049;
      }

      &__text {
        padding-left: 2rem;
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
        flex: 2;
        margin: 0;
        color: #3d3d3d;
      }
      &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        flex: 1;
      }
    }
  }
}

.footer {
  &__cancel {
    cursor: pointer;
    font-size: 14px;
    color: rgb(252, 105, 52);
    font-weight: 600;
  }
}
</style>