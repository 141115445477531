import queuePreferencesService from '@/services/queue-preference.js';

export default {
  namespaced: true,
  state: {
    currentPhoneAttendanceRequiresTag: false,
    currentEmailAttendanceRequiresTag: false,
    currentChatAttendanceRequiresTag: false,
  },
  getters: {
    CURRENT_CALL_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentPhoneAttendanceRequiresTag;
    },
    CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentEmailAttendanceRequiresTag;
    },
    CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG(state) {
      return state.currentChatAttendanceRequiresTag;
    },
  },
  mutations: {
    SET_CURRENT_CALL_ATTENDANCE_REQUIRES_TAG(state, currentPhoneAttendanceRequiresTag) {
      state.currentPhoneAttendanceRequiresTag = currentPhoneAttendanceRequiresTag;
    },
    SET_CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG(state, currentEmailAttendanceRequiresTag) {
      state.currentEmailAttendanceRequiresTag = currentEmailAttendanceRequiresTag;
    },
    SET_CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG(state, currentChatAttendanceRequiresTag) {
      state.currentChatAttendanceRequiresTag = currentChatAttendanceRequiresTag;
    },
  },
  actions: {
    async fetchGroupPreferences({ commit }, { groupId, companyId }) {
      groupId = groupId?.trim();
      try {
        if (!groupId) return;
        const response = await queuePreferencesService.getGroupPreferences({
          companyId,
          groupId: groupId,
        });

        const groupPreferences = response || [];
        if (!groupPreferences || !groupPreferences?.length) return;

        const mandatoryTagAfterEmail = groupPreferences?.[0]?.mandatoryTagAfterEmail;
        const mandatoryTagAfterChat = groupPreferences?.[0]?.mandatoryTagAfterChat;

        commit('SET_CURRENT_EMAIL_ATTENDANCE_REQUIRES_TAG', mandatoryTagAfterEmail);
        commit('SET_CURRENT_CHAT_ATTENDANCE_REQUIRES_TAG', mandatoryTagAfterChat);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
