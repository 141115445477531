<template lang="pug">
.flex.gap-4.flex-col.justify-center
    .w-32.h-32.border.border-dashed.border-brand-500.flex.justify-center.items-center.rounded-full
        .bg-brand-100.flex.justify-center.items-center.rounded-full(class='w-3/4 h-3/4')
            .material-icons.notranslate.text-brand-500.text-4xl info
    slot
</template>

<script>
export default {
  props: {
    lg: Boolean,
    md: {
      type: Boolean,
      default: true,
    },
    sm: Boolean,
    xs: Boolean,
  },
};
</script>
