<template lang="pug">
modal(
  @click="$emit('close')"
) 
  modal-body
    template(v-slot:header)
      .flex
        v-heading Encaminhar email
        .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(
          @click="$emit('close')"
        ) close
    template(v-slot:body)
      .flex.gap-2.flex-col.w-96
        .flex.flex-col.gap-1.items-start.w-full
          p.font-semibold Encerrar atendimento após encaminhar este email:
          switch-checkbox(v-model="terminateChatWhenForward")
          p.text-xs.text-red-500(v-if="terminateChatWhenForward") Seu atendimento será finalizado
        icon-input(
          label="Email de destino"
          icon="mail"
          v-model="toEmail"
        )
        rich-text-editor(
          @write="handleEmailChangeText"
        )
    template(v-slot:footer)
      div.w-full.flex.gap-4
        v-button-secondary(
          @click="$emit('close')"
        ) Fechar
        v-button(
          :disabled="!toEmail"
          @click="$emit('forward-email', {toEmail, text, terminateChatWhenForward})"
        ) Encaminhar
</template>

<script>
import vButtonSecondary from '../Buttons/v-button-secondary.vue';
import RichTextEditor from '@/components/Inputs/RichTextEditor.vue';
import SwitchCheckbox from '../Inputs/SwitchCheckbox.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import VHeading from '@/components/Text/v-heading.vue';
import vButton from '../Buttons/v-button.vue';
import Modal from '@/components/Modals/Modal.vue';

export default {
  components: {
    vButtonSecondary,
    RichTextEditor,
    SwitchCheckbox,
    IconInput,
    ModalBody,
    VHeading,
    vButton,
    Modal,
  },
  data() {
    return {
      toEmail: '',
      text: '',
      terminateChatWhenForward: false,
    };
  },
  methods: {
    handleEmailChangeText(val) {
      this.text = val;
    },
  },
};
</script>
