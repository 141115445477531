<template lang="pug">
v-dropdown(placement='bottom-start')
  .border.px-4.py-1.rounded-md.flex.items-center.justify-between.gap-4.cursor-pointer.bg-zinc-50(:class="{'h-full' : useAllHeight}")
    .font-semibold.text-zinc-500.text-sm.font-inter {{ placeholder }}
    .material-icons.notranslate.text-zinc-500 expand_more
  template(#popper)
    .divide-y(class='child:px-4 child:py-2 child:cursor-pointer')
      slot
</template>

<script>
export default {
  props: {
    autoClose: Boolean,
    placeholder: String,
    useAllHeight: Boolean,
  },
};
</script>
