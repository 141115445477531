<template lang="pug">
.px-2.py-1.pr-1.pb-2.rounded.rounded-b-lg.bg-gradient-to-br.flex.ml-auto.gap-1.relative.justify-between(
  class="dark:invert min-w-[16rem]"
  :class="[pauseBackground]"
)
  .bg-red-500.absolute.w-full.h-full.animate-ping.z-0(
    v-show="exceeded"
  )
  .flex.flex-col.items-start.z-10
    .text-white.font-plus.font-semibold.text-sm {{ pauseName }}
    .flex.gap-1
      .text-white.font-plus.font-regular.text-xs.text-opacity-60 {{ exceeded ? 'Tempo esgotado:' : 'Tempo em pausa:' }} 
      .text-white.font-plus.font-semibold.text-xs {{ timestamp }} 
  .flex.flex-col.z-10
    .flex.gap-2
      v-tooltip
        span.material-icons.notranslate.h-7.w-7.grid.place-items-center.rounded-full.bg-white.bg-opacity-40.text-white.text-lg.cursor-pointer.transition(
          @click="showChangePauseModal"
          class="hover:text-red-500 hover:bg-opacity-60"
        ) autorenew
        template(#popper) 
          p.font-plus Troca de pausa
      v-tooltip
        span.material-icons.notranslate.h-7.w-7.grid.place-items-center.rounded-full.bg-white.bg-opacity-40.text-white.text-lg.cursor-pointer.transition(
          @click="unPause"
          class="hover:text-red-500 hover:bg-opacity-60"
        ) close
        template(#popper) 
          p.font-plus Sair da pausa
    v-tooltip
      span.material-icons.notranslate.text-white.text-lg.cursor-default info
      template(#popper)
        .flex.flex-col.items-start
          .flex.gap-1
            p.font-plus Tempo em pausa:
            p.font-plus.font-semibold {{ timestamp }}
          .flex.gap-1
            p.font-plus Tempo máximo diário:
            p.font-plus.font-semibold {{ timeLimit }}
          .flex.gap-1(v-if="pauseCategory == 'productive'")
            p.font-plus Canais pausados:
            p.font-plus.font-semibold {{ formatEnabledChannels({pause_call: pausedChannels.pause_call, pause_chat: pausedChannels.pause_chat, pause_email: pausedChannels.pause_email}) }}
  .w-full.absolute.h-2.bg-yellow-100.bottom-0.left-0.shadow-lg.rounded-b.overflow-clip(
    :class="[pauseProgressBackground]"
  )
    .h-2(
      :style="`width:${progress}%`"
      :class="[pauseProgressBarBackground]"
    )
</template>

<script>
import { Tooltip } from 'floating-vue';
export default {
  components: {
    VTooltip: Tooltip,
  },
  data() {
    return {
      pauseCategory: '',
      pauseName: '',
      pausedChannels: {
        pause_call: '',
        pause_chat: '',
        pause_email: '',
      },
      timeLimitInMinutes: 0,
      stoppedDuration: 0,
      totalPauseTime: 0,
      lastMinute: 0,
      progress: 0,
      duration: 0,
      timeStopped: null,
      pauseClock: null,
      timestamp: null,
      timeLimit: null,
      timeBegan: null,
      started: null,
      clock: null,
      exceeded: false,
      running: false,
    };
  },
  computed: {
    isPaused() {
      return this.user.pause;
    },
    pause() {
      return this.$store.getters['GET_PAUSE'];
    },
    pauseBackground() {
      if (!this.exceeded && this.pauseCategory == 'unproductive') return 'from-yellow-500 to-amber-500';
      if (!this.exceeded && this.pauseCategory == 'productive') return 'from-blue-500 to-blue-600';
      return 'from-red-500 to-rose-600';
    },
    pauseProgressBackground() {
      if (!this.exceeded && this.pauseCategory == 'unproductive') return 'bg-yellow-100';
      if (!this.exceeded && this.pauseCategory == 'productive') return 'bg-blue-100';
      return 'bg-red-100';
    },
    pauseProgressBarBackground() {
      if (!this.exceeded && this.pauseCategory == 'unproductive') return 'bg-yellow-400';
      if (!this.exceeded && this.pauseCategory == 'productive') return 'bg-blue-500';
      return 'bg-red-500';
    },
  },
  watch: {
    isPaused() {
      try {
        this.setup();
      } catch (error) {}
    },
  },
  methods: {
    formatEnabledChannels(channels) {
      const channelNames = {
        pause_call: 'Telefonia',
        pause_chat: 'Chat',
        pause_email: 'E-mail',
      };
      const enabledChannels = Object.keys(channels)
        .filter((channel) => channels[channel])
        .map((channel) => channelNames[channel]);
      if (!enabledChannels.length) return 'Nenhum canal habilitado';
      return enabledChannels.join(', ');
    },
    async setup() {
      this.exceeded = false;
      const res = await this.$http.get(`${this.requestLinks.cservice}/companies/availability-records/pause`);
      const pauseTimeLimit = parseInt(res.data.limit);
      const pauseTimeUsed = parseInt(res.data.time_today);

      this.pauseCategory = res.data.category;
      this.pausedChannels = {
        pause_call: res.data.pause_call,
        pause_chat: res.data.pause_chat,
        pause_email: res.data.pause_email,
      };
      this.pauseName = res.data.pause_name;
      this.timeLimit = `${res.data.limit / 60}min`;
      this.timeLimitInMinutes = pauseTimeLimit / 60;
      clearInterval(this.clock);
      let start = this.$moment(res.data.start_date).tz(this.user.timezone);

      this.clock = setInterval(() => {
        const end = this.$moment().tz(this.user.timezone);
        const diff = end.diff(start);
        const diffAsDays = Math.floor(this.$moment.duration(diff).asDays());
        const pausedTime = this.$moment.utc(diff).valueOf() / 1000;

        if (diffAsDays <= 0) this.timestamp = this.$moment.utc(pausedTime * 1000).format('HH:mm:ss');
        else this.timestamp = this.$moment.utc(pausedTime * 1000).format('D[d] h[h] m[m]');
        this.totalPauseTime = pausedTime + pauseTimeUsed;
        this.progress = (this.totalPauseTime / pauseTimeLimit) * 100;
        if (this.totalPauseTime > pauseTimeLimit) {
          this.exceeded = true;
        }
      }, 1000);
    },
    setRingProgress(progress) {
      let circle = document.querySelector('.progress__circle');
      let radius = circle.r.baseVal.value;
      let circumference = radius * 2 * Math.PI;
      circle.style.strokeDasharray = `${circumference} ${circumference}`;
      circle.style.strokeDashoffset = `${circumference}`;

      function setProgress(percent) {
        const offset = circumference - circumference * (percent / 100);
        circle.style.strokeDashoffset = offset;
      }

      setProgress(progress);
    },
    async unPause() {
      this.$store.commit('SET_PAUSE', {});
      const res = await this.$http.put(`${this.requestLinks.cservice}/companies/users/unpause`);
    },
    showChangePauseModal() {
      this.$store.commit('SET_MODAL', { name: 'confirmPause' });
    },
  },
  beforeDestroy() {
    clearInterval(this.clock);
  },
  async created() {
    try {
      await this.setup();
    } catch (e) {
      this.$log.error(e);
    }
  },
};
</script>

<style>
.exceeded-background {
  background: #eb5757 !important;
}
.exceeded-text {
  color: #ffffff !important;
}
.progress__circle {
  transition: stroke-dashoffset 0.35s;
  transform: rotateZ(-90deg) translateX(-100%);
  transform-origin: center;
}
.margin-right {
  margin-right: auto;
}
.margin-left {
  margin-left: auto;
}
.leave-pause-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: var(--brand-color);
}
.pause-body {
  padding-left: 51px;
  padding-right: 51px;
  background: var(--brand-color-op-1);
  border: 1px solid var(--brand-color);
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-left: 30px;
}
.pause-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-right: 16px;
  line-height: 22px;
  color: #495057;
}
.pause-type-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-right: 15%;
  line-height: 24px;
  color: #495057;
}
.pause-left-aligner {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
}
.pause-right-aligner {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: 20%;
}
.pause-clock-icon {
  margin-right: 35px;
  color: var(--brand-color);
  font-size: 40px !important;
}
</style>
