<template lang="pug">
div(:class="'status status-' + convertStatusToClass[status]" @click.stop) {{ status }}
  span.material-icons.notranslate.dropdown-icon.status-icon(v-if="icon") keyboard_arrow_down
</template>

<script>
export default {
  props: ["icon", "status"],
  data() {
    return {
      convertStatusToClass: {
        Aberto: "open",
        "Em andamento": "inprogress",
        Fechado: "closed",
      },
    };
  },
};
</script>

<style>
</style>