import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_RHISTORY}`;

export async function getEventsById(id, params, config = { signal: null }) {
  const { data } = await $http.get(`${service}/event/call/${id}`, { params }, config);
  return data;
}

async function createTag(payload) {
  try {
    const event = await $http.post(`${service}/event`, payload);
    return event.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTags(companyId, params) {
  try {
    const res = await $http.get(`${service}/tags/${companyId}`, { params });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTagsPaginated(params) {
  try {
    const res = await $http.get(`${service}/tags/paginate`, { params });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getTagsById(callid) {
  try {
    const res = await $http.get(`${service}/event/call/${callid}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export async function getTagsGroups(params) {
  try {
    const res = await $http.get(`${service}/tags-groups`, { params });
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function removeTag(id, callid) {
  try {
    const res = await $http.delete(`${service}/event/tag/${id}/call/${callid}`);
  } catch (e) {
    this.$log.info(e);
  }
}

async function getTagById(id) {
  try {
    const res = await $http.get(`${service}/tags/search/${id}`);
    return res.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export default { createTag, getTags, getTagsPaginated, getTagsById, removeTag, getEventsById, getTagsGroups, getTagById };
