<template lang="pug">
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Vincular Device
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd

  template(v-slot:content)
    p.text-gray-400.mt-8 Para vincular seu device SIP, siga as instruções abaixo:
    NumberedLabelDisplay.my-8.ml-12(:number="1")
      template(v-slot:content)
        .flex.flex-col.items-start
          p.ml-3.text-gray-400 Em seu device SIP, ligue para o número:
          p.ml-3.mt-2.text-2xl.text-gray-400.font-extrabold *90
    NumberedLabelDisplay.my-8.ml-12(:number="2")
      template(v-slot:content)
        .flex.flex-col.items-start
          p.ml-3.text-gray-400 Ao ouvir a mensagem solicitando o código de autenticação, digite o código a seguir:
          p.ml-3.mt-2.text-2xl.text-gray-400.font-extrabold {{ code }}
          .ml-3.my-1.w-28.bg-gray-200.h-1.relative
            .h-full.bg-orange(:style="{ width: `${(timer / 60) * 100}%` }")
          p.ml-3.text-gray-400.text-xs Expira em:
            span.text-gray-500.font-extrabold {{ $moment.utc(timer * 1000).format('mm:ss') }}

    NumberedLabelDisplay.ml-12(:number="3")
      template(v-slot:content)
        p.ml-3.text-gray-400 Aguarde a mensagem de áudio de confirmação de vínculo de device.

  template(v-slot:footer)
    button.w-32.text-brand(class="dark:invert" @click="$store.commit('modal/setActiveModal', '')") Cancelar
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding";
import NumberedLabelDisplay from "@/components/Labels/NumberedLabelDisplay.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
import services from "@/services";

export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    NumberedLabelDisplay,
  },
  data() {
    return {
      code: "????",
      timer: 60,
    };
  },
  async created() {
    try {
      const r = await this.$http.post(
        `${this.requestLinks.cservice}/companies/users/devices`,
        {
          type: "peer",
        }
      );
      this.$store.dispatch("cstate/joinRoom", {
        room: `${this.user.company_id}-${r.data.code}`,
      });
      this.socket.on("enabled", async () => {
        this.updateUserDeviceStatus();
      });
      this.code = r.data.code;
    } catch (e) {
      this.$log.info(e);
    }
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      if (this.timer >= 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async updateUserDeviceStatus() {
      try {
        this.$store.commit("modal/setActiveModal", "LinkSuccess");
      } catch (e) {
        return Promise.reject(e);
      }
    },
  },
  watch: {
    timer(value) {
      if (value <= 0) this.$store.commit("modal/setActiveModal", "LinkFail");
    },
  },
  computed: {
    socket() {
      return this.$store.getters["cstate/GET_SOCKET"];
    },
  },
  beforeDestroy() {
    this.$store.dispatch("cstate/leaveRoom", {
      room: `${this.user.company_id}-${this.code}`,
    });
  },
};
</script>

<style>
.progress-bar-empty {
  margin-top: 10px;
  width: 86px;
  height: 3px;
  left: 203.84px;
  top: 343px;
  background: #e9ecef;
}
.progress-bar-fill {
  margin-top: 10px;
  width: 0px;
  height: 3px;
  left: 203.84px;
  top: 343px;
  animation-name: fillBar;
  animation-duration: 65s;
  animation-fill-mode: forwards;
  background: var(--brand-color);
}
@keyframes fillBar {
  0% {
    width: 0%;
  }
  12.5% {
    width: 12.5%;
  }
  25% {
    width: 25%;
  }
  37.5% {
    width: 37.5%;
  }
  50% {
    width: 50%;
  }
  62.5% {
    width: 62.5%;
  }
  75% {
    width: 75%;
  }
  87.5% {
    width: 87.5%;
  }
  100% {
    width: 100%;
  }
}
.cancel-button-aligner {
  display: flex;
  justify-content: center;
}
.step-separator {
  margin-bottom: 37px;
}
.step-margin-top {
  width: 447px;
  margin-top: 7px;
  height: 51px;
  margin-left: 15px;
}
.number-text {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  margin-top: 6px;
  color: #868e96;
}
.step-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 7px;
  color: #868e96;
}
.step-horizontal {
  display: inline-flex;
}
.page-aligner {
  display: flex;
  justify-content: center;
}
.step-ball {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  background: rgba(242, 108, 69, 0.1);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: var(--brand-color);
}
.step-page {
  margin-top: 32px;
  width: 80%;
}
.step-subtitle {
  margin-top: 32px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}
.page-content {
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 99999;
  background: #ffffff;
  width: 717px;
  height: 533px;
  padding: 32px 49px;
}
.step-header {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}
.step-counter {
  margin-left: 5px;
  width: 32px;
  height: 3px;
  background: #e9ecef;
}
.step-counter-passed {
  margin-left: 5px;
  width: 32px;
  height: 3px;
  background: var(--brand-color);
}
.step-title {
  width: 96%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 29px;
  color: #868e96;
}
.line-separator {
  width: 100%;
  height: 0px;
  margin-top: 20px !important;
  border: 1px solid #dee2e6;
}
</style>
