<template>
  <div v-click-outside="checkOutside" class="tags-content">
    <input
      @focus="showOptions"
      @input="filterTags"
      v-model="tagModel"
      ref="input-tag"
      class="form-control input"
    />
    <div v-if="search" class="tags-list-content">
      <div
        class="tag-list-option"
        v-for="(data, index) in filteredCompanyTags"
        :key="index"
        @click="addingTags(data.text)"
      >
        {{ data.text }}
      </div>
    </div>
    <div class="tags-aligner">
      <div v-for="(data, index) in tags" :key="index">
        <div class="multiple-tags-margin editable-tag">
          {{ data.text
          }}<span
            @click="removeTag(data, index)"
            class="close-tag-icon no-translate material-icons"
            >cancel</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rhService from "@/services/rship.js";
export default {
  props: ["savedCall"],
  data() {
    return {
      tagModel: "",
      renderedTags: [],
      search: false,
      companyTags: [],
      tags: [],
      filteredCompanyTags: [],
    };
  },
  props: {
    callId: String,
  },
  computed: {
    context() {
      return this.$store.getters["modal/getContext"];
    },
  },
  methods: {
     async removeTag(data, index) {
      try {
        await rhService.removeTag(data.id, data.callId)
        this.getTags()
        this.$toast.success("Tag removida")
      } catch (e) {
        this.$log.info(e);
      }
    },
    showOptions() {
      if (this.tagModel.length > 0 || this.companyTags.length > 0) {
        this.search = true;
      }
    },
    filterTags() {
      if (this.tagModel.length > 0) {
        this.search = true;
      } else {
        this.search = false;
      }
      this.filteredCompanyTags = this.companyTags.filter((element) => {
        return element.text
          .toLowerCase()
          .startsWith(this.tagModel.toLowerCase());
      });
    },
    checkOutside(e) {
      if (e.target.className != "tag-list-option") {
        this.search = false;
      }
    },
    async getTags() {
      try {
        const tags = await rhService.getTagsById(this.context.callId);
        this.tags = tags.filter(e => e.eventType === 'tag')
      } catch (e) {
        this.$log.info(e);
      }
    },
    async addingTags(text) {
      try {
        await rhService.createTag({
          eventType: "tag",
          companyId: this.user.company_id,
          identifier: this.user.username,
          contact: this.context.contact,
          callId: this.context.callId,
          text: text,
          createdBy: this.user.id,
        });
        this.filteredCompanyTags = this.companyTags;
        this.search = false;
        this.$refs["input-tag"].blur();
        this.tagModel = "";
        await this.getTags()
        await this.initData();
        this.$toast.success("Tag adicionada!")
      } catch (e) {
      }
    },
    async initData() {
      try {
        this.companyTags = await rhService.getTags(this.user.company_id);
        this.filteredCompanyTags = this.companyTags;
      } catch (e) {
      }
    },
  },
  async created() {
    this.initData()
    this.getTags()
  },
  watch: {
  },
};
</script>

<style scoped>
.add-tag-text {
  color: var(--brand-color) !important;
}
.tags-aligner {
  max-width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.tags-content {
  display: flex;
  padding-top: 8px;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.tags-list-content {
  z-index: 9;
  margin-top: 56px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  position: absolute;
  padding-top: 8px;
  padding-bottom: 8px;
  max-height: 250px;
  overflow: auto;
}
.tag-list-option {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #868e96;
  height: 39px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  cursor: pointer;
}
.tag-list-option:hover {
  background: #f8f9fa;
}
.tag-input-mask {
  outline: none;
  border: none;
  height: 48px;
  background: none;
  margin-left: 14px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #868e96;
}
.close-tag-icon {
  color: #adb5bd;
  margin-left: 11px;
  font-size: 17px;
  cursor: pointer;
}
.close-tag-icon:hover {
  color: #8e949b;
}
.tag-box {
  display: flex;
  align-items: center;
  margin-top: 8px;
  padding-left: 16px;
  height: 48px;
  width: 100%;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
}
.editable-tag {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 12px;
  width: fit-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  box-sizing: border-box;
  border-radius: 25px;
}
</style>