<template lang='pug'>
.activity-modal(
  style="display: flex; width: 670px; z-index: 9999",
  :ref="'modalActivityId'"
)
  .modal-left-aligner(style="width: 100%")
    .modal-icon(
      style="margin-left: 16px; margin-right: 20px; background: #dc6058"
    )
      span#modalIcon.material-icons(style="font-size: 26px") info
    a#modalTextId.activity-modal-text Você não preencheu campos obrigatórios ou inseriu informações invalidas.
</template>

<script>
export default {
  methods: {
    fadeOut() {
      this.$refs["modalActivityId"].style.opacity = 0;
      setTimeout(this.resetActivity, 800);
    },
    resetActivity() {
      this.$refs["modalActivityId"].style.display = "none";
      this.$refs["modalActivityId"].style.opacity = 1;
      this.$store.commit("SET_MODAL", null);
    },
  },
  created() {
    setTimeout(this.fadeOut, 1500);
  },
};
</script>

<style>
</style>