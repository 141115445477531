import auth from "./auth";
import customerService from "./cservice";
import customerState from "./cstate";
import callReport from "./call-report";
import notify from "./notify";
import integrations from "./integrations";

export default {
  auth,
  callReport,
  customerService,
  customerState,
  notify,
  integrations
};
