<template lang="pug">
.w-full.relative
  label.font-semibold.text-gray-500.font-inter(v-if="labelTitle") {{ labelTitle }}
    p.text-red-500.text-sm(v-if="contactError")
      | Você não pode iniciar uma conversa com esse usuário pois ele já está em atendimento.  
      span.font-bold.cursor-pointer(@click="openDetailsContactPage") ver detalhes.
  v-dropdown.w-full.relative(auto-size="inner")
    span
      .flex.items-center.justify-between.form-control.input(
        :class="{ 'border-2 border-red-500': contactError }"
        @click="search = !search"
      )
        |
        div(v-if="multipleSelection") {{ selectedContacts.length > 0 ? `${selectedContacts.length} contato(s) selecionados` : `${placeholder}` }}
        div(v-else-if="!selected") {{ placeholder }}
        client-card(v-else, :contact="selected", @click="search = !search")
        span.material-icons.notranslate.dropdown-icon(v-if="multipleSelection")
          | keyboard_arrow_down
        span.material-icons.notranslate.dropdown-icon(v-else-if="!selected")
          | keyboard_arrow_down
        span.material-icons.notranslate.dropdown-icon(v-else, @click.stop="removeFilter()")
          | cancel
    template(#popper)
      .webhook-select__window
        input.webhook-select__window__input(
          :placeholder="placeholder",
          @focus="showOptions",
          @input="inputHandler",
          v-model="model[displayAttr || attr]"
        )
        .webhook-select__options-list
          client-card(
            v-for="(contact, index) in optionsList",
            :key="index",
            :contact="contact",
            @click="selectData(contact)"
            :selected="selectedContacts.includes(contact)"
            :isEmail="isEmail"
          )
          Observer(@intersect="$emit('intersect')")
</template>

<script>
import { Dropdown, hideAllPoppers } from 'floating-vue';
import ClientCard from '@/components/Cards/ClientCard.vue';
import Observer from '@/components/Observer.vue';

export default {
  props: [
    'labelTitle',
    'model',
    'attr',
    'dataFilter',
    'useId',
    'displayAttr',
    'jsonData',
    'placeholder',
    'contactError',
    'noFilter',
    'multipleSelection',
    'currentTotalUsers',
    'isEmail',
  ],
  components: {
    ClientCard,
    VDropdown: Dropdown,
    Observer,
  },
  data() {
    return {
      search: false,
      dataList: [],
      name: '',
      selected: null,
      isFilterEnabled: false,
      selectedContacts: [],
    };
  },
  computed: {
    options() {
      if (!this.dataList) return [];
      return this.dataList.filter((data) => {
        return data[this.dataFilter];
      });
    },
    optionsList() {
      return this.options.filter((option) => {
        if (!this.isFilterEnabled) return true;
        if (this.noFilter) return true;
        return (
          option[this.dataFilter] &&
          option[this.dataFilter]?.toLowerCase().includes(this.model[this.displayAttr || this.attr]?.toLowerCase())
        );
      });
    },
  },
  methods: {
    openDetailsContactPage() {
      this.$store.commit('modal/setActiveModal', '');
      if (this.$router.currentRoute.name === 'ContactDetail' && this.$route.params.id === this.selected.id) return;
      this.$router.push({
        name: 'ContactDetail',
        params: { id: this.selected.id },
      });
    },
    inputHandler(e) {
      this.isFilterEnabled = true;
      this.$emit('value', e.target.value);
      this.$emit('input-insert');
    },
    showOptions() {
      this.search = true;
    },
    selectData(selected) {
      if (this.isEmail && (!selected.emails?.length || !selected.emails[0]?.address)) {
        return this.$toast.error('Selecione um contato que possua um email cadastrado');
      }
      if (this.useId) {
        this.model.id = selected.id;
      } else this.model[this.attr] = selected[this.dataFilter];
      if (this.displayAttr && !this.multipleSelection) this.model[this.displayAttr] = selected[this.dataFilter];
      this.search = false;
      this.name = selected.name;
      this.selected = selected;
      if (!this.multipleSelection) hideAllPoppers();
      if (this.multipleSelection) {
        if (!this.selectedContacts.includes(selected)) {
          if (this.currentTotalUsers + this.selectedContacts.length == 100 || this.selectedContacts.length == 100) {
            this.$toast.error('Limite de 100 usuários atingido');
          } else {
            this.selectedContacts.push(selected);
          }
        } else {
          this.selectedContacts = this.selectedContacts.filter((user) => user.id != selected.id);
        }
        this.$emit('selectedUsers', this.selectedContacts);
      }
      this.$emit('data-selected', selected);
    },
    removeFilter() {
      const selected = {};
      if (this.useId) {
        this.model.id = selected.id;
      } else this.model[this.attr] = selected[this.dataFilter];
      if (this.displayAttr) this.model[this.displayAttr] = selected[this.dataFilter];
      this.search = false;
      this.name = selected.name;
      this.selected = null;
      this.$emit('remove-filter');
      hideAllPoppers();
    },
    closeFilter() {
      this.search = false;
    },
  },
  async created() {
    this.dataList = this.jsonData;
    if (this.model.id) {
      this.selected = this.dataList.filter((element) => {
        return element.id == this.model.id;
      })[0];
    }
    if (this.model.belongsTo) this.selected = this.model;
  },
  watch: {
    jsonData(newData) {
      this.dataList = newData;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-icon {
  font-size: 23px !important;
  color: #adb5bd;
  cursor: pointer;
  transition: 0.1s;
}
.v-popper--theme-dropdown .v-popper__inner {
  background: #f8f9fa;
}
.form-control.input {
  height: fit-content;
  padding: 11px 12px;
  .callback-contact {
    pointer-events: none;
  }
}
.up-placeholder {
  font-family: 'Inter', sans-serif;
}
.org {
  display: inline-block;
  position: relative;
  width: 100%;

  &-dropdown {
    padding-top: 16px;
    padding-bottom: 16px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    color: #868e96;
    overflow: auto;
  }
  li {
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    padding-left: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #868e96;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
  }
  li:hover {
    background: #f8f9fa;
  }
}
.webhook-select {
  background: #ffffff;
  width: 100%;
  height: 48px;
  margin-left: auto;
  font-family: Inter;
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 0px 16px 0px 16px;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  color: #495057;
  border-bottom: 1px solid #868e96;

  .material-icons.notranslate {
    color: #868e96;
    margin-left: auto;
    font-size: 24px !important;
  }

  &__main {
    position: relative;
    margin-left: auto;
    width: 395px;
  }

  &__options-list {
    width: 100%;
    max-height: 240px;
    overflow: auto;
    margin-bottom: 32px;
  }

  &__window {
    z-index: 1;
    width: 100%;
    background: #f8f9fa;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 16px;
    justify-content: center;

    button {
      font-family: 'Inter' sans-serif;
      font-style: normal;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #868e96;
    }

    &__input {
      width: 100%;
      height: 2rem;
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      border: none;
      height: 48px;
      outline: none;
      margin-bottom: 16px;
      border-bottom: 1px solid #868e96;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-indent: 16px;
      color: #495057;
    }
    .selectable-option {
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      cursor: pointer;
      transition: 0.2s;
      border-bottom: 1px solid #e9ecef;

      .text {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #495057;
      }

      span {
        color: #adb5bd;
        font-size: 26px !important;
      }

      &:hover {
        background: rgba(233, 236, 239, 0.4);
      }
    }
  }
}
</style>
