<template lang="pug">
.content
  <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet"/>
  <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet"/>
  <link href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap" rel="stylesheet"/>
  .container-fluid
    .pagearea.m-0.pt-8
      div(style="width: 100%; display: flex; justify-content: center")
        InvalidInfo(v-if="this.$store.getters['GET_MODAL'] === 'InvalidInfo'")
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          div(
            @click="$router.push({ name: 'Contatos' })",
            role="button",
            style="flex-direction: row; display: flex"
          )
            span.arrow-icon.material-icons(
              style="padding-left: 0px; min-width: 0px; width: 20px"
            ) &#xe5c4
            a.back-text(style="margin-top: 3px") Voltar
          .title(style="margin: 0px") Adicionar contato

      add-contact-content
</template>

<script>
import InvalidInfo from '@/components/Modals/InvalidInfo.vue';
import AddContactContent from './AddContactContent.vue';

export default {
  title: 'Opens - Adicionar contato',
  components: {
    InvalidInfo,
    AddContactContent,
  },
};
</script>
