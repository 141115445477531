<template lang="pug">
.event-box
  .upside
    span.material-icons.notranslate.context bolt
    span.event-text
      | Contexto recebido via integrações 
      span.event-text.text-brand-200.font-bold.cursor-pointer(
        v-if="!contact.belongsTo && $route.name == 'ContextArea'"
        class="hover:text-brand-300"
        @click="$emit('open-overlay')"
      )
        | clique aqui
        span.event-text.cursor-default 
          | para usar informações no cadastro
    span.date-text(
      v-tooltip="formatTimestamp(data.updatedAt)"
    ) {{ formatTimestamp(data.updatedAt) }}
  .downside 
    .info-box 
      section-expander(
        class="md:w-[30rem] xl:w-[36rem] 3xl:w-[42rem]"
        :title="'Informações do contexto'"
        :opened="true"
      )
        span.font-plus.font-bold.break-all(
            v-for="key in Object.keys(trackingData).slice(0,4)"
            v-if="!wordsBlackList.includes(trackingData[key].chave)"
            :key="key"
          )
            | {{ trackingData[key].chave }}: 
            span.font-plus.font-medium(v-if="!validateUrl(trackingData[key].valor)") {{ trackingData[key].valor }}
            a.font-plus.font-medium.text-blue-500(v-else :href='trackingData[key].valor' target="_blank") {{ trackingData[key].valor }}
        span.font-plus.font-bold.text-brand.cursor-pointer(
          v-if="Object.keys(trackingData).length > 4"
          @click="openInfoModal = true"
        ) Ver mais
        modal(
          @click="openInfoModal = false"
          v-if="openInfoModal == true"
        )
          modal-body-no-footer(class="w-[40rem]")
            template(v-slot:header)
              .flex
                h4.text-2xl.text-gray-500.font-semibold.font-inter Informações do contexto
                .material-icons.notranslate.text-gray-500.ml-auto.cursor-pointer(@click="openInfoModal = false") close
            template(v-slot:body)
              icon-input(
                icon="search" v-model="search" label="Titulo"
              )
              div.max-h-56.w-full.mt-4.break-all.overflow-y-auto
                transition-group.w-full.flex.flex-col.items-start(name="slide-fade" :duration="200" mode="out-in")
                  span.font-plus.font-bold(
                    v-for="key in Object.keys(filteredData)"
                    v-if="!wordsBlackList.includes(filteredData[key].chave)"
                    :key="key"
                  )
                    | {{ filteredData[key].chave }}: 
                    span.font-plus.font-medium(v-if="!validateUrl(filteredData[key].valor)") {{ filteredData[key].valor }}
                    a.font-plus.font-medium.text-blue-500(v-else :href='filteredData[key].valor' target="_blank") {{ filteredData[key].valor }}
                p.w-full.mt-3.text-center(
                  v-if="Object.keys(filteredData).length < 1"
                ) Nenhuma informação encontrada
</template>
<script>
import SectionExpander from '@/components/SectionExpander.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import { dateFormatMixin } from '@/mixins/date-format.mixin';

export default {
  mixins: [dateFormatMixin],
  components: {
    SectionExpander,
    Modal,
    ModalBodyNoFooter,
    IconInput,
  },
  props: ['data', 'calldate'],
  data() {
    return {
      openInfoModal: false,
      search: '',
      trackingData: '',
    };
  },
  watch: {
    openModal(val) {
      if (!val) return (this.search = '');
    },
  },
  computed: {
    wordsBlackList() {
      return ['password'];
    },
    config() {
      return this.$store.getters['config'];
    },
    filteredData() {
      const asArray = Object.entries(this.desestruturarObjeto(JSON.parse(`${this.data.eventBody.replace(/'/g, '"')}`)));
      const filtered = asArray.filter(([key, value]) =>
        asArray[key][1].chave.toLowerCase().includes(this.search.toLowerCase()),
      );
      return Object.fromEntries(filtered);
    },
    phonenumber() {
      return this.$store.getters['rh/GET_PHONENUMBER'];
    },
    contact() {
      const contact = this.$store.getters['rh/GET_CONTACT'];
      contact.number = this.phonenumber;
      return contact;
    },
  },
  methods: {
    desestruturarObjeto(objeto, objectFiltered = []) {
      if (!objeto) {
        return objectFiltered;
      }
      for (const [chave, valor] of Object.entries(objeto)) {
        if (typeof valor === 'object') {
          this.desestruturarObjeto(valor, objectFiltered);
        } else {
          objectFiltered.push({ chave: chave, valor: valor });
        }
      }
      return objectFiltered;
    },
    validateUrl(url) {
      try {
        if (new URL(url)) return true;
      } catch (error) {
        return false;
      }
    },
  },
  created() {
    const asArray = Object.entries(this.desestruturarObjeto(JSON.parse(`${this.data.eventBody.replace(/'/g, '"')}`)));
    const filtered = asArray.filter(([key, value]) => asArray[key][1].chave);
    this.trackingData = Object.fromEntries(filtered);
  },
};
</script>
<style lang="scss" scoped>
.event-box {
  width: 100%;
  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }
  .context {
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: #6d3595;
    color: #fff;
    font-size: 12px !important;
  }
  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    padding-top: 16px;
    padding-bottom: 10px;
    .info-box {
      padding: 10px;
      background: #ffffff;
      border: 1px solid #e9ecef;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #495057;
    }
  }
  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
