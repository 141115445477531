import { filter, intersection } from "lodash";
import moment from "moment-timezone";
// ACTIONS
export default {
  async getUserWorkgroups({ commit }, $http) {
    const schema = process.env.VUE_APP_SCHEMA;
    const cservice = process.env.VUE_APP_CSERVICE;
    const workGroups = [];
    const seenUser = {};
    const users = [];

    const r = await $http(`${schema}://${cservice}/bonds/work-groups`);

    for (const group of r.data) {
      workGroups.push({
        name: group.name,
        id: group.id,
        selected: false,
        users: [],
      });
      for (const user of group.workgroups_users) {
        if (seenUser[user.id]) continue;
        workGroups[workGroups.length - 1].users.push(user.id);
        users.push({ name: user.username, id: user.id, selected: false });
        seenUser[user.id] = true;
      }
    }

    commit("SET_WORK_GROUPS", workGroups);
  },
  async CALC_USER_PERFORMANCE({ commit, getters, dispatch }) {
    const { calls } = getters.CALLS;
    const calls_list = [];
    const final = await dispatch("generateTemplate");

    for (const c in calls) {
      const tmp = generateTemplate();
      const call = calls[c]._source;
      await dispatch("countCsat", { call, tmp });
      if (!call.callflow || !call.callflow.length)
        await dispatch("countDirectAnswerStatus", { call, tmp });
      else await dispatch("countDeepAnswerStatus", { call, tmp });
      await dispatch("applyDirectFilters", { call, tmp });
      await dispatch("mergeTemplate", { tmp, final, calldate: call.calldate });

      if (!tmp.filtered) calls_list.push(calls[c]);
    }
    commit("SET_USER_PERFORMANCE", final);
    commit("SET_CALLS", { calls_list });
  },

  // EVENTOS RECEBIDOS/EFETUADOS
  countDirectAnswerStatus({ commit, getters, rootGetters }, { call, tmp }) {
    const users = [...getters.SELECTED_USERS];
    const { timezone } = rootGetters.config;
    const answered = call.status === "ANSWERED" ? true : false;
    const filters = getters.FILTERS;
    const start = moment(call.calldate).tz(timezone);
    const end = moment(call.hangupdate).tz(timezone);
    const answerTime = call.answerdate
      ? moment(call.answerdate).tz(timezone)
      : null;

    // EFETUADAS
    if (users.includes(call.from_id)) {
      tmp.uniqueDevicesRec[call.to] = true;

      // ATENDIDO
      if (answered) {
        tmp.connectionTime += end.diff(answerTime, "seconds");
        tmp.sentAnswered++;
        if (filters.filter_made_answered) tmp.filtered = true;
      } // NAO ATENDIDO
      else {
        tmp.sentNotAnswered++;
        if (filters.filter_made_not_answered) tmp.filtered = true;
      }
    }

    // RECEBIDAS
    if (users.includes(call.to_id)) {
      tmp.uniqueDevicesSent[call.to] = true;
      // ATENDIDO
      if (answered) {
        tmp.aasa += answerTime.diff(start, "seconds");
        tmp.tma += end.diff(answerTime, "seconds");
        tmp.receivedAnswered++;
        if (filters.filter_received_answered) tmp.filtered = true;
        // NAO ATENDIDO
      } else {
        tmp.receivedNotAnswered++;
        tmp.aasa += end.diff(start, "seconds");
        if (filters.filter_received_not_answered) tmp.filtered = true;
      }
    }
  },

  async countDeepAnswerStatus(
    { rootGetters, getters, dispatch },
    { call, tmp }
  ) {
    const filters = getters.FILTERS;
    const config = rootGetters.config;
    const users = [...getters.SELECTED_USERS];
    let start = moment(call.calldate).tz(config.timezone);
    const end = moment(call.hangupdate).tz(config.timezone);
    const answered = call.status === "ANSWERED" ? true : false;

    for (const flow of call.callflow) {
      if (!flow.callflow && flow.event === "transfer") {
        if (filters.filter_transferred) tmp.filtered = true;
        // RECEBIDA ATENDIDA
        /*  if (users.includes(flow.to_id)) {
           tmp.receivedAnswered++;
           const tStart = moment(flow.calldate);
           const tAnswer = moment(flow.answerdate);
           tmp.aasa = tAnswer.diff(tStart, "seconds");
           tmp.tma = end.diff(tAnswer, "seconds");
           if (filters.filter_received_answered) {
             tmp.filtered = true;
           }
         } */

        // QUEM EFETUOU TRANSFERENCIA
        if (users.includes(flow.from_id)) {
          if (call.from_id === flow.to_id || call.to_id === flow.to_id) {
            await dispatch("countDirectAnswerStatus", { call, tmp });
          }
        }
        continue;
      }
      for (const flow2 of flow.callflow) {
        switch (flow2.event) {
          case "queueanswer":
            {
              // RECEBIDA ATENDIDA
              if (users.includes(flow2.peer_id)) {
                tmp.uniqueDevicesRec[flow2.from] = true;
                tmp.receivedAnswered++;
                tmp.aasa = (tmp.aasa || 0) + parseInt(flow2.ringtime);
                tmp.tma =
                  (tmp.ama || 0) +
                  end.diff(
                    moment(flow2.calldate).tz(config.timezone),
                    "seconds"
                  );
                tmp.uniqueDevicesRec[flow2.from] = true;
                if (filters.filter_received_answered) tmp.filtered = true;
              }

              // EFETUADA POR
              if (users.includes(flow2.from_id)) {
                tmp.uniqueDevicesSent[flow2.peer] = true;
                tmp.sentAnswered++;
                tmp.connectionTime += parseInt(flow2.holdtime);
                if (filters.filter_made_answered) tmp.filtered = true;
              }
            }
            break;
          case "ringnoanswer":
            {
              if (users.includes(flow2.from_id)) {
                tmp.sentNotAnswered++;
                if (filters.filter_made_not_answered) {
                  tmp.filtered = true;
                }
              }
              if (users.includes(flow2.peer_id)) {
                tmp.uniqueDevicesRec[flow2.from] = true;
                tmp.aasa = (tmp.aasa || 0) + parseInt(flow2.ringtime);
                tmp.receivedNotAnswered++;
                if (filters.filter_received_not_answered) tmp.filtered = true;
              }
            }
            break;
          case "queueabandon":
            {
              if (filters.filter_abandoned) tmp.filtered = true;
              if (users.includes(flow2.from_id)) {
                tmp.sentNotAnswered++;
                if (filters.filter_made_not_answered) tmp.filtered = true;
              }
            }
            break;
          case "queueleave":
            {
              if (filters.filter_timeout) tmp.filtered = true;
              if (users.includes(flow2.from_id)) {
                tmp.sentNotAnswered++;
                if (filters.filter_made_not_answered) tmp.filtered = true;
              }
            }
            break;
        }
      }
    }
  },

  generateTemplate({ rootGetters, getters }) {
    const { format, formatDiff } = getters.FORMAT;
    const { start, end } = getters.PERIOD;
    const { timezone } = rootGetters.config;
    const map = {};
    const elapsed = moment(end)
      .tz(timezone)
      .diff(moment(start).tz(timezone), formatDiff);

    let date = moment(start).tz(timezone);
    let increase = 0;
    if (elapsed > 0) {
      for (let i = 0; i <= elapsed; i++) {
        map[date.add(increase, formatDiff).format(format)] = generateTemplate();
        increase = 1;
      }
    }

    return {
      receivedAnswered: 0,
      receivedNotAnswered: 0,
      sentAnswered: 0,
      sentNotAnswered: 0,
      uniqueDevices: {},
      uniqueDevicesSent: {},
      uniqueDevicesRec: {},
      connectionTime: 0,
      tma: 0,
      aasa: 0,
      csatAnswered: 0,
      csatSatisfaction: 0,
      csatSent: 0,
      map: map,
    };
  },

  applyDirectFilters({ getters }, { call, tmp }) {
    const filters = getters.FILTERS;

    const hangup = call.hangupreason;
    if (hangup) {
      if (hangup === "agent" && filters.filter_agent_hangup)
        tmp.filtered = true;
      else if (hangup === "caller" && filters.filter_client_hangup)
        tmp.filtered = true;
    }

    if (
      filters.filter_tags.length &&
      !intersection(call.tags, filters.filter_tags).length
    ) {
      tmp.filtered = true;
    }
    // processo CONTATOS
    if (
      filters.filter_contact.length &&
      !call.user_set.filter((u) => {
        return filters.filter_contact.includes(u.user_id);
      }).length
    )
      tmp.filtered = true;

    // processo CATEGORIA
    if (
      filters.filter_category.length &&
      !call.categories.filter((c) => {
        return filters.filter_category.includes(c);
      }).length
    )
      tmp.filtered = true;

    // processo PROTOCOLO
    if (filters.filter_protocol && call.protocol !== filters.filter_protocol)
      tmp.filtered = true;

    // filtra NUMERO
    if (filters.filter_number) {
      if (call.from === filters.filter_number) tmp.filtered = true;
    }

    if (filters.filter_tma_range) {
      if (
        tmp.connectionTime < filters.filter_tma_range.start ||
        tmp.connectionTime > filters.filter_tma_range.end
      ) {
        tmp.filtered = true;
      }

      if (
        tmp.tma < filters.filter_tma_range.start ||
        tmp.tma > filters.filter_tma_range.end
      ) {
        tmp.filtered = true;
      }
    }
  },

  mergeTemplate({ rootGetters, getters }, { tmp, final, calldate }) {
    if (tmp.filtered) return;
    const config = rootGetters.config;
    let start = moment(calldate).tz(config.timezone);
    const mapKey = start.format(getters.FORMAT.format);
    final.receivedAnswered += tmp.receivedAnswered;
    final.receivedNotAnswered += tmp.receivedNotAnswered;
    final.sentNotAnswered += tmp.sentNotAnswered;
    final.sentAnswered += tmp.sentAnswered;
    final.csatAnswered += tmp.csatAnswered;
    final.csatSent += tmp.csatSent;
    final.csatSatisfaction += tmp.csatSatisfaction;
    final.connectionTime += tmp.connectionTime;
    final.tma += tmp.tma;
    final.aasa += tmp.aasa;
    final.uniqueDevices = { ...final.uniqueDevices, ...tmp.uniqueDevices };
    final.uniqueDevicesRec = {
      ...final.uniqueDevicesRec,
      ...tmp.uniqueDevicesRec,
    };
    final.uniqueDevicesSent = {
      ...final.uniqueDevicesSent,
      ...tmp.uniqueDevicesSent,
    };

    if (!final.map[mapKey]) final.map[mapKey] = generateTemplate();
    const mapped = final.map[mapKey];
    mapped.receivedAnswered += tmp.receivedAnswered;
    mapped.receivedNotAnswered += tmp.receivedNotAnswered;
    mapped.sentNotAnswered += tmp.sentNotAnswered;
    mapped.sentAnswered += tmp.sentAnswered;
    mapped.csatAnswered += tmp.csatAnswered;
    mapped.csatSent += tmp.csatSent;
    mapped.csatSatisfaction += tmp.csatSatisfaction;
    mapped.connectionTime += tmp.connectionTime;
    mapped.tma += tmp.tma;
    mapped.aasa += tmp.aasa;
    mapped.uniqueDevices = { ...mapped.uniqueDevices, ...tmp.uniqueDevices };
    mapped.uniqueDevicesRec = {
      ...mapped.uniqueDevicesRec,
      ...tmp.uniqueDevicesRec,
    };
    mapped.uniqueDevicesSent = {
      ...mapped.uniqueDevicesSent,
      ...tmp.uniqueDevicesSent,
    };
  },

  countCsat({ getters }, { call, tmp }) {
    const filters = getters.FILTERS;

    if (call.csat_stage === "sent") tmp.csatSent++;
    if (call.csat) {
      tmp.csatAnswered++;
      const score = parseInt(call.csat);
      if (!filters.filter_csat[score]) tmp.filtered = true;
      if (score >= 4) {
        tmp.csatSatisfaction++;
      }
    } else if (!filters.filter_csat[0]) {
      tmp.filtered = true;
    }
  },

  // QUEUE ACTION
  async CALC_PERFORMANCE(
    { commit, getters },
    { payload, timezone, callbacksLoad }
  ) {
    if (payload && callbacksLoad) {
      commit("SET_CALLS", { calls: payload, callbacks: callbacksLoad });
    }
    const filters = getters.FILTERS;
    const { format, formatDiff } = getters.FORMAT;
    const { calls, callbacks } = getters.CALLS;
    const { start, end } = getters.PERIOD;
    const calls_list = [];

    let count = 0,
      transfers = 0,
      event_total = 0,
      abandons = 0,
      answers = 0,
      timeouts = 0,
      csatSatisfaction = 0,
      csatScores = 0,
      tma = 0,
      tme = 0,
      callbackComplete = 0,
      callbackIncomplete = 0,
      unique = 0,
      map = {},
      map_week = {},
      map_hour = {},
      sent_to_evaluation = 0,
      evaluated = 0,
      agent_hangup = 0,
      client_hangup = 0,
      sla_targets = 0,
      sla_target_abandon = 0;

    const elapsed = moment(end)
      .tz(timezone)
      .diff(moment(start).tz(timezone), formatDiff);
    const params = getters.CONFIG;
    let date = moment(start).tz(timezone);

    let dayIncrease = 0;
    if (elapsed > 0) {
      for (let i = 0; i <= elapsed; i++) {
        map[date.add(dayIncrease, formatDiff).format(format)] = 0;
        dayIncrease = 1;
      }
    }
    for (let i = 0; i < 24; i++) {
      map_hour[
        date.set({ hour: i, minute: 0, second: 0, millisecond: 0 }).valueOf()
      ] = { total: 0, busy: 0 };
    }

    try {
      for (const call of calls) {
        let temp_count = 0,
          temp_evaluated = 0,
          temp_event_total = 0,
          temp_abandons = 0,
          temp_answers = 0,
          temp_timeouts = 0,
          temp_transfers = 0,
          temp_csatSatisfaction = 0,
          temp_csatScores = 0,
          temp_tma = 0,
          temp_tme = 0,
          temp_map = {},
          temp_map_week = {},
          temp_map_hour = {},
          temp_client_hangup = 0,
          temp_agent_hangup = 0,
          temp_sent_to_evaluation = 0,
          filtered = false,
          temp_sla_targets = 0,
          temp_sla_target_abandon = 0;

        temp_count++;
        const day = moment(call._source.calldate)
          .tz(timezone)
          .format(format);
        const weekDay = moment(call._source.calldate)
          .tz(timezone)
          .isoWeekday();
        const hour = date
          .set({
            hour: moment(call._source.calldate)
              .tz(timezone)
              .hour(),
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .valueOf();
        calcMaps(temp_map_hour, hour, temp_map_week, weekDay);
        totalCalendarMapCount(temp_map, day);

        // processo CSAT
        if (call._source.csat_stage === "sent") temp_sent_to_evaluation++;
        if (call._source.csat) {
          temp_evaluated++;
          temp_csatScores++;
          temp_map[day].csatScores++;
          const score = parseInt(call._source.csat);
          if (!filters.filter_csat[score]) continue;
          if (score >= 4) {
            temp_csatSatisfaction++;
            temp_map[day].csatSatisfaction++;
          }
        } else if (!filters.filter_csat[0]) {
          continue;
        }
        // processo TAGS
        if (
          filters.filter_tags.length &&
          !intersection(call._source.tags, filters.filter_tags).length
        ) {
          continue;
        }
        // processo CONTATOS
        if (
          filters.filter_contact.length &&
          !call._source.user_set.filter((u) => {
            return filters.filter_contact.includes(u.user_id);
          }).length
        )
          continue;

        // processo CATEGORIA
        if (
          filters.filter_category.length &&
          !call._source.categories.filter((c) => {
            return filters.filter_category.includes(c);
          }).length
        )
          continue;

        // processo PROTOCOLO
        if (
          filters.filter_protocol &&
          call._source.protocol !== filters.filter_protocol
        )
          continue;
        // processo  HANGUP REASON
        const hangup = call._source.hangupreason;
        if (hangup) {
          if (hangup === "agent") temp_agent_hangup++;
          else if (hangup === "caller") temp_client_hangup++;
        }

        // filtra NUMERO
        if (filters.filter_number) {
          if (call._source.from === filters.filter_number) continue;
        }
        // processo EVENTOS EM FILA
        let marked_tma = false;
        let marked_tme = false;
        for (const top_flow of call._source.callflow) {
          let start_call;
          let end_call;
          if (top_flow.event === "transfer") {
            temp_transfers++;
            if (filters.filter_transferred) filtered = true;
            continue;
          }

          temp_event_total++;
          for (const flow of top_flow.callflow) {
            if (flow.event === "enterqueue") {
              start_call = moment(flow.calldate);
            } else if (flow.event === "queueanswer") {
              if (filters.filter_answered) filtered = true;
              let diff;
              // tme calc
              end_call = moment(flow.calldate);
              diff = end_call.diff(start_call, "seconds");
              temp_map[day].tme += diff;
              temp_tme += diff;

              if (params.target_tme_checked && temp_tme <= params.target_tme)
                marked_tme = true;

              // tma calc
              end_call = moment(call._source.hangupdate);
              diff = end_call.diff(moment(flow.calldate), "seconds");
              temp_map[day].tma += diff;
              temp_tma += diff;

              if (params.target_tma_checked && temp_tma <= params.target_tma)
                marked_tma = true;

              temp_map_hour[hour].busy += diff;
              temp_map[day].answers++;
              temp_answers++;
              break;
            } else if (flow.event === "queueabandon") {
              if (filters.filter_abandoned) filtered = true;
              end_call = moment(flow.calldate);
              const diff = end_call.diff(start_call, "seconds");
              temp_map[day].tme += diff;
              temp_tme += diff;

              temp_map[day].abandons++;
              temp_abandons++;
              if (params.target_abandon_remove_checked) {
                if (diff < params.target_abandon_remove) {
                  temp_sla_target_abandon++;
                }
              }
              break;
            } else if (flow.event === "queueleave") {
              if (filters.filter_timeout) filtered = true;
              end_call = moment(flow.calldate);
              const diff = end_call.diff(start_call, "seconds");
              temp_map[day].tme += diff;
              temp_tme += diff;

              temp_map[day].timeouts++;
              temp_timeouts++;
              break;
            }
          }
        }
        filtered = filterByTmaRange(filters, temp_tma, filtered);
        filtered = filterByTmeRange(filters, temp_tme, filtered);
        if (marked_tma && marked_tme) temp_sla_targets++;
        else if (!params.target_tma_checked && marked_tme) temp_sla_targets++;
        else if (!params.target_tme_checked && marked_tma) temp_sla_targets++;
        if (filtered) continue;

        calls_list.push(call);
        count += temp_count;
        event_total += temp_event_total;
        abandons += temp_abandons;
        answers += temp_answers;
        timeouts += temp_timeouts;
        csatSatisfaction += temp_csatSatisfaction;
        csatScores += temp_csatScores;
        tma += temp_tma;
        tme += temp_tme;
        agent_hangup += temp_agent_hangup;
        client_hangup += temp_client_hangup;
        sent_to_evaluation += temp_sent_to_evaluation;
        evaluated += temp_evaluated;
        transfers += temp_transfers;
        sla_target_abandon += temp_sla_target_abandon;
        sla_targets += temp_sla_targets;
        unique++;
        for (const key of Object.keys(temp_map_hour)) {
          map_hour[key].total =
            (map_hour[key].total || 0) + temp_map_hour[key].total;
          map_hour[key].busy =
            (map_hour[key].busy || 0) + temp_map_hour[key].busy;
        }
        for (const key of Object.keys(temp_map_week))
          map_week[key] = (map_week[key] || 0) + temp_map_week[key];
        for (const day of Object.keys(temp_map)) {
          if (!map[day]) map[day] = {};
          for (const data of Object.keys(temp_map[day]))
            map[day][data] = (map[day][data] || 0) + temp_map[day][data];
        }
      }
      for (const callback of callbacks) {
        if (!callback) continue;
        if (callback.status == 101) callbackIncomplete++;
        else callbackComplete++;
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit("SET_CALLS", { calls, callbacks, calls_list });
      commit("SET_PERFORMANCE", {
        agent_hangup,
        client_hangup,
        sent_to_evaluation,
        event_total,
        abandons,
        answers,
        timeouts,
        tma,
        tme,
        unique,
        csatSatisfaction,
        csatScores,
        map,
        map_week,
        callbackComplete,
        callbackIncomplete,
        map_hour,
        sent_to_evaluation,
        evaluated,
        transfers,
        sla_target_abandon,
        sla_targets,
      });
    }
  },
};

// helper functions
function calcMaps(map_hour, hour, map_week, weekDay) {
  map_hour[hour] = { total: 0, busy: 0 };
  map_hour[hour].total += 1;
  if (!map_week[weekDay]) map_week[weekDay] = 0;
  map_week[weekDay]++;
}

function generateTemplate() {
  return {
    receivedAnswered: 0,
    receivedNotAnswered: 0,
    sentAnswered: 0,
    sentNotAnswered: 0,
    uniqueDevices: {},
    uniqueDevicesSent: {},
    uniqueDevicesRec: {},
    connectionTime: 0,
    tma: 0,
    aasa: 0,
    csatAnswered: 0,
    csatSatisfaction: 0,
    csatSent: 0,
    map: {},
  };
}

function totalCalendarMapCount(map, day) {
  if (!map[day]) {
    map[day] = {
      total: 0,
      sla: 0,
      abandons: 0,
      timeouts: 0,
      answers: 0,
      tme: 0,
      tma: 0,
      csatScores: 0,
      csatSatisfaction: 0,
    };
  }
  map[day].total++;
}

function filterByTmaRange(filters, temp_tma, initFilter) {
  if (filters.filter_tma_range) {
    if (
      temp_tma < filters.filter_tma_range.start ||
      temp_tma > filters.filter_tma_range.end
    ) {
      return true;
    }
  }
  return initFilter;
}

function filterByTmeRange(filters, temp_tme, initFilter) {
  if (filters.filter_tme_range) {
    if (
      temp_tme < filters.filter_tme_range.start ||
      temp_tme > filters.filter_tme_range.end
    ) {
      return true;
    }
  }
  return initFilter;
}
