<template lang="pug">
.flex.flex-col.gap-2.items-start.justify-center
  .flex.justify-center.items-start.relative.group(class="dark:invert")
    video.aspect-video.rounded-sm.w-96(
      ref="video"
      :src="videoUrl"
      controls
      controlslist="nodownload"
    )
    f-dropdown.left-2.top-2.absolute(placement="bottom-start")
        button.opacity-0.flex.items-center.cursor-pointer.select-none.mr-1.transition.w-8.h-8.rounded-full.group.bg-gray-100(
          class="active:scale-95 group-hover:opacity-90",
        )
          i.material-icons.notranslate.text-gray-900(class="group-hover:text-brand dark:invert") more_vert
        template(#popper)
          ul.divide-y(v-close-popper.all)
            dropdown-item(
              @click="downloadVideo",
              label="Baixar video",
              icon="e2c4"
            )
  p.self-start.break-words.whitespace-pre-line.text-zinc-50.font-plus(
    class="dark:text-zinc-200"
    v-if="message?.caption"
  ) {{ message.caption }}
</template>

<script>
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
import rhService from '@/services/rship.js';

export default {
  name: 'chat-video',
  components: {
    FDropdown: Dropdown,
    DropdownItem,
  },
  data() {
    return {};
  },

  props: {
    message: {
      type: Object,
      required: true,
    },
  },

  computed: {
    filename() {
      return this.message.messageBody.split('.').slice(2).join('.');
    },
    videoUrl() {
      return `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${this.message.messageBody}`;
    },
  },

  methods: {
    async addTrackingOfDownload() {
      const { createTag } = rhService;
      try {
        const payload = {
          eventType: 'record',
          companyId: this.user.company_id,
          identifier: this.user.username,
          callId: this.message.chatRoomId,
          text: `Video baixado pelo usuário ${this.user.username}.`,
          eventBody: this.message.messageBody,
        };
        await createTag(payload);
        return { ok: true };
      } catch (error) {
        console.error(error);
        this.$toast.error('Erro ao salvar registro de download');
        return false;
      }
    },
    async downloadVideo() {
      try {
        const response = await fetch(this.videoUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const tracking = await this.addTrackingOfDownload();
        if (!tracking.ok) {
          throw new Error(`Falha ao salvar registro ${response.status}`);
        }

        const blob = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.filename;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        this.$toast.error('Erro ao baixar o áudio, tente novamente mais tarde');
      }
    },
  },

  mounted() {
    const userAudioVolume = localStorage.getItem('audioVolume');
    this.$nextTick(() => {
      if (!this.$refs?.video) return;
      this.$refs.video.volume = userAudioVolume || 0.5;
    });
  },
};
</script>
