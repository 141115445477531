<template lang="pug">
a.p-2.border-2.transition.rounded-t-md.w-full.flex.gap-4.cursor-pointer.relative(class="dark:hover:bg-inverted-brand-500 dark:hover:bg-opacity-10 dark:hover:border-inverted-brand-500 hover:bg-brand-500 hover:bg-opacity-10 hover:border-brand-200 max-w-xl" :href="urlData?.url" target="_blank")
  .material-icons.notranslate.absolute.right-1.top-0(class="dark:text-gray-400 dark:hover:text-cyan-500 !text-lg hover:text-red-500" @click.prevent="$emit('close')") close
  img.h-12.rounded-md.w-24(:src="urlData?.images[0]")
  .flex.flex-col.items-start
    p.font-plus.font-semibold.text-sm.text-gray-800 {{ urlData?.title }}
    p.font-plus.font-medium.text-xs.text-gray-500(class="max-w-lg") {{ urlData?.description }}
    p.font-plus.font-semibold.text-gray-400.flex.items-center.gap-1(class="max-w-lg text-[10px]") {{ urlData?.url }}
</template>
<script>
import chatService from '@/services/chat';

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      urlData: null,
    };
  },
  async created() {
    if (!this.url) return this.$emit('error');
    try {
      this.urlData = await chatService.getLinkPreview({ url: this.url });
      if (!this.urlData.title || !this.urlData.description) return this.$emit('error');
    } catch (error) {
      this.$emit('error');
      this.urlData = null;
    }
  },
};
</script>
