<template lang="pug">
.flex.rounded-md.p-1.border.px-3.gap-1.flex-col
  .flex.gap-1.w-full.justify-center.items-center
    .material-icons description
    p.truncate(class="max-w-[8rem]" v-tooltip="file.filename") {{ file.filename }}
  .flex.gap-1.w-full.justify-center.items-center(v-if="file?.error")
    .material-icons.text-red-400 error
    p.truncate(class="max-w-[8rem]" v-tooltip="file.error") Erro ao carregar arquivo
  .flex.border-t.w-full(v-if="!file?.error && hasPreview && !loadingAttachment && !loadingFailed")
    a.cursor-pointer.w-full.p-2(:href="attachmentFileUrl" target="_blank")
      v-button-secondary.w-full Fazer download
  .flex.border-t.w-full.py-2.flex-col.gap-2(v-if="!file?.error && hasPreview && loadingAttachment")
    p.font-semibold Carregando arquivo
    loading-ring
  .flex.border-t.w-full.py-2.flex-col.gap-2(v-if="!file?.error && hasPreview && !loadingAttachment && loadingFailed")
    .flex.gap-1 
      p.font-semibold Erro ao carregar arquivo
      .material-icons.notranslate.text-sm.text-brand-300.cursor-pointer(@click="getAttachment" v-tooltip="'Tentar novamente'") refresh
    .material-icons.notranslate.text-red-400 error
</template>

<script>
import { getAttachmentById } from '@/services/records.js';
import LoadingRing from '../LoadingRing.vue';
import vButtonSecondary from '../Buttons/v-button-secondary.vue';

export default {
  props: ['file', 'hasPreview', 'provider'],
  components: {
    LoadingRing,
    vButtonSecondary,
  },
  data() {
    return {
      loadingAttachment: true,
      loadingFailed: false,
      attachmentFileUrl: '',
    };
  },
  methods: {
    async getAttachment() {
      this.loadingAttachment = true;
      this.loadingFailed = false;
      try {
        const data = await getAttachmentById(this.file.id, this.provider);
        return (this.attachmentFileUrl = data.link);
      } catch (e) {
        this.loadingFailed = true;
      } finally {
        this.loadingAttachment = false;
      }
    },
  },
  async created() {
    if (this.hasPreview) await this.getAttachment();
  },
};
</script>
