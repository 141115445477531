<template>
  <div>
    <div class="upside-aligner">
      <div class="activity-left">
        <div :class="'event ' + activity.eventType">
          <span class="material-icons notranslate event_icon">{{
            iconsTranslate[activity.eventType]
          }}</span>
        </div>
        <div v-if="activity.eventType != 'tag'" class="activity-type-text">
          <span
            v-if="activity.eventType === 'leave'"
            class="activity-type-text bold"
          >
            {{ activity.identifier }} </span
          >{{ activitiesText[activity.eventType] }}
          <span
            v-if="
              activity.eventType === 'note' ||
              activity.eventType === 'queuehangup' ||
              activity.eventType === 'queueanswer'
            "
            class="activity-type-text bold"
          >
            {{ activity.identifier }}
          </span>
          <span
            v-else-if="
              activity.eventType === 'enter_queue' ||
              activity.eventType === 'enter_ura'
            "
            class="activity-type-text bold"
          >
            {{ activity.text }}
          </span>
        </div>
        <div
          v-if="activity.eventType === 'transfer'"
          class="activity-type-text"
        >
          <span style="margin-left: 5px" class="activity-type-text bold">{{
            activity.origin
          }}</span>
          para
          <span class="activity-type-text bold">{{
            activity.destination
          }}</span>
        </div>
        <div v-if="activity.eventType === 'tag'" class="activity-type-text">
          {{ 'Tag "' + tagText + '" adicionada' }}
        </div>
        <span
          v-if="isYours() && activity.eventType === 'note'"
          class="edited-icon notranslate material-icons"
          ref="editing-icon"
          role="button"
          @click="changeEditingIcon"
          >{{ !isEditing ? "edit" : "close" }}</span
        >
        <span
          v-if="activity.createdAt != activity.updatedAt"
          class="edited-text"
          >Editado</span
        >
      </div>
      <div class="activity-right">
        <div class="activity-date-text">{{ activityDateFormat() }}</div>
      </div>
    </div>
    <div v-if="activity.eventType != 'note'" class="downside-aligner">
      <div class="activity-type-text" v-if="activity.eventType == 'callback'">
        de
        <span class="activity-type-text bold">{{ activityIdentifier }}</span>
        para
        <span class="activity-type-text bold">{{ activity.contact }}</span>
      </div>
      <div class="activity-type-text" v-else-if="activity.eventType == 'tag'">
        por
        <span class="activity-type-text bold">{{ activityIdentifier }}</span>
      </div>
    </div>
    <div v-if="activity.eventType == 'note'" class="downside-aligner-note">
      <div :contenteditable="isEditing" @input="changeNote" class="note-holder">
        {{ actualText }}
        <div
          @click="openText"
          v-if="activity.text.length >= 256 && !isEditing"
          class="history-button show-more"
        >
          {{ seeText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activity", "eventDetail", "calldate"],
  data() {
    return {
      identifier: null,
      tagText: "",
      isEditing: false,
      iconsTranslate: {
        callback: "assignment_late",
        note: "comment",
        tag: "label",
        leave: "call_end",
        queuehangup: "call_end",
        hangup: "call_end",
        queueanswer: "call_received",
        answer: "call_received",
        transfer: "phone_forwarded",
        enter_queue: "call_received",
        enter_ura: "call_received",
      },
      activitiesText: {
        callback: "Retorno atribuído",
        tag: "Tag ",
        note: "Anotação por ",
        leave: " saiu da chamada",
        queuehangup: "Chamada finalizada por ",
        hangup: "Chamada finalizada",
        queueanswer: "Chamada atendida por ",
        answer: "Chamada iniciada",
        transfer: "Transferida de ",
        enter_queue: "Chamada entrou na fila ",
        enter_ura: "Entrou na URA",
      },
      actualText: "",
      seeText: "Ver mais",
      textChange: false,
    };
  },
  computed: {
    activityIdentifier() {
      return this.activity.identifier || "Desconhecido";
    },
  },
  methods: {
    openText() {
      if (this.seeText == "Ver mais") {
        this.seeText = "Ver menos";
        this.actualText = this.activity.text;
      } else {
        this.seeText = "Ver mais";
        this.actualText =
          this.activity.text.length >= 256
            ? this.activity.text.substring(0, 256) + "..."
            : this.activity.text;
      }
    },
    changeEditingIcon() {
      let editingIcon = this.$refs["editing-icon"];

      editingIcon.style.transform = "rotateZ(180deg)";
      editingIcon.style.opacity = 0;
      setTimeout(() => {
        this.isEditing = !this.isEditing;
        if (!this.isEditing && this.textChange)
          this.activity.text = this.textChange;
        this.checkTextOverflow();
        editingIcon.style.transform = "rotateZ(0deg)";
        editingIcon.style.opacity = 1;
      }, 200);
    },
    changeNote(payload) {
      this.textChange = payload.target.innerText;
    },
    checkTextOverflow() {
      if (this.isEditing) {
        this.actualText = this.activity.text;
      } else {
        this.actualText =
          this.activity.text.length >= 256
            ? this.activity.text.substring(0, 256) + "..."
            : this.activity.text;
      }
    },
    isYours() {
      if (this.activity.identifier != this.user.username) {
        return false;
      } else {
        if (!this.eventDetail) {
          return false;
        }
        return true;
      }
    },
    activityDateFormat() {
      try {
        if (
          this.activity.eventType === "callback" ||
          this.activity.eventType === "note" ||
          this.activity.eventType === "tag"
        ) {
          const date = this.$moment.tz(
            this.activity.createdAt,
            this.user.timezone
          );
          return date.format("DD/MM/yyyy [-] HH:mm");
        } else {
          return new Date(
            new Date(this.activity.createdAt) - new Date(this.calldate)
          )
            .toISOString()
            .substr(11, 8);
        }
      } catch (e) {
        this.$log.info(e);
        return "??:??";
      }
    },
  },
  async created() {
    if (!this.activity.text) return;
    this.actualText = this.activity.text;
    this.tagText =
      this.activity.text.length > 12
        ? this.activity.text.substring(0, 12) + "..."
        : this.activity.text;
    this.checkTextOverflow();
    try {
      if (!this.activity.identifier) {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/find-and-set/identifier/${this.activity.id}`
        );
        this.identifier = res.data.identifier || null;
      }
    } catch (e) {}
  },
  watch: {
    activity: function (value) {
      if (!this.activity.text || this.eventDetail) return;
      this.activity = value;
      this.actualText =
        this.activity.text.length >= 256
          ? this.activity.text.substring(0, 256) + "..."
          : this.activity.text;
      this.tagText =
        this.activity.text.length > 12
          ? this.activity.text.substring(0, 12) + "..."
          : this.activity.text;
    },
  },
  async beforeDestroy() {
    if (this.textChange) {
      try {
        const resnote = await this.$http.put(
          `${this.requestLinks.rhistory}/event/${this.activity.id}`,
          {
            text: this.textChange,
          }
        );
      } catch (e) {
        this.$log.info(e);
      }
    }
  },
};
</script>

<style>
.edited-text {
  margin-left: 10px;
  font-size: 14px;
  color: #adb5bd;
}
.edited-icon {
  margin-left: 10px;
  font-size: 22px !important;
  color: #adb5bd;
  transition: transform 0.15s, opacity 0.2s;
}
.activity-date-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #868e96;
}
.activity-left {
  width: inherit;
  min-width: 50%;
  display: flex;
  flex-direction: row;
}
.activity-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.show-more:hover {
  transform: none;
  opacity: 0.4;
}
.show-more {
  width: fit-content;
}
.note-holder {
  width: 100%;
  word-break: break-all;
  font-family: "Inter", sans-serif;
  padding: 10px;
  font-style: normal;
  font-weight: normal;
  caret-color: var(--brand-color);
  font-size: 14px;
  color: #495057;
  height: auto;
  border: 1px solid #e9ecef;
  outline: none;
  box-sizing: border-box;
  border-radius: 4px;
}
.upside-aligner {
  display: flex;
  flex-direction: row;
}
.downside-aligner {
  margin-left: 9px;
  margin-top: 4px;
  min-height: 22px;
  padding-left: 19px;
  margin-bottom: 22px;
  border-left: 1px solid #e9ecef;
}
.downside-aligner-note {
  margin-left: 10px;
  margin-top: 4px;
  padding-top: 16px;
  padding-left: 19px;
  margin-bottom: 22px;
  border-left: 1px solid #e9ecef;
}
.activity-type-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #495057;
  width: fit-content;
}
.activity-type-text .bold {
  font-weight: bold;
}
.event_icon {
  color: #fff;
  font-size: 14px !important;
}
.event {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.callback {
  background-color: #f8a354;
}
.upside-aligner {
  width: 100%;
}
.note {
  background-color: #6ea3f3;
}
.transfer {
  background-color: var(--green-default);
}
.queueanswer {
  background-color: var(--green-default);
}
.answer {
  background-color: var(--green-default);
}
.upside-aligner .tag {
  padding: 0px;
  background-color: #adb5bd;
}
.leave {
  background-color: #dc6058;
}
.queuehangup {
  background-color: #dc6058;
}
.hangup {
  background-color: #dc6058;
}
.enter_queue {
  background-color: var(--green-default);
}
.enter_ura {
  background-color: var(--green-default);
}
</style>
