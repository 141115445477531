<template lang="pug">
.activity-info-box
  .w-full.flex.justify-between
    .flex
      .activity-info-img(v-if="failedImg") {{ data.contact.name[0].toUpperCase() }}
      img.callback-callto-user-img.activity-info-size(
        v-else-if="!failedImg",
        :src="`${this.requestLinks.clist}/uploads/${data.contact.id}.jpg`",
        @error="failedImg = true"
      )
      .activity-info-texts
        p.activity-info-name
          | {{ data.contact.name || data.contact.username || "Contato desconhecido"}}
        p.activity-info-org(v-if="data.contact.organization")
          | {{ data.contact.organization.name }}
        p.activity-info-org(v-else)
          | {{ _formatNumber(data.contact.phones[0].number) || '' }}
      .activity-user-tag.ml-4.mb-auto(v-if="data.contact.category") {{ data.contact.category.name }}
    .flex.gap-4
      a.call-duration-text {{ _formatNumber(data.contact.phones[0].number) || '' }}
      .call-duration-text(v-if="data.contact.emails")
        | {{ data.contact.emails[0].address }}
      .call-duration-text(v-if="data.contact.phones", style="color: #495057")
        | {{ _formatNumber(data.contact.phones[0]) }}
    v-dropdown
      Status(:icon="true", :status="statusTypes[data.caseStatusId]")
      template(#popper)
        StatusDropdown(:options="statusTypes", @status-selected="changeStatus")
  .activity-info-separator
  .activity-downside-aligner
    .activity-assigner-area
      p.title Título
      p.info {{ data.title || 'Sem título'  }}
    .activity-note-area
      p.title Descrição
      p.info(v-if="data.description") {{ data.description }}
      p.info(v-else) Sem descrição
    .activity-add-area
      p.title Criado
      p.info {{ timeAdded(data.createdAt) || '' }}
</template>

<script>
import StatusDropdown from "@/pages/Cases/Cases.StatusDropdown.vue";
import Status from "@/pages/Cases/Cases.Status.vue";
import { Dropdown, hideAllPoppers } from "floating-vue";

export default {
  props: ["data"],
  components: {
    StatusDropdown,
    Status,
    VDropdown: Dropdown,
  },
  data() {
    return {
      failedImg: false,
      status: "Aberto",
      statusTypes: { 3: "Aberto", 4: "Em andamento", 5: "Fechado" },
      convertStatusToId: { Aberto: 3, "Em andamento": 4, Fechado: 5 },
    };
  },
  methods: {
    async changeStatus(data) {
      try {
        const res = await this.$http.put(
          `${this.requestLinks.cases}/cases/${this.data.id}`,
          {
            caseStatusId: this.convertStatusToId[data],
          }
        );
      } catch (error) {
      }
      this.data.caseStatusId = this.convertStatusToId[data];
      this.$store.dispatch('updateCases', this.$http);
      hideAllPoppers();
    },
    timeAdded(date) {
      let dateNow = new Date();
      let addedTime = (dateNow - new Date(date)) / 1000;
      if (addedTime > 59 && addedTime < 3600) {
        return "Há " + parseInt(addedTime / 60) + " min";
      } else if (addedTime < 60) {
        return "Há " + parseInt(addedTime) + " seg";
      } else if (addedTime > 3599 && addedTime < 3600 * 24) {
        if (parseInt(addedTime / 3600) === 1) {
          return "Há 1 hora";
        }
        return "Há " + parseInt(addedTime / 3600) + " horas";
      } else if (addedTime > 3600 * 24) {
        return "Há " + parseInt(addedTime / 3600 / 24) + " dias";
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.material-icons {
  color: #fafafa;
}
</style>

<style>
.activity-info-img {
  background: var(--brand-color-op-2);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-align: center;
  color: var(--brand-color);
  height: 64px;
  width: 64px;
  margin-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 4px;
}
.activity-downside-aligner {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  height: fit-content;
}
.activity-assigner-area {
  margin-right: 40px;
  white-space: nowrap;
}
.activity-note-area {
  margin-right: 40px;
  width: fit-content;
  max-width: 483px;
}
.activity-downside-aligner .title {
  font-family: "Inter", sans-serif;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}
.activity-downside-aligner .info {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}
.flex-end {
  justify-content: flex-end;
  width: 100%;
  margin-top: 16px;
  position: absolute;
}
.activity-info-separator {
  height: 1px;
  background: #e9ecef;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}
.activity-phones-aligner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 90px;
  margin-top: 8px;
  position: absolute;
}
.activity-user-tag {
  padding: 5px 12px;
  height: max-content;
  width: max-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #495057;
}
.activity-img-info-aligner {
  display: flex;
  position: relative;
  flex-direction: row;
}
.activity-info-box {
  width: 100%;
  height: fit-content;
  min-height: fit-content;
  padding-top: 30px;
  padding-left: 20px;
  padding: 1.9rem 1.2rem 1.9rem 1.4rem;
  padding-right: 24px;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
}
.activity-info-size {
  width: 64px !important;
  height: 64px !important;
  margin-right: 20px;
}
.activity-info-org {
  font-family: "Inter", sans-serif;
  margin-top: 8px;
  margin-bottom: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}
.activity-info-name {
  font-family: "Inter", sans-serif;
  font-style: normal;
  margin-bottom: 0px;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.info {
  word-break: break-all;
}
</style>