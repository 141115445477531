<template lang="pug">
.overlay-content.bg-neutral-50(ref="overlay-content")
  .container-fluid
    .overlay-pagearea
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          .overlay-title-text Tickets

        .incall-right-aligner(style="width: 44%")
          span.mr-6.text-brand-600.font-extrabold.cursor-pointer(
            @click="openCreateTicketModal"
            class="dark:invert"
          ) Criar Ticket
          span.close-overlay-icon.material-icons(@click="closeOverlay") &#xe5cd

      .calls-content.divide-y 
        .flex.justify-between.p-4(
          v-for="(ticket, index) in ticketsList",
          :key="index"
        )
          .flex
            p.pr-6.text-black.font-extrabold {{ ticket.id }}
            p.text-black {{ ticket.subject }}
          .flex
            p.border.h-8.text-center.px-4.py-1.rounded-full.overflow-hidden {{ ticket.status }}
            button.text-md.cursor-pointer.w-7.h-7.text-gray.flex.ml-2.rounded-full.material-icons(title="Vincular ligação ao ticket" @click="openEditTicketModal(ticket.id)") &#xe89c
</template>      

<script>
import "../../assets/css/vue-phone-number-input.css";

export default {
  data() {
    return { ticketsList: [] };
  },
  methods: {
    closeOverlay() {
      let overlay = this.$refs["overlay-content"];
      overlay.style.opacity = 0;
      setTimeout(() => this.$emit("close-overlay"), 300);
    },
    openCreateTicketModal() {
      this.$store.commit("modal/setActiveModal", "MovideskCreateTicketModal");
    },
    openEditTicketModal(ticketId) {
      this.$store.commit("modal/setContext", { ticketId });
      this.$store.commit("modal/setActiveModal", "MovideskEditTicketModal");
    },
  },
  created() {
    this.ticketsList = JSON.parse(
      JSON.stringify(this.$store.getters["modal/getContext"])
    );
  },
};
</script>
<style>
.calls-content {
  max-height: 90%;
  overflow-y: auto;
}
</style>
