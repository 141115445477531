import libphonenumber from 'google-libphonenumber';

/**
 * @typedef {Object} ParsePhoneNumberResult
 * @property {boolean} isValidNumber - Indicates if the phone number is valid
 * @property {string} ddiParsed - DDI code parsed
 * @property {string} phoneNumberParsed - Phone number parsed
 * @property {libphonenumber.PhoneNumberUtil} instance - Instance of the libphonenumber
 * @property {string} brazilNumber - Phone number parsed with the brazilian region
 * @property {boolean} isValidBrazilNumber - Indicates if the phone number is valid with the brazilian region
 */

/**
 * @param {string} number - Phone number to be parsed
 * @param {number | string} ddi  - DDI code
 * @returns {ParsePhoneNumberResult} - Returns a promise with the result of the parsing
 */
export function parsePhoneNumber(number, ddi = 55) {
  const onlyNumbers = number.replace(/\D/g, '');

  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

  const regionCode = phoneUtil.getRegionCodeForCountryCode(ddi);
  const phoneNumber = phoneUtil.parseAndKeepRawInput(onlyNumbers, regionCode);

  const result = {
    isValidNumber: phoneUtil.isValidNumber(phoneNumber),
    ddiParsed: phoneNumber.getCountryCode().toString(),
    phoneNumberParsed: phoneNumber.getNationalNumber().toString(),
    instance: phoneUtil,
    brazilNumber: null,
    isValidBrazilNumber: false,
  };

  if (+ddi === 55 && result.phoneNumberParsed.length === 10 && result.phoneNumberParsed[2] === '9') {
    const phoneWithDigitNine = result.phoneNumberParsed.slice(0, 2) + '9' + result.phoneNumberParsed.slice(2);
    const brazilNumber = phoneUtil.parseAndKeepRawInput(phoneWithDigitNine, 'BR');

    return {
      ...result,
      brazilNumber: brazilNumber.getNationalNumber().toString(),
      isValidBrazilNumber: phoneUtil.isValidNumber(brazilNumber),
    };
  }

  return result;
}
