export default {
  state: {
    cases: [],
  },
  mutations: {
    SET_CASES: function (state, payload) {
      state.cases = payload;
    },
  },
  getters: {
    GET_CASES: function (state) {
      return state.cases;
    },
  },
  actions: {
    async updateCases({ commit }, $http) {
      try {
        const schema = process.env.VUE_APP_SCHEMA;
        const cases = process.env.VUE_APP_CASES;
        const res = await $http.get(`${schema}://${cases}/cases`);
        commit("SET_CASES", res.data);
      } catch (error) {}
    },
  },
};
