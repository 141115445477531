<template lang="pug">
.w-96.rounded.font-plus.flex-col
  .flex.border-b.p-3.justify-between.items-center.bg-zinc-50.sticky.w-full.z-10.top-0
    p.font-bold.text-zinc-700.text-sm.mr-auto.font-plus Notificações
    v-button-secondary.text-xs.font-bold(
      @click="markAllAsRead",
    ) Marcar todas como lidas

  .p-2.pr-3.scroll-brand(v-if="notifications.length", class="max-h-[400px]")
    .flex-col.justify-start.w-full.overflow-hidden(v-if="todayNotifications.length")
      span.text-gray-500.font-semibold.font-plus Recentes
      NotificationMessage(
        v-close-popper,
        v-for="notification in todayNotifications",
        :key="notification.id",
        :notification="notification"
      )
    .flex-col.justify-start.w-full.overflow-hidden(v-if="oldNotifications.length")
      span.text-gray-500.font-semibold.font-plus Antigas
      NotificationMessage(
        v-close-popper,
        v-for="notification in oldNotifications",
        :key="notification.id",
        :notification="notification",
        v-if="!isToday(notification.createdAt)",
        :old="isToday(notification.createdAt)"
      )

  .flex.flex-col.justify-center.items-center.mb-12(v-else)
    CircularIcon.p-2.mt-12(:icon="'playlist_add_check'")
    p.font-extrabold.text-black.text-sm.text-center.my-2(class="w-2/3") Você não possui notificações no momento
</template>

<script>
import NotificationMessage from "@/components/Modals/Notification/NotificationMessage.vue";
import CircularIcon from "@/components/Icons/IconCircularBg.vue";
import notifyService from "@/services/notify.js";
import vCloseButton from "@/components/Buttons/v-close-button.vue";
import VButtonSecondary from "@/components/Buttons/v-button-secondary.vue";

export default {
  components: {
    NotificationMessage,
    CircularIcon,
    vCloseButton,
    VButtonSecondary
  },

  props: {
    notifications: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      todayNotifications: [],
      oldNotifications: [],
    };
  },

  methods: {
    isToday(timestamp) {
      const today = this.$moment();
      const yesterday = this.$moment().subtract(1, "day");
      const engagementDate = timestamp;

      if (this.$moment(engagementDate).isSame(today, "day")) return true;
      else if (this.$moment(engagementDate).isSame(yesterday, "day"))
        return false;
    },

    async markAllAsRead() {
      try {
        await notifyService.markAllAsRead({ userId: this.user.id });
        this.notifications.forEach((notification) => {
          notification.read = true;
        });
      } catch (error) {
        this.$toast.error(error);
      }
    },
  },

  created() {
    const [todayNotifications, oldNotifications] = this.notifications.reduce(
      (acc, notification) => {
        if (this.isToday(notification.createdAt)) acc[0].push(notification);
        else acc[1].push(notification);
        return acc;
      },
      [[], []]
    );

    this.todayNotifications = todayNotifications;
    this.oldNotifications = oldNotifications;
  },
};
</script>

<style lang="scss" scoped>
.material-icons {
  font-size: 16px;
}
</style>