<template lang="pug">
.w-full.flex.justify-between.p-2
  .flex.flex-col.items-start
    p.font-inter.text-sm.font-semibold.text-neutral-700 {{ contactName }}
    p.font-inter.text-sm.text-neutral-500 {{ contactNumber }}
  p.font-inter.text-sm.text-neutral-500 {{ onHoldTimer }}
</template>

<script>
export default {
  props: {
    contactName: {
      type: String,
      default: 'Desconhecido',
    },
    contactNumber: {
      type: String,
      default: '????',
    },
    timestamp: String,
  },
  data() {
    return {
      timerInterval: null,
      onHoldTimer: '00:00:00',
    };
  },
  async created() {
    this.timerInterval = setInterval(() => {
      const timeDifference = this.$moment().diff(this.$moment(this.timestamp), 'milliseconds');
      const duration = Math.max(0, timeDifference);
      this.onHoldTimer = this.$moment.utc(duration).format('HH:mm:ss');
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
  },
};
</script>
