<template lang="pug">
.flex.flex-col.gap-2.relative
  .w-full.h-12.absolute.top-0.z-10.rounded-lg.bg-gray-200.bg-opacity-80.flex.items-center.gap-4.p-2.px-4.justify-center(v-if="loading")
    p.text-gray-500.font-semibold.min-w-fit Inserindo tag
    loading-ring.max-w-fit
  icon-input.w-full(label="Procurar tag..." v-model="tagFilter" @focus="searching = true" @blur="blurInput")
  .flex.flex-wrap.justify-start.max-h-56.overflow-auto.bg-white.absolute.top-12.mt-2.rounded-md.w-full.shadow-md.shadow-gray-200.p-1(v-if="searching")
    .p-2.px-3.w-full.font-plus.font-medium.text-gray-700.rounded-md.cursor-pointer.transition(
      class='hover:bg-brand-500 hover:bg-opacity-10 active:scale-95 hover:text-brand-500'
      @click="addTag(tag)"
      v-for="tag in tags.filter(tag=>{return tag.text.toLowerCase().includes(tagFilter.toLowerCase())}).sort((a, b) => a.text.localeCompare(b.text))" :key="tag.id"
    ) {{ tag.text }}
</template>

<script>
import LoadingRing from '../LoadingRing.vue';
import rhService from '@/services/rship';
import callReport from '@/services/call-report';
import IconInput from './IconInput.vue';
export default {
  components: {
    IconInput: IconInput,
    LoadingRing
  },
  props: ['contact', 'callId'],
  data() {
    return {
      tagFilter: '',
      loading: false,
      tags: [],
      searching: false,
    };
  },
  methods: {
    blurInput() {
      setTimeout(() => (this.searching = false), 100);
    },
    async addTag(tag) {
      this.loading = true;
      try {
        const newTag = await callReport.createCallTag({
          tagId: tag.id,
          companyId: this.user.company_id,
          linkedId: this.linkedid,
          callId: this.callId,
        });
        await rhService.createTag({
          eventType: 'tag',
          companyId: this.user.company_id,
          identifier: this.user.username,
          callId: this.linkedid,
          text: tag.text,
          createdBy: this.user.id,
        });
        this.$emit('new-tag', newTag);
        this.$toast.success('Tag adicionada com sucesso!');
      } catch (error) {
        console.error(error);
        this.$toast.error('Falha ao adicionar tags, por favor tente novamente mais tarde');
      }
      this.loading = false;
    },
  },
  computed: {
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'] || {};
    },
    linkedid() {
      return this.$route.params.callId;
    },
  },
  async created() {
    const tags = await rhService.getTags(this.user.company_id);
    this.tags = tags.filter((tag) => tag.enable);
  },
};
</script>
