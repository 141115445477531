import axios from 'axios';
import jwtDecode from 'jwt-decode';

function getCookie(name) {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const $http = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Client-Id': 'yunique-ui',
    Authorization: `Bearer ${getCookie('snep7')}`,
  },
});

$http.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      return response.redirect('/auth/login');
    }
    return Promise.reject(error);
  },
);

export const companyId = getCookie('snep7') ? jwtDecode(getCookie('snep7'))?.company_id : false;

export default $http;
