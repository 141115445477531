<template lang="pug">
.w-full.py-4.px-1.cursor-pointer.rounded.text-inside-brand.overflow-hidden(
  @click="redirectURL"
)
  .flex.gap-2.items-start.w-full.overflow-hidden(v-if="!loading")
    .flex.bg-brand-100.rounded-full.w-14.items-center.justify-center.relative
      Avatar(
        class="w-8"
        :avatarId="sender?.avatar || randomID"
        :src="`${requestLinks.cservice}/avatar/${sender?.id}.png`",
      )
    .flex-col
      .flex.justify-between.w-full.mr-auto.mb-1
        .flex
          p.font-semibold.text-xs.mr-4.font-plus {{ notification.title }}
          p.text-xs.text-gray-400.font-plus {{ old ? $moment.tz(notification.createdAt, user.timezone).format('DD/MM/yyy[ - ]HH:mm') : $moment.tz(notification.createdAt, user.timezone).fromNow() }}
        i.w-2.h-2.bg-red-500.rounded-full.mr-1.animate-pulse(
          v-if="!notification.read"
        )
      p.text-xs.font-plus {{ notification.text }}
</template>

<script>
import openLinkMixin from "@/mixins/openExternalLinks.js";
import Avatar from "@/components/Avatars/avatar.vue";
import { getUserById } from '@/services/cservice.js'

export default {
  components: {
    Avatar
  },


  data() {
    return {
      sender: null,
      loading: true,
      randomID: Math.random().toString(36).substring(7),
    }
  },

  props: {
    notification: {
      type: Object,
      required: true,
    },
    old: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    redirectURL() {
      if (this.notification.type === "activity") {
        this.$router.push({ path: this.notification.link, query: { tab: 'activity' } })
      } else {
        this.$router.push({ path: this.notification.link})

      }
    },
  },

  mixins: [openLinkMixin],
  async created() {
    try {
      if (!this.notification?.senderId) return
      const sender = await getUserById(this.notification.senderId);
      this.sender = sender
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false
    }
  },
};
</script>