<template lang="pug">
MovideskModal
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Vincular Ticket # {{ context.ticketId }}
    i.cancel.material-icons(@click="$store.commit('modal/setActiveModal', '')") close
  template(v-slot:content)
    .input-aligner.row
      label.up-placeholder Descrição
      textarea.form-control.input.h-28(
        v-model="description",
        name="description",
        placeholder="Insira uma descrição..."
      )
  template(v-slot:footer)
    .flex.w-full.items-center.justify-end
      p.footer__cancel.mr-16(
        @click="$store.commit('modal/setActiveModal', '')"
      ) Cancelar
      button.margin-top-medium.buttonsave(
        :class="{ disabled: !description }",
        @click="linkTicket",
        :disabled="!description"
      ) VINCULAR TICKET
</template>

<script>
import MovideskModal from '@/components/Movidesk/ModalComponent.vue';
import integrationService from '@/services/integrations.js';
import rhService from '@/services/rship.js';
export default {
  components: {
    MovideskModal,
  },
  data() {
    return {
      description: '',
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
    movidesk() {
      return this.$store.getters['GET_MOVIDESK'];
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
  },
  methods: {
    async linkTicket() {
      try {
        const data = await integrationService.executeAction(
          { actionName: 'edit-ticket', partnerName: 'movidesk' },
          {
            companyId: this.user.company_id,
            queryParams: [this.movidesk.token, this.context.ticketId],
            data: {
              actions: [
                {
                  origin: 13,
                  type: 1,
                  description:
                    this.description +
                    ` <hr> Criado através do Opens, pelo usuario <b>${this.user.username}</b> <br> <b>Gravação:</b> <b>${this.user.username}</b> <br> <b>Gravação:</b> ${window.location.origin}/client/#/historic/detail/${this.call.linkedid}`,
                },
              ],
            },
          },
        );
        await rhService.createTag({
          eventType: 'tag',
          companyId: this.call.company_id,
          identifier: this.user.username,
          callId: this.call.linkedid || this.linkedid,
          text: 'Ticket atualizado',
          createdBy: this.user.id,
        });
        this.$toast.success('Ticket atualizado com sucesso!');
        this.$store.commit('modal/setActiveModal', '');
      } catch (error) {
        this.$toast.error('Não foi possivel vincular o ticket!');
      }
    },
  },
};
</script>
