import $http from "./client";
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CASES}`;

async function getCaseList() {
    try {
        const { data } = await $http.get(`${service}/cases`);
        return data;
    } catch (error) {
        return [];
    }
}

export const getCaseFeed = async function ({ caseId }) {
    try {
        const { data } = await $http.get(
            `${service}/cases-feed?caseId=${caseId}`
        );
        return data;
    } catch (error) {
        return Promise.reject(error);
    }
};


export default {
    getCaseList,
    getCaseFeed
};
