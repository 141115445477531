import moment from "moment-timezone";

export const dateFormatMixin = {
  methods: {
    formatTimestamp: (timestamp, format = "DD/MM/yyyy [-] HH:mm:ss") => {
      const date = moment(timestamp);
      return date.format(format);
    },
    formatTimeDiff(dateOne, dateTwo, format = "HH:mm:ss") {
      const dataOneInstance = moment(dateOne);
      const dataTwoInstance = moment(dateTwo);

      const oldestDate = moment.min(dataOneInstance, dataTwoInstance);
      const latestDate = moment.max(dataOneInstance, dataTwoInstance);
      const duration = moment.duration(moment(latestDate).diff(oldestDate));
      return moment.utc(duration.asMilliseconds()).format(format);
    },
  }
};