<template lang="pug">
modal(@click="cancelModal")
  modal-body
    template(v-slot:header)
      .flex
        v-heading Selecione uma pausa
        .material-icons.notranslate.ml-auto.cursor-pointer.text-gray-400(
          @click="cancelModal"
        ) close
    template(v-slot:body)
      .flex.gap-2.flex-col.w-96
        icon-input(
          label="Nome da pausa"
          v-model="search"
        )
        .flex.w-full.flex-col.max-h-64.overflow-y-scroll.justify-start
          transition-group.w-full(name="slide-fade" :duration="200" mode="out-in")
            .flex.w-full.justify-between.p-2(
              class="hover:bg-gray-200 transition-all cursor-pointer"
              @click="pauseClick(pause)"
              :key="pause.id"
              v-for="pause in pausesList"
            )
              .flex.gap-1.items-center
                p.font-plus {{ pause.name }}
                p.font-semibold.font-plus.text-xs {{ `(${translatePauseCategory[pause.category]})` }}
                .material-icons.text-gray-400.cursor-help.notranslate.text-sm(
                  v-if="pause.category == 'productive'"
                  v-tooltip="{content: formatEnabledChannels({pause_call: pause.pause_call, pause_chat: pause.pause_chat, pause_email: pause.pause_email}), html: true}"
                ) help
              .material-icons.text-brand-400.notranslate.text-sm arrow_forward
    template(v-slot:footer)
      loading-ring(v-if="loading")
      .flex.gap-2(
        v-else 
      )
        v-button-secondary(
          @click="cancelModal"
        ) Cancelar
        v-button(
          @click="pauseClick(selectedPause)"
          v-if="selectChannels"
        ) Confirmar
</template>

<script>
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import vButton from '../Buttons/v-button.vue';
import LoadingRing from '../LoadingRing.vue';
import Modal from './Modal.vue';
import ModalBody from './ModalBody.vue';
import vHeading from '../Text/v-heading.vue';
import dropdownItem from '../Dropdown/dropdown-item.vue';
import IconInput from '../Inputs/IconInput.vue';
import user from '@/store/user';

export default {
  components: {
    VButtonSecondary,
    LoadingRing,
    Modal,
    ModalBody,
    vHeading,
    dropdownItem,
    IconInput,
    vButton,
  },
  data: () => ({
    pauses: [],
    loading: false,
    search: '',
    translatePauseCategory: {
      unproductive: 'Improdutiva',
      productive: 'Produtiva',
    },
    emailWhiteListedCompanies: process.env.VUE_APP_EMAIL_WHITE_LIST,
  }),
  computed: {
    pausesList() {
      return this.pauses
        .filter((pause) => {
          return pause.name.toLowerCase().includes(this.search.toLowerCase());
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    showEmailText() {
      if (this.emailWhiteListedCompanies && !this.emailWhiteListedCompanies?.split(',').includes(this.user.company_id))
        return false;
      return true;
    },
  },
  methods: {
    formatEnabledChannels(channels) {
      const channelNames = {
        pause_call: 'Telefonia',
        pause_chat: 'Chat',
        pause_email: 'E-mail',
      };
      const enabledChannels = Object.keys(channels)
        .filter((channel) => channels[channel])
        .map((channel) => channelNames[channel]);
      if (!enabledChannels.length) return 'Nenhum canal habilitado';
      return `<b>Canais que serão pausados:</b> ${enabledChannels.join(', ')}`;
    },
    cancelModal() {
      if (this.selectChannels) {
        this.selectChannels = false;
        this.selectPause = true;
        this.selectedPause = {};
        return;
      }
      this.$store.commit('SET_PAUSE', { ispaused: false });
      this.$store.commit('SET_MODAL', null);
    },
    async pauseClick(pause) {
      try {
        this.loading = true;
        if (this.user.profile == 'p_agent') {
          let url = `${this.requestLinks.cservice}/companies/users/pause`;
          if (this.$store.getters['IS_PAUSED']) url = `${this.requestLinks.cservice}/companies/users/change-pause`;

          const res = await this.$http.put(url, {
            pause: pause.name,
          });

          this.$store.commit('SET_MODAL', null);
          this.$store.commit('SET_PAUSE', {
            ...pause,
            ispaused: true,
            type: pause.name,
          });
        } else {
          const res = await this.$http.put(`${this.requestLinks.cservice}/companies/users/state`, {
            state: 'disabled',
            pause: pause.name,
          });
          this.$store.commit('SET_MODAL', null);
          this.$store.commit('SET_PAUSE', {
            ...pause,
            ispaused: true,
            type: pause.name,
          });
        }
        this.$toast.success('Pausa realizada com sucesso!');
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$toast.error('Não foi possível realizar a pausa. Tente novamente!');
      }
    },
  },
  async created() {
    try {
      this.pauses = await this.$store.getters['GET_COMPANY_PAUSES'];
      if (this.user.profile == 'p_agent') return;
      this.pauses = this.pauses.filter((pause) => pause.category == 'unproductive');
    } catch (e) {}
  },
};
</script>
<style></style>
