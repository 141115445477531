<template lang="pug">
.w-full.border.rounded-md.transition(:class="{'border-gray-200': opened, 'border-transparent': !opened}")
  .py-2.px-3.flex.w-full.justify-between.cursor-pointer(@click="opened = !opened")
    .font-plus.text-gray-500.text-sm.font-medium {{title}}
    i.material-icons.notranslate.text-s.transition(:class="{'text-brand': opened, 'text-gray-500': !opened}") {{!opened ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}
  Transition(name="fade" :duration="100")
    slot(name="body" v-if="opened")
</template>
<script>
export default {
  props: ['title', 'isOpen'],
  data() {
    return {
      opened: false,
    };
  },
  created() {
    this.opened = this.isOpen;
  }
};
</script>
