<template lang="pug">
.return-modal
  .step-header
    .step-title Atribuir retorno
  .line-separator(style="margin-bottom: 32px")
  a.modal-tag Ligar para
  .callback-contact(v-if="name")
    .callback-callto-user-img(v-if="failedImg") {{ name[0].toUpperCase() }}
    img.callback-callto-user-img(
      v-else-if="!failedImg",
      :src="`${requestLinks.clist}/uploads/${contact.id}.jpg`",
      @error="failedImg = true"
    )
    .contact-info-aligner
      a.colega-name-text.no-margin {{ name }}
      a.card-info-text(style="text-align: left" v-if="phone") {{ phone }}
      a.card-info-text(style="text-align: left" v-else-if="contact.organization") {{ contact.organization.name }}
    .callback-callto-tag-margin.user-tag(v-if="contact.category?.name") {{ contact.category.name }}
    .user-tag(
      v-else-if="contact.email",
      :style="'color:#F9A454;border: 1px solid rgba(249, 164, 84, 0.2);margin-left:5px'"
    ) Colega
    .unknown-text.no-tag-margin(v-else) Sem categoria

  .callback-contact(v-else)
    .callback-callto-user-img CD
    .contact-info-aligner
      a.colega-name-text.no-margin Contato Desconhecido
      a.card-info-text {{ contact.endpoint }}
    .unknown-text.no-tag-margin Sem categoria

  contact-dropdown-input(
    style="margin-bottom: 16px",
    :model="callback",
    :labelTitle="'Responsável'",
    :attr="'assignedTo'",
    :displayAttr="'username'",
    :jsonData="companyUserList",
    :useId="true",
    :dataFilter="'username'",
    :placeholder="'Selecione um responsável'"
  )

  a.modal-tag Observações
  textarea.form-control.note-input(
    v-model="noteValue",
    style="margin-top: 8px; height: 77px; width: 100%",
    placeholder="Observação"
  )
  .button-margin-callback.cancel-button-aligner
    v-button-secondary.mr-2(v-on:click="$emit('close-modal')") Cancelar
    v-button(v-on:click="addCallBack") Concluir
</template>

<script>
import ContactDropdownInput from './Contact.DropdownInput.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButton from '@/components/Buttons/v-button.vue';
import CService from '@/services/cservice';

export default {
  components: {
    ContactDropdownInput,
    VButton,
    VButtonSecondary,
  },
  props: ['contact', 'linkedId'],
  data: () => ({
    callback: {
      user: '',
      assignedTo: '',
    },
    failedImg: false,
    companyUserList: [],
    noteValue: '',
  }),
  computed: {
    name() {
      return this.contact.name || this.contact.username;
    },
    phone() {
      if (this.contact.phones) {
        return this.contact.phones[0].number;
      }
      return this.contact.number || this.contact.endpoint;
    },
  },
  methods: {
    async addCallBack() {
      let phone = '';
      let email = '';
      let note = this.noteValue;
      let itemId = this.$store.getters['GET_ITEMID'];
      if (!this.contact.endpoint) {
        await this.$http
          .get(`${this.requestLinks.clist}/contact/${itemId}`)
          .then((r) => {
            phone = r.phones[0].number;
            email = r.emails[0].address;
          })
          .catch((e) => {});
      }
      const res = await this.$http.post(`${this.requestLinks.cblist}/callback`, {
        type: 'CALL',
        status: 101,
        category: 'Phone',
        assignedTo: this.callback.assignedTo,
        assignedBy: this.user.id,
        assignedByName: this.user.username,
        contactNumber: phone || this.phone,
        contactEmail: this.contact.email || email,
        linkedid: this.linkedId,
        note: note,
      });
      this.$emit('close-modal');
      if (res.status == 200) {
        this.$toast.success('Retorno atribuído com sucesso!');
        this.$store.commit('SET_ITEMID', null);
        this.$emit('add-callback-event', this.callback.assignedTo);
      } else {
        this.$toast.error('Falha ao atribuir retorno!');
        this.$store.commit('SET_ITEMID', null);
      }
    },
  },
  async created() {
    this.companyUserList = await CService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
  },
};
</script>
<style>
.button-margin-callback {
  margin-top: 48px;
}
.callback-contact {
  margin-top: 8px;
  display: flex;
}
.contact-info-aligner {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.no-margin {
  margin: 0px;
}
</style>
