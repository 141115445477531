<template lang="pug">
li.px-3.py-2.cursor-pointer.flex.justify-between(@click="$emit('click')" class="hover:bg-zinc-100 active:scale-90 transition-all") 
  p.text-xs.font-inter.text-zinc-500.mr-8.text-marker(class="dark:text-black" v-html="label")
  i.material-icons.notranslate.text-brand-600.text-base(v-html="`&#x${icon}`" v-if="!hideIcon" class="dark:invert")
  .h-6.w-6.border.rounded-full.flex.items-center.justify-center(
    v-else
    :class="selected ? 'bg-brand-500 border-brand-500' : ''"
  )
    .material-icons.notranslate.text-sm.text-white check
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Item',
    },
    icon: {
      type: String,
      default: 'item',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
