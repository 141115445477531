<template lang="pug">
.min-w-200.bg-zinc-50.px-12.overflow-auto(
  class="w-3/4 shadow-[15px_5px_40px_-10px_rgba(0,0,0,0.2)]"
) 
  .w-full.h-12.pt-8.flex.justify-between.items-center.mb-4
    h2.text-lg.font-extrabold.text-zinc-700.flex.justify-start.items-center Criar caso
    .flex
      span.material-icons.notranslate.text-zinc-400.text-2xl.cursor-pointer(@click="close") &#xe5cd
  h4.text-xs.font-inter.font-medium.text-zinc-400.flex.justify-start.items-center *Todas as informações são obrigatórias!
  .flex.flex-col.items-start(class="w-3/5")
    label.up-placeholder Título*
    input.form-control.input(v-model="title")
    label.up-placeholder Descrição*
    textarea.form-control(
      placeholder="Insira uma descrição",
      v-model="description"
    )
    DropdownTabInput(
      :model="responsible",
      :attr="'name'",
      labelTitle="Responsável*",
      :displayAttr="'name'",
      :jsonData="responsibleList",
      :useId="true",
      :dataFilter="'name'",
      @remove-filter="resetResponsible",
      :placeholder="'Selecione um agente ou grupo responsável'"
    )
    dropdown-clients(
      :model="client",
      @value="setValue",
      :attr="'name'",
      label-title="Cliente*",
      :displayAttr="'name'",
      :jsonData="clientsList",
      :useId="true",
      :dataFilter="'name'",
      :placeholder="'Selecione um cliente'",
      @remove-filter="resetClient",
      @intersect="intersected"
    )
    .flex.w-full.justify-center.mt-16.gap-2
      v-button-secondary(@click="close") Cancelar
      v-button(@click="createCase()") Criar
</template>

<script>
import { debounce } from '@/helpers';
import ContactDropdownInput from '@/pages/Contacts/Contact.DropdownInput.vue';
import DropdownClients from '@/components/Dropdown/dropdown-clients.vue';
import DropdownTabInput from '@/components/Dropdown/dropdown-tab-input.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import vButton from '@/components/Buttons/v-button.vue';
import contactList from '@/services/contact-list';
import CService from '@/services/cservice';

export default {
  components: {
    ContactDropdownInput,
    DropdownClients,
    DropdownTabInput,
    vButton,
    vButtonSecondary,
  },
  data() {
    return {
      description: '',
      title: '',
      responsible: {
        user: '',
        id: '',
      },
      client: {
        name: '',
        id: '',
      },
      companyUserList: [],
      responsibleList: {
        agents: [],
        groups: [],
      },
      clientsList: [],
      page: 1,
      oldName: '',
      contactSearchValue: '',
    };
  },
  computed: {
    workGroups() {
      return this.$store.getters['performance/WORK_GROUPS'];
    },
    context() {
      return this.$store.getters['overlay/getContext'];
    },
  },
  watch: {
    contactSearchValue(val) {
      this.searchTable();
    },
  },
  methods: {
    setValue(val) {
      this.contactSearchValue = val;
    },

    async intersected() {
      const { getAllContactsQuery } = contactList;
      const data = await getAllContactsQuery({
        offset: this.page * 20,
        limit: this.page * 20 + 20,
      });

      this.page++;
      this.clientsList = [...this.clientsList, ...data];
    },
    searchTable: debounce(async function () {
      if (this.oldName != this.contactSearchValue) {
        this.page = 1;
      }
      const page = this.page;

      if (!this.contactSearchValue) {
        this.clientsList = await contactList.getAllContactsQuery({
          offset: 0,
          limit: 20,
        });
        return;
      }
      this.clientsList = await contactList.getContactsByName({
        offset: (page - 1) * 20,
        limit: (page - 1) * 20 + 20,
        name: this.contactSearchValue.toLowerCase(),
      });
      this.oldName = this.contactSearchValue.toLowerCase();
    }, 500),
    resetResponsible() {
      this.responsible = {
        user: '',
        id: '',
      };
    },
    resetClient() {
      this.client = {
        name: '',
        id: '',
      };
    },
    async createCase() {
      const caso = {
        description: this.description,
        title: this.title,
        customerId: this.client.id,
        assignedTo: this.responsible.id,
        createdBy: this.user.id,
        caseStatusId: 3,
      };
      try {
        const res = await this.$http.post(`${this.requestLinks.cases}/cases`, caso);
        this.$store.commit('SET_CASES', []);
        this.$store.dispatch('updateCases', this.$http);
        this.close();
        this.$toast.success('Caso criado com sucesso!');
      } catch (error) {
        this.$toast.error('Erro! Preencha todas as informações ou tente novamente!');
      }
    },
    close() {
      this.$store.commit('overlay/setActiveOverlay', null);
    },
  },
  async created() {
    const { getAllContactsQuery } = contactList;
    try {
      this.companyUserList = await CService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
      this.clientsList = await getAllContactsQuery({ offset: 0, limit: 20 });

      this.responsibleList.agents = this.companyUserList;
      this.responsibleList.groups = this.workGroups;
    } catch (error) {
      this.$toast.error('Erro ao carregar informações, tente novamente!');
    }
  },
};
</script>
<style scoped>
textarea {
  width: 100%;
  height: 125px;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  box-sizing: border-box;
  border-radius: 4px;
  transition: border 0.3s, background 0.3s, resize 0s;
}
</style>
