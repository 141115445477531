<template lang="pug">
overlay
  .p-7
    header.flex.justify-between.mb-4.items-center
      .font-titillium.font-extrabold.text-3xl.text-zinc-700(class="dark:text-zinc-300 dark:selection:bg-brand-300") Adicionar contato
      i.material-icons.notranslate.bg-gray-200.p-1.rounded-full.text-gray-400.cursor-pointer(
        @click="$emit('close-overlay')",
        v-tooltip="'Fechar'",
        class="active:scale-95 hover:bg-red-100 hover:text-red-600 dark:bg-zinc-900 dark:text-zinc-600 dark:hover:bg-red-400"
      ) close
    add-contact-content.scroll-brand.overflow-auto.mb-28(
      :is-overlay="true"
      :overlay-contact="overlayContact"
      @close-overlay="$emit('close-overlay')"
    )
</template>

<script>
import AddContactContent from '@/pages/Contacts/AddContactContent.vue';
import overlay from './overlay.vue';

export default {
  components: {
    AddContactContent,
    overlay
  },

  props: {
    overlayContact: {
      type: Object,
      required: true
    }
  },
};
</script>
