<template lang="pug">
.event-box.h-fit
  .upside.min-h-fit
    span.material-icons.notranslate.bg-blue-600.text-xs.w-6.h-6.rounded-full.flex.items-center.justify-center(class="dark:invert min-w-[1.5rem] min-h-[1.5rem]") share
    span.event-text.min-h-fit(v-if="data.text.includes('email')") Áudio compartilhado por email pelo usuário <b>{{ data.identifier }}</b>.
    span.event-text.min-h-fit(v-else) {{ data.text.replace(`${data.identifier}.`, '') }} <b>{{ data.identifier }}</b>.
    span.date-text(
      v-tooltip="formatTimestamp(data.updatedAt)"
    ) {{ formatTimestamp(data.updatedAt) }}
  .downside
</template>

<script>
import { dateFormatMixin } from '@/mixins/date-format.mixin';

export default {
  mixins: [dateFormatMixin],
  props: ['data', 'calldate', 'isChat'],
};
</script>

<style lang="scss" scoped>
.event-box {
  width: 100%;
  height: 48px;
  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }
  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    height: 22px;
  }
  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
