export default {
  data() {
    return {
      activeEndpoint: undefined,
    };
  },
  computed: {
    operator() {
      const operatorPayload = {
        id: '',
        name: 'Desconhecido',
        number: '',
        category: '',
        organization: '',
        email: '',
        avatar: '',
        avatarUrl: '',
        tag: '',
        unknown: true,
      };

      if (!this.activeEndpoint) return operatorPayload;

      const operator = this.$store.getters['usersList/userByEndpoint'](this.activeEndpoint);

      if (!operator?.users_devices) return operatorPayload;
      operatorPayload.id = operator.users_devices.id;
      operatorPayload.number = operator.users_devices.endpoint;
      operatorPayload.name = operator.users_devices.username;
      operatorPayload.category = operator.users_devices.occupation;
      operatorPayload.email = operator.users_devices.email;
      operatorPayload.avatar = operator.users_devices.id;
      operatorPayload.tag = operator.users_devices?.occupation;
      operatorPayload.avatarUrl = `${this.requestLinks.cservice}/avatar/${operator.users_devices.id}.png`;
      operatorPayload.organization = '';
      operatorPayload.unknown = false;

      return { ...operator.users_devices, ...operatorPayload };
    },
  },
  watch: {
    activeEndpoint(value) {
      if (!value) return;
      this.$store.cache.dispatch('usersList/fetchUserByEndpoint', {
        peer: this.activeEndpoint,
        company_id: this.user.company_id,
      });
    },
  },
};
