<template lang="pug">
.w-full
  .flex.justify-start.items-center(v-if="message.recipientId != user.id")
    .flex.flex-col.items-start
      audio-player.w-80(:source="file", bg="bg-zinc-600" :is-recipient="message.recipientId != user.id")
    f-dropdown.mr-1(placement="bottom-start")
      button.flex.items-center.cursor-pointer.select-none.mr-1.transition.w-8.h-8.rounded-full.group.bg-gray-100(
        class="active:scale-95"
      )
        .material-icons.notranslate.text-gray-900(class="group-hover:text-brand dark:invert") more_vert
      template(#popper)
        ul.divide-y
          dropdown-item(
            @click="downloadAudio",
            label="Baixar áudio",
            icon="e2c4"
          )
  .flex.justify-end.items-center.w-full(v-else)
    .flex.flex-col.items-end.w-96
      audio.h-14(controls="")
        source(:src="file", type="audio/ogg")
        p Your browser does not support the audio element.
</template>

<script>
import AudioPlayer from '@/components/Player/audio-player.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
import rhService from "@/services/rship.js";
export default {
  components: {
    FDropdown: Dropdown,
    DropdownItem,
    AudioPlayer,
  },
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      file: undefined,
    };
  },
  methods: {
    async addTrackingOfDownload() {
      const {createTag} = rhService;
      try {
        const payload = {
          eventType: "record",
          companyId: this.user.company_id,
          identifier: this.user.username,
          callId: this.message.chatRoomId,
          text: `Áudio baixado pelo usuário ${this.user.username}.`,
          eventBody: this.message.messageBody,
        }
        await createTag(payload);
        return {ok: true};
      } catch (error) {
        console.log(error)
        this.$toast.error('Erro ao salvar registro de download');
        return false;
      }
    },
    async downloadAudio() {
      try {
        // Fetch the audio file from the external URL
        const response = await fetch(this.file);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const tracking = await this.addTrackingOfDownload();
        if(!tracking.ok) {
          throw new Error(`Falha ao salvar registro ${response.status}`);
        }

        // Convert the response to a Blob
        const blob = await response.blob();

        // Create a link element for downloading
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.message.messageBody; // Set your desired file name and extension

        // Append the link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Clean up the URL object
        window.URL.revokeObjectURL(link.href);
      } catch (error) {
        this.$toast.error('Erro ao baixar o áudio, tente novamente mais tarde');
      }
    },
  },
  async created() {
    this.file = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${this.message.messageBody}`;
  },
};
</script>
