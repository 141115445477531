<template lang="pug">
table.table-auto.w-full(aria-label="Dessert calories")
  thead
    tr.h-10
      th.pl-6(role="columnheader" scope="col") Tipo de atividade
      th(role="columnheader" scope="col") Data
      th(role="columnheader" scope="col") Contato
      th(role="columnheader" scope="col" v-if="calls?.length") Fila
      th(role="columnheader" scope="col" v-if="chats?.length") Tag
      th(role="columnheader" scope="col") Duração
      th(role="columnheader" scope="col") Ações
  transition-group(tag="tbody" name="slide-fade" :duration="200" mode="out-in" class="mdc-data-table__content")
    call-row(
      v-if="calls.length"
      v-for="(call, index) in calls"
      :key="call.linkedId"
      :call="call"
      :index="index"
    )
    chat-row(
      v-for="(chat, index) in chats"
      v-if="chats.length"
      :key="chat.chatRoomId"
      :chat="chat"
      :index="index"
    )
</template>

<script>
import CallRow from '../TablesRows/CallRow.vue';
import ChatRow from '../TablesRows/ChatRow.vue';

export default {
  name: 'EventsHistoryTable',
  props: ['calls', 'chats'],
  components: {
    CallRow,
    ChatRow,
  },
};
</script>
