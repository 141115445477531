<template lang="pug">
.text-inside-brand.relative.w-full.p-3(
  class="h-[110px]"
  v-tooltip="{ content: !number.length && 'Esse contato não possui numero', hide: 0 }"
)
  .flex.w-full.h-full.items-center.gap-2
    .flex.flex-col.justify-start.pt-4.h-full
      .list-user-img.relative
        .firstLetter(v-if="failedImg") {{ contact.name[0] }}
        img.w-8.h-8.rounded.object-cover(
          v-else-if="!failedImg",
          :src="`${requestLinks.clist}/uploads/${contact.id}.jpg`",
          @error="failedImg = true"
        )
    .grid.items-start(class="w-[90%]")
      span.font-semibold.font-inter.truncate(class="dark:text-black dark:font-extrabold") {{ contact.name }}
      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-700") {{ formattedNumber }}
      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900") {{ contact?.organization?.name || 'Sem organização' }}
    .flex.flex-col.justify-evenly.relative.items-center
      .truncate.user-tag.flex.justify-start.colega-tag.border-green-500.text-green-500(
        class="dark:invert dark:bg-transparent",
        v-if="contact?.isMate"
      ) Colega
      .truncate.user-tag.flex.justify-start(
        class="max-w-[80px]",
        v-else-if="contact?.category?.name",
        v-tooltip="contact.category.name"
      )
        | {{ contact.category.name }}
      .items-center.justify-center.flex.h-full(class="dark:invert")
        i.fa.fa-phone.items-center.justify-center.flex.rounded-full.transition-all.text-2xl.w-11.h-11.mt-1(
          class="hover:bg-brand-gradient hover:text-white text-[#58dca5]",
          @click.stop="makeCall()",
          role="button",
          v-if="number.length"
          v-tooltip="'Ligar para ' + contact?.name || 'Contato desconhecido'"
        )
</template>

<script>
export default {
  name: 'dialpad-contacts-card',
  props: {
    contact: {
      type: Object,
      required: true,
    },
    isTransfer: {
      type: Boolean,
      required: false,
    }
  },

  data() {
    return {
      failedImg: false,
      ImageUrl: process.env.VUE_APP_CSERVICE,
    };
  },

  computed: {
    number() {
      return this?.contact?.phones[0]?.number || '';
    },
    formattedNumber() {
      if (!this.number) return '????';
      let number = this.number;
      try {
        if (number.length > 4) {
          number = this.phoneUtil.format(
            this.phoneUtil.parseAndKeepRawInput(number, 'BR'),
          );
        } else if (number.length === 4) {
          number = 'SIP ' + number;
        }
      } catch (error) {
        return this.number;
      }
      return number;
    },
  },

  methods: {
    makeCall() {
      if(this.isTransfer) {
        this.$emit('calling', { contact: this.contact, num: this.number });
      }
      if (this.$store.getters['sip/agent']) {
        this.$store.dispatch('sip/makeCall', { callNum: this.number });
      } else {
        this.callNumber(this.number);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.user-tag {
  padding: 2px 6px;
  width: fit-content;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.1);
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 10px;
  color: #495057;
}
</style>
