<template lang="pug">
.flex.items-center.justify-center.h-screen.w-screen.relative
  .flex.flex-col.gap-2.z-20
    .flex.border.rounded-lg.border-brand-300.border-dashed.px-6.py-1.bg-white
      p.text-brand-300.font-bold.font-plus.text-lg 404
    p.text-4xl.font-bold.font-plus.text-black Ops! Acho que você se perdeu
    p.font-plus.text-xl Essa página não existe ou foi movida
    v-button-primary.mt-4(
      @click="$router.push({name: 'Principal'})"
    ) Voltar ao início
  img.w-20.absolute.top-0.left-10.z-10(src="~@/assets/images/opens-app-logo.png")
  img.absolute.top-0.select-none(
    class="w-11/12"
    src="~@/assets/images/bg-pattern.svg"
  )
</template>
<script>
import VButtonPrimary from '@/components/Buttons/v-button.vue';

export default {
  components: {
    VButtonPrimary,
  },
  title: 'Opens - Página não encontrada',
};
</script>
