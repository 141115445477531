<template>
  <div style="position: fixed; margin-top: 10%" class="page-content">
    <div class="step-header">
      <div class="step-title">Adicionar foto de perfil</div>
    </div>
    <div class="line-separator"></div>
    <div v-if="!file">
      <div class="center-aligner">
        <i class="cloud-icon fa-upload"></i>
      </div>
      <div class="center-aligner">
        <div class="text-xl font-extrabold">Selecione sua imagem de usuário</div>
      </div>
    </div>
    <div v-else-if="file" class="align-photo">
      <img
        style="width: 180px; height: 180px; border-radius: 4px"
        ref="imgPreview"
        :src="setImage()"
      />
    </div>
    <div class="cancel-button-aligner">
      <button @click="closeModal()" class="buttoncancel margin-right">
        Cancelar
      </button>
      <input
        @change="saveImage($event)"
        type="file"
        ref="avatar"
        style="display: none"
      />
      <button
        v-if="!savingStep"
        ref="button"
        @click="$refs.avatar.click()"
        class="buttonsave"
      >
        SELECIONAR IMAGEM
      </button>
      <button
        v-else-if="savingStep"
        ref="button"
        @click="applyImage()"
        class="buttonsave"
      >
        SALVAR IMAGEM
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      file: null,
      savingStep: false,
    };
  },
  methods: {
    async applyImage() {
      if (this.file) {
        let formData = new FormData();
        formData.append("avatar", this.file);
        await this.$http.put(
          `${this.requestLinks.cservice}/companies/users/avatar`,
          formData
        );
        this.$emit("closemodal");
      }
    },
    saveImage(e) {
      this.file = e.currentTarget.files[0];
      this.setImage();
      this.$emit("newImage", URL.createObjectURL(this.file));
      this.savingStep = true;
    },
    closeModal() {
      this.$emit("closemodal");
    },
    setImage() {
      return URL.createObjectURL(this.file);
    },
  },
};
</script>

<style>
.align-photo {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cloud-icon {
  font: normal normal normal 14px/1 FontAwesome;
  margin-top: 30px;
  font-size: 106px !important;
  box-shadow: none;
  color: var(--brand-color);
  background: none;
}
.select-text {
  margin-top: 50px;
  margin-bottom: 60px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 44px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.04em;
  color: #343a40;
}
</style>