<template lang="pug">
div(class="ml-auto w-full h-full pl-8")
  div(class="flex")
    h2(class="text-base font-plus font-semibold leading-6 w-full pl-4 pb-3 text-slate-800 border-b-2 border-slate-300")
      | Rastreio de atividades

    div(
      class="border-b-2 leading-7 pb-2 border-slate-300 font-inter text-sm px-3 cursor-pointer"
      :class="[ selectedTab === enumSelectedTab.seeAll ? 'font-bold border-brand-800' : 'font-normal' ]"
      @click="changeTab(enumSelectedTab.seeAll)"
    ) Todas

    div(
      class="border-b-2 leading-7 pb-2 border-slate-300 font-inter text-sm px-3 cursor-pointer"
      :class="[ selectedTab === enumSelectedTab.onlyNotes ? 'font-bold border-brand-800' : 'font-normal' ]"
      @click="changeTab(enumSelectedTab.onlyNotes)"
    )
      | Anotações

    div(
      class="border-b-2 leading-7 pb-2 border-slate-300 font-inter text-sm px-3 cursor-pointer"
      :class="[ selectedTab === enumSelectedTab.onlyAgile ? 'font-bold border-brand-800' : 'font-normal' ]"
      @click="changeTab(enumSelectedTab.onlyAgile)"
    )
      | Contexto

  div(
    class="mt-8 w-full p-4 h-full flex max-h-[472px] overflow-y-auto gap-6 flex-col justify-start no-scroll-bar"
  )
    component(
      :calldate="initialDate"
      :data="track"
      :is="track.eventType.replace('_', '')"
      :isChat="isChat"
      :key="track?.id"
      v-for="track in tracking"
    )
    loading-ring.h-8(v-if="loadingEvents")
    observer(@intersect="intersectedEvents" v-if="!eventsEnd")
</template>

<script>
import rhService from "@/services/rship.js";
import trackingTypesMixin from "@/mixins/tracking-types.mixin";
import Observer from "@/components/Observer.vue";
import LoadingRing from "@/components/LoadingRing.vue";

export default {
  mixins: [trackingTypesMixin],
  components: {
    Observer,
    LoadingRing,
  },
  props: {
    isChat: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String | Number,
      required: true,
    },
    initialDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      limit: 15,
      selectedTab: 0,
      enumSelectedTab: {
        seeAll: 0,
        onlyNotes: 1,
        onlyAgile: 2,
      },
      eventsPage: 0,
      loadingEvents: false,
      eventsEnd: false,
      eventType: null,
      tracking: [],
    };
  },
  methods: {
    async intersectedEvents() {
      if (this.eventsEnd || this.loadingEvents) return;
      await this.getEvents();
    },
    async changeTab(tab) {
      if (this.selectedTab === tab || this.loadingEvents) return;

      try {
        this.selectedTab = tab;
        this.tracking = [];
        this.loadingEvents = true;

        const tabToEventType = {
          [this.enumSelectedTab.seeAll]: null,
          [this.enumSelectedTab.onlyNotes]: ["note"],
          [this.enumSelectedTab.onlyAgile]: ["agile"],
        };

        this.eventType = tabToEventType[tab];

        await this.getInitialEvents();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
    async getEvents() {
      const { getEventsById } = rhService;

      try {
        this.loadingEvents = true;

        const eventsResponse = await getEventsById(this.id, {
          limit: this.limit,
          offset: this.eventsPage * this.limit,
          eventType: this.eventType,
        });

        this.tracking = [...this.tracking, ...eventsResponse];
        this.eventsPage += 1;
        this.eventsEnd = eventsResponse.length < this.limit;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
    async getInitialEvents() {
      const { getEventsById } = rhService;

      try {
        this.loadingEvents = true;
        this.eventsPage = 0;

        const eventsResponse = await getEventsById(this.id, {
          limit: this.limit,
          offset: this.eventsPage * this.limit,
          eventType: this.eventType,
        });

        this.tracking = eventsResponse;
        this.eventsPage += 1;
        this.eventsEnd = eventsResponse.length < this.limit;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
  },
  created() {
    this.getInitialEvents();
  },
};
</script>

<style lang="scss" scoped>
.no-scroll-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
