<template lang="pug">
li.px-3.py-2.cursor-pointer.flex.justify-between(@click="$emit('click')" class="hover:bg-zinc-100") 
    p.text-xs.font-inter.text-red-500.mr-8(class="dark:invert") {{label}}
    i.material-icons.notranslate.text-red-600.text-base(class="dark:invert" v-html="`&#x${icon}`")
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "Item",
    },
    icon: {
      type: String,
      default: "item",
    },
  },
};
</script>
