import $http from "./client";
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_AUTH}`
async function generateToken() {
  try {
    const { data } = await $http.post(`${service}/api-token`)
    return data.tokenId;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function removerToken({tokenId}){
  try {
    await $http.delete(`${service}/api-token/${tokenId}`);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * 
 * @param {Object} param 
 * @param {string} param.userId 
 * @param {string} param.companyId
 * @returns 
 */
async function findTokenByUserId({userId, companyId}){
  try {
    const {data} = await $http.get(`${service}/api-token/user?userId=${userId}&companyId=${companyId}`);
    return data
  } catch (e) {
    return Promise.reject(e);
  }
}


export default {
  generateToken,
  removerToken,
  findTokenByUserId
};
