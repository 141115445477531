<template lang="pug">
.w-80.border-l
  .dialpad-container
    .loading-page-dial(
      v-if="loaded && this.$store.getters['user/GET_DEVICES'].sip.length < 1"
    )
      .loading-texts(style="width: 224px")
        .confirm-circle(style="margin-bottom: 24px")
          span.material-icons.notranslate(style="color: #f26c45") phone
        .loading-error-text(style="font-size: 16px; color: #495057") Você ainda não possui um ramal vinculado
        .dialer-error-subtext Para receber e realizar ligações, solicite que um administrador vincule um ramal a sua conta.

    Default(
      v-else-if="!dialerOn && this.$store.getters['user/GET_DEVICES'].sip.length > 0"
    )
    .bg-dark(v-else-if="dialerOn")
    incoming-call(
      v-if="isValidEvent && currentDialState === dialState.INCOMING_CALL",
      :data="socketMessage",
      :extra="extraCall",
      @hangup-call="currentDialState = dialState.DEFAULT"
    )
    calling(
      v-else-if="isValidEvent && currentDialState === dialState.CALLING",
      :data="socketMessage",
      :extra="extraCall",
      @hangup-call="currentDialState = dialState.DEFAULT"
    )
    in-call(
      v-else-if="isValidEvent && currentDialState === dialState.IN_CALL",
      :data="socketMessage",
      :extra="extraCall",
      @hangup-call="currentDialState = dialState.DEFAULT"
    )
    call-ended(
      v-else-if="isValidEvent === dialState.CALL_ENDED",
      :data="socketMessage",
      :extra="extraCall"
    )
</template>

<script>
import IncomingCall from '@/components/CallDisplays/IncomingCall';
import Calling from '@/components/CallDisplays/Calling';
import InCall from '@/components/CallDisplays/InCall';
import CallEnded from '@/components/CallDisplays/CallEnded';
import Default from '@/layout/Dialpad/Dialpad.Main';
import CService from '@/services/cservice';

export default {
  components: {
    IncomingCall,
    Calling,
    InCall,
    Default,
    CallEnded,
  },
  data() {
    return {
      mates: [],
      queues: [],
      loaded: false,
      dialerOn: false,
      error: false,
      dialState: {
        DEFAULT: 0,
        INCOMING_CALL: 1,
        CALLING: 2,
        IN_CALL: 4,
        CALL_ENDED: 5,
      },
    };
  },
  computed: {
    callId() {
      return this.$store.getters['cstate/GET_CALL_ID'];
    },
    isInContext() {
      return this.$store.getters['cstate/IS_IN_CONTEXT'];
    },
    currentDialState: {
      set(newstate) {
        this.$store.commit('cstate/SET_DIALER_STATE', newstate);
      },
      get() {
        return this.$store.getters['cstate/GET_DIALER_STATE'];
      },
    },
    webphone() {
      return this.$store.getters['sip/agent'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    activeInterface() {
      if (!this.webphone) return true;
      return !!this.session.session;
    },
    extraCall() {
      return this.$store.getters['cstate/GET_EXTRA_CALL'];
    },
    socketMessage() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    isValidEvent() {
      if (this.isWebRTC) {
        return this.session.session && this.activeInterface;
      } else return true;
    },
  },
  async created() {
    try {
      await this.loadData();
      this.loaded = true;
    } catch (e) {
      this.error = true;
    }
  },
  methods: {
    async loadData() {
      try {
        const usersBonds = await CService.getAllUsers({
          status: 'activated',
          includeWorkGroups: true,
          withoutOpensUsers: true,
        });

        this.$store.commit('SET_COMPANYMATES', usersBonds);
      } catch (e) {
        return Promise.reject();
      }
    },
  },
};
</script>

<style lang="scss">
@import './style';
</style>
