<template lang="pug">
.h-full.overflow-hidden
  button#phone-tab.tab-item.border-box.font-plus(
    :class="activePhoneTab",
    @click="tabChange('Phone')",
    class="dark:text-black dark:font-bold"
  ) Phone
  button#colegas-tab.tab-item.border-box.font-plus(
    :class="activeMateTab",
    @click="tabChange('Colegas')",
    class="dark:text-black dark:font-bold"
  ) Colegas
  .flex.flex-col.justify-center
    .usable-area
      device-status
  phone-tab.overflow-y-scroll.h-full(
    v-if="actualTab === 'Phone' && !pauseSelect",
    style="max-height: calc(100% - 200px)"
  )
  slot
  div(v-if="pauseSelect")
    .dial-area
      .usable-area
        a.card-info-text(style="font-size: 16px !important") Selecione o tipo de pausa
        div(style="margin-bottom: 15px")
  #Filas.tab-identifier(v-if="actualTab === 'Filas' && !pauseSelect")
    .dial-area
      .usable-area
        queue-card
  #Colegas.tab-identifier(v-if="actualTab === 'Colegas' && !pauseSelect")
    .dial-area
      .usable-area
        .flex.justify-center.w-full.mt-2
          .input-icons-left.relative.flex.justify-start.items-center.border.rounded-md.px-1
            i.material-icons.notranslate.p-1.cursor-pointer search
            input.search-input.form-control.border-none.indent-0(
              ref="inputColega",
              v-model="searchValue",
              type="text",
              placeholder="Buscar colega"
            )
    .scroll-style.dial-area
      div.w-full.divide-y.flex.flex-col.items-center.justify-start.h-full(class="max-w-[280px]")
        //-  COLEGAS ONLINE
        mate-card(
          v-for="(mate, index) in online",
          :search="searchValue",
          :mate="mate",
          :index="index",
          :socket="$store.getters['cstate/GET_SOCKET']",
          ref="colegaDiv",
          :key="mate.id",
          @offline="changeStatusGroup(index, online, 'offline')",
          @paused="changeStatusGroup(index, online, 'paused')",
          @busy="changeStatusGroup(index, online, 'busy')"
        )
        //-  COLEGAS OCUPADOS
        mate-card(
          v-for="(mate, index) in busy",
          :search="searchValue",
          :mate="mate",
          :index="index",
          :socket="$store.getters['cstate/GET_SOCKET']",
          ref="colegaDiv",
          :key="mate.id",
          @offline="changeStatusGroup(index, busy, 'offline')",
          @paused="changeStatusGroup(index, busy, 'paused')",
          @online="changeStatusGroup(index, busy, 'online')"
        )
        //-  COLEGAS EM PAUSA OU NAO PERTURBE
        mate-card(
          v-for="(mate, index) in paused",
          :search="searchValue",
          :mate="mate",
          :index="index",
          :socket="$store.getters['cstate/GET_SOCKET']",
          ref="colegaDiv",
          :key="mate.id",
          @busy="changeStatusGroup(index, paused, 'busy')",
          @offline="changeStatusGroup(index, paused, 'offline')",
          @online="changeStatusGroup(index, paused, 'online')"
        )
        //-  COLEGAS OFFLINE
        mate-card(
          v-for="(mate, index) in offline",
          :search="searchValue",
          :mate="mate",
          :index="index",
          :socket="$store.getters['cstate/GET_SOCKET']",
          ref="colegaDiv",
          :key="mate.id",
          @busy="changeStatusGroup(index, offline, 'busy')",
          @paused="changeStatusGroup(index, offline, 'paused')",
          @online="changeStatusGroup(index, offline, 'online')"
        )
</template>

<script>
import QueueCard from '@/components/Cards/QueueCard';
import MateCard from '@/components/Cards/MateCard';
import PhoneTab from '@/layout/Dialpad/Dialpad.PhoneTab';
import DeviceWarning from '@/layout/Dialpad/Dialpad.Warning';
import DeviceStatus from './DeviceStatus.vue';
import { Dropdown } from 'floating-vue';
import eventBus from '@/main-event-bus';

export default {
  components: {
    QueueCard,
    MateCard,
    PhoneTab,
    DeviceWarning,
    VDropdown: Dropdown,
    DeviceStatus,
  },
  data() {
    return {
      online: [],
      offline: [],
      busy: [],
      paused: [],
      statusText: '',
      time: '00:00:00',
      timeBegan: null,
      timeStopped: null,
      stoppedDuration: 0,
      started: null,
      running: false,
      actualTab: 'Phone',
      actualPause: '',
      searchValue: '',
      socket: {},
      companyName: '',
      queues: [],
      loaded: false,
      pauses: [],
      pauseSelect: false,
      filteredMates: [],
      activeDevice: 'sip',
    };
  },
  computed: {
    isPausedText() {
      if (this.pause) {
        return 'Em pausa';
      }
      return 'Pausar';
    },
    companyMates() {
      const mates = this.$store.getters['GET_COMPANYMATES'];
      return mates?.filter((e) => e.id !== this.user.id) || [];
    },
    devices() {
      return this.$store.getters['user/GET_DEVICES'];
    },
    deviceSIP() {
      for (const device of this.devices.sip) {
        if (device.status === 'enabled') return device.device;
      }
      return {};
    },
    deviceMobile() {
      for (const device of this.devices.mobile) {
        if (device.status === 'enabled') return device.device;
      }
      return {};
    },
    isConfirmed() {
      return this.$store.getters['GET_MODAL'];
    },
    pause() {
      return this.user.pause || this.$store.getters['IS_PAUSED'] || this.$store.getters['GET_PAUSE'].ispaused;
    },
    isDeviceConnected() {
      return this.$store.getters['cstate/IS_DEVICE_CONNECTED'];
    },
    activePhoneTab() {
      return this.actualTab === 'Phone' ? 'active' : '';
    },
    activeMateTab() {
      return this.actualTab === 'Colegas' ? 'active' : '';
    },
    modal() {
      return this.$store.getters['GET_MODAL'];
    },
  },
  watch: {
    companyMates(mates) {
      if (this.loaded) return;
      if (!mates) return;
      this.loaded = true;
      this.filteredMates = mates;
      this.offline = this.filteredMates.sort(this.dynamicSort('username'));
    },
    pause(value) {
      if (value !== true) {
        this.$refs['enabled'].checked = true;
        this.statusText = 'Receber ligações a qualquer momento';
        return;
      }
    },
    modal(newVal, oldVal) {
      if (this.pause) return;
      if (oldVal === 'confirmPause') {
        this.$refs['enabled'].checked = true;
      }
    },
  },
  async created() {
    try {
      const pauses = await this.$http.get(
        `${this.requestLinks.cservice}/companies/pauses/type/public`,
      );
      this.pauses = pauses.data.pauses;
      this.$store.commit('SET_COMPANY_PAUSES', this.pauses);
    } catch (e) {
      this.$log.info(e);
    } finally {
      let statusTexts = {
        enabled: 'Receber ligações a qualquer momento',
        disabled: 'Não receber ligações independente do horário',
        auto: 'Receber ligações apenas em horário de trabalho',
        pause: 'Não receber ligações enquanto estiver em pausa',
      };

      this.statusText = statusTexts[this.user.state];
      if (this.user.pause) {
        let pauses = this.pauses;
        let pause = pauses.find((element) => element.name === this.user.pause);

        this.$store.commit('SET_PAUSE', {
          ...pause,
          ispaused: true,
          type: this.user.pause,
        });
      }
    }
  },
  methods: {
    dynamicSort(property) {
      let sortOrder = 1;

      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }

      return function (a, b) {
        if (sortOrder === -1) {
          return b[property].localeCompare(a[property]);
        } else {
          return a[property].localeCompare(b[property]);
        }
      };
    },
    tabChange(tab) {
      this.actualTab = tab;
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-tab-change',
        payload: { tab },
      });
    },
    checkDisplay(event) {
      if (this.loaded) {
        if (event.target.className !== 'select-box-device') {
          this.$refs['dropdown-device'].style.display = 'none';
        }
      }
    },
    selectDevice(device) {
      this.activeDevice = device;
      this.$refs['dropdown-device'].style.display = 'none';
    },
    async check(inputId) {
      if (this.user.profile !== 'p_agent') {
        const res = await this.$http.put(
          `${this.requestLinks.cservice}/companies/users/state`,
          {
          state: inputId,
          },
        );
        this.$store.commit('user/SET_USER', res.data);
        await this.$store.dispatch('performance/getUserWorkgroups', this.$http);
        if (inputId !== 'pause') {
          this.$store.commit('SET_PAUSE', {});
          this.pauseSelect = false;
        }
      } else {
        if (inputId === 'pause') {
          this.pauseSelect = true;
        } else {
          const res = await this.$http.put(
            `${this.requestLinks.cservice}/companies/users/state`,
            {
            state: inputId,
            },
          );
          this.$store.commit('user/SET_USER', res.data);
          await this.$store.dispatch('performance/getUserWorkgroups', this.$http);
          this.$store.commit('SET_PAUSE', {});
          this.pauseSelect = false;
        }
      }
      if (inputId === 'enabled') {
        this.statusText = 'Receber ligações a qualquer momento';
      } else if (inputId === 'auto') {
        this.statusText = 'Receber ligações apenas em horário de trabalho';
      } else if (inputId === 'disabled') {
        this.statusText = 'Não receber ligações independente do horário';
      } else if (inputId === 'pause') {
        this.statusText = 'Não receber ligações enquanto estiver em pausa';
      }
    },
    changeStatusGroup(index, origin, groupName) {
      const user = origin.splice(index, 1)[0];
      this[groupName].push(user);
      this[groupName].sort(this.dynamicSort('username'));
    },
    confirmPause() {
      if (this.pause) return;
      this.$store.commit('SET_MODAL', { name: 'confirmPause' });
    },
  },
};
</script>
