<template lang="pug">
.flex.flex-col.justify-start.items-start.line-break.break-anywhere.w-full.gap-2.relative
  ckeditor(
    :editor="editor" 
    v-model="text" 
    :config="editorConfig"
    @ready="setEditor"
  )
  f-dropdown.absolute.left-36.ml-1.select-none.mt-1
    .flex
      .flex.rounded-sm.transition-all.p-1.rounded-sm.border.border-transparent(
        class="hover:bg-zinc-100 active:border-blue-500"
        title="Emoji"
      )
        .material-icons.text-black(class="text-[22px]") mood
    template(#popper)
      emoji-input(
        @emojiSelected="addEmojiToInput"
      )
  .flex.absolute.right-0.p-1(v-if="hasSendButton")
    .flex.items-center.justify-center
      .flex.p-1.rounded-sm.transition-all(
        :class="{'cursor-pointer bg-brand-500 hover:opacity-60 active:scale-90' : text, 'cursor-not-allowed bg-brand-200' : !text}"
        v-tooltip="'Enviar mensagem'"
        @click="$emit('send-email')"
      )
        .material-icons.text-white send
</template>
<script>
import { Dropdown } from 'floating-vue';
import EmojiInput from '@/components/Inputs/EmojiInput.vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br.js';
import 'floating-vue/dist/style.css';
export default {
  props: ['initial-text', 'has-send-button', 'draft-text'],
  components: {
    ckeditor: CKEditor.component,
    FDropdown: Dropdown,
    ClassicEditor,
    EmojiInput,
  },
  data() {
    return {
      text: '',
      editor: ClassicEditor,
      textEditor: {},
      editorConfig: {
        toolbar: ['undo', 'redo', '|', 'bold', 'italic'],
        language: 'pt-br',
      },
    };
  },
  methods: {
    addEmojiToInput(emoji) {
      const content = emoji;
      const viewFragment = this.textEditor.data.processor.toView(content);
      const modelFragment = this.textEditor.data.toModel(viewFragment);
      this.textEditor.model.insertContent(modelFragment);
    },
    setEditor(e) {
      this.textEditor = e;
    },
  },
  watch: {
    text(val) {
      this.$emit('write', val);
      this.$emit('draft', val);
    },
    initialText(val) {
      this.text = val;
    },
    draftText(val) {
      this.text = val;
    },
  },
  created() {
    this.text = this.initialText;
  },
};
</script>
