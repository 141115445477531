import { updateCallback, getCallBackById, getUserCallbacks, getQueuesAbandonCallbacks } from '@/services/cblist';
import { updateCallbackReport } from '@/services/call-report';
import EventBus from '@/main-event-bus';

export default {
  methods: {
    async removeCallBack(callbackId, abandon) {
      this.actualModal = '';
      try {
        this.actualModal = '';
        await updateCallback({
          callbackId: callbackId,
          payload: {
            status: 303,
          },
        });
        await this.updateCallbacks();
      } catch (e) {
        this.$log.info(e);
      }
    },
    async finishCallBack(callbackId) {
      const abandonCallBack = await getCallBackById(callbackId);
      if (abandonCallBack) {
        if (abandonCallBack.linkedid) {
          await updateCallbackReport(abandonCallBack.linkedid);
        }
      }

      const agentId = this.user.id;

      try {
        this.actualModal = '';
        await updateCallback({
          callbackId: callbackId,
          payload: {
            status: 202,
            completedBy: agentId,
          },
        });
        await this.updateCallbacks();
      } catch (e) {
        this.$log.info(e);
      }
    },
    async updateCallbacks() {
      if (!this.myCallbacks || !this.abandonCallbacks) return;

      try {
        this.overlay = '';

        await this.countCallbacks();

        if (this.selectedTab === this.tabsEnum.myCallbacks) {
          await this.fetchMyCallbacks();
        } else if (this.selectedTab === this.tabsEnum.notAssigned) {
          await this.fetchAbandonCallbacks();
        }
      } catch (e) {
        this.$log.info(e);
      }
    },
  },
};
