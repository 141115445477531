export default {
  data() {
    return {
      activeContactId: undefined,
    };
  },
  computed: {
    contact() {
      const contactPayload = {
        id: '',
        name: 'Desconhecido',
        number: '',
        category: '',
        organization: '',
        email: '',
        avatar: '',
        avatarUrl: '',
        tag: '',
        unknown: true,
      };

      if (!this.activeContactId) return contactPayload;

      const contact = this.$store.getters['contactList/contactById'](this.activeContactId);
      if (!contact) return contactPayload;

      contactPayload.id = contact.id;
      contactPayload.name = contact.name;
      contactPayload.number =
        contact.phones[0]?.country != 'BR'
          ? `+${contact.phones[0]?.ddi} ${contact.phones[0]?.number}`
          : this.formatInputValue(contact.phones[0]?.number);
      contactPayload.category = contact.category?.name;
      contactPayload.organization = contact.organization?.name;
      contactPayload.email = contact.emails[0]?.address;
      contactPayload.tag = contact?.category?.name;
      contactPayload.avatar = contact.id;
      contactPayload.avatarUrl = `${this.requestLinks.clist}/uploads/${contact.id}.jpg`;
      contactPayload.unknown = false;
      return { ...contact, ...contactPayload };
    },
  },
  watch: {
    activeContactId(value) {
      if (!value) return;
      this.$store.cache.dispatch('contactList/fetchContactById', this.activeContactId);
    },
  },
  methods: {
    formatInputValue(number) {
      try {
        if (number.length > 4) {
          return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number, 'BR'), this.PNF.INTERNATIONAL);
        } else if (number.length === 4) {
          return 'SIP ' + number;
        }
        return number;
      } catch (error) {
        return number;
      }
    },
  },
};
