<template lang="pug">
div
  .flex.justify-start.items-start
    .flex.flex-shrink-0.align-center.justify.center.w-16.h-16.rounded-full.bg-brand-200.text-brand.text-lg.font-bold(class="dark:invert") {{ number }}
    slot(name="content")
</template>

<script>
export default {
  props: ["number"],
};
</script>