import { toStringTag } from "core-js/fn/symbol";

export default {
  namespaced: true,
  state: {
    protocol: "",
    companyTags: [],
    contact: {},
    phonenumber: "",
    orgname: "",
    callername: "",
    avatar: "",
    tag: {},
    tags: {},
  },
  mutations: {
    SET_PROTOCOL: function(state, protocol) {
      state.protocol = protocol;
    },
    SET_COMPANYTAGS: function(state, companyTags) {
      state.companyTags = companyTags;
    },
    SET_CONTACT: function(state, contact) {
      state.contact = contact;
    },
    SET_AVATAR: function(state, avatar) {
      state.avatar = avatar;
    },
    SET_PHONENUMBER: function(state, phonenumber) {
      state.phonenumber = phonenumber;
    },
    SET_ORGNAME: function(state, orgname) {
      state.orgname = orgname;
    },
    SET_CALLERNAME: function(state, callername) {
      state.callername = callername;
    },
    SET_TAG: function(state, tag) {
      state.tag = tag;
    },
    ADD_TO_TAGS: function(state, { linkedid, payload }) {
      if (!state.tags[linkedid]) {
        const a = {};
        a[linkedid] = [];
        state.tags = Object.assign({}, state.tags, a);
      }
      state.tags[linkedid].push(payload);
    },
    REMOVE_FROM_TAGS: function(state, { linkedid, id }) {
      state.tags[linkedid] = state.tags[linkedid].filter((tag) => {
        return tag.id != id;
      });
    },
    RESET: function(state) {},
    RESET_TAGS: function(state) {
      state.tags = [];
    },
  },
  getters: {
    GET_PROTOCOL: function(state) {
      return state.protocol;
    },
    GET_COMPANYTAGS: function(state) {
      return state.companyTags;
    },
    GET_CONTACT: function(state) {
      return state.contact;
    },
    GET_AVATAR: function(state) {
      return state.avatar;
    },
    GET_PHONENUMBER: function(state) {
      return state.phonenumber;
    },
    GET_ORGNAME: function(state) {
      return state.orgname;
    },
    GET_CALLERNAME: function(state) {
      return state.callername;
    },
    GET_TAG: function(state) {
      return state.tag;
    },
    GET_TAGS: function(state) {
      return (linkedid) => {
        return state.tags[linkedid];
      };
    },
  },
};
