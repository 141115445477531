import http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CSTATE}`;
import moment from 'moment-timezone';

async function isDeviceConnected(endpoint) {
  try {
    const r = await http.get(`${service}/online/device/${endpoint}`);
    return r.data.status;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getQueueSummary() {
  try {
    const { data } = await http.get(`${service}/queuesummary`);
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
}

async function checkTime() {
  try {
    const timezone = moment.tz.guess();
    const localTime = moment().tz(timezone);

    const { data } = await http.post(`${service}/check-time`, {
      localTime,
      timezone,
      threshold: 10,
    });

    return {
      isTimeOffset: data.isTimeOffset,
      timeDifference: data.timeDifference,
    };
  } catch (error) {
    throw new Error('Error checking time:' + error);
  }
}

export default { isDeviceConnected, getQueueSummary, checkTime };
