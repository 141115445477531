<template lang="pug">
.main
  .call-info-box
    protocol-box(
      v-if="call?.protocol"
      :protocol="call.protocol"
    )

    .flex.flex-col
      .w-full.mb-2.pr-4
        v-dropdown.w-44(placement="bottom")
          button(class="flex items-center px-3 py-1 pr-1 mr-1 transition border rounded-md cursor-pointer select-none group hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10")
            .text-xs.font-semibold.font-plus.text-zinc-500(class="group-active:text-brand") Ações
            span(class="text-gray-400 material-icons notranslate group-hover:text-brand") keyboard_arrow_down
          template(#popper)
            ul(class="w-full divide-y")
              dropdown-item(v-if="!preventTagEdit" @click="openTagModal" label="Adicionar tag" icon="f05b")
              dropdown-item(@click="openModal('NotesChat')" label="Adicionar anotação" icon="e873")
              dropdown-item(v-if="plan.cases" @click="openCaseModal()" label="Vincular Caso" icon="e85d")
        label.text Tags
        .w-full.flex.flex-wrap.justify-start
          call-tag.mr-2.my-2(@tag-removed='populateTags' v-for="(data, index) in tags" :key="data.id + data.text" :call-tag='getCompleteTag(data)')
          .text-gray-400.font-inter(v-show="!tags[0]") Nenhuma tag nesta atividade
    .input-box(v-if="callAudio != undefined && !this.$store.getters['performance/IS_SUPER']")
      label.text Gravação da chamada
      audio-player(v-if="callAudio && !callHasTransferEvent" :source="callAudio")
      .text-gray-400.font-inter(v-else-if="callHasTransferEvent") A gravação não está disponível pois possui
        span.font-bold transferência de chamada.
        | No momento só é possível escutá-la pela
        span.font-bold interface de gestão.
  events-list(
    :id="call.linkedid"
    :initial-date="call.calldate"
  )
</template>

<script>
import { getAllCallTags } from '@/services/call-report.js';
import rhService from '@/services/rship.js';
import TagInput from '@/components/Inputs/TagInput.vue';
import AudioPlayer from '@/components/Player/audio-player.vue';
import CallTag from './call-tag.vue';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import io from 'socket.io-client';
import EventBus from '@/main-event-bus';
import ProtocolBox from '../ProtocolBox.vue';
import EventsList from '@/components/CallEventTracking/EventsList.vue';

export default {
  components: {
    TagInput,
    DropdownItem,
    VDropdown: Dropdown,
    AudioPlayer,
    CallTag,
    ProtocolBox,
    EventsList,
  },
  props: ['call', 'contact'],
  data() {
    return {
      tagsInfo: [],
      tags: [],
      audio_src: '',
      volumeValue: 20,
      volumeIcon: 'volume_up',
      isPlaying: false,
      audioCurrentTime: '00:00',
      audioLastingTime: '-00:00',
      callAudio: undefined,
    };
  },
  methods: {
    getCompleteTag(tag) {
      return {
        ...tag,
        text: this.tagsInfo.find((tagInfo) => tagInfo.id === tag.tagId).text,
        callId: this.call.linkedid,
      };
    },
    socketConfig() {
      this.socket = io(`${this.requestLinks.rhistory}`, {
        query: {
          callId: this.call.linkedid || this.call.uniqueid,
        },
        reconnect: true,
      });

      this.socket.on('rh-update', (payload) => {
        switch (payload.eventType) {
          case 'protocol':
            {
              this.$store.commit('rh/SET_PROTOCOL', payload.identifier);
            }
            break;
          default: {
            this.populateTags();
            this.tracking.unshift(payload);
          }
        }
      });
      this.socket.on('rh-delete', (payload) => {
        this.populateTags();
      });
    },
    openCaseModal() {
      this.$store.commit('modal/setActiveModal', {
        name: 'linkCase',
        context: { contact: this.contact },
      });
      hideAllPoppers();
    },
    openTagModal() {
      this.$store.commit('modal/setActiveModal', 'TagChat');
      this.$store.commit('modal/setContext', {
        callId: this.call.callid,
        linkedid: this.call.linkedid,
        type: 'call',
      });
      hideAllPoppers();
    },
    openModal(type) {
      this.$store.commit('modal/setActiveModal', type);
      this.$store.commit('modal/setContext', {
        callId: this.call.linkedid,
        type: 'call',
      });
      hideAllPoppers();
    },
    async getAudio() {
      try {
        const res = await this.$http.get(`${this.requestLinks.records}/record/${this.call.callid}`);
        this.callAudio = res.data.record;
      } catch (error) {
        this.callAudio = undefined;
        if (error.response.status == 404) {
          this.$toast.error('Não foi possível localizar o áudio');
        } else if (error.response.status == 401) {
          this.$toast.error('Autorização para carregar aúdio negada');
        } else {
          this.$toast.error(error);
        }
      }
    },
    async populateTags() {
      this.tags = await getAllCallTags({
        companyId: this.user.company_id,
        linkedId: this.call.linkedid,
      });
    },
  },
  computed: {
    config() {
      return this.$store.getters['config'];
    },
    callHasTransferEvent() {
      const transferEvents = ['transfer', 'established_transfer', 'start_transfer'];
      return this.call?.callflow?.some((step) => transferEvents.includes(step?.event));
    },
    preventTagEdit() {
      return this.$store.getters['callTagsState/preventTagEdit'];
    },
  },
  async created() {
    if (this.call.status === 'ANSWERED') {
      await this.getAudio();
    }

    await this.populateTags();
    this.tagsInfo = await rhService.getTags(this.user.company_id);
    this.socketConfig();

    try {
      const FIVE_MINUTES = 5 * 60 * 1000;
      await this.$store.cache.dispatch('callTagsState/fetchPreventTagEdit', null, { timeout: FIVE_MINUTES });
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style lang="scss">
.main {
  width: 100%;
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
}
/*Audio Player----------------*/
.audio-volume-content {
  margin-left: 13px;
  pointer-events: none;
  margin-right: 26px;
  position: relative;
  display: flex;
}
.audio-volume-content .material-icons.notranslate {
  color: #868e96;
}
.audio-volume-content:hover .volume-box {
  opacity: 1;
}
.volume-box {
  width: 110px;
  padding-left: 12px;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  bottom: 7px;
  padding-right: 12px;
  height: 32px;
  position: absolute;
  opacity: 0;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}
.audio-range {
  width: 100%;
  height: 4px;
  pointer-events: all;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background: #e9ecef;
}
.audio-range::-webkit-slider-thumb {
  opacity: 0;
  background: var(--brand-color);
}
.audio-range::-moz-range-thumb {
  opacity: 0;
  background: var(--brand-color);
}
.volume-range {
  width: 100%;
  height: 4px;
  margin-top: 10px;
  margin-left: 12px;
  pointer-events: all;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  background: #e9ecef;
}
.volume-range::-webkit-slider-thumb {
  opacity: 0;
  background: var(--brand-color);
}
.volume-range::-moz-range-thumb {
  opacity: 0;
  background: var(--brand-color);
}
.audio-duration-content {
  margin-top: 4px;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;
}
.audio-duration-left {
  width: 50%;
}
.audio-duration-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}
.audio-progress-content {
  position: relative;
  width: 87%;
  height: 100%;
  display: flex;
  margin-left: 12px;
  flex-direction: column;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #868e96;
  padding-top: 10px;
}
.volume-progress {
  margin-left: 12px;
  margin-top: 10px;
  position: absolute;
  height: 4px;
  pointer-events: none;
  width: 20px;
  background: var(--brand-color);
}
.audio-progress {
  position: absolute;
  height: 4px;
  pointer-events: none;
  width: 0px;
  background: var(--brand-color);
}
.audio-progress-bar {
  width: 100%;
  position: relative;
  height: 4px;
  background: #e9ecef;
}
.player-box {
  width: 100%;
  padding-left: 14px;
  padding-top: 11px;
  height: 48px;
  display: flex;
  flex-direction: row;
  position: relative;
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  margin-top: 8px;
  box-sizing: border-box;
  border-radius: 4px;
}
.play-icon {
  font-size: 24px !important;
  color: var(--brand-color);
}
.play-button {
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}
.copy-icon {
  font-size: 19px !important;
  color: var(--brand-color);
  cursor: pointer;
  font-weight: 500;
  margin-left: 5px;
}
.number {
  margin-left: 10px;
}
.text {
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #868e96;
}
.call-info-box {
  display: flex;
  width: 50%;
  max-width: 549px;
  flex-direction: column;
  margin-left: 2rem;
  .header {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
  }
}
.body {
  display: flex;
  height: 100%;
}
</style>
