<template lang='pug'>
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Vincular Device
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd

  template(v-slot:content)
    .flex.flex-col.mt-12
      IconCircularBg.h-12.w-12.mb-12(:icon="'error'")
      p.text-black.text-center.text-3xl.font-bold.mb-8(class="w-3/5") Oops! Não foi possivel vincular seu device.
      p.text-gray-400.text-center(class="w-3/5") Não foi possível vincular o seu device, seu código expirou ou foi inserido incorretamente, tente novamente. Caso o erro persista, <span class="text-brand dark:invert">solicite suporte</span> para essa ação.

  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-36.text-brand(
        @click="$store.commit('modal/setActiveModal', '')"
      ) Fechar
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'LinkDevice')"
      ) TENTAR NOVAMENTE
</template>


<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
};
</script>