<template lang="pug">
.w-80
  .flex.rounded.cursor-pointer.bg-zinc-300.h-14(
    @click="viewPDF",
    ref="document"
    :on-load="showDocument = true"
    v-if="!loading && !error"
    class="hover:brightness-90"
  )
    i.mr-2.material-icons.notranslate.text-3xl.text-brand(class="dark:invert") insert_drive_file
    p.text-sm.text-zinc-600.truncate {{ documentFilename }}

  //- Loading esqueleto
  .flex.w-full.h-14.bg-gray-300.rounded.skeleton-card(v-if="loading")

  //- Error
  p.text-xs.text-red-500.flex.rounded.px-4.py-2.cursor-pointer.mb-1.bg-opacity-10.font-plus(
    class="hover:bg-gray-400 hover:bg-opacity-20"
    :class="['bg-zinc-600']"
    v-if="error"
  ) {{ error }}
    i.material-icons-round.notranslate.text-xl.text-red-500.transition-all(
      @click="retryGetFile",
      class="hover:text-red-600 hover:rotate-[360deg] duration-300"
      v-tooltip="'Tentar novamente'"
    ) refresh
</template>

<script>
import * as AdapterService from '@/services/adapter';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    message: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      file: undefined,
      loading: true,
      showDocument: false,
      baseUrl: null,
      error: null,
      intervalId: null,
      documentFilename: '',
      documentUrl: '',
    };
  },

  computed: {
    isRecipient() {
      return this.message.recipientId !== this.user.id;
    },
    shouldDisplayAdditionalContent() {
      return !!(this.message?.caption || this.message?.messageHeader || this.message?.messageFooter);
    },
  },

  methods: {
    async retryGetFile() {
      this.loading = true;
      this.error = null;
      try {
        await this.waitForFileAvailability();
      } catch (error) {
        this.error = error?.message;
      } finally {
        this.loading = false;
      }
    },
    async waitForFileAvailability() {
      return new Promise(async (resolve, reject) => {
        let retries = 0;
        const intervalId = setInterval(async () => {
          try {
            let url;
            const urlNew = await AdapterService.checkStaticFileAvailability(this.documentUrl);
            const urlOld = await AdapterService.checkStaticFileAvailability(
              this.documentUrl.replace(`${this.companyId}.`, `${this.companyId}:`),
            );
            url = urlNew || urlOld || null;
            if (url) {
              this.baseUrl = url;
              clearInterval(intervalId);
              resolve();
            } else {
              retries += 1;
              if (retries > 3) {
                clearInterval(intervalId);
                reject(new Error('Não foi possível carregar o arquivo.'));
              }
            }
          } catch (error) {
            clearInterval(intervalId);
            reject(new Error('Não foi possível carregar o arquivo.'));
          }
        }, 1500);
      });
    },

    async viewPDF() {
      const separatedUrl = this.baseUrl.split('.');
      const archiveExtension = separatedUrl[separatedUrl.length - 1];
      if (archiveExtension !== 'pdf') return await this.downloadFile();
      this.$store.commit('modal/setActiveModal', {
        name: 'PdfViewer',
        context: `${this.baseUrl}`,
      });
    },

    async downloadFile() {
      const data = await AdapterService.getFile(this.documentUrl);
      const blob = new Blob([data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.documentFilename;
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },

  async created() {
    try {
      const pathname = this.message.messageHeader.split('uploads/').pop();
      const filename = pathname.split('.');
      if (filename.length > 3) {
        this.documentFilename = filename.splice(2).join('.');
      } else {
        this.documentFilename = filename.splice(1).join('.');
      }
      this.documentUrl = pathname;
      await this.waitForFileAvailability();
    } catch (error) {
      this.error = error?.message;
    } finally {
      this.loading = false;
    }
  },

  watch: {
    showDocument(bool) {
      if (bool && this.$refs.subtitle) {
        this.$refs.subtitle.style.maxWidth = `${this.$refs.document.clientWidth}px`;
      }
    },
  },
});
</script>
