<template lang="pug">
.event-box
  .upside
    span.material-icons.notranslate.hangup {{ data.createdBy ? '&#xe158' : 'settings' }}
    .flex(v-if="!data.createdBy")
      span.event-text Convite automático enviado
      f-tooltip(@show='getUser()' @hide='clearData()')
          i.material-icons.notranslate.ml-2.w-5.h-5.bg-zinc-200.rounded-full.cursor-pointer.text-sm.flex.justify-center.items-center.text-zinc-400 person
          template(#popper)
            p Para: {{ operator.username }}
    .flex.gap-1(v-else)
      span.event-text Convite enviado 
      f-tooltip(@show='getUsers()' @hide='clearData()')
          i.material-icons.notranslate.mx-1.w-5.h-5.bg-zinc-200.rounded-full.cursor-pointer.text-sm.flex.justify-center.items-center.text-zinc-400 person
          template(#popper)
            .flex.flex-col.items-start(v-if="createdByUser.username")
              p Por: {{ createdByUser.username }} 
              p Para: {{ operator.username }}
    span.date-text(
      v-tooltip="formatTimestamp(data.createdAt)"
    ) {{ formatTimeDiff(data.createdAt, calldate) }}
  .downside
</template>

<script>
import { Tooltip } from "floating-vue";
import "floating-vue/dist/style.css";
import cservice from "@/services/cservice";
import { dateFormatMixin } from '@/mixins/date-format.mixin';

export default {
  mixins: [dateFormatMixin],
  props: ["data", "calldate"],
  components: {
    FTooltip: Tooltip,
  },
  data() {
    return {
      operator: {},
      createdByUser: {},
    };
  },
  methods: {
    async getUser() {
      try {
        const data = await cservice.getUserById(this.data.eventBody);
        this.operator = data;
      } catch (error) {
        this.clearData();
      }
    },
    clearData() {
      this.operator = {};
      this.createdByUser = {};
    },
    async getUsers() {
      try {
        const createdByUser = await cservice.getUserById(this.data.createdBy);
        const operator = await cservice.getUserById(this.data.eventBody);

        this.createdByUser = createdByUser;
        this.operator = operator;
      } catch (error) {
        this.clearData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.event-box {
  width: 100%;
  height: 48px;

  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  .hangup {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: var(--brand-color);
    color: #fff;
    font-size: 12.5px !important;
  }

  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }

  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }

  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    height: 22px;
  }

  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
