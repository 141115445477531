<template lang="pug">
.flex.flex-col.items-start
  p.font-plus.font-semibold.text-sm Protocolo:
  .flex.gap-2.items-center
    tooltip
      .flex.justify-start.items-center.cursor-pointer.gap-1.transition-all(
        @click="copyProtocol(protocol)",
        v-tooltip="{ content: 'Copiado!', shown: shownCopiedTooltip, triggers: [], delay: 200 }"
      )
        p.text-gray-600.font-plus.text-sm {{ protocol }}
        .material-icons.notranslate.protocol.text-base.text-gray-600 link
      template(#popper)
        .flex.flex-col.items-center.gap-1
          p.text-white.font-medium.font-plus Clique para copiar!
          span.text-sm.text-zinc-200.font-plus {{ protocol }}
</template>
<script>
import { Tooltip } from 'floating-vue';

export default {
  props: ['protocol'],
  components: {
    Tooltip,
  },
  data() {
    return {
      shownCopiedTooltip: false,
    };
  },
  methods: {
    copyProtocol() {
      const clipboardData =
        event?.clipboardData || window?.clipboardData || event?.originalEvent?.clipboardData || navigator?.clipboard;
      clipboardData.writeText(this.protocol);
      this.shownCopiedTooltip = true;
      setTimeout(() => {
        this.shownCopiedTooltip = false;
      }, 1500);
    },
  },
};
</script>
