<template lang="pug">
.flex.flex-col.justify-start.h-full.overflow-hidden.px-4.scrollbar-none
  .flex.justify-center.w-full.mt-2
    .input-icons
      .input-icons-left.relative.flex.justify-start.items-center.border.rounded-md.px-1.relative
        .material-icons.notranslate.p-1.cursor-pointer.text-xl &#xe61d
        input.search-input.form-control.border-none.indent-0.pr-5.font-plus(
          class="placeholder:text-xs"
          type="text",
          placeholder="Digite nome ou número para ligar",
          v-model="inputValue",
          v-on:keyup.enter="makeCall"
        )
        i.material-icons.notranslate.cursor-pointer.absolute.right-9(v-if="inputValue", @click="inputValue = ''", v-tooltip="'Limpar pesquisa'", class="hover:text-red-500") close
        .flex.justify-center.items-center.p-1.select-none(@click="refreshContacts")
          .material-icons.notranslate.cursor-pointer.select-none.text-xl(
            class="hover:text-brand",
            :class="{ 'animate-spin text-brand': loadingRefresh }",
            v-tooltip="'Recarregar contatos'"
          ) refresh
  p.text-center.text-xs.text-gray-500.cursor-default.mt-4.mb-2(v-if="!inputValue") Histórico das últimas 3 chamadas 
  .overflow-y-auto.scroll-brand.overflow-x-hidden.scrollbar-w-1.w-full.divide-y.px-1(
    v-if="!inputValue"
  )
    recent-call-card.w-full(
      v-if="!loadingHistory && !!recentCalls.length",
      v-for="(call, index) in recentCalls",
      :index="index",
      :call="call",
      :key="call.id"
    )
    .divide-y.relative.py-3.px-4.h-24.w-full.skeleton-card.mb-2(
      v-if="loadingHistory",
      v-for="i in 5",
      :key="`${i}loading`"
    )
    p.text-brand-400.font-semibold.cursor-pointer.text-center(@click="$router.push({ name: 'Atividades' })") Clique aqui para ver mais


  .overflow-y-auto.h-full.scroll-brand.overflow-x-hidden.scrollbar-w-1.w-full(
    v-else-if="inputValue"
  )
    dialpad-search-contact(:search="inputValue")
</template>

<script>
import services from '@/services';
import ContactList from '@/services/contact-list';
import DialpadContactsCard from '@/components/Cards/dialpad-contacts-card.vue';
import DialpadSearchContact from './dialpad-search-contact.vue';
import RecentCallCard from '@/components/Cards/RecentCallCard.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    DialpadSearchContact,
    DialpadContactsCard,
    RecentCallCard,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      inputValue: '',
      recentCalls: [],
      stream: null,
      loadingRefresh: false,
      loadingHistory: false,
      totalHistoryCards: 5,
    };
  },
  computed: {
    cStateSocket() {
      return this.$store.getters['cstate/GET_SOCKET'];
    },
    device() {
      return this.user.endpoint;
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth;
    });
  },
  async created() {
    this.openCallReportStream();
    this.updateRecentCalls();

    this.cStateSocket.on('user:call:saved', (data) => {
      this.recentCalls.pop();
      this.recentCalls.unshift(data.call);
    });
  },
  methods: {
    makeCall() {
      if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: this.inputValue });
      else this.callNumber(this.inputValue);
    },
    async updateRecentCalls() {
      try {
        this.loadingHistory = true;
        const response = await services.callReport.getPaginateCalls({
          limit: 3,
          offset: 0,
          companyId: this.user.company_id,
          isUserPerformance: true,
          toId: this.user.id,
          fromId: this.user.id,
          createdAtLt: new Date().toISOString(),
          stepsRequired: false,
        });

        this.recentCalls = response;
      } catch (e) {
        this.$log.error(e);
      } finally {
        this.loadingHistory = false;
      }
    },
    async openCallReportStream() {
      if (this.stream) this.stream.close();
      this.stream = services.callReport.connectToCallSSE(this.$http, {
        device: this.device,
      });
      this.stream.onmessage = (message) => {
        setTimeout(() => {}, 5000);
      };
    },
    async refreshContacts() {
      try {
        if (this.loadingRefresh) return;
        await this.updateRecentCalls();
        this.loadingRefresh = true;
        const contacts = await ContactList.getAllContacts();
        this.$store.commit('SET_CLISTMATES', contacts);
        this.$toast.success('Contatos atualizados com sucesso');
      } catch (e) {
        this.$toast.error('Error ao atualizar contatos, tente novamente mais tarde');
      } finally {
        this.loadingRefresh = false;
      }
    },
  },
});
</script>
