<template>
  <div class="callback-modal-content" @click.self="$emit('close-modal')">
    <div class="modal-box">
      <div class="step-title">Concluir atividade</div>
      <div class="modal-line-separator"></div>
      <div class="modal-info-text">
        Deseja concluir a atividade de retorno para
        <a class="modal-info-text bold">{{ activity.contact.username }}</a>
        criada por
        <a v-if="activity.assignedBy.username" class="modal-info-text bold">{{
          activity.assignedBy.username
        }}</a>
        <a v-else class="modal-info-text bold"
          >abandono na fila {{ activity.payload.description }}</a
        >
        <div class="modal-button-aligner">
          <button @click="$emit('close-modal')" class="buttoncancel">
            Cancelar
          </button>
          <button
            @click="
              $emit(
                'finish-activity',
                activity.payload.id,
                activity.assignedBy ? false : true
              )
            "
            class="buttonsave"
          >
            CONFIRMAR
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["activity"],
};
</script>

<style scoped>
.bold {
  font-weight: bold;
}
.modal-button-aligner {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}
.callback-modal-content {
  width: 80%;
  z-index: 20;
  height: 70%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-box {
  width: 610px;
  height: 285px;
  background: #ffffff;
  padding: 48px;
  box-shadow: 0px 5px 15px 10px #1c1c1c14;
}
.modal-line-separator {
  margin-top: 20px;
  margin-bottom: 24px;
  width: 100%;
  background: #868e96;
  opacity: 0.2;
  height: 1px;
}
</style>