<template lang="pug">
div.font-plus.transition.cursor-pointer.px-4.py-1.border.rounded-full.flex.justify-center.items-center.gap-2(
  class='hover:bg-brand-400 hover:bg-opacity-10 border'
  :class="{'border-brand font-semibold': selected}"
  @click="$emit('click', tag.id)"
)
  .rounded.h-4.w-4.border.flex.items-center.justify-center(
    :class="selected ? 'bg-brand-500 border-brand-500' : 'border-gray-300'"
  )
    .material-icons.notranslate.text-sm.text-white(v-show="selected") check
  p {{ tag.text }}
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Object,
      required: true,
    },
  },
};
</script>
