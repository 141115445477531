<template lang="pug">
Onboarding
  template(v-slot:header) 
    h4.text-2xl.text-gray-400.font-extrabold Instale o Softphone
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    p.text-gray-400.my-6 Faça o download e instale o softphone Opens no seu computador
    button.w-72.h-9.text-white.bg-orange.rounded(
      @click="openLink('https://mandrillapp.com/track/click/30311944/dl.opens.com.br?p=eyJzIjoiMFI5SVdZa1JmQml3UlZ0emFoNmtRWmh1X1cwIiwidiI6MSwicCI6IntcInVcIjozMDMxMTk0NCxcInZcIjoxLFwidXJsXCI6XCJodHRwczpcXFwvXFxcL2RsLm9wZW5zLmNvbS5iclxcXC9hcmNoaXZlc1xcXC9vcGVucy0zLjE5LjI5LmV4ZVwiLFwiaWRcIjpcIjA5ZDBiYTFjOGZhNDQxYWY5NzRjYTdkMDQzNDUxNjgxXCIsXCJ1cmxfaWRzXCI6W1wiNDhlOTUzZWUyNWFkMThmOTVhNTRkYzIzMGY1MmEwZTY4YjU2ZmFlZlwiXX0ifQ')"
    ) DOWNLOAD SOFTPHONE OPENS
    iframe.my-6.h-60(
      width="450",
      src="https://www.youtube.com/embed/zCxosRTunWk",
      title="YouTube video player",
      frameborder="0",
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
      allowfullscreen
    )
    p.text-gray-400.my-6 Após conluir a instalação, avance para o próximo passo
  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-32.text-brand(
        class="dark:invert"
        @click="askForHelp()"
      ) preciso de ajuda
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'SoftPhoneConfig')"
      ) AVANÇAR
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
import OnboardingMixin from "@/mixins/onboarding.js";
export default {
  mixins: [OpenLinkMixin, OnboardingMixin],
  components: {
    Onboarding,
  },
};
</script>
