<template lang="pug">
.h-full.w-full.flex.justify-start.items-start
  a.mt-0.bg-zinc-100.pref-side-navigator.relative.pl-4.w-56.pt-6.min-w-fit(
    class="!rounded-tl-3xl"
  )
    h1.flex.items-center.gap-1.justify-start.font-titilium.font-bold.text-xl.text-gray-700 Integrações
    a.p-side-nav-item.text-gray-700.font-plus.rounded-md.rounded-r-none.p-3.w-full.text-marker.mt-6.text-sm(
      class="dark:invert dark:bg-zinc-900 hover:bg-white",
      href="#/preferences/devices",
      role="button"
    ) Gerenciar ramais
    a.p-side-nav-item.current.bg-white.text-gray-700.font-plus.font-bold.rounded-md.rounded-r-none.p-3.border-r-4.border-brand.w-full.text-marker.mt-2.text-sm(
      class="dark:invert dark:bg-zinc-900",
      href="#/preferences/integrations",
      role="button"
    ) Integrações
  .flex.items-start.justify-start.m-0.page-area.w-full
    .header
      .titles-area
        h1.title Integrações
        .flex.gap-2
          .mt-2.rounded-md.border.border-gray-200.shadow-lg.shadow-gray-100.p-4.w-fit.flex.flex-col.items-start(v-for="partner in partners")
            img(:src="getImgUrl(partner?.img)")
            p.font-plus.text-gray-700.font-bold.mt-2 {{ partner?.title }}
            p.font-plus.text-gray-400.font-medium.mt-1.text-sm.w-56 {{ partner?.description }}
            v-button-secondary.mt-2.ml-auto(@click="openActivateRDModal = true") Ativar integração
            modal(v-if="openActivateRDModal")
              modal-body.bg-zinc-50.rounded-sm.fade-left-slide.max-w-4xl(
                class="w-3/4"
              )
                template(v-slot:header)
                  .flex.flex-col.items-center.justify-center.w-full.overflow-clip.h-32
                    h4.text-2xl.text-gray-600.font-semibold.font-inter.z-20.font-plus.mt-12 Ativando integração no RD CRM
                    .material-icons.notranslate.cursor-pointer.text-gray-500.absolute.top-8.right-8(
                      class="hover:text-red-500",
                      @click="openActivateRDModal = false"
                    ) close
                    RDHeader.fade-top-slide.absolute.-top-10.mx-auto(class="w-2/3")
                template(v-slot:body)
                  .flex.flex-col.fade-left-slide.h-full.items-start.p-4.max-h-96.overflow-auto.pt-20
                    p.font-plus.text-base.font-semibold.text-gray-700 É bem simples, só precisamos passar as informações do Opens para o RD CRM
                    .flex.flex-col.mt-4.items-start
                      p.font-plus.text-base.font-medium.text-gray-700 1 - Entre com sua conta no RD CRM e acesse a <b>Configurações do Discador</b> em perfil
                      a.ml-1(
                        href="https://crm.rdstation.com/app#/settings/profile/set-discador",
                        target="blank"
                      )
                        v-button-secondary.flex.items-center.mt-2 Configurações do Discador
                          span.material-icons.notranslate.text-brand open_in_new
                      p.font-plus.text-base.font-medium.text-gray-700.mt-4 Você vai se deparar com essa página, com algo parecido com a seguinte imagem:
                      img.mt-2.h-40.w-64.aspect-video.border.border-gray-200.shadow-lg.shadow-gray-200.rounded-md(
                        src="@/assets/images/discator_settings.png",
                      )
                      p.flex.items-center.font-plus.text-base.font-medium.text-gray-700.mt-6 2 - Preencha os respectivos campos e salve:
                      .flex.flex-col.items-start.w-fit
                        p.flex.items-center.font-plus.text-base.font-medium.text-gray-700.mt-4.gap-1 Em <b>Ramal</b> preencha: 
                        .p-2.rounded-md.border-2.border-gray-200.flex.justify-start.font-plus.text-base.font-medium.text-gray-700.w-full {{user.endpoint}}
                        p.flex.items-center.font-plus.text-base.font-medium.text-gray-700.mt-2.gap-1 Em <b>Token de acesso</b> preencha: 
                        .p-2.rounded-md.border-2.border-gray-200.flex.justify-start.font-plus.text-base.font-medium.text-gray-700.w-full {{user.token_id}}
                template(v-slot:footer)
                  .flex.w-full.gap-4.items-center.justify-center.mb-10
                    v-button-secondary(@click="openActivateRDModal = false") Cancelar
                    v-button-primary.flex.items-center.gap-2.group(
                      @click="openActivateRDModal = false"
                    ) Concluir
</template>

<script>
import PartnerCard from '@/components/Cards/PartnerCard.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButtonPrimary from '@/components/Buttons/v-button.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import RDHeader from '@/components/RDHeader.vue';

export default {
  components: {
    PartnerCard,
    VButtonSecondary,
    VButtonPrimary,
    Modal,
    ModalBody,
    RDHeader,
  },
  data() {
    return {
      openActivateRDModal: false,
      partners: [
        {
          name: 'rdcrm',
          title: 'RD CRM',
          description: 'Use o click2call registre os dados das suas ligações no RD CRM',
          img: 'RD',
        },
      ],
    };
  },
  methods: {
    getImgUrl(name) {
      var images = require.context('@/assets/images/', false, /\.png$/);
      return images('./' + name + '.png');
    },
  },
};
</script>

<style></style>
