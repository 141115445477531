<template>
  <div class="w-full m-0 content">
    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap" rel="stylesheet" />
    <div class="w-full container-fluid">
      <div class="w-full prefpages">
        <SideNav> </SideNav>
      </div>
    </div>
  </div>
</template>
<script>
import SideNav from './PrefSideNav.vue';
export default {
  title: 'Opens - Preferências',
  components: { SideNav },
};
</script>
<style>
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 0px !important;
  margin-right: auto;
  margin-left: auto;
}
.title {
  font-family: 'Titillium Web', sans-serif;
  font-weight: 900;
  font-size: 30px;
  line-height: 36px;
  color: #343a40;
  margin-bottom: 40px;
}
.subtitle {
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 24px;
  line-height: 32px;
  color: #343a40;
}
.subtitle-info {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 55px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #adb5bd;
}
.page-area {
  padding: 35px;
  padding-left: 35px;
  width: 75%;
  display: flex;
  margin-left: 22%;
  flex-direction: column;
}
.prefpages {
  display: flex;
  flex-direction: row;
}
.buttonadd {
  width: fit-content;
  padding: 0.2rem 1rem;
  font-weight: normal;
  color: #fff;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 22px;
  background-color: var(--brand-color) !important;
  border-radius: 4px;
  transition: 0.2s;
}
.buttonadd:hover {
  transform: scale(1.05);
}
.button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.thread-snep7 {
  background: #f5f5f5;
  border-radius: 4px;
  height: 56px !important;
}
.card {
  border: none;
  padding: 0px;
}
.card .card-body {
  padding: 0px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafafa !important;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: none !important;
  border-bottom: 1px solid #f8f9fa;
}
.device-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  vertical-align: middle;
  color: #495057;
}
.device-number {
  margin-left: 10px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
.status-color {
  width: 8px;
  height: 8px;
  font-size: 1px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 8px;
  background-color: var(--green-default);
}

.status-color-enabled {
  width: 8px;
  height: 8px;
  font-size: 1px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 8px;
  background-color: var(--green-default);
}

.status-color-disabled {
  width: 8px;
  height: 8px;
  font-size: 1px;
  margin-right: 8px;
  vertical-align: middle;
  display: inline-block;
  border-radius: 8px;
  background-color: #adb5bd;
}
.btn-remove {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  background: #f8f9fa;
  border-radius: 4px;
  border: none;
}
</style>
