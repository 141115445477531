<template>
  <div v-click-outside="checkDisplay" class="org">
    <input
      @input="filterOptions"
      v-model="inputModel"
      @focus="search = true"
      class="form-control input"
    />
    <div v-if="filtered.length > 0" class="org-dropdown" v-show="search">
      <li
        v-for="(data, index) in filtered"
        @click="selectData(data)"
        :key="index"
      >
        {{ data.label }}
      </li>
    </div>
  </div>
</template>

<script>
import timezones from "timezones-list";

export default {
  props: ["model"],
  data() {
    return {
      search: false,
      timezoneList: [],
      filtered: [],
      inputModel: "",
      dataSelected: false,
    };
  },
  methods: {
    filterOptions() {
      this.filtered = this.timezoneList.filter((element) => {
        return element.label.toLowerCase().indexOf(this.inputModel.toLowerCase()) > -1;
      });
      this.search = true;
    },
    checkDisplay(event) {
      if (event.target.className != "org-dropdown") {
        this.search = false;
      }
    },
    selectData(data) {
      this.dataSelected = true;
      this.inputModel = data.label;
      this.$emit("timezone-select", data);
      this.search = false;
    },
  },
  created() {
    this.inputModel = this.model;
    this.timezoneList = timezones;
    for (let i = 0; i < timezones.length; i++) {
      if (timezones[i].tzCode == this.model) {
        this.inputModel = timezones[i].label;
        break;
      }
    }
    this.filtered = this.timezoneList;
  },
};
</script>

<style lang="scss" scoped>
.org {
  display: inline-block;
  position: relative;
  width: 100%;

  &-dropdown {
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    width: 100%;
    color: #868e96;
    overflow: auto;
  }
  li {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    padding-left: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #868e96;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
  }
  li:hover {
    background: #f8f9fa;
  }
}
</style>