<template>
  <div v-if="transferCall" class="flex flex-row">
    <a class="transfer-activity-text">Em ligação</a
    ><span class="transfer-activity-text transfer-call-timer">{{
      calldate
    }}</span>
  </div>
  <div v-else class="flex flex-col">
    <a class="card-info-text bold-text" style="color: #adb5bd !important"
      >Em ligação</a
    >
    <a class="card-info-text" style="color: #adb5bd !important">{{
      calldate
    }}</a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      calldate: "",
      timer: null,
    };
  },
  props: ["transferCall"],
  created() {
    let SECONDS = 0;
    this.timer = setInterval(() => {
      SECONDS++;
      this.calldate = new Date(SECONDS * 1000).toISOString().substr(11, 8);
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.flex-row{
  flex-direction:row;
}
</style>