<template lang="pug">
.contents
  tr.border-b.font-plus.grid-cols-6.h-20(v-if="loaded")
    td.align-middle.pl-6
      .flex.items-center.justify-start.gap-2
        span.rounded-full.cursor-default.material-icons.notranslate.text-base.w-6.h-6.grid.place-items-center(:class="mapCallType[firstStep.status.toUpperCase()]")
          i.history-call-indicator-icon.material-icons.notranslate.text-white {{ "call_" + translateCallType(firstStep) }}
        span.font-inter.text-zinc-700.text-sm {{ textForCallType[translateCallType(firstStep)] }}
    td.align-middle
      span.font-inter.text-zinc-700.text-sm {{ activityDateFormat(call.createdAt)}}
    td.align-middle
      .flex.gap-2.justify-start
        .flex.w-10.h-10.bg-brand-50.rounded(v-if="failedImg") 
          .firstLetter {{ identityContact?.name[0]?.toUpperCase()}}
        img.w-10.h-10.rounded(
          v-else-if="!failedImg",
          :src="identityContact?.avatarUrl",
          @error="failedImg = true"
        )
        .flex.flex-col.justify-start.items-start
          span.font-semibold.font-inter.text-zinc-700 {{ identityContact?.name }}
          span.text-zinc-500(class="dark:text-gray-800 dark:font-semibold" v-if="identityContact?.occupation")
            | {{ identityContact.occupation }}
            | {{ identityContact?.queues ? " - " + getFirstQueueName(identityContact.queues) : "" }}
          span.text-zinc-500(class="dark:text-gray-800 dark:font-semibold" v-else-if="identityContact?.endpoint")
            | {{ identityContact?.number }}
          span.text-zinc-500(class="dark:text-gray-800 dark:font-semibold" v-else)
            | {{ identityContact?.number }}
        .user-tag.colega-tag(
          v-if="identityContact?.endpoint",
        ) Colega
        .user-tag(
          v-else-if="identityContact?.tag"
        ) {{ identityContact.tag }}
    td.align-middle
      span.font-inter.text-zinc-700.text-sm {{ callQueue }}
    td.align-middle
      span.font-inter.text-zinc-700.text-sm {{ duration }}
    td.align-middle.text-center(:width="80")
      i.material-icons.notranslate.text-zinc-400.cursor-pointer(
          v-tooltip="'Expandir atividade'"
          class="hover:text-zinc-600"
          @click="isOpened = !isOpened",
          :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'"
        ) keyboard_arrow_down
  tr(v-if="isOpened && loaded")
    call-event-detail(:call="call" :contact="identityContact")
  tr.w-full.h-20(v-if="!loaded")
    td.align-middle.px-4(v-for="n in 6" :key="n")
      content-placeholders(:rounded="true")
        content-placeholders-img.w-full.h-14

</template>

<script>
import { defineComponent } from 'vue';
import CallEventDetail from '../CallEventDetail.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';

export default defineComponent({
  mixins: [UserOrContactMixin],
  props: ['call'],
  components: {
    CallEventDetail,
  },
  data() {
    return {
      firstStep: null,
      failedImg: false,
      loaded: false,
      isOpened: false,
      mapCallType: {
        ANSWERED: 'received',
        NOANSWER: 'missed',
        ABANDONED: 'missed',
        TIMEDOUT: 'missed',
      },
      textForCallType: {
        made: 'Chamada efetuada atendida',
        received: 'Chamada recebida atendida',
        missed: 'Chamada recebida não atendida',
        missed_outgoing: 'Chamada efetuada não atendida',
      },
    };
  },
  computed: {
    duration() {
      const start = this.$moment(this.call.createdAt);
      const end = this.$moment(this.call.hungupAt);
      return this.$moment.utc(end.diff(start, 'milliseconds')).format('HH:mm:ss');
    },
    callQueue() {
      return this?.call?.steps?.find((step) => step.queue)?.queue || 'Nenhuma';
    },
  },
  methods: {
    getFirstQueueName(queues) {
      const queuesNamesArray = Object.keys(queues);
      return queuesNamesArray.find((queue) => queue !== '[object Object]') || '';
    },
    formatDuration(seconds) {
      return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : '???';
    },
    translateCallType(item) {
      const idToSearch = this.user.id;
      const fromIds = this.call.steps.map((step) => step.fromId);

      const returnIcon = {
        ANSWERED: () => {
          return fromIds.includes(idToSearch) ? 'made' : 'received';
        },
        NOANSWER: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
        ABANDONED: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
        TIMEDOUT: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
      };

      const type = returnIcon[item.status.toUpperCase()];
      return type ? type() : null;
    },
    activityDateFormat(callDate) {
      const date = this.$moment.tz(callDate, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm');
    },
    async getContact() {
      const fromId = this.firstStep.fromId;
      const isMineCall = fromId === this.user.id;
      const callerId = isMineCall ? this.firstStep.toId : fromId;
      const callerNumber = isMineCall ? this.firstStep.toNumber : this.firstStep.fromNumber;

      await this.setContactOrOperator({
        id: callerId,
        number: callerNumber,
      });
    },
  },
  async created() {
    try {
      if (this.call.steps[0].status === 'timedout') {
        this.firstStep = this.call.steps.length > 1 ? this.call.steps[1] : this.call.steps[0];
      } else {
        this.firstStep = this.call.steps[0];
      }

      await this.getContact();
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded = true;
    }
  },
});
</script>
