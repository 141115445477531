<template lang="pug">
.flex.w-full.justify-between.items-center
  .img-name
    .img-status-aligner.w-12
      div.z-10(:class="'status-indicator ' + translateStatus[status]")
      .flex.items-center.justify-center.mr-2
        .flex.items-center.h-10.w-10(
          v-if='myUser.username && myUser.invalid'
          class="!bg-brand-50 !rounded-md"
        )
          .firstLetter.px-4 {{ myUser.username[0].toUpperCase() }}
        img.rounded.h-9.w-9.min-w-fit.object-cover.aspect-square(
          v-else-if='!myUser.invalid && myUser.id',
          :src='`${this.requestLinks.cservice}/avatar/${myUser.id}.png`',
          @error='myUser.invalid = true; $forceUpdate();'
        )
    .flex.flex-col.items-start
      .flex.items-center.gap-2
        span.text-gray-900.font-plus.text-sm.font-semibold {{ myUser.username }}
        v-dropdown(placement="bottom")
          div
            span.cursor-pointer.material-icons.notranslate.text-brand.text-lg(v-tooltip="'Em chamada com ' + myUser.callnumber" v-if="translateStatus[status] == 'Ocupado' && (user.profile == 'p_manager' || user.profile == 'p_admin')") call
          template(#popper)
            ul(class="w-full divide-y")
              dropdown-item(@click="listenCallModal = true" class="transition-all active:scale-90" label="Escutar conversa" icon="e023")
              dropdown-item(@click="whisperCallModal = true" class="transition-all active:scale-90" label="Sussurrar conversa" icon="e311")
      .flex.gap-1
        span.text-gray-400.font-plus.text-xs Ramal
        span.font-semibold.text-gray-500.text-xs {{ myUser.endpoint }}
  .flex.flex-col.items-end(v-if="myUser.pause && translateStatus[status] === 'EmPausa'", :pausetime='timestamp')
    p.text-xs.text-gray-500 {{ statusText }} {{ timestamp }}
    p.text-xs.text-brand(class='dark:invert') {{ myUser.pause }}

  modal(v-if="listenCallModal")
    modal-body(class="w-[30rem]")
      template(v-slot:header)
        .flex
          h4.text-2xl.text-gray-500.font-semibold.font-inter Escutar conversa
          .material-icons.notranslate.ml-auto.cursor-pointer(@click="listenCallModal = false") close
      template(v-slot:body)
        .flex.gap-4.flex-col.items-start
          span.font-inter.text-sm.text-gray-400.font-medium
            | Tem certeza que deseja escutar a conversa entre o atendente
            span.text-gray-600
              | {{ myUser.username }}
            span
              | e
            span.text-gray-600
              | {{ myUser.callnumber }}?
          span.font-inter.text-sm.text-gray-400.font-medium O atendente não irá receber uma notificação
      template(v-slot:footer)
        .flex.gap-4
          v-button-primary(@click="spyCall(false)") Confirmar
          v-button-secondary(@click="listenCallModal = false") Cancelar

  modal(v-if="whisperCallModal")
    modal-body(class="w-[30rem]")
      template(v-slot:header)
        .flex
          h4.text-2xl.text-gray-500.font-semibold.font-inter Sussurrar conversa
          .material-icons.notranslate.ml-auto.cursor-pointer(@click="whisperCallModal = false") close
      template(v-slot:body)
        .flex.gap-4.flex-col.items-start
          span.font-inter.text-sm.text-gray-400.font-medium
            | Tem certeza que deseja sussurrar a conversa entre o atendente
            span.text-gray-600
              | {{ myUser.username }}
            span
              | e
            span.text-gray-600
              | {{ myUser.callnumber }}?
          span.font-inter.text-sm.text-gray-400.font-medium O atendente irá receber uma notificação
      template(v-slot:footer)
        .flex.gap-4
          v-button-primary(@click="spyCall(true)") Confirmar
          v-button-secondary(@click="whisperCallModal = false") Cancelar

</template>

<script>
import eventBus from '../../main-event-bus';
import csService from '@/services/cservice.js';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import Modal from '@/components/Modals/Modal.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButtonPrimary from '@/components/Buttons/v-button.vue';
import { spyCall } from '@/services/cservice';

export default {
  props: ['agent', 'index', 'queue'],
  data() {
    return {
      whisperCallModal: false,
      listenCallModal: false,
      myUser: {},
      isDeviceOnline: true,
      amOnline: false,
      currentPayload: null,
      socketMessage: {},
      schedule: [],
      timestamp: '???',
      statusText: '',
      init: true,
      status: null,
      statusTemp: 504,
      isAuto: false,
      state: {
        AUTO: 10,
        CONECTADO: 100,
        DISPONIVEL: 101,
        AGENTE_DISPONIVEL: 102,
        NAO_PERTURBE: 202,
        EM_PAUSA: 303,
        FIM_PAUSA: 304,
        EM_CHAMADA: 404,
        FIM_CHAMADA: 405,
        OFFLINE: 504,
      },
      translateStatus: {
        101: 'Disponivel',
        102: 'Disponivel',
        202: 'NaoPerturbe',
        303: 'EmPausa',
        404: 'Ocupado',
        504: 'Offline',
      },
      translateCServiceStatus: {
        enabled: 101,
        disabled: 202,
        online: 101,
        auto: 10,
      },
      portugueseTranslate: {
        NaoPerturbe: 'Não Perturbe',
        EmPausa: 'Em pausa',
        Offline: 'Offline',
        Ocupado: 'Ocupado',
        Disponivel: 'Disponível',
      },
      translateWeekDayMapReverse: {
        0: 'dom',
        1: 'seg',
        2: 'ter',
        3: 'qua',
        4: 'qui',
        5: 'sex',
        6: 'sab',
      },
      translateStatusGraph: {
        101: 0,
        102: 0,
        202: 2,
        303: 2,
        404: 1,
        504: 3,
      },
      clockStart: null,
      clockEnd: null,
      statusToTextIndex: {
        100: 'Disponivel há:',
        101: 'Disponivel há:',
        102: 'Disponivel há:',
        202: 'Em pausa há:',
        303: 'Em pausa há:',
        404: 'Ocupado há:',
        403: 'Chamando há:',
        504: 'Offline há:',
      },
    };
  },
  components: {
    VDropdown: Dropdown,
    DropdownItem,
    Modal,
    ModalBody,
    VButtonPrimary,
    VButtonSecondary,
  },
  methods: {
    async spyCall(isWhisper) {
      this.listenCallModal = false;
      this.whisperCallModal = false;
      try {
        await this.startSpyCall(this.myUser.endpoint, this.user.endpoint, isWhisper);
      } catch (error) {
        console.log(error);
        if (isWhisper) return this.$toast.error('Erro ao tentar sussurrar está chamada');
        this.$toast.error('Erro ao tentar escutar está chamada');
      }
    },
    async statusListener(user) {
      try {
        await this.updateUser(user);
      } catch (e) {
        this.$log.error(e);
      }
    },
    async peerStatusListener(payload) {
      if (payload.online) return;
      this.isDeviceOnline = false;
      this.statusTemp = this.state.OFFLINE;
    },
    checkCall() {
      const num = this.myUser.endpoint || this.myUser.phones[0].number;
      if (this.isTransfer) {
        this.$emit('calling', { contact: this.myUser, num: num });
      } else {
        if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
        else this.callNumber(num);
      }
    },
    socketConfig() {
      eventBus.$on(`restart-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`status-agent-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`peerStatus-${this.myUser.endpoint}`, this.peerStatusListener);
      eventBus.$on(`device-${this.myUser.id}`, this.statusListener);
      eventBus.$on(`updateWorkSchedule-${this.myUser.id}`, this.statusListener);
    },
    // SEPARA HORARIOS DE TRABALHO PELO DIA DE HJ EM UMA UNICA PILHA EM ORDEM CRESCENTE
    parseWorkSchedule() {
      if (!this.myUser.users_schedules) {
        this.schedule = [];
        return;
      }
      if (!this.myUser.users_schedules.length) {
        this.schedule = [];
        return;
      }

      const schedules = this.parseWorkScheduleDays(this.myUser.users_schedules);
      const stack = this.parseWorkScheduleHours(schedules);
      this.schedule = stack;
    },
    parseWorkScheduleDays(myUserSchedules) {
      const currentDay = this.translateWeekDayMapReverse[new Date().getDay()];
      const horarios = myUserSchedules.filter((schedule) => {
        if (schedule.day.includes(currentDay) && schedule.enabled) return true;
        return false;
      });
      return horarios;
    },
    parseWorkScheduleHours(range) {
      const stack = [];
      const d = new Date();
      const hour = (d.getHours() * 60 + d.getMinutes()) * 60 * 1000;

      range.forEach((day) => {
        let startTime = parseInt(day.start_time.split(':')[0]) * 60 + parseInt(day.start_time.split(':')[1]);

        let endTime = parseInt(day.end_time.split(':')[0]) * 60 + parseInt(day.end_time.split(':')[1]);

        const schedule = {
          start: startTime * 60 * 1000,
          end: endTime * 60 * 1000,
        };

        if (hour <= schedule.end) {
          const i = this.sortedIndex(stack, schedule.start);
          stack.splice(i, 0, schedule);
        }
      });
      return stack;
    },
    sortedIndex(arr, val) {
      var low = 0,
        high = arr.length;

      while (low < high) {
        var mid = (low + high) >>> 1;
        if (arr[mid]['start'] < val) low = mid + 1;
        else high = mid;
      }
      return low;
    },
    async verifyUserStatus() {
      if (!this.myUser) return;
      if (this.myUser.oncall) {
        this.status = 404;
        this.statusTemp = this.state.EM_CHAMADA;
      } else if (this.myUser.pause) {
        this.statusTemp = this.state.EM_PAUSA;
      } else if (this.myUser.state == 'auto') {
        this.statusTemp = this.state.AUTO;
      } else {
        if (this.myUser.state) {
          const initStatus = this.translateCServiceStatus[this.myUser.state];
          if (this.myUser.profile == 'p_agent') {
            await this.checkOnlineStatus();
          }
          if (!this.myUser.endpoint) this.statusTemp = this.state.OFFLINE;
          else if (this.myUser.profile == 'p_agent' && !this.amOnline) this.statusTemp = this.state.OFFLINE;
          else if (this.myUser.profile == 'p_agent' && this.myUser.pause) this.statusTemp = this.state.EM_PAUSA;
          else this.statusTemp = initStatus;
        } else {
          this.statusTemp = this.state.OFFLINE;
        }
      }
    },
    async updateUser(user) {
      try {
        this.myUser = user;

        // verifica se endpoint esta online ou não
        const cstRes = await this.$http.get(`${this.requestLinks.cstate}/online/device/${this.myUser.endpoint}`);
        if (cstRes.data.status) this.status = 101;
        this.isDeviceOnline = cstRes.data.status;
      } catch (e) {
      } finally {
        this.verifyUserStatus();
      }
    },
    async checkOnlineStatus() {
      try {
        const res = await this.$http(`${this.requestLinks.cstate}/online/${this.myUser.id}`);
        this.amOnline = res.data;
      } catch (e) {
        return false;
      }
    },
    async startClock() {
      try {
        clearInterval(this.clock);
        let start = this.$moment().tz(this.myUser.timezone);
        const isLastEventTimeValidDate = this.$moment(
          this.myUser.last_event_time,
          this.$moment.ISO_8601,
          true,
        ).isValid();
        start = isLastEventTimeValidDate
          ? this.$moment.tz(this.myUser.last_event_time, this.myUser.timezone)
          : this.$moment().tz(this.myUser.timezone);
        this.clock = setInterval(() => {
          const end = this.$moment().tz(this.myUser.timezone);
          const diff = end.diff(start);
          const diffAsDays = Math.floor(this.$moment.duration(diff).asDays());
          if (diffAsDays <= 0) this.timestamp = this.$moment.utc(diff).format('HH:mm:ss');
          else this.timestamp = this.$moment.utc(diff).format('D[d] h[h] m[m]');
        }, 1000);
      } catch (e) {}
    },
  },
  watch: {
    isDeviceOnline(value) {
      if (!value) return;
      switch (this.status) {
        case this.state.OFFLINE:
          {
            this.$emit('offline', this.index);
          }
          return;
        case this.state.DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.A_DISPONIVEL:
          {
            this.$emit('online', this.index);
          }
          return;
        case this.state.EM_PAUSA:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE:
          {
            this.$emit('paused', this.index);
          }
          return;
        case this.state.NAO_PERTURBE: {
          {
            this.$emit('busy', this.index);
          }
          return;
        }
      }
    },
    status(n, old) {
      this.statusText = this.statusToTextIndex[n];
      this.$emit('updateChart', {
        add: this.translateStatusGraph[n],
        remove: this.translateStatusGraph[old],
      });
      this.$store.commit('cstate/UPDATE_QUEUE_WORKER', {
        queue: this.queue,
        memberIndex: this.index,
        name: this.myUser.username,
        status: n,
      });
      this.startClock();
    },
    statusTemp(currentStatusValue) {
      if (!this.isDeviceOnline) this.status = this.state.OFFLINE;
      else if (!this.myUser.endpoint) this.status = this.state.OFFLINE;
      else this.status = currentStatusValue;
    },
  },
  computed: {
    hasActiveDevice() {
      return this.$store.getters['user/HAS_ACTIVE_DEVICE'];
    },
    isVisible() {
      // o prop "search" nao existe ou esta vazio; sempre visivel
      if (!this.search || this.search.length === 0) return true;
      // caso contrario, aplica os filtros e retorna falso sempre que os filtros falharem
      else {
        // filtro de nome;
        if (this.myUser.username.toLowerCase().startsWith(this.search.toLowerCase())) return true;
        // filtro por device
        if (this.myUser.endpoint && this.myUser.endpoint.startsWith(this.search)) return true;
        return false;
      }
    },
  },
  async mounted() {
    try {
      const { users_devices } = await csService.getUserByPeer({
        peer: this.agent.agent,
        company_id: this.user.company_id,
      });
      this.myUser = users_devices;
      const cstRes = await this.$http.get(`${this.requestLinks.cstate}/online/device/${this.myUser.endpoint}`);
      if (cstRes.data.status) this.status = 101;
      this.isDeviceOnline = cstRes.data.status;
      this.verifyUserStatus();
      this.socketConfig();
    } catch (e) {
      this.$log.error(e);
    }
  },
  async created() {
    try {
    } catch (error) {}
  },
  beforeDestroy() {
    this.$emit('updateChart', {
      add: null,
      remove: this.translateStatusGraph[this.status],
    });
    clearInterval(this.clock);
    eventBus.$off(`restart-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`status-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`status-agent-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`peerStatus-${this.myUser.endpoint}`, this.peerStatusListener);
    eventBus.$off(`device-${this.myUser.id}`, this.statusListener);
    eventBus.$off(`updateWorkSchedule-${this.myUser.id}`, this.statusListener);
  },
};
</script>

<style scoped>
.card-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 65px;
  border: 1px solid #e9ecef;
  margin: 10px 0;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.img-status-aligner {
  position: relative;
}

.img-name {
  display: flex;
  align-items: center;
}

.small-user-img {
  width: 32px;
  margin-right: 8px;
  height: 32px;
  border-radius: 4px;
  background: #495057;
}

.Online {
  background: var(--green-default) !important;
}

.Offline {
  background: #868e96 !important;
}

.Em {
  background: #f8a354 !important;
}

.status-indicator {
  width: 9px;
  height: 9px;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 78%;
  left: 65%;
}

.username-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}
</style>
