<template lang="pug">
dropdown(@hide="showAllReactions = false", @show="showAllReactions = true")
  .flex.rounded-full.bg-zinc-100.border.cursor-pointer.border.px-2.flex.items-center.justify-center.select-none.transition-all(
    class="hover:bg-white hover:border-zinc-500 dark:invert dark:bg-zinc-900 dark:border-zinc-500"
  )
      span.text-sm.font-noto-emoji {{ reactions[0].emoji }}
  template(#popper)
    .flex.flex-col.items-center.px-4.py-2(v-if="showAllReactions")
      div(v-if="!loadingMembersIdentity")
        .flex.flex-row.items-center.gap-2(v-for="reaction in reactions", :key="reaction?.id")
          avatar.w-8.h-8.border-brand(
            :src="reaction?.avatar"
            :key="reaction.id",
            :avatar-id="`${reaction.recipientId}`"
          )
          .flex.flex-col.gap-1.items-start
            span.text-sm.text-gray-400 {{ reaction?.username }}
            span.text-xs.text-gray-400 {{ $moment(reaction?.updatedAt).format('DD/MM/yyyy [-] HH:mm') }}
          span.text-2xl.font-noto-emoji.ml-2(class="dark:invert") {{ reaction?.emoji }}
      div.w-40(v-else)
        content-placeholders.flex.items-start.gap-2(v-for="item in reactions.length" :key="item.id")
          content-placeholders-img(class='w-8 h-8 rounded-full')
          content-placeholders-text(:lines="2" class='w-20')
          content-placeholders-img.ml-2(class='w-8 h-8 rounded-full')

</template>

<script>
import { defineComponent } from 'vue';
import { Dropdown } from 'floating-vue';
import { getContactById } from '@/services/contact-list';
import { getUserById } from '@/services/cservice';
import Avatar from '../Avatars/avatar.vue';

export default defineComponent({
  name: 'ChatMessageReactions',
  components: {
    Dropdown,
    Avatar,
  },

  props: {
    reactions: {
      type: Array,
      required: true,
    },
    members: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      showAllReactions: false,
      loadingMembersIdentity: true,
    };
  },

  computed: {
    roomMembers() {
      return this?.members || this.$store.getters['chat/activeRoom']?.room?.members;
    },
  },

  methods: {
    async extractMembersIdentity() {
      try {
        this.loadingMembersIdentity = true;
        await Promise.all(
          this.reactions.map(async (reaction) => {
            const member = this.roomMembers.find((item) => item.id === reaction.chatRoomMemberId);
            if (!member) return;
            if (member.provider === 'opens') {
              const user = await getUserById(member.recipientId);
              reaction.username = user.username;
              reaction.avatar = `${this.requestLinks.cservice}/avatar/${member.recipientId}.png`;
            } else {
              const contact = await getContactById(member.recipientId);
              reaction.username = contact.name;
              reaction.avatar = `${this.requestLinks.clist}/uploads/${member.recipientId}.jpg`;
            }
          }),
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingMembersIdentity = false;
      }
    },
  },

  watch: {
    showAllReactions: {
      async handler() {
        if (this.showAllReactions) {
          await this.extractMembersIdentity();
        }
      },
      immediate: false,
    },
  },
});
</script>
