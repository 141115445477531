<template lang="pug">
//- .transfer-box(id="transferBox")
//-   .horizontal-aligner
//-     .transfer-left-aligner
//-       a.card-ingo-text.text-sm Transferindo Ligação
//-     .trasnfer-right-aligner
//-       a.cancel-transfer(v-on:click="cancelTransfer()" role="button") Cancelar
//-   .input-icons(v-if="!isCalling")
//-     i.fa.fa-search-icon
//-     input.search-input.form-control(id="inputTransfer" @input="filterMates" type="text" v-model="inputValue" placeholder="Buscar colega")
//-   .transfer-search.scroll-style(v-if="!isCalling" class="h-[52vh]" id="colegaId")
//-     mate-card(v-for="(mate, indes) in filteredMates" :mate="mate" :index="index" :socket="$store.getters['cstate/GET_SOCKET']" :key="mate.id" ref="colegaDiv" @calling="setTransfering" :isTransfer="true")
//-     unknown-mate-card(v-if="unknown" :inputValue="inputValue" @calling="setTransfering" ref="colegaDiv" :isTransfer="true")
//-   .transfer-search.scroll-style(v-if="isCalling" class="h-[64vh]" id="colegaId")
//-     mate-card(v-if="transferContact != 'unknown'" :mate="transferContact" :index="0" :key="0" :socket="$store.getters['cstate/GET_SOCKET']" ref="colegaDiv" :isTransfer="true")
//-     unknown-mate-card(v-if="transferContact == 'unknown'" :inputValue="inputValue" :isTransfer="true" ref="colegaDiv")
//-     .transfer-icon-aligner
//-       .transfer-activity-text(v-if="!transferred") Chamando
//-       call-duration(v-else :transferCall="true" :startDate="callDate")
//-       .right-icon(@click="hangUpTransfering" class="w-1/2")
//-         .flex.items-center(class="h-[90px]")
//-           .turnoff-call-icon
//-             span.material-icons.notranslate.text-2xl phone
//-         a.card-info-text(class="text-[#adb5bd]") Desligar

.flex.w-full.flex-1.bg-gray-50.mt-2.rounded-md.items-start.flex-col.scale-up-ver-center.overflow-auto(id="transferBox")
  .flex.w-full.justify-start.bg-gray-200.p-3.rounded-t-md
    p.font-plus.font-semibold Transferir ligação
    .material-icons.notranslate.ml-auto.text-gray-400.cursor-pointer(
      @click="cancelTransfer"
      class="transition-all hover:text-red-500 active:scale-90"
      v-tooltip="'Fechar'"
    ) close
  .flex.w-full.flex-1.p-2.justify-start.flex-col.gap-2.overflow-auto.w-full(v-if="!isCalling")
    .w-full
      icon-input(
        :label="'Buscar'"
        v-model="inputValue"
      )
    .flex.flex-col.justify-start.overflow-y-scroll.overflow-x-hidden.w-full
      mate-card(
        v-for="(mate, index) in filteredMates"
        :mate="mate"
        :index="index"
        :socket="$store.getters['cstate/GET_SOCKET']"
        :key="mate.id" ref="colegaDiv"
        :isTransfer="true"
        @calling="setTransfering"
      )
      dialpad-contacts-card(
        v-if="inputValue.length > 0"
        v-for="(contact, index) in contacts",
        @calling="setTransfering"
        :isTransfer="true"
        :key="index",
        :contact="contact"
      )
      unknown-mate-card(
        v-if="unknown"
        :inputValue="inputValue"
        @callingUnkown="setTransferingUnkown"
        ref="colegaDiv"
        :isTransfer="true"
      )
  .flex.w-full.flex-1.p-2.justify-start.flex-col.gap-2.overflow-auto.w-full(v-else)
    mate-card(
      v-if="transferContact != 'unknown' && filteredMates.length > 0"
      :mate="transferContact"
      :index="0"
      :key="0"
      :socket="$store.getters['cstate/GET_SOCKET']"
      ref="colegaDiv"
      :isTransfer="true"
    )
    dialpad-contacts-card(
      v-if="transferContact != 'unknown'"
      @calling="setTransfering"
      :isTransfer="true"
      :key="index",
      :contact="transferContact"
    )
    unknown-mate-card(
      v-if="transferContact == 'unknown'"
      :inputValue="inputValue"
      :isTransfer="true"
      ref="colegaDiv"
    )
    .transfer-icon-aligner.gap-2
      .transfer-activity-text(v-if="!transferred") Chamando
      call-duration(
        v-else
        :transferCall="true"
        :startDate="callDate"
      )
      .right-icon(
        @click="hangUpTransfering"
        class="w-1/2"
      )
        .flex.items-center(class="transition-all hover:opacity-50 active:scale-90")
          .turnoff-call-icon
            .material-icons.notranslate.text-2xl.text-white phone
        a.card-info-text(class="text-[#adb5bd]") Desligar
</template>

<script>
import MateCard from '@/components/Cards/MateCard';
import UnknownMateCard from '@/components/Cards/UnknownMateCard.vue';
import DialpadContactsCard from '@/components/Cards/dialpad-contacts-card.vue';
import CallDuration from '@/components/CallDisplays/call.duration.vue';
import SipMixin from '@/mixins/invite-calls.js';
import IconInput from '@/components/Inputs/IconInput.vue';
import cservice from '@/services/cservice';
import { getContactsByIdentifier } from '@/services/contact-list';
import EventBus from '@/main-event-bus';
import { debounce } from '@/helpers';
import csService from '@/services/cservice.js';

export default {
  mixins: [SipMixin],
  props: ['extra'],
  components: {
    MateCard,
    UnknownMateCard,
    CallDuration,
    DialpadContactsCard,
    IconInput,
  },
  data() {
    return {
      socket: {},
      filteredMates: [],
      contacts: [],
      inputValue: '',
      transferContact: {},
      unknown: false,
      isCalling: false,
      transferred: false,
      callDate: null,
    };
  },
  watch: {
    extraCall(newValue) {
      this.transferred = true;
      this.callDate = newValue.calldate;
    },
    inputValue(newValue) {
      this.filterMates();
      this.getContacts(newValue);
    },
  },
  methods: {
    getContacts: debounce(async function (filterString, { limit = 8, offset = 0 } = {}) {
      try {
        const { contacts, hasNextPage, hasPreviousPage, totalItems, total_count } = await getContactsByIdentifier({
          filter: filterString,
          limit,
          offset,
        });

        this.contacts = contacts;

        this.paginationOptions = {
          ...this.paginationOptions,
          hasNextPage,
          hasPreviousPage,
          totalItems,
          total_count,
        };
      } catch (error) {
        this.$toast.error('Erro ao buscar contatos');
      } finally {
        this.loading = false;
      }
    }, 500),
    filterMates() {
      this.filteredMates = this.searchFunction(this.mates, this.inputValue);
      if (this.filteredMates.length < 1) {
        this.unknown = true;
      } else {
        this.unknown = false;
      }
    },
    cancelTransfer() {
      this.$emit('close-down');
    },
    async hangUpTransfering() {
      await this._hangUpCall();
      this.transferContact = {};
    },
    async setTransfering({ contact, num }) {
      try {
        if (contact?.pause)
          return this.$toast.warning('Não é possível transferir para este operador. Operador entrou em pausa');
        this.attendTransfer(num);
        EventBus.$emit('mixpanel-event', { eventName: 'transfer-call', payload: {} });
        this.isCalling = true;
        this.transferContact = contact;
      } catch (e) {
        if (e.response.status == 401) {
          window.location.pathname = '/auth/login';
        }
      }
    },
    async setTransferingUnkown(contactNumber) {
      if (!Number(contactNumber)) return this.$toast.warning('Insira um número válido');
      try {
        if (contactNumber.length < 8) {
          const { users_devices } = await csService.getUserByPeer({
            peer: contactNumber,
            company_id: this.user.company_id,
          });
          if (users_devices?.pause)
            return this.$toast.warning('Não é possível transferir para este operador. Operador entrou em pausa');
        }
        this.attendTransfer(contactNumber);
        this.isCalling = true;
        this.transferContact = user_device || contactNumber;
      } catch (e) {}
    },
  },
  computed: {
    mates() {
      return this.$store.getters['GET_COMPANYMATES'];
    },
    extraCall() {
      return this.extra;
    },
  },
  async created() {
    const users = await cservice.getAllUsers({ status: 'activated', withoutOpensUsers: true });
    this.filteredMates = users;
    this.socket = this.$store.getters['cstate/GET_SOCKET'];
    this.socket.on('cancel-transfer', () => {
      this.cancelTransfer();
    });
  },
  beforeDestroy() {
    this.socket.off('cancel-transfer');
  },
};
</script>

<style>
.transfer-call-timer {
  font-weight: 700 !important;
  margin-left: 3px;
}

.mates-heigth {
  height: calc(100% - 400px);
}

.scale-up-ver-center {
  -webkit-animation: scale-up-ver-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-center 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-9-26 17:53:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
@keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
</style>
