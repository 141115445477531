<template>
  <div class="icon">
    <i
      @click.stop="$emit('clicked')"
      @mouseenter="showTooltip=true"
      @mouseleave="showTooltip=false"
      class="material-icons notranslate transition select-none hover:text-gray-500"
      :id="item.name + 'icon'"
      >{{icon}}</i
    >
    <p v-if="showTooltip" class="icon__tooltip">{{text}}</p>
  </div>
</template>

<script>
export default {
  props: ["item", "icon", "text"],
  data() {
    return {
      showTooltip: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.material-icons {
  color: #adb5bd;
  margin: 5px;
  box-sizing: border-box;
  cursor: pointer;
}

.icon {
  position: relative;
  &__tooltip {
    color: #fafafa;
    position: absolute;
    background-color: #2b2b2b9d;
    font-size: 11px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    margin-top: -5px;
    padding: 3px;
    box-sizing: border-box;
    z-index: 2;
  }
}
</style>