<template lang="pug">
div
  addContactOverlay(
    class="dark:invert"
    v-if="showAddContactOverlay"
    :is-overlay="true"
    :overlay-contact="overlayContact"
    @close-overlay="showAddContactOverlay = false"
  )
  modal(v-if="showSelectContactModal", @click="showSelectContactModal = false")
    modal-body
      template(v-slot:header)
        p.font-bold.text-xl.font-plus.text-zinc-700 Selecione um contato.
        p.text-sm.text-zinc-500 Esse contato possui números de telefones cadastrados em mais de um registro
      template(v-slot:body)
        .flex.w-ful.relative.mb-2(
          v-for="(phone, index) in selectContactModalData",
          :key="index"
        )
          client-card.w-full.border.rounded.border-brand-50.transition-all(
            class="hover:border-brand dark:hover:border-inverted-brand"
            :class="{ 'border-brand-300 border dark:border-inverted-brand-300': selectContactModalSelectedContactIndex === index }",
            :contact="phone.contactRegistered.contact"
            @click="handleSelectContactToStartChat(phone.contactRegistered.contact, index)"
          )
          .flex.h-full.absolute.w-10.right-0.flex.bg-transparent.pointer-events-none.transition-all
            i.material-icons.notranslate.text-sm.text-white.h-6.w-6.border.border-2.flex.items-center.justify-center.cursor-pointer.pointer-events-none.bg-brand.rounded-full(
              class="dark:invert"
              v-if="selectContactModalSelectedContactIndex === index"
            ) check
      template(v-slot:footer)
        .flex.w-full.items-start.justify-center.gap-4
          v-button-secondary(
            @click="handleCancelSelectContactModal()"
          ) Cancelar
          .flex(
            v-tooltip="selectContactModalSelectedContactIndex === null && 'Selecione um contato para iniciar uma conversa.'"
          )
            v-button-primary(
              :disabled="selectContactModalSelectedContactIndex === null"
              @click="startChat(selectContactModalSelectedContact)"
            ) Iniciar conversa

  dropdown.w-80(
    v-for="contact in contacts"
    class="mb-2 last:mb-0"
    theme="menu"
    :delay="0"
    :triggers="['click', 'focus', 'touch']"
    :popperTriggers="[]"
    :key="contact.id"
    auto-size
    strategy="fixed"
  )
    template(#popper)
      .flex.flex-col.items-start.gap-2.p-2.bg-white.font-plus
        h1.w-full.font-bold.text-zinc-700.font-plus.text-lg.select-none Contato
        .flex.flex-col.w-full.rounded.bg-brand-50(class="dark:invert")
          .flex.p-2.px-3.justify-start.bg-brand-gradient.w-full.rounded-t
            i.material-icons.notranslate.mr-2.bg-brand-200.rounded-full.p-1.text-brand-200.select-none(
              class="dark:invert dark:text-inverted-brand-200"
            ) person
            p.font-plus.font-medium.truncate.text-white(class="selection:bg-brand-150 dark:invert-0") {{ contact.name }}
          .flex.w-full.justify-end.py-1
            i.material-icons.notranslate.mr-2.bg-brand-200.rounded-full.p-1.text-brand-200.select-none.cursor-pointer.transition-all.duration-200(
              class="active:scale-90 dark:text-brand-200 dark:bg-brand-100 dark:hover:bg-brand-150 dark:hover:text-brand hover:bg-brand-150 hover:text-brand"
              v-tooltip="'Adicionar contato'"
              v-close-popper
              v-if="!isRegistered(contact)",
              @click="openAddContactOverlay(contact)"
            ) person_add
            i.material-icons.notranslate.mr-2.bg-brand-200.rounded-full.p-1.text-brand-200.select-none.cursor-pointer.transition-all.transition-all.duration-200(
              v-close-popper
              v-tooltip="'Iniciar conversa'"
              v-if="isRegistered(contact)",
              class="active:scale-90 dark:text-brand-200 dark:bg-brand-100 dark:hover:bg-brand-150 dark:hover:text-brand hover:bg-brand-150 hover:text-brand"
              @click="handleStartChat(contact)"
            ) chat
        section.scroll-brand.scrollbar-w-1.max-h-48.overflow-y-auto.w-full.mt-2
          .flex.flex-col.items-start.w-full.justify-center(
            v-if="contact?.phones?.length"
            class="dark:invert"
          )
            .select-none.text-zinc-700.font-plus.font-semibold Números
            .flex.w-full(v-for="(number, index) in contact.phones")
              v-tooltip
                template(#popper)
                  div.text-xs
                    p O numero {{ number.number }} está cadastrado como {{ number.contactRegistered?.contact.name }}
                    .flex.text-zinc-400.justify-start.font-semibold Clique para ver o contato
                i.material-icons.notranslate.bg-brand-200.rounded-full.h-4.w-4.text-brand-300.select-none.text-xs.flex.items-center.justify-center.animate-pulse.cursor-pointer(
                  class="dark:invert"
                  v-if="number.hasContactRegistered"
                  @click="$router.push({ name: 'ContactDetail', params: { id: number.contactRegistered?.contact.id } })"
                ) info
              span.text-zinc-600.text-xs.font-plus.p-2.transition-colors.w-full.rounded.flex.items-center.justify-between.group {{ number.number }}
                i.material-icons.notranslate.rounded-full.h-6.w-6.text-green-500.select-none.text-lg.cursor-pointer.bg-gray-50.flex.justify-center.items-center(
                  @click="call(number.number)"
                  class="dark:invert dark:bg-gray-900"
                  v-tooltip="'Ligar para esse número'"
                  v-close-popper
                ) phone
          .flex(v-else)
            span.text-zinc-600.text-xs.font-plus.p-2.transition-colors.w-full.rounded Contato sem número
          .flex.flex-col.items-start.w-full.justify-center.mt-6(
            v-if="contact?.emails?.length"
            class="dark:invert"
          )
            .select-none.text-zinc-700.font-plus.font-semibold Emails
            .flex.w-full(v-for="(email, index) in contact.emails")
              span.text-zinc-600.text-xs.font-plus.p-2.transition-colors.w-full.rounded {{ email.email }}

    .flex.bg-zinc-300.p-2.px-3.justify-start.rounded.cursor-pointer.w-80(class="dark:bg-zinc-900")
      i.material-icons.notranslate.mr-2.bg-zinc-200.rounded-full.p-1.text-brand-200(class="dark:bg-zinc-800") person
      p.font-plus.font-medium.truncate(
        v-tooltip="contact.name"
        class="dark:text-zinc-300"
      ) {{ contact.name }}
</template>

<script lang="js">
import { Dropdown } from 'floating-vue';
import addContactOverlay from '../Overlay/add-contact.overlay.vue';
import { getContactByPhone } from '@/services/contact-list';
import { parsePhoneNumber } from '@/utils/google-libphonenumber.util';
import { defineComponent } from 'vue';
import Modal from "@/components/Modals/Modal.vue";
import ModalBody from "@/components/Modals/ModalBody.vue";
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import ClientCard from '@/components/Cards/ClientCard.vue';
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import VButtonPrimary from '@/components/Buttons/v-button.vue';


export default defineComponent({
  name: "contacts",

  components: {
    Dropdown,
    addContactOverlay,
    ModalBodyNoFooter,
    Modal,
    ModalBody,
    VButtonPrimary,
    VButtonSecondary,
    ClientCard,
  },

  data() {
    return {
      contacts: [],
      showSelectContactModal: false,
      selectContactModalSelectedContact: null,
      selectContactModalSelectedContactIndex: null,
      selectContactModalData: null,
      showAddContactOverlay: false,
      overlayContact: {},
    };
  },

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  methods: {
    async startChat(contactRegistered) {
      if(this.showSelectContactModal) {
        this.handleCancelSelectContactModal();
      }
      this.$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: contactRegistered } });
    },

    handleCancelSelectContactModal() {
      this.showSelectContactModal = false;
      this.selectContactModalSelectedContact = null;
      this.selectContactModalSelectedContactIndex = null;
      this.selectContactModalData = null
    },

    handleSelectContactToStartChat(contact, index) {
      this.selectContactModalSelectedContact = contact;
      this.selectContactModalSelectedContactIndex = index;
    },

    handleStartChat(contact) {
      if (this.hasMoreOneContactRegistered(contact)) {
        this.selectContactModalData = contact.phones.filter(phone => phone.hasContactRegistered);
        this.showSelectContactModal = true;
        return;
      }

      this.startChat(this.getContactRegistered(contact));
    },

    isRegistered(contact) {
      return contact?.phones?.some(phone => phone.hasContactRegistered);
    },

    hasMoreOneContactRegistered(contact) {
      return contact?.phones?.filter(phone => phone.hasContactRegistered).length > 1;
    },

    getContactRegistered(contact) {
      return contact?.phones.find(phone => phone.hasContactRegistered)?.contactRegistered.contact;
    },

    openAddContactOverlay(contact) {
      this.overlayContact = {
        ...contact,
        phones: contact.phones.filter(phone => !phone.hasContactRegistered)
      };
      this.showAddContactOverlay = true;
    },

    addDigit9ToBrazilNumbers(phone) {
      if (phone.length === 10 && phone[2] === '9') {
        return phone.slice(0, 2) + '9' + phone.slice(2);
      }
      return phone;
    },

    async checkIfContactIsRegistered(phone) {
      try {
        let { phoneNumberParsed, ddiParsed, instance } = await parsePhoneNumber(phone.number);
        phoneNumberParsed = this.addDigit9ToBrazilNumbers(phoneNumberParsed);

        const regionCode = instance.getRegionCodeForCountryCode(ddiParsed);
        const newParse = instance.parseAndKeepRawInput(phoneNumberParsed, regionCode);
        if (!instance.isValidNumber(newParse)) throw new Error('Invalid phone number');

        const contactResponse = await getContactByPhone({
          number: phoneNumberParsed,
          companyId: this.user.company_id
        });

        return {
          ...phone,
          hasContactRegistered: !!contactResponse?.data,
          contactRegistered: contactResponse?.data,
        };
      } catch (error) {
        return {
          ...phone,
          hasContactRegistered: false,
          contactRegistered: null,
        };
      }
    },

    async call(callNum) {
      try {
        let { phoneNumberParsed } = await parsePhoneNumber(callNum);
        phoneNumberParsed = this.addDigit9ToBrazilNumbers(phoneNumberParsed);
        if (this.$store.getters["sip/agent"]) {
          this.$store.dispatch("sip/makeCall", { callNum: `${phoneNumberParsed}` });
        }
      } catch (e) {
        this.$toast.error('Falha ao completar a ligação, tente novamente!');
      }
    },
  },


  async created() {
    const parsedContacts = JSON.parse(this.message.messageBody);

    this.contacts = await Promise.all(parsedContacts.map(async (contact) => {
      const phones = await Promise.all(contact.phones?.map(async (phone) => {
        return await this.checkIfContactIsRegistered(phone);
      }) || []);

      return {
        ...contact,
        phones: phones
      };
    }));
  },
});
</script>
