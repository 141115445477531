<template lang="pug">
div(v-if="task")
  .contact-history-box
    .history-info-side
      .history-left-info
        div(:class="'history-track-icon blue'")
          span.text-white.history-call-indicator-icon.material-icons.notranslate assignment_late
        .history-type-text(
          :style="isOpened ? 'font-weight:bold' : 'font-weight:normal'"
        ) {{ task.name }}
      .history-center-info
        .history-type-text {{ activityDateFormat() }}
        .history-type-text.font-bold {{ contact.name }}
      .history-right-info
        .tag {{  task.tags &amp;&amp; task.tags.length ? task.tags[0].text : &quot;Sem Tag&quot;  }}
        .aditional-tag-margin.tag(
          v-if="task.tags && task.tags.length > 1",
          @mouseover="showAllTags",
          @mouseleave="hideAllTags"
        ) +{{ task.tags.length - 1 }}
    .history-icon-side
      span.expand-activity-icon.material-icons(
        @click="isOpened = !isOpened",
        :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'"
      ) keyboard_arrow_down
  .tags-component-aligner
    .tags-component-content(
      v-if="task.tags && task.tags.length > 1",
      ref="tags-component"
    )
      p.tags-text Tags
      .tags-box
        .tag.multiple-tags-margin(
          v-for="(data, index) in task.tags",
          :key="index"
        ) {{ data.text }}
  EventDetail(:calldata="task", v-if="isOpened")
</template>

<script>
import EventDetail from "@/pages/EventDetail.vue";

export default {
  props: ["task"],
  data() {
    return {
      isOpened: false,
      tags: [],
      hideTagsTimeout: null,
      showTagsTimeout: null,
      contact: {},
    };
  },
  components: {
    EventDetail,
  },
  methods: {
    hideAllTags() {
      if (!this.$refs["tags-component"]) return;
      clearTimeout(this.showTagsTimeout);
      this.$refs["tags-component"].style.opacity = 0;
      this.hideTagsTimeout = setTimeout(
        () => (this.$refs["tags-component"].style.display = "none"),
        300
      );
    },
    showAllTags() {
      if (!this.$refs["tags-component"]) return;
      clearTimeout(this.hideTagsTimeout);
      this.$refs["tags-component"].style.display = "flex";
      this.showTagsTimeout = setTimeout(
        () => (this.$refs["tags-component"].style.opacity = 1),
        1
      );
    },
    formatDuration(seconds) {
      return seconds
        ? new Date(seconds * 1000).toISOString().substr(11, 8)
        : "00:00:00";
    },
    activityDateFormat() {
      const date = this.$moment.tz(this.task.createdAt, this.user.timezone);
      return date.format("DD/MM/yyyy [-] HH:mm");
    },
  },
  async created() {
    try {
      const res = await this.$http.get(
        `${this.requestLinks.clist}/contact/${this.task.ownerId}`
      );
      this.contact = res.data;
    } catch (error) {
      this.contact = {
        name: "Contato Desconhecido"
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.history-track-icon.blue {
  background: #6ea3f3;
}
</style>
