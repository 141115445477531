<template lang="pug">
div(v-if="activity")
  .contact-history-box
    .history-info-side
      .history-left-info
        i.text-white.rounded-full.cursor-default.small.material-icons.notranslate.text-base.w-6.grid.place-items-center(
          :class="bgClass",
          class="dark:text-black"
        ) chat
        .history-type-text.text-marker(:style="isOpened ? 'font-weight:bold' : 'font-weight:normal'")
          | {{ textForCallType[activityRoom.status] }}
        span.got-callback-icon.material-icons(v-if="activity.callbacks") assignment_late
      .history-center-info.text-marker
        .history-type-text {{ $moment(activity.createdAt).format('DD/MM/yyyy - HH:mm') }}
        .history-type-text Duração {{ $moment.utc(activityRoom.attendanceTime * 1000).format('HH:mm:ss') }}
      .history-right-info
        .tag {{ activityRoom.tags && activityRoom.tags.length ? activityRoom.tags[0].tagName : 'Sem Tag' }}
        .aditional-tag-margin.tag(
          @mouseleave="hideAllTags",
          @mouseover="showAllTags",
          v-if="activityRoom.tags && activityRoom.tags?.length > 1"
        )
          | +{{ activityRoom.tags?.length - 1 }}
    .history-icon-side.gap-1
      span.expand-activity-icon.material-icons(
        :style="isOpened ? 'transform:rotate(180deg)' : 'transform:rotate(0deg)'",
        @click="isOpened = !isOpened",
        v-tooltip="'Expandir atividade'"
      ) keyboard_arrow_down
      span.expand-activity-icon.material-icons.notranslate.text-lg.transition-none(
        @click="$store.commit('modal/setActiveModal', { name: 'linkCase', context: { contact: contact, callId: activityRoom.id, type: 'chat' } })",
        v-if="!isFeed",
        v-tooltip="'Vincular a um caso'"
      ) assignment

  .tags-component-aligner
    .tags-component-content(ref="tags-component", v-if="activityRoom.tags?.length")
      p.tags-text Tags
      .tags-box
        .tag.multiple-tags-margin(:key="index", v-for="(data, index) in activityRoom.tags")
          | {{ data.tagName }}
  chat-event-detail(:chat-data="{room: activity}", v-if="isOpened")
.no-device(style="width: 100%", v-else)
  span.info-orange-icon.material-icons.notranslate error_outline
  | Esse contato n&atilde;o possui atividades.
</template>

<script>
import contactList from '@/services/contact-list';
import ContactListMixin from '@/mixins/contact-list';
import ChatEventDetail from './ChatEventDetail.vue';
import chat from '@/services/chat';

export default {
  props: ['activity', 'number', 'isFeed'],
  mixins: [ContactListMixin],
  data() {
    return {
      members: [],
      isOpened: false,
      mapCallType: {
        ANSWERED: 'received',
        NOANSWER: 'missed',
      },
      textForCallType: {
        terminated: 'Conversa finalizada pelo operador',
        completed: 'Conversa finalizada',
        expired: 'Conversa finalizada pelo sistema',
        established: 'Conversa em andamento',
        routing: 'Conversa em espera',
        pending: 'Conversa pendente',
      },
      tags: [],
      hideTagsTimeout: null,
      showTagsTimeout: null,
    };
  },
  computed: {
    bgClass() {
      const colors = {
        terminated: 'bg-green-400 dark:invert',
        completed: 'bg-green-400 dark:invert',
        expired: 'bg-red-400 dark:invert',
        established: 'bg-yellow-400 dark:invert',
        routing: 'bg-yellow-400 dark:invert',
        pending: 'bg-yellow-400 dark:invert',
      };
      return colors[this.activityRoom.status];
    },
    activityRoom() {
      return this.activity.room ? this.activity.room : this.activity;
    },
  },
  components: {
    ChatEventDetail,
  },
  methods: {
    translateCallType() {
      const userId = this.user.id;
      const returnIcon = {
        ANSWERED: function (call, id) {
          if (activity.from_ids.includes(id)) {
            return 'received';
          }
          return 'made';
        },
        NOANSWER: function (call, id) {
          if (activity.from_ids.includes(id)) {
            return 'missed';
          }
          return 'missed_outgoing';
        },
      };
      let type = returnIcon[this.activity.status];
      return type(this.activity, userId);
    },
    hideAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.showTagsTimeout);
      this.$refs['tags-component'].style.opacity = 0;
      this.hideTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.display = 'none'), 300);
    },
    showAllTags() {
      if (!this.$refs['tags-component']) return;
      clearTimeout(this.hideTagsTimeout);
      this.$refs['tags-component'].style.display = 'flex';
      this.showTagsTimeout = setTimeout(() => (this.$refs['tags-component'].style.opacity = 1), 1);
    },
    formatDuration(seconds) {
      return seconds ? new Date(seconds * 1000).toISOString().substr(11, 8) : '00:00:00';
    },
    activityDateFormat() {
      const date = this.$moment.tz(this.activity.calldate, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm');
    },
    async getMembers() {
      try {
        const res = await chat.findById(this.activityRoom.id, {
          companyId: this.user.company_id,
          includeMembers: true,
          includeMessages: false,
          limitMessages: 0,
          orderMessages: 'asc',
        });
        this.activity.members = res.members;
      } catch (error) {
        console.error(error);
      }
    },
  },
  async created() {
    const { getContactById } = contactList;
    this.activity.members = this.activity.members;
    if (!this.activity?.members?.length) await this.getMembers();
    try {
      let member = this.activity.members.filter((e) => e.provider !== 'opens')[0];
      let number = member.origin;
      let id = member.recipientId;
      if (this.activityRoom.creatorProvider === 'opens') contactPhone = this.activityRoom.subject;
      this.activeContactId = id;
    } catch (error) {
      console.error(error);
    }
  },
};
</script>
