<template lang="pug">
ul.flex.items-center
  span.pagination-info(v-if="showLimitOptions") Itens por página
  select.pagination-select(v-if="showLimitOptions" v-model="limit")
    option(:value="5") 5
    option(selected="", :value="10") 10
    option(:value="15") 15
    option(:value="20") 20
  span.pagination-size-text {{ paginationLabel }}
  li.flex.items-center
    button.pagination-icon.material-icons.notranslate.cursor-pointer(
      class="active:scale-90"
      :class="{'pointer-events-none' : isInFirstPage}"
      v-tooltip="'Página anterior'"
      type="button",
      @click="onClickPreviousPage",
      :disabled="isInFirstPage"
    ) &#xe5cb
  li.flex.items-center
    button.pagination-icon.material-icons.notranslate.cursor-pointer(
      class="active:scale-90"
      :class="{'pointer-events-none' : isInLastPage}"
      v-tooltip="'Próxima página'"
      type="button",
      @click="onClickNextPage",
      :disabled="isInLastPage"
    ) &#xe5cc
</template>

<script>
export default {
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    hasMorePages: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    showLimitOptions: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      limit: 10,
    };
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons;
      }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (let i = this.startPage; i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages); i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
    paginationLabel() {
      const start = this.perPage * (this.currentPage - 1) + 1;
      let end = this.currentPage * this.perPage;
      if (end > this.totalItems) {
        end = this.totalItems;
      }
      const label = `${start} - ${end} de ${this.totalItems}`;
      return label;
    },
  },
  watch: {
    limit(value) {
      this.$emit('limit', value);
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      if (!this.hasMorePages) return;
      this.$emit('pagechanged', this.currentPage + 1);
    },
  },
  created() {
    this.limit = this.perPage;
  },
};
</script>
