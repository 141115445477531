<template>
  <div class="page-content">
    <link
      href="https://fonts.googleapis.com/css2?family=Inter&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap"
      rel="stylesheet"
    />
    <div class="step-header">
      <div class="step-title">Vincular Device SIP</div>
      <div class="step-counter-passed"></div>
      <div class="step-counter-passed"></div>
    </div>
    <div class="line-separator"></div>
    <div class="center-aligner">
      <div class="confirm-circle">
        <div class="confirm-inside-circle">
          <i class="check-icon fa fa-exclamation fa-2x"></i>
        </div>
      </div>
    </div>
    <div class="center-aligner">
      <div class="success-text">
        Oops! Não foi possível vincular seu device.
      </div>
    </div>
    <div class="center-aligner">
      <div class="step-info">
        Não foi possível vincular o seu device, seu código expirou ou foi
        inserido incorretamente, tente novamente. Caso o erro persista,
        <a
          style="color: #f26c45"
          class="step-info"
          href="https://suporte.opens.com.br"
          >solicite suporte</a
        >
        para essa ação.
      </div>
    </div>
    <div style="margin-top: 86px" class="cancel-button-aligner">
      <button @click="close()" class="buttoncancel margin-right">Fechar</button>
      <button @click="tryAgain()" class="buttonsave">TENTAR NOVAMENTE</button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    tryAgain() {
      this.$emit("openStepDevice1");
    },
    close(){
      this.$emit("closeDown");
    }
  },
};
</script>

<style>
.step-info {
  width: 700px;
  font-family: "Inter", sans-serif;
  font-weight: -300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #868e96;
}
</style>
