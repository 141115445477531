<template>
  <div :class="[margin == true ? 'margin-top' : '', 'align-dialer']">
    <div class="dialer-area">
      <div class="digits-aligner">
        <a @click="clicked(1)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">1</a>
        <a @click="clicked(2)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">2</a>
        <a @click="clicked(3)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">3</a>
      </div>
      <div class="digits-aligner">
        <a @click="clicked(4)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">4</a>
        <a @click="clicked(5)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">5</a>
        <a @click="clicked(6)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">6</a>
      </div>
      <div class="digits-aligner">
        <a @click="clicked(7)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">7</a>
        <a @click="clicked(8)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">8</a>
        <a @click="clicked(9)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">9</a>
      </div>
      <div class="digits-aligner">
        <a @click="clicked('*')" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">*</a>
        <a @click="clicked(0)" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">0</a>
        <a @click="clicked('#')" class="digit hover:border border-gray-500/50 w-12 h-12 transition-all rounded-full flex justify-center items-center">#</a>
      </div>
      <div class="digits-aligner">
        <div class="empty-space-aligner"></div>
        <div
          v-if="hangup == true"
          @click="$emit('hangup-call')"
          class="align-center hover:opacity-50 transition-all active:scale-90"
        >
          <div class="turnoff-call-icon">
            <span style="font-size: 28px" class="material-icons notranslate">phone</span>
          </div>
          <a class="card-info-text" style="color: #adb5bd !important">Desligar</a>
        </div>
        <div v-else class="dialer-call-box call-icon">
          <span class="dialer-call-icon notranslate material-icons"> phone </span>
        </div>
        <a @click="$emit('close-keyboard')" class="hide-text">Ocultar</a>
      </div>
    </div>
  </div>
</template>

<script>
import SipMixin from '@/mixins/invite-calls.js';
export default {
  props: ['margin', 'hangup'],
  mixins: [SipMixin],
  methods: {
    clicked(number) {
      this.$emit('digit-clicked', number);
      this.sendDTMF(number);
    },
    sendDTMF(itemStr) {
      if (!this.session || !this.session.session) return;
      var options = {
        duration: 100,
        interToneGap: 70,
      };
      this.session.session.sessionDescriptionHandler.sendDtmf(itemStr, options);
    },
  },
};
</script>

<style>
.margin-top {
  margin-top: 21px;
}
</style>
