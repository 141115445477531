<template lang="pug">
#movidesk-area.movidesk-area-option
  #movidesk-ticket.p-0
    .movidesk-text-bold {{ client.businessName }}
    .movidesk-text(v-if="!loadingTickets") {{ movideskTicketsOpen > 0 ? `${movideskTicketsOpen} tickets` : ' Sem tickets' }}
      span.movidesk-right(v-if="movideskTicketsOpen > 0")
        a.movidesk-button(@click="openTickets") Ver tickets
    .movidesk-text(v-else) Verificando tickets
      span(v-for="point in points" :key="point") .

    .movidesk-divider
    .movidesk-action
      a.movidesk-button.mt-14(@click="openCreateTicketModal") Criar Ticket
</template>

<script>
export default {
  props: ['movideskToken', 'client'],
  data() {
    return {
      loadingTickets: true,
      tickets: [],
      points: 1,
    };
  },
  methods: {
    mitOverlay(payload) {
      this.$emit('open-overlay', payload);
    },
    closeOverlay() {
      let overlay = this.$refs['overlay-content'];
      overlay.style.opacity = 0;
      setTimeout(() => this.$emit('close-overlay'), 300);
    },
    openTickets() {
      this.$store.commit('modal/setContext', this.tickets);
      this.$emit('open-overlay', 'movidesk-tickets');
    },
    openCreateTicketModal() {
      this.$store.commit('modal/setActiveModal', 'MovideskCreateTicketModal');
    },
  },
  async created() {
    const clock = setInterval(() => {
      this.points++;
      if (this.points > 3) this.points = 0;
    }, 1000);
    this.$store.commit('SET_MOVIDESK_CONTEXT', {
      client: this.client,
      movideskToken: this.movideskToken,
    });

    let ticketsRequest = `https://api.movidesk.com/public/v1/tickets?token=${this.movideskToken}&$select=id,subject,category,urgency,status,baseStatus,justification,origin,createdDate,originEmailAccount,owner,ownerTeam,createdBy,serviceFull,lastActionDate,clients,actions,type,createdBy&$expand=clients($expand=organization)&$filter=clients/any(e: e/id eq '${this.client.id}') AND (baseStatus eq 'New' OR baseStatus eq 'Stopped' OR baseStatus eq 'InAttendance')&$top=10`;
    const res = await this.$http.get(ticketsRequest);
    this.movideskTicketsOpen = res.data.length;
    this.tickets = res.data;
    this.loadingTickets = false;
    clearInterval(clock);
  },
};
</script>
