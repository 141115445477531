<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.pb-8.px-10.rounded-lg(class="w-[36rem]")
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter Iniciar nova conversa
      .flex.flex-col.justify-center.items-center.w-full.gap-4
        .flex.flex-col.justify-start.items-start.w-full.w-full
          span.font-inter.text-sm.text-gray-400.font-medium Para iniciar, escreva o assunto desta conversa, selecione o contato e a conta
          icon-input.mt-4(
            :label="'Assunto da conversa'"
            :icon="'subject'"
            v-model="emailTopic"
          )

          contact-email-dropdown.-mt-2(
            @selected-contact="selectContact"
          )

          v-dropdown.w-full.mt-4(
            :placeholder="selectedEmailAccount?.id ? selectedEmailAccount.user : 'Selecione uma conta'"
            :label="'Selecione a conta que irá enviar os emails desta conversa'"
          )
            .flex.w-full.justify-between
              .flex.w-full.items-center.justify-center.cursor-pointer.border-b.pb-2.transition-all(
                class="hover:border-b-brand"
                :class="{'border-b-brand' : selectedAccountProvider == emailAccountProviderEnum.outlook}"
                @click="selectedAccountProvider = emailAccountProviderEnum.outlook"
              )
                p.font-plus Outlook
              .flex.w-full.items-center.justify-center.cursor-pointer.border-b.pb-2.transition-all(
                class="hover:border-b-brand"
                :class="{'border-b-brand' : selectedAccountProvider == emailAccountProviderEnum.gmail}"
                @click="selectedAccountProvider = emailAccountProviderEnum.gmail"
              )
                p.font-plus Gmail
            dropdown-item(
              v-if="!loadingAccounts"
              v-close-popper
              hide-icon
              v-for="emailAccount in emailAccounts"
              :key="emailAccount.id"
              :label="emailAccount?.userAlias ? `<b>${emailAccount?.userAlias}</b> (${emailAccount.user})` : emailAccount.user"
              @click="selectedEmailAccount = emailAccount"
              :selected="selectedEmailAccount.id == emailAccount.id"
            )
            p.text-xs.p-2.w-96(v-if="!loadingAccounts && emailAccounts.length == 0") Você não está inserido em nenhum grupo de atendimento de chat que possui um email vinculado
            loading-ring(v-if="loadingAccounts")
            observer(v-if="emailAccounts.length >= 10" @intersect="loadMoreEmailAccounts")
          
        .flex.w-full.items-start.justify-center.gap-2
          v-button-secondary(
            @click="$store.commit('modal/setActiveModal', '')"
          ) Cancelar
          v-button(
            @click="startEmail()"
            :disabled="!selectedEmailAccount?.id || !selectedContact?.id || !emailTopic"
          ) Iniciar conversa
</template>

<script>
import { debounce } from '@/helpers';
import user from '@/store/user';
import Overlay from '@/components/Modals/Overlay.vue';
import chatConfigService from 'src/services/chat-config.js';
import chatService from 'src/services/chat.js';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import DropdownClients from '@/components/Dropdown/dropdown-clients.vue';
import VDropdown from '@/components/Dropdown/dropdown.vue';
import VButton from '../Buttons/v-button.vue';
import imapService from '@/services/imap';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import Observer from '@/components/Observer.vue';
import chatFetchRoomMixin from '@/mixins/chat/fetch-rooms.mixin';
import IconInput from '../Inputs/IconInput.vue';
import ContactEmailDropdown from '@/components/Dropdown/contact-email-dropdown.vue';

export default {
  mixins: [chatFetchRoomMixin],
  components: {
    VButtonSecondary,
    Overlay,
    VButton,
    VDropdown,
    DropdownClients,
    DropdownItem,
    Observer,
    LoadingRing,
    IconInput,
    ContactEmailDropdown,
  },
  data() {
    return {
      loadingAccounts: false,
      emailText: '',
      emailTopic: '',
      selectedAccountProvider: 0,
      currentLine: 0,
      totalAccounts: 0,
      emails: [],
      emailAccounts: [],
      userQueues: [],
      emailAccountProviderEnum: {
        outlook: 0,
        0: 'outlook',
        gmail: 1,
        1: 'gmail',
      },
      selectedEmailAccount: {},
      selectedContact: {},
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
  },
  watch: {
    selectedAccountProvider(val) {
      this.emailAccounts = [];
      this.currentLine = 0;
      this.getEmailAccounts();
    },
  },
  methods: {
    selectContact(value) {
      this.selectedContact = value;
    },
    async startEmail() {
      const { createRoom } = chatService;
      let data;

      try {
        data = await createRoom({
          companyId: this.user.company_id,
          createdBy: this.selectedContact.contactId,
          subject: this.selectedEmailAccount.id,
          creatorProvider: this.emailAccountProviderEnum[this.selectedAccountProvider],
          chatConfigId: this.selectedEmailAccount.id,
          inactivityLimit: this.chatInactivityLimit,
          topic: this.emailTopic,
          status: 'established',
          members: [
            {
              recipientId: this.user.id,
              provider: 'opens',
              companyId: this.user.company_id,
              channel: this.user.id,
              origin: this.user.id,
            },
            {
              recipientId: this.selectedContact.contactId,
              provider: this.emailAccountProviderEnum[this.selectedAccountProvider],
              companyId: this.user.company_id,
              channel: this.selectedContact.address,
              origin: '',
              providerAccessToken: this.selectedEmailAccount.id,
              subject: this.selectedEmailAccount.id,
            },
          ],
        });
      } catch (error) {
        if (error?.response?.data?.message) return this.$toast.error(error.response.data.message);
        this.$toast.error('Não foi possível iniciar a conversa');
      }

      if (!data) return;
      this.$router.push({
        name: 'Emails',
      });
      await this.fetchUserEmailRooms({ isOpen: true, orderMessages: 'asc' });
      this.$store.commit('email/setActiveEmail', { ...data, messages: [] });
      this.$store.commit('modal/setActiveModal', '');
    },
    async loadMoreEmailAccounts() {
      this.currentLine++;
      await this.getEmailAccounts();
    },
    async getEmailAccounts() {
      if (this.loadingAccounts) return;
      this.loadingAccounts = true;
      const { getAccounts } = imapService;
      const { getAllQueues } = chatConfigService;
      try {
        if (this.currentLine * 10 > this.totalAccounts) return;
        if (!this.userQueues.length) {
          const queues = await getAllQueues();
          queues.forEach((queue) => {
            queue.members.forEach((member) => {
              if (member.memberId == this.user.id) {
                this.userQueues.push(queue);
              }
            });
          });
          this.userQueues = this.userQueues.map((queue) => {
            return {
              ownedByQueueId: queue.id,
            };
          });
        }
        const payload = {
          $skip: this.currentLine * 10,
          $select: ['user', 'id', 'userAlias'],
          companyId: this.user.company_id,
          provider: this.emailAccountProviderEnum[this.selectedAccountProvider],
        };
        if (this.userQueues.length) {
          payload.$or = this.userQueues;
        }
        const emailAccounts = await getAccounts(payload);
        this.emailAccounts = [...emailAccounts.data];
        this.totalAccounts = emailAccounts.total;
      } catch (e) {
        this.$toast.error(e);
      } finally {
        this.loadingAccounts = false;
      }
    },
  },
  mounted() {
    this.getEmailAccounts();
  },
};
</script>
