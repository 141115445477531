<template lang="pug">
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Vincular Device
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    .flex.flex-col.mt-12
      IconCircularBg.h-12.w-12.mb-12(:icon="'check'")
      p.text-black.text-center.text-3xl.font-bold(class="w-3/5") Seu device foi vinculado com sucesso!
  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.bg-orange.text-white.w-48.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', '')"
      ) CONCLUIR
</template>

<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
  created() {
    setTimeout(() => {
      this.$router.go();
    }, 1000);
  },
};
</script>
