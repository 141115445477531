<template lang="pug">
.w-full.mb-2
  .flex.justify-center.items-center
    p.text-xs.text-zinc-500.px-4.py-2.rounded <span class='font-semibold'>{{ operator?.username }}</span> {{ text }}
</template>

<script>
import csService from '@/services/cservice.js';
export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      operator: {},
    };
  },
  computed: {
    text() {
      if (this.message.eventCount > 1) {
        return `foi convidado ${this.message.eventCount} vezes a participar desta conversa`;
      }
      return `foi convidado a participar desta conversa`;
    },
  },
  async created() {
    try {
      this.operator = await csService.getUserById(this.message.messageBody);
    } catch (error) {}
  },
};
</script>
