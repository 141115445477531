import $http from "./client";
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_LICENSES}`;

async function getCompanyPlans({ companyId }) {
  const { data } = await $http.get(`${service}/companyPlan/${companyId}`);
  return data;
}

export default {
  getCompanyPlans,
};
