<template lang="pug">
.w-full.mb-2
  .flex.justify-center.items-center
    p.text-xs.text-zinc-500.px-4.py-2.bg-zinc-200.rounded Convite feito para <span class='font-semibold'>{{ operator?.username }}</span> foi cancelado
</template>

<script>
import csService from "@/services/cservice.js";
export default {
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    text() {
      if(this.message.eventCount > 1) {
        return `${this.message.eventCount} convites feitos para ${this.operator?.username} foram cancelados`
      }
      return `Convite feito para ${this.operator?.username} foi cancelado`
    }
  },
  data() {
    return {
      operator: {},
    };
  },
  async created() {
    try {
      this.operator = await csService.getUserById(this.message.messageBody);
    } catch (error) {}
  },
};
</script>
