<template lang="pug">
.mb-4.relative.flex.justify-center.items-center.bottom-0.px-4.pointer-events-none(class="dark:invert")
  .px-5.h-16.bg-zinc-800.rounded-full.flex.justify-center.items-center.cursor-pointer.pointer-events-auto(@click="$router.push({ name: 'Chat'})")
    p.text-white Você possui novas mensagens no chat, 
      span.text-brand-400(class="dark:font-semibold dark:text-brand-300") clique aqui para acessar o chat!
    .relative
      span.material-icons.notranslate.ml-6.p-1.bg-brand-400.text-brand-400.rounded-full.absolute.animate-ping &#xe0b7
      span.material-icons.notranslate.ml-6.p-1.bg-brand-400.text-brand-800.rounded-full &#xe0b7
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>
