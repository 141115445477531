<template lang="pug">
component.cursor-pointer(:is="tag", @click.native="hideSidebar", v-bind="$attrs", tag="li")
  a.mt-1.nav-link(v-bind="$attrs")
    slot
      i(v-if="link.icon", :class="link.icon")
      p {{ link.name }}
</template>
<script>
export default {
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: [String, Object],
      default: () => {
        return {
          name: "",
          path: "",
          icon: "",
        };
      },
    },
    tag: {
      type: String,
      default: "router-link",
    },
  },
  methods: {
    hideSidebar() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style></style>
