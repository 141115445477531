<template lang="pug">
.simple-input(
    :class="`${numberPhoneLength > maxNumberLength ? 'invalid' : ''}`"
  )
  .flex.h-full.pl-2
    p.text-gray-500.font-plus.font-medium.transition(:class="{'translate-y-0 text-sm': upPlaceholder(), '-translate-y-1/3 text-xs pb-1': !upPlaceholder()}") {{ label }}
  input.absolute.w-full.h-full.outline-none.bg-transparent.appearence-none.indent-2.pt-3.font-plus.font-semibold.text-gray-800(
    class="dark:!text-white"
    :placeholder="placeholder",
    @input="handleInput",
    @focus="focus = true"
    @blur="focus = false"
    v-model="content",
    :type="replaceType || type",
    @keyup.enter="$emit('keyup-enter')"
    :MAXLENGTH="caract"
    @click="handleClick"
  )
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    placeholder: {
      type: String,
      default: '',
    },
    type: String,
    tooltip: Object,
    caract: {
      type: Number,
      default: 1000,
    },
    icon: {
      type: String,
      default: 'search',
    },
    includeParentheses: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      contentSet: false,
      content: '',
      visibility: false,
      replaceType: this.type,
      focus: false,
      lastInputCursorPosition: null,
      maxNumberLength: 11,
    };
  },
  methods: {
    upPlaceholder() {
      if (this.focus) return false;
      if (this.content) return false;
      return true;
    },
    handleInput(e) {
      if (this.lastInputCursorPosition) {
        this.lastInputCursorPosition = e.target.selectionStart;

        if (e.target.value.length === this.maxNumberLength) this.lastInputCursorPosition += 1;

        this.$nextTick(() => {
          e.target.setSelectionRange(this.lastInputCursorPosition, this.lastInputCursorPosition);
        });
      }

      this.content = this.formatPhoneNumber(e.target.value);

      this.$emit('input', this.content);
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return;
      phoneNumber = phoneNumber.replace(/\D/g, '');
      let formattedPhoneNumber = '';
      if (phoneNumber.length > 11) return phoneNumber;
      if (phoneNumber.length > 0) {
        const parentheses = this.includeParentheses ? '(' : '';
        formattedPhoneNumber += parentheses + phoneNumber.substring(0, 2);
      }
      if (phoneNumber.length > 2) {
        const parentheses = this.includeParentheses ? ') ' : ' ';
        formattedPhoneNumber += parentheses + phoneNumber.substring(2, 7);
      }
      if (phoneNumber.length > 7) {
        formattedPhoneNumber += '-' + phoneNumber.substring(7, 11);
      }
      if (formattedPhoneNumber.includes('-') && formattedPhoneNumber.split('-')[1]?.length < 4) {
        formattedPhoneNumber = formattedPhoneNumber.replace('-', '').replace(/(\d{4})(\d+)/g, '$1-$2');
      }
      return formattedPhoneNumber;
    },
    handleClick(e) {
      this.lastInputCursorPosition = e.target.selectionStart;
    },
  },
  watch: {
    focus(value) {
      if (!value) {
        this.$emit('blur');
        return;
      }
      this.$emit('focus');
    },
    value(n) {
      if (this.contentSet) return;
      this.content = n;
      this.contentSet = true;
    },
  },
  computed: {
    numberPhoneLength() {
      return this.content?.replace(/\D/g, '')?.length || 0;
    },
  },
  created() {
    this.content = this.formatPhoneNumber(this.value);
  },
};
</script>
<style lang="scss" scoped>
.invalid {
  border: none;
  border-bottom: 2px solid red !important;
}
</style>
