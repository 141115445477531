<template lang="pug">
ul.dropdown-list
  li.status-option(v-for="(data, index) in options", :key="index")
    div(
      class="dark:invert"
      :class="'status status-' + convertStatusToClass[data]",
      @click="selectStatus(data)"
    ) {{ data }}
</template>

<script>
export default {
  props: ["options"],
  data() {
    return {
      convertStatusToClass: {
        Aberto: "open",
        "Em andamento": "inprogress",
        Fechado: "closed",
      },
    };
  },
  methods: {
    selectStatus(data) {
      this.$emit("status-selected", data);
      this.$emit("close-dropdown");
    },
  },
};
</script>

<style lang="scss">
.dropdown-list {
  padding-top: 16px;
  width: max-content;
  z-index: 2;
  padding-bottom: 16px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding-left: 0px !important;
}
.status-option {
  height: 40px;
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 0px 24px;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #868e96;
  transition: 0.1s;
}
.status-option:hover {
  background: #f8f9fa;
}
.status {
  padding: 0.3rem 1rem;
  width: fit-content;
  font-family: "Inter";
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  background: rgba(134, 142, 150, 0.1);
  color: #868e96;
  border-radius: 4px;
  text-indent: 0;
  line-height: 22px;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}
.status-open {
  background: rgba(74, 142, 244, 0.1);
  color: #4a8ef4;
  .material-icons {
    color: #4a8ef4;
  }
}
.status-inprogress {
  color: #f9a454;
  background: rgba(249, 164, 84, 0.1);
  .material-icons {
    color: #f9a454;
  }
}
.status-closed {
  background: rgba(134, 142, 150, 0.1);
  color: #868e96;
  .material-icons {
    color: #868e96;
  }
}
</style>