export default {
  namespaced: true,
  state: {
    plan: {},
  },
  mutations: {
    setPlan: function (state, payload) {
      state.plan = payload;
    },
  },
  getters: {
    getPlan: function (state) {
      return state.plan;
    },
  },
};
