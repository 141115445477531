import $http from "./client";
const service = `https://brasilapi.com.br/api`;

async function getInfoByCep(cep) {
  try {
    const { data } = await $http.get(`${service}/cep/v1/${cep}`);
    return data;
  } catch (error) {
    Promise.reject(error);
  }
}

async function getCitiesByState(state) {
  try {
    const { data } = await $http.get(
      `${service}/ibge/municipios/v1/${state}?providers=dados-abertos-br,gov,wikipedia`
    );
    return data;
  } catch (error) {
    Promise.reject(error);
  }
}

export default {
  getInfoByCep,
  getCitiesByState,
};
