import { render, staticRenderFns } from "./Cases.CaseInfoBox.vue?vue&type=template&id=4879f012&scoped=true&lang=pug"
import script from "./Cases.CaseInfoBox.vue?vue&type=script&lang=js"
export * from "./Cases.CaseInfoBox.vue?vue&type=script&lang=js"
import style0 from "./Cases.CaseInfoBox.vue?vue&type=style&index=0&id=4879f012&prod&lang=postcss&scoped=true"
import style1 from "./Cases.CaseInfoBox.vue?vue&type=style&index=1&id=4879f012&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4879f012",
  null
  
)

export default component.exports