<template lang="pug">
.mt-2.rounded-md.border.border-gray-200.shadow-lg.shadow-gray-100.p-4.w-fit
  img(:src="getImgUrl(partner?.img)")
  p.font-plus.text-gray-700.font-bold.mt-2 {{partner?.title}}
  p.font-plus.text-gray-400.font-medium.mt-1.text-sm.w-56 {{partner?.description}}
  v-button-secondary.bg-opacity-10.text-sm.mt-4.group.flex.items-center(class="!py-1" @click="$router.push('./partners/rdcrm')") Integrar
    span.material-icons.notranslate.transition.ml-1(class="group-hover:translate-x-1") arrow_forward
</template>
<script>
export default {
  props: ["partner"],
  methods: {
    getImgUrl(name) {
      var images = require.context('@/assets/images/', false, /\.png$/);
      return images('./' + name + '.png');
    },
  }
}
</script>