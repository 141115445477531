export default {
  namespaced: true,
  state: {
    activeCampaignId: null,
    handleCampaignsUpdate: 0,
  },
  mutations: {
    setActiveCampaignId: function (state, payload) {
      state.activeCampaignId = payload;
    },
    setHandleCampaignsUpdate: function (state, payload) {
      state.handleCampaignsUpdate = payload;
    },
  },
  getters: {
    getActiveCampaignId: function (state) {
      return state.activeCampaignId;
    },
    getHandleCampaignUpdate: function (state) {
      return state.handleCampaignsUpdate;
    },
  },
};
