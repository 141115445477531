import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}`;

export const getFile = async function (filename) {
  try {
    const { data } = await $http.get(`${service}/uploads/${filename}`, { responseType: 'blob' });
    return data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export async function checkStaticFileAvailability(filename) {
  try {
    let url = `${service}/uploads/${filename}`;
    const response = await fetch(url, { method: 'HEAD' });
    if (response.ok) {
      return url;
    }
  } catch (error) {
    throw new Error('File not found');
  }
}

export async function getTemplatesByConfigId({ configId, templateName, next, cancelToken }) {
  const nextQuery = next ? `?next=${next}` : '';

  const {
    data: { data, paging },
  } = await $http.get(`${service}/message-templates/${configId}${nextQuery}`, {
    params: {
      templateName,
    },
    cancelToken,
  });

  return { data, paging };
}

async function saveFileInAdapter({ fileFormData }) {
  try {
    const { data } = await $http.post(`${service}/upload-file`, fileFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });

    return { filename: data.file };
  } catch (error) {
    throw error;
  }
}

export default {
  getFile,
  checkStaticFileAvailability,
  getTemplatesByConfigId,
  saveFileInAdapter,
};
