<template lang="pug">
.fixed.z-10.bottom-0.mb-12.w-full.flex.items-center.justify-center
    .flex.flex-col.items-start.justify-start.bg-zinc-700.text-white.rounded.p-6.divide-y.bg-opacity-90(class='w-1/3 dark:bg-zinc-200')
        .flex.justify-between.items-center.w-full
            .font-extrabold.text-lg.mb-2.flex.w-full.justify-start
                img.h-5.mr-2(src='~@/assets/images/warning.svg')
                p.text-base(class="dark:invert") Notificações desabilitadas!
                .ml-auto.font-plus.text-yellow-500.cursor-pointer.mr-4.font-semibold.text-base.transition.select-none(v-if="!denied()" class="dark:invert hover:text-yellow-600" @click="notificationsPermission()") Habilitar
            i.material-icons.notranslate.text-zinc-300.mb-2.cursor-pointer(@click="close" class="dark:invert") &#xe5cd
        p.pt-2.text-zinc-300.text-sm(class="dark:invert") Habilite suas notificações para que você seja avisado caso algo aconteça! Por padrão, você sera avisado quando o aplicativo ou extensão quiser enviar notificações.
</template>

<script>
import vButtonSecondaryVue from '../Buttons/v-button-secondary.vue';
export default {
  components: {
    VButtonSecondary: vButtonSecondaryVue
  },
  methods: {
    denied() {
      return Notification.permission == 'denied';
    },
    close() {
      this.$emit("close");
    },
    notificationsPermission() {
      Notification.requestPermission().then(() => {
        this.close();
      });
    },
  },
};
</script>
