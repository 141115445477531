<template lang="pug">
overlay
  .mb-8.flex.justify-between.items-center.mt-3
    .flex(style='flex-direction: column; display: flex')
      .text-3xl.font-black.font-titillium(class="text-[#343A40]") Detalhe da atividade
    .flex
      i.material-icons.notranslate.cursor-pointer.p-2.flex.items-center.justify-center.rounded-full.text-gray-400.bg-gray-100(
        @click='closeOverlay',
        v-tooltip="'Fechar'"
        class="hover:bg-red-100 hover:text-red-500"
      ) close
  activity-info-box(:data='activityData')
  .mt-10(v-if='activityData.contact.organization')
    .font-inter.font-bold.mb-2.text-zinc
      | Histórico de atividades do contato
    contact-history-box(v-for='(data, index) in callsHistory' :key='index' :activity='data._source')
    .flex.w-ful.items-center.justify-center.mt-11.mb-20
      v-button-secondary.flex.gap-2(
        v-if='callsHistory.length > 0' @click='loadNewActivities'
      )
        | {{ loadingNewActivities ? 'Carregando' : 'Ver mais' }}
        loading-component.w-5.h-5(v-if='loadingNewActivities')
    .no-device(style='width: 100%' v-if='callsHistory.length < 1')
      span.info-orange-icon.material-icons.notranslate  error_outline 
      | Esse contato não possui atividades.

</template>

<script>
import ActivityInfoBox from "./Callback.ActivityInfoBox";
import ContactHistoryBox from "@/components/ContactHistoryComponent";
import Overlay from '@/components/Overlay/overlay.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import vButtonSecondary from '@/components/Buttons/v-button-secondary.vue';

export default {
  components: {
    ActivityInfoBox,
    ContactHistoryBox,
    Overlay,
    LoadingComponent,
    vButtonSecondary
  },
  props: ["activityData"],
  data() {
    return {
      callsHistory: [],
      loadingNewActivities: false,
    };
  },
  methods: {
    async loadNewActivities() {
      this.loadingNewActivities = true;
      try {
        let quantity = 10;
        if (this.callsHistory.length > 3) {
          quantity = quantity + this.callsHistory.length;
        }
        let numberToSearch = this.activityData.contact.phones
          ? this.activityData.contact.phones[0].ddi +
          this.activityData.contact.phones[0].number
          : this.activityData.payload.contactNumber;
        const res = await this.$http.get(
          `${this.requestLinks.callreport}/call/d/${numberToSearch.replace(
            /[^\d]+/g,
            ""
          )}/${quantity}/0`
        );
        if (this.callsHistory.length === 3) {
          res.data = res.data.slice(3, 10);
        }
        if (this.callsHistory.length > 3) {
          res.data = res.data.slice(this.callsHistory.length, quantity);
        }
        let callsTags = await this.getTags(res.data);
        callsTags = await this.getCallbacks(callsTags);
        this.callsHistory = this.callsHistory.concat(callsTags);
        this.callsHistory.sort(function (a, b) {
          if (a._source && b._source) {
            return a._source.calldate < b._source.calldate
              ? 1
              : a._source.calldate > b._source.calldate
                ? -1
                : 0;
          }
        });
        this.loadingNewActivities = false;
      } catch (e) {
        this.$log.info(e);
      }
    },
    closeOverlay() {
      this.$emit("close-overlay");
    },
    async getTags(calls) {
      if (!calls || !calls.length) return;
      let newCalls = calls;
      let callsId = "";
      for (let i = 0; i < calls.length; i++) {
        if (i === calls.length - 1) {
          callsId += calls[i]._source.linkedid;
        } else {
          callsId += calls[i]._source.linkedid + ",";
        }
      }
      try {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/event/${calls[0]._source.company_id}/calltags/${callsId}`
        );

        for (const element of newCalls) {
          if (res.data[element._source.linkedid]) {
            element._source.tags = res.data[element._source.linkedid];
          }
        }
        return newCalls;
      } catch (e) {
        this.$log.info("[ERRO] > ", e);
      }
    },
    async getCallbacks(calls) {
      if (!calls || !calls.length) return;
      let newCalls = calls;
      let callsId = "";
      for (let i = 0; i < calls.length; i++) {
        if (i === calls.length - 1) {
          callsId += calls[i]._source.linkedid;
        } else {
          callsId += calls[i]._source.linkedid + ",";
        }
      }
      try {
        const res = await this.$http.get(
          `${this.requestLinks.rhistory}/event/${calls[0]._source.company_id}/callback/${callsId}`
        );
        this.$log.info(res.data);
        for (const element of newCalls) {
          if (res.data[element._source.linkedid]) {
            element._source.callbacks =
              res.data[element._source.linkedid];
          }
        }
        return newCalls;
      } catch (e) {
        this.$log.info("[ERRO] > ", e);
      }
    },
  },
  async created() {
    try {
      let numberToSearch = this.activityData.contact.phones
        ? this.activityData.contact.phones[0].ddi +
        this.activityData.contact.phones[0].number
        : this.activityData.payload.contactNumber;
      const res = await this.$http.get(
        `${this.requestLinks.callreport}/call/d/${numberToSearch.replace(
          /[^\d]+/g,
          ""
        )}/3/0`
      );
      this.callsHistory = await this.getTags(res.data) || [];
      this.callsHistory = await this.getCallbacks(this.callsHistory) || [];
      this.callsHistory.sort(function (a, b) {
        if (a._source && b._source) {
          return a._source.calldate < b._source.calldate
            ? 1
            : a._source.calldate > b._source.calldate
              ? -1
              : 0;
        }
      });
    } catch (e) {
      this.$log.info(e);
    }
  },
};
</script>
