<template lang="pug">
.w-full.relative
  label.up-placeholder(v-if="labelTitle") {{ labelTitle }}
  div
    span(v-click-outside="closeFilter")
      .flex.items-center.justify-between.form-control.input(
        @click="search = !search"
      ) {{ name ? name : placeholder }}
        span.material-icons.notranslate.dropdown-icon(v-if="!name")
          | keyboard_arrow_down
        span.material-icons.notranslate.dropdown-icon(v-else, @click.stop="removeFilter()")
          | cancel
      .webhook-select__window.absolute(v-show="search")
        input.webhook-select__window__input(
          :placeholder="this.placeholder",
          @focus="showOptions",
          @input="inputHandler",
          v-model="model[displayAttr || attr]"
        )
        .webhook-select__options-list(v-if="options.length > 0")
          .selectable-option.cursor-pointer(
            class="hover:bg-gray-100",
            v-for="(data, index) in optionsList",
            @click="selectData(data)",
            :key="index"
          ) 
            .text {{ data[displayAttr] || data[attr] || data[dataFilter] }}
</template>

<script>
import Vue from "vue";
export default {
  props: [
    "labelTitle",
    "model",
    "attr",
    "dataFilter",
    "useId",
    "displayAttr",
    "jsonData",
    "placeholder",
  ],
  data() {
    return {
      search: false,
      dataList: [],
      name: "",
      isFilterEnabled: false,
    };
  },
  computed: {
    options() {
      if (!this.dataList) return [];
      return this.dataList.filter((data) => {
        return data[this.dataFilter];
      });
    },
    optionsList() {
      return this.options.filter((option) => {
        if (!this.isFilterEnabled) return true;
        return (
          option[this.dataFilter] &&
          option[this.dataFilter]
            .toLowerCase()
            .includes(this.model[this.displayAttr || this.attr]?.toLowerCase())
        );
      });
    },
  },
  methods: {
    inputHandler() {
      this.isFilterEnabled = true;
      this.$emit("input-insert");
    },
    showOptions() {
      this.search = true;
    },
    selectData(selected) {
      if (this.useId) {
        this.model[this.attr] = selected.id;
      } else this.model[this.attr] = selected[this.dataFilter];
      if (this.displayAttr)
        this.model[this.displayAttr] = selected[this.dataFilter];
      this.search = false;
      this.name = selected.username;
      this.$emit("data-selected", selected);
      Vue.mixin({
        data: () => ({
          callBackData: this.model,
        }),
      });
    },
    removeFilter() {
      if (this.useId) {
        this.model.id = null;
      } else this.model[this.attr] = "";
      if (this.displayAttr) this.model[this.displayAttr] = "";
      this.search = false;
      this.name = "";
      this.$emit("remove-filter");
    },
    closeFilter() {
      this.search = false;
    },
  },
  async created() {
    this.dataList = this.jsonData;

    if (this.model.assignedTo) {
      this.name = this.dataList.filter((element) => {
        return element.id == this.model.assignedTo;
      })[0].username;
    }
  },
  watch: {
    jsonData(newData) {
      this.dataList = newData;
    },
  },
};
</script>

<style lang="scss" scoped>
.up-placeholder {
  font-family: "Inter", sans-serif;
}
.org {
  display: inline-block;
  position: relative;
  width: 100%;

  &-dropdown {
    margin-top: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    background: #ffffff;
    border: 1px solid #e9ecef;
    box-sizing: border-box;
    box-shadow: 0px 7px 16px -12px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    max-height: 200px;
    color: #868e96;
    overflow: auto;
  }
  li {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    padding-left: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #868e96;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40px;
  }
  li:hover {
    background: #f8f9fa;
  }
}
.webhook-select {
  background: #ffffff;
  width: 100%;
  height: 48px;
  margin-left: auto;
  font-family: Inter;
  font-style: normal;
  display: flex;
  align-items: center;
  font-weight: normal;
  padding: 0px 16px 0px 16px;
  font-size: 16px;
  cursor: pointer;
  line-height: 24px;
  color: #495057;
  border-bottom: 1px solid #868e96;

  .material-icons {
    color: #868e96;
    margin-left: auto;
    font-size: 24px !important;
  }

  &__main {
    position: relative;
    margin-left: auto;
    width: 395px;
  }

  &__options-list {
    width: 100%;
    max-height: 240px;
    overflow: auto;
    margin-bottom: 32px;
  }

  &__window {
    margin-top: 8px;
    z-index: 1;
    width: 100%;
    background: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px 16px;
    justify-content: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);

    button {
      font-family: "Inter" sans-serif;
      font-style: normal;
      background: none;
      outline: none;
      border: none;
      cursor: pointer;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #868e96;
    }

    &__input {
      width: 100%;
      height: 2rem;
      background: #ffffff;
      border-radius: 4px 4px 0px 0px;
      border: none;
      height: 48px;
      outline: none;
      margin-bottom: 16px;
      border-bottom: 1px solid #868e96;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      text-indent: 16px;
      color: #495057;
    }
    .selectable-option {
      width: 100%;
      height: 48px;
      padding: 12px 16px;
      cursor: pointer;
      transition: 0.2s;
      border-bottom: 1px solid #e9ecef;

      .text {
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #495057;
      }

      span {
        color: #adb5bd;
        font-size: 26px !important;
      }

      &:hover {
        background: rgba(233, 236, 239, 0.4);
      }
    }
  }
}
</style>
