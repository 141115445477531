<template lang="pug">
.call-received(
  v-if="user",
  class="dark:bg-gray-100",
  ref="inCallId"
  @click="handleClick",
  @mouseenter="handleMouseEnter"
)
  a.call-received-title-text.text-marker.font-inter(style="color: #adb5bd !important") Em ligação
  .call-profile-img-box
    .colega-card-aligner
    .relative
      .w-14.h-14.bg-brand-500.bg-opacity-50.rounded.flex.justify-center.items-center.border.border-brand-100(
        class="dark:invert"
      )
        contact-avatar(
          :identity-contact="identityContact",
          :failed-img="failedImg",
          @failedImg="failedImg = true"
        )
  .call-text-box.mb-0
    a.calling-dial-name.white-text.text-marker {{ identityContact?.name || 'Desconhecido' }}
  div(v-if="keyboard")
    .input-line-aligner
      input.incall-transfer-input(
        ref="inCallInput",
        class="dark:text-black",
        @blur="focus",
        v-model="inputValue"
        @keypress="emmitSound"
      )
      .keyboard-line-separator
    Dialer.scale-up-ver-center(
      @close-keyboard="keyboard = !keyboard",
      :margin="false",
      @hangup-call="hangUp",
      @digit-clicked="digitClicked",
      :hangup="true"
    )
  .align-center.flex-1.overflow-auto.w-full(v-show="!keyboard")
    .client-text(
      v-if="identityContact?.tag",
      style="padding: 5px !important; width: max-content !important; margin-bottom: 2px; margin-top: 0px"
    ) {{ identityContact?.tag }}
    .client-text.Colega.colega-tag.p-1(
      v-else-if="identityContact?.state",
    ) Colega
    a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.organization }}
    a.card-info-text(style="color: #adb5bd !important").text-marker {{ identityContact?.number || callerNumber }}
    .call-line-separator(class="dark:bg-gray-300")
    CallDuration.text-marker.mb-5(v-if="!transferred", :startDate="data.calldate")
    a.card-info-text.bold-text(v-else, style="color: #adb5bd !important") Ligação transferida com sucesso
    TransferBox(
      v-if="transferCall && !successTransferred",
      @hangup-transfer-call="transferSuccess",
      @close-down="transferCall = false",
      @hangup-call="hangUp",
      :extra="extraCall"
    )
    audio(ref="remoteMedia", controls, v-show="false")
    #actionsDiv.actions-div(v-if="!transferCall", style="width: 100%")
      .icons-aligner
        .left-icon(queueData.queue, v-if="isWebRTC")
          button.icon-container(class="transition-all hover:opacity-50 active:scale-90"  v-on:click="transferCall = true")
            .in-call-icons
              span.material-icons.notranslate phone_forwarded
          a.card-info-text.text-marker(style="color: #adb5bd !important") Transferir
        .right-icon(v-if="isWebRTC")
          button.icon-container(class="transition-all hover:opacity-50 active:scale-90"  @click="holdCall")
            div(:class="holdState == 'on' ? 'in-call-icons-active' : 'in-call-icons'")
              span.material-icons.notranslate pause
          a.card-info-text.text-marker(style="color: #adb5bd !important") Hold
        .right-icon(v-if="isWebRTC")
          button.icon-container(class="transition-all hover:opacity-50 active:scale-90"  @click="checkMute")
            div(:class="mute == 'on' ? 'in-call-icons-active' : 'in-call-icons'")
              span.material-icons.notranslate mic_off
          a.card-info-text.text-marker(style="color: #adb5bd !important") Mute

      .icons-aligner
        .right-icon.cursor-pointer(
          @click="openKeyboard",
          style="width: 30%",
          v-if="webphone"
        )
          div(style="display: flex; align-items: center; height: 90px" class="transition-all hover:opacity-50 active:scale-90" )
            .bg-gray-300.w-14.h-14.rounded-full.flex.justify-center.items-center
              span.material-icons.notranslate.text-4xl.text-gray-800(style="font-size: 28px", class="") keyboard
          a.card-info-text.text-marker(style="color: #adb5bd !important") Teclado
        .right-icon(@click="hangUp", v-if="isWebRTC", style="width: 30%")
          div(style="display: flex; align-items: center; height: 90px" class="transition-all hover:opacity-50 active:scale-90" )
            .turnoff-call-icon
              span.material-icons.notranslate(style="font-size: 28px", class="") phone
          a.card-info-text.text-marker(style="color: #adb5bd !important") Desligar
        .right-icon(v-if="showCsatButton || queueCsat", @click="sendToCsat", style="width: 30%")
          div(style="display: flex; align-items: center; height: 90px" class="transition-all hover:opacity-50 active:scale-90")
            .send-csat-icon
              span.material-icons.notranslate(style="font-size: 28px") perm_phone_msg
          a.card-info-text(style="color: #adb5bd !important; width: 100px") Enviar para avaliação
</template>

<script>
import CallDuration from '@/components/CallDisplays/call.duration';
import TransferBox from './transfer-box';
import Dialer from '@/layout/Dialpad/Dialpad.Dialer.vue';
import SipMixin from '@/mixins/invite-calls.js';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import cservice from '@/services/cservice';
import ContactAvatar from '../ContactAvatar.vue';
import rship from '@/services/rship';
import eventBus from '@/main-event-bus';

export default {
  components: {
    CallDuration,
    TransferBox,
    Dialer,
    ContactAvatar,
  },
  mixins: [UserOrContactMixin, SipMixin],
  props: ['extra', 'data'],
  data() {
    return {
      mute: 'off',
      muted: false,
      inputValue: '',
      holdState: 'off',
      isCalling: false,
      keyboard: false,
      selectedMate: '',
      transferCall: false,
      transferred: false,
      successTransferred: false,
      isTheCaller: true,
      failedImg: false,
      queueCsat: false,
    };
  },
  computed: {
    showCsatButton() {
      return (
        this.$route.params.callId == this.queueData.linkedid &&
        this.queueData.queue_csat &&
        this.queueData.queue_csat === '1' &&
        this.isTheCaller
      );
    },
    extraCall() {
      return this.extra;
    },
    call() {
      return this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    queueData() {
      return this.$store.getters['cstate/GET_QUEUE_DATA'];
    },
    session() {
      return this.$store.getters['sip/session'];
    },
    fromId() {
      return this.call?.fromId || this.call?.from_id;
    },
    isMineCall() {
      return this.fromId === this.user.id || this.call.from === this.user.endpoint;
    },
    callerId() {
      return this.isMineCall ? this.call.toId : this.fromId;
    },
    callerNumber() {
      return this.isMineCall ? this.call.to : this.call.from;
    },
  },
  watch: {
    call(newPayload) {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  mounted() {
    if (this.webphone) {
      this.createWebphoneAudioStream();
    }
  },
  methods: {
    handleClick() {
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-in-call-mouse-event',
        payload: { event: 'click' },
      });
    },
    handleMouseEnter() {
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-in-call-mouse-event',
        payload: { event: 'mouse-enter' },
      });
    },
    emmitSound(e) {
      const keyPressed = e.key;
      if (!this.session || !this.session.session) return;
      var options = {
        duration: 100,
        interToneGap: 70,
      };
      this.session.session.sessionDescriptionHandler.sendDtmf(keyPressed, options);
    },
    openKeyboard() {
      this.keyboard = true;
      setTimeout(() => this.focus(), 1);
    },
    digitClicked(number) {
      this.inputValue = this.inputValue + number;
    },
    async sendToCsat() {
      await this._sendCsatCall(this.$route.params.callId, {
        techChannel: this.user.tech_channel,
        endpoint: this.user.endpoint,
      });
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-sent-to-csat',
        payload: {
          techChannel: this.user.tech_channel,
          endpoint: this.user.endpoint,
        },
      });
      this.$emit('hangup-call');
    },
    transferSuccess() {
      this.transferred = true;
      this.successTransferred = true;
      this._hangUpCall();
      setTimeout(() => {
        this.$emit('hangup-call');
        this.transferred = false;
        this.successTransferred = false;
      }, 1500);
    },
    async hangUpCall() {
      await this._hangUpCall();
      this.$emit('hangup-call');
    },
    async holdCall() {
      if (this.holdState == 'off') {
        this.holdState = 'on';
        this.hold();
      } else {
        this.holdState = 'off';
        this.unhold();
      }
    },
    async checkMute() {
      if (this.mute == 'off') {
        this.mute = 'on';
        this.muted = true;
      } else {
        this.mute = 'off';
        this.muted = false;
      }
      try {
        if (this.webphone && this.session) this.muteSession(this.muted);
        else await this.muteFunction(this.mute);
      } catch (error) {
        this.$toast.open({ message: 'Não foi possivel mutar audio', type: 'error' });
      }
    },
    async hangUp() {
      try {
        await this._hangUpCall();
      } catch (error) {}
      this.$emit('hangup-call');
    },
    async getQueueCsat(id) {
      try {
        const res = await cservice.getCompanyQueues();
        const queue = res.find((queue) => queue.name === id);
        this.queueCsat = queue.csat === '1' ? true : false;
      } catch (error) {}
    },
    async initData() {
      await this.setContactOrOperator({
        id: this.callerId,
        number: this.callerNumber,
      });

      if (this.queueData.queue) {
        await this.getQueueCsat(this.queueData.queue);
      }
    },
    focus() {
      this.$refs['inCallInput'].focus();
    },
  },
};
</script>

<style scoped>
.scroll-style {
  height: 56vh !important;
}
</style>

<style>
.input-line-aligner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 46px;
}
.keyboard-line-separator {
  background: #495057;
  height: 1px;
  width: 82.5%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.incall-transfer-input {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 24px;
  background: none;
  border: none;
  outline: none;
  color: #fff;
}
.icon-unknown {
  background-image: linear-gradient(156.96deg, var(--brand-color) 9.13%, var(--brand-orange) 103.34%) !important;
  font-size: 52px !important;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.transfer-icon-aligner {
  display: flex;
  padding-top: 30px;
  align-items: center;
  flex-direction: column;
}
.transfer-activity-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #868e96;
}
.in-call-icons-active {
  color: #212529;
  background: #fff;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.in-call-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #212529;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.noresult-text {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.transfer-search {
  background-color: #fff;
  border-radius: 4px;
  margin-top: 10px;
  width: 290px;
  max-height: 80%;
  overflow: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.transfer-right-aligner {
  width: 55px;
}
.transfer-left-aligner {
  width: 255px;
  margin-bottom: 10px;
}
.cancel-transfer {
  font-family: ' Inter', sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  color: #dc6058 !important;
}
.transfer-box {
  padding: 20px;
  margin-top: 30px;
  width: 320px;
  display: flex;
  justify-self: flex-start;
  flex-direction: column;
  align-items: center;
  height: 56%;
  background: #212529;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;
  border: none !important;
  outline: none !important;
}
.icon-container:focus .incall-icon {
  color: #212529;
  background: #fff;
}
.incall-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  font-weight: 100 !important;
  padding: 0px !important;
  font-size: 18px !important;
  border-radius: 50%;
  color: #fff;
  background: #212529;
}
</style>
