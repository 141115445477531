<template lang="pug">
.content
  CallbackModal(
    @close-modal="actualModal = ''",
    v-if="actualModal === 'assignCallback'",
    :contact="callbackContact"
  )
  link(
    href="https://fonts.googleapis.com/css2?family=Titillium+Web:wght@700&display=swap",
    rel="stylesheet"
  )
  link(
    href="https://fonts.googleapis.com/css2?family=Inter&display=swap",
    rel="stylesheet"
  )
  .container-fluid
    .pagearea.m-0.pt-8
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          .flex.gap-2.justify-start.items-center.p-2.w-fit.transition-all(
            class="hover:bg-zinc-200 rounded-full"
            @click="backToPreviousPage",
            role="button",
          )
            i.material-icons.notranslate.text-brand-500.text-xl &#xe5c4
            span.font-inter.text-zinc-700.font-medium Voltar
          a.title(style="margin: 0px") Detalhe do contato
      .history-contact-box(style="margin-top: 32px")
        ContactBox(
          @opencallback="openCallback",
          v-if="contact",
          :userData="contact"
        )
        ContactHistoryContent(
          :contact-id="$route.params.id",
        )
</template>
<script>
import 'floating-vue/dist/style.css';
import EventDetail from 'src/pages/EventDetail.vue';
import CallbackModal from './Contact.CallbackModal.vue';
import ContactBox from './Contact.ContactBox.vue';
import { Dropdown } from 'floating-vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import ContactHistoryContent from '@/pages/Contacts/Contact.History.Content.vue';
import contactListMixin from '@/mixins/contact-list';

export default {
  mixins: [contactListMixin],
  title: 'Opens - Detalhes do contato',
  components: {
    EventDetail,
    CallbackModal,
    ContactBox,
    FDropdown: Dropdown,
    DropdownItem,
    ContactHistoryContent,
  },
  data() {
    return {
      actualModal: '',
      callbackContact: {},
      previousPage: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPage = from.name;
    });
  },
  methods: {
    backToPreviousPage() {
      if (this.previousPage && this.$route.name !== this.previousPage) {
        this.$router.push({ name: this.previousPage });
      } else {
        this.$router.push({ name: 'Contatos' });
      }
    },
    openCallback(contact) {
      this.callbackContact = contact;
      this.actualModal = 'assignCallback';
      this.$store.commit('SET_ITEMID', contact.id);
    },
    openEventDetail(eventId) {
      const event = document.getElementById(eventId);
      event.style.display = event.style.display == 'none' ? 'block' : 'none';
    },
  },

  async created() {
    this.activeContactId = this.$route.params.id;
  },
};
</script>
