<template lang='pug'>
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Adicione um device
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    .flex.flex-col.mt-12
      IconCircularBg.h-12.w-12.mb-12(:icon="'error'")
      p.text-gray-400.text-center(class="w-4/5") Para concluir este processo, entre em contato com nossa equipe de suporte ou seu suporte em TI.
  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-32.text-brand(
        class="dark:invert"
        @click="$store.commit('modal/setActiveModal', '')"
      ) Fechar
      button.bg-orange.text-white.w-48.h-10.rounded(@click="openLink('https://suporte.opens.com.br/')") FALAR COM SUPORTE
</template>


<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
};
</script>