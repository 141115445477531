<template lang="pug">
.content.flex.flex-col
  .container-fluid
    .pt-8.m-0.pagearea
      .flex.items-start.gap-2.w-full.justify-center.flex-col
        .flex.justify-center.gap-2(
          @click="$router.push({ name: 'Atividades' })",
          role="button"
        )
          span.pl-0.min-w-0.w-5.arrow-icon.notranslate.material-icons arrow_backward
          a.mt-1.back-text Voltar
        .m-0.title Detalhe da atividade
  .flex.w-full.flex-col.items-center.justify-center.gap-2.p-10(v-if="loading")
    p.text-sm.font-plus Carregando...
    loading-ring
  .flex.w-full.flex-col.items-center.justify-center.gap-2.p-10(v-if="error")
    p.text-sm.font-plus Ocorreu algum erro ao carregar esta conversa, <b class="font-plus text-brand-500 cursor-pointer" @click="getChatInfo">tentar novamente</b>
    .material-icons.notranslate.text-red-500 error
  .flex.w-full.flex-col.pl-5.gap-2(v-if="!loading && !error")
    table.table-auto.w-full.border-y
      chat-row(:chat="activity", :hide-expand="true")
    contact-box.w-full.rounded-t-md(:user-data="identityContact", @openCallback="callbackModal = true")
    chat-event-detail.w-full(:chat-data="activity", :opened-by-callback="true")
  callback-modal(
    v-if="callbackModal",
    @close-modal="callbackModal = false",
    :contact="identityContact",
    :linkedId="activityId"
  )
</template>
<script>
import ActivityCard from '@/pages/Callbacks/Callbacks.History.ActivityCard.vue';
import ContactBox from '@/pages/Callbacks/Callbacks.History.ContactBox.vue';
import CallbackModal from '@/pages/Contacts/Contact.CallbackModal.vue';
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import ChatEventDetail from '@/components/ChatEventDetail.vue';
import ChatRow from '@/components/TablesRows/ChatRow.vue';
import LoadingRing from '@/components/LoadingRing.vue';
import ChatReport from '@/services/chat';

export default {
  title: 'Opens - Detalhes da atividade',
  mixins: [UserOrContactMixin],
  components: {
    ChatEventDetail,
    CallbackModal,
    ActivityCard,
    LoadingRing,
    ContactBox,
    ChatRow,
  },
  data() {
    return {
      callbackModal: false,
      loading: true,
      error: false,
      activity: null,
    };
  },
  computed: {
    activityProtocol() {
      return this.$route.params.id;
    },
  },
  methods: {
    async getChatInfo() {
      const { findByProtocol } = ChatReport;
      this.loading = true;
      this.error = false;
      try {
        const res = await findByProtocol(this.activityProtocol, {
          companyId: this.user.company_id,
        });

        const userId = res.createdBy;

        await this.setContactOrOperator({
          id: userId,
        });

        this.activity = { room: res, chatRoomId: res.id };
      } catch (e) {
        console.error(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getChatInfo();
  },
};
</script>
