<template lang="pug">
.flex.justify-between.w-full.h-fit.py-3.px-4.clickable-item.gap-6(@click="$emit('click')")
  .case-title
    .case-title-text {{ caso.title }}
    .case-description {{ caso.description ? caso.description : 'Sem descrição' }}
  .callback-contact.mr-auto
    .callback-callto-user-img(v-if="failedImg") {{ caso.client?.name ? caso.client.name[0].toUpperCase() : caso.client?.username[0].toUpperCase() }}
    img.callback-callto-user-img(
      v-else-if="!failedImg",
      :src="`${requestLinks.clist}/uploads/${caso.client.id}.jpg`",
      @error="failedImg = true"
    )
    .contact-info-aligner
      a.colega-name-text.no-margin {{ caso.client?.name || caso.client?.username }}
      a.card-info-text(style="text-align: left") {{ caso.client?.number || caso.client?.endpoint || caso.client?.phones[0].number }}
    .callback-callto-tag-margin.user-tag(v-if="caso.client?.category") {{ caso.client?.category.name }}
    .user-tag.colega-tag(
      v-else-if="caso.client?.email",
    ) Colega
    .unknown-text.no-tag-margin(v-else) Sem categoria
  CasesStatus(:status="statusTypes[caso.caseStatusId]")
</template>
    
    <script>
import CasesStatus from "@/pages/Cases/Cases.Status.vue";
export default {
  components: { CasesStatus },
  props: ["caso"],
  data() {
    return {
      statusTypes: { 3: "Aberto", 4: "Em andamento", 5: "Fechado" },
      failedImg: false,
    };
  },
};
</script>
<style lang="scss">
.clickable-item {
  cursor: pointer;
  &:hover {
    background: rgba(233, 236, 239, 0.4);
  }
}
</style>
<style lang="scss" scoped>
.callback-contact {
  text-indent: 0;
  margin-top: 0;
  padding: 0.4rem;
}
.case-title {
  max-width: 20rem;
  width: 30%;
  text-indent: 0;
  height: fit-content;
}
.case-title-text {
  max-height: 1.9rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: normal;
  font-size: 0.875rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #495057;
}
.case-description {
  margin-top: 0.2rem;
  font-family: "Inter", sans-serif;
  font-style: normal;
  max-height: 1.8rem;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}
</style>