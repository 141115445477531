<template lang="pug">
button.px-5.py-2.rounded-md.transition-all.font-plus.font-bold.truncate(
  class="dark:invert",
  :class="[disabled ? '!bg-brand-100 cursor-not-allowed hover:!bg-brand-100' : 'bg-brand active:scale-95 ', textSize, buttonType, size]"
  :disabled="disabled"
  @click="$emit('click')"
)
  slot
</template>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    small: Boolean,
    danger: Boolean,
    size: {
      type: String,
      required: false,
      default: 'w-fit',
    },
  },
  computed: {
    textSize() {
      if (this.small) return 'text-xs';
      return 'text-base';
    },
    buttonType() {
      if (this.danger) return 'bg-transparent text-red-500 hover:bg-red-100';
      return 'hover:bg-brand-600 bg-brand text-white disabled:bg-brand-100 disabled:hover:bg-brand-100';
    },
  },
};
</script>
