import PortalVue from 'portal-vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import Hotjar from 'vue-hotjar';
// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';
import 'floating-vue/dist/style.css';
// router setup
import routes from './routes/routes';
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.css';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import VueApexCharts from 'vue-apexcharts';
import VueContentPlaceholders from 'vue-content-placeholders';
import FloatingVue, { VTooltip, VClosePopper, Dropdown, Tooltip, Menu } from 'floating-vue';
// vuex store setup
import store from './store';
// Global Mixin
import GlobalMixin from './globalMixin';
import titlesMixin from './titlesMixin';
import logger from 'vue-bunyan-logger';
// moment setup
import moment from 'moment-timezone';
import mixpanel from 'mixpanel-browser';
import './assets/tailwind.css';
import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import './assets/css/main.scss';
// Cookie-parser setup
import VueCookies from 'vue-cookies';
// axios setup
import axios from 'axios';

import VueTour from 'vue-tour';

import TooltipIcon from '@/components/Icons/tooltip-icon.vue';

require('vue-tour/dist/vue-tour.css');

// plugin setup
Vue.use(VueTour);
Vue.use(PortalVue);

//floating-vue
Vue.use(FloatingVue);
Vue.directive('tooltip', VTooltip);
Vue.directive('close-popper', VClosePopper);
Vue.component('VDropdown', Dropdown);
Vue.component('VTooltip', Tooltip);
Vue.component('AppTooltip', TooltipIcon);
Vue.component('VMenu', Menu);
Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(VueToast, { position: 'top' });
Vue.use(LightBootstrap);
Vue.use(VueApexCharts);
Vue.use(VueCookies);
Vue.use(VueContentPlaceholders);
Vue.use(logger, { name: 'snep7-ui' });
Vue.mixin(titlesMixin);
// Vue Bunyan Logger
Vue.component('v-calendar', Calendar);
Vue.component('v-date-picker', DatePicker);
Vue.component('apexchart', VueApexCharts);
Vue.mixin(GlobalMixin);

moment.locale('pt_br');
Vue.prototype.$moment = moment;
Vue.prototype.$mixpanel = mixpanel;
Vue.prototype.$mixpanel.init(process.env.VUE_APP_MIXPANEL, {
  debug: process.env.VUE_APP_PROD ? false : true,
});
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

import Clarity from '@microsoft/clarity';
Vue.prototype.$clarity = Clarity;

const instance = axios.create({
  headers: {
    'Client-Id': 'yunique-ui',
  },
});
Vue.prototype.$http = instance;

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  router,
  store,
});
