<template lang="pug">
portal(
    :to='outlet'
  )
  .z-50.absolute.h-full.w-full.bg-black.bg-opacity-20.flex.justify-center.align-center(
    @click.self="$emit('click')")
      .fade-in-bottom.w-full.flex.items-center
        slot
</template>

<script>
export default {
  props: {
    mo1: Boolean,
    mo2: Boolean,
  },
  computed: {
    outlet() {
      if (this.mo1) return 'modal-outlet';
      if (this.mo2) return 'modal-outlet-2';
      return 'modal-outlet';
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
