<template lang="pug">
.w-full
  component(:is="`attachment-${attachment.type}`" :attachment="attachment", :provider="provider")
</template>

<script>
import { defineComponent } from 'vue';
import AttachmentImage from './image.vue';
import AttachmentAudio from './audio.vue';
import AttachmentVideo from './video.vue';
import AttachmentDocument from './document.vue';

export default defineComponent({
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    provider: {
      type: String,
      required: true,
    },
  },

  components: {
    AttachmentImage,
    AttachmentAudio,
    AttachmentVideo,
    AttachmentDocument,
  },

  data() {
    return {};
  },
});
</script>
