<template lang="pug">
.cursor-pointer.w-full.py-2.px-2.rounded(
  class="hover:bg-brand-400 hover:bg-opacity-10"
  @click="$emit('click')"
)
  slot(v-if="$slots.default")

  .flex.justify-between.items-center.w-full(v-else)
    p.font-plus.text-sm.font-medium(
      :class="[selected ? 'text-brand' : 'text-black']"
    ) {{ text }}
    .material-icons.notranslate.text-brand.text-sm(v-show="selected") check
</template>

<script>
export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: false,
    },
  },
};
</script>
