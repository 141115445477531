<template lang="pug">
.event-box
  .upside
    span.material-icons.notranslate.note insert_comment
    span.event-text
      | Anota&ccedil;&atilde;o por 
      b {{ data.identifier }}
    span.date-text(
      v-tooltip="formatTimestamp(data.updatedAt)"
    ) {{ formatTimestamp(data.updatedAt) }}
  .downside
    .bg-white.border(class="border-[#e9ecef] md:w-[30rem] xl:w-[36rem] 3xl:w-[42rem]" )
      span.font-inter.text-sm(class="text-[#495057]")
        div.line-break.p-2.break-anywhere(v-html="formatText(data.text)")
</template>

<script>
import { dateFormatMixin } from "@/mixins/date-format.mixin";

export default {
  mixins: [dateFormatMixin],
  props: ["data", "calldate"],
  methods: {
    formatText(text) {
      if (text.includes("\n")) {
        text = text.replace(/\n/g, "<br>");
      }
      if (text.includes("<b>") && text.includes("</b>")) {
        text = text
          .replace(/<b>/g, "<strong>")
          .replace(/<\/b>/g, "</strong>");
      }
      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.event-box {
  width: 100%;
  .upside {
    display: flex;
    gap: 21px;
    align-items: center;
  }

  .note {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    background: var(--blue-default);
    color: #fff;
    font-size: 12px !important;
  }
  .edit-icon {
    color: #adb5bd;
    cursor: pointer;
  }
  .event-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #495057;
  }
  .date-text {
    margin-left: auto;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #868e96;
  }
  .downside {
    margin-left: 10px;
    border-left: 1px solid #e9ecef;
    padding-left: 31px;
    margin-top: 4px;
    padding-top: 16px;
    padding-bottom: 10px;

    .editable-note-box {
      padding: 10px;
      background: #ffffff;
      border: 1px solid #e9ecef;
      box-sizing: border-box;
      border-radius: 4px;
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #495057;
    }
  }
  .event-info {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #495057;
  }
}
</style>
