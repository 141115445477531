<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-zinc-50.divide-y.flex.flex-col.justify-start(class="w-[42rem]")
      .w-full.h-24.flex.justify-start.items-center.pl-8
        p.text-zinc-400.font-extrabold.text-2xl Cancelar atividade
      .w-full.h-32.flex.justify-start.items-start.pl-8.pt-4
        p Esta ação ira cancelar a atividade de retorno, continuar?
      .w-full.flex.flex.justify-center.items-center.py-8
        button.bg-brand-orange.text-white.w-36.rounded.px-4.py-2.grow-0.mr-8.font-extrabold(@click="cancelCallback" class="dark:invert") CONFIRMAR
        button.text-brand-600.w-20.grow-0(class="dark:invert" @click="$store.commit('modal/setActiveModal', '')") Cancelar
</template> 

<script>
import Overlay from "@/components/Modals/Overlay.vue";
import { updateCallback } from "@/services/cblist.js";
export default {
  components: {
    Overlay,
  },
  computed: {
    context() {
      return this.$store.getters["modal/getContext"];
    },
  },
  methods: {
    async cancelCallback() {
      try {
        await updateCallback({
          callbackId: this.context.callback.id,
          payload: { status: 303 },
        });
        this.$store.commit('modal/setActiveModal', '')
        this.$toast.open({ message: "Atividade cancelada", type: "success" });
        this.context.onSuccess()
      } catch (error) {
          this.$store.commit('modal/setActiveModal', '')
        this.$toast.open({
          message: "Não foi possivel cancelar essa atividade",
          type: "error",
        });
      }
    },
  },
  created() {},
};
</script>
</script>