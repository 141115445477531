<template lang="pug">
.w-full.relative
  label.font-semibold.text-gray-500.font-inter(v-if="label") {{ label }}
  v-dropdown.w-full(placement="bottom-center", auto-size="min")
    .flex.border.border-gray-200.rounded-md(
      class="hover:bg-gray-100 cursor-pointer transition-colors"
      v-if="selectedContact?.contactId"
    )
      contact-phone-row(
        :data="selectedContact"
      )
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    .w-full.border.border-gray-200.rounded-md.flex.justify-between.py-3.font-inter(
      v-else
      class="bg-[#F8F9FA]"
    )
      p.ml-5.text-base Selecione um número de telefone
      .material-icons.notranslate.mr-3.text-gray-400 keyboard_arrow_down
    template(#popper)
      .flex.w-full.p-1.flex-col.items-start
        icon-input.w-full(
          label="Contato",
          v-model="search"
        )
        p.text-xs.text-gray-500.font-plus.mt-1 Pesquise por qualquer campo do contato, nome, número, email, organização, CPF ou categoria
      .flex.max-h-80.flex-col.overflow-y-scroll.justify-start.min-w-full
        contact-phone-row(
          v-for="contactPhone in contactsPhonesList",
          :key="contactPhone.id",
          :data="contactPhone",
          :selected="contactPhone?.id == selectedContact?.id",
          @select-contact="selectContact",
        )
        .flex.p-2(v-if="loading")
          loading-ring
        observer(
          v-else 
          @intersect="loadMoreContacts"
        )
</template>

<script>
import ContactPhoneRow from '@/components/TablesRows/contact-phone-row.vue';
import { hideAllPoppers, Dropdown } from 'floating-vue';
import contactList from '@/services/contact-list';
import IconInput from '../Inputs/IconInput.vue';
import LoadingRing from '../LoadingRing.vue';
import Observer from '../Observer.vue';
import { debounce } from '@/helpers';
import user from '@/store/user';

export default {
  components: {
    vDropdown: Dropdown,
    ContactPhoneRow,
    LoadingRing,
    IconInput,
    Observer,
  },
  props: {
    label: {
      type: String,
      default: 'Selecione o número de telefone',
    },
    placeholder: {
      type: String,
      default: 'Selecione um número de telefone',
    },
    preSelectedUser: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      search: '',
      lastRequestLength: 0,
      offset: 0,
      loading: false,
      contactsPhonesList: [],
      selectedContact: {},
    };
  },
  watch: {
    search() {
      this.searchContact();
    },
  },
  methods: {
    selectContact(value) {
      this.selectedContact = value;
      this.$emit('selected-contact', value);
      hideAllPoppers();
    },
    searchContact: debounce(async function () {
      this.contactsPhonesList = [];
      this.offset = 0;
      this.lastRequestLength = 0;
      await this.getContacts();
    }, 500),
    async loadMoreContacts() {
      if (this.lastRequestLength < 20) return;
      this.offset = this.offset + 20;
      await this.getContacts();
    },
    async getContacts() {
      this.loading = true;
      const { getContactsPhones } = contactList;
      try {
        const payload = {
          limit: 20,
          offset: this.offset,
          companyId: this.user.company_id,
          orderBy: 'name',
          associations: ['organization', 'category', 'campaigns'],
        };
        if (this.search) {
          payload.filter = this.search;
        }
        const data = await getContactsPhones(payload);
        this.lastRequestLength = data.phones.length;
        this.contactsPhonesList = [...this.contactsPhonesList, ...data.phones];
      } catch (e) {
        this.$toast.error('Ocorreu algum erro na busca');
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.getContacts();
    if (this.preSelectedUser) {
      this.search = this.preSelectedUser.contact.name;
      this.selectContact(this.preSelectedUser);
    }
  },
};
</script>
