import ContactMixin from '@/mixins/contact-list';
import OperatorMixin from '@/mixins/user-list';

export default {
  mixins: [OperatorMixin, ContactMixin],
  computed: {
    identityContact() {
      return this.activeContactId ? this.contact : this.operator;
    },
  },

  methods: {
    setContactOrOperator({ id, number }) {
      this.activeContactId = null;
      this.activeEndpoint = null;
      if (number?.length < 8) this.activeEndpoint = number;
      else this.activeContactId = id;
    },
  },
};
