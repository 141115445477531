import cService from '@/services/cservice.js';
export default {
  namespaced: true,
  state: {
    preventTagEdit: false,
  },
  mutations: {
    setPreventTagEdit(state, value) {
      state.preventTagEdit = value;
    }
  },
  getters: {
    preventTagEdit: state => state.preventTagEdit
  },
  actions: {
    async fetchPreventTagEdit(context) {
      try {
        const { data } = await cService.getCompanieConfigByKey('prevent-tag-edit');
        context.commit('setPreventTagEdit', data?.value === 'true');
      } catch (error) {
        console.error(error);
      }
    },
  },
};
