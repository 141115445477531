import _ from "lodash";

export default {
  state: {
    companyMates: null,
    clistMates: null,
    lookUpContactNumber: {},
    colleagueLookUpTable: {},
  },
  mutations: {
    SET_COMPANYMATES: function (state, payload) {
      payload.forEach((colleague) => {
        _.set(state.colleagueLookUpTable, colleague.id, colleague);
      });
      state.companyMates = payload;
    },
    SET_CLISTMATES: function (state, payload) {
      state.clistMates = payload;
    },
    ADD_TO_LOOK_UP_TABLE: function (state, { key, user }) {
      _.set(state.lookUpContactNumber, key, user);
    },
    UPSERT_COLLEAGUE_LOOK_UP_TABLE: function (state, { key, colleague }) {
      _.set(state.colleagueLookUpTable, key, colleague);
    },
  },
  getters: {
    COLLEAGUE_LOOKUP_TABLE(state) {
      return (searchKey) => {
        return state.colleagueLookUpTable[searchKey];
      };
    },
    USER_LOOKUP_TABLE(state) {
      return (number) => {
        return state.lookUpContactNumber[number];
      };
    },
    GET_ALLMATES: function (state) {
      if(!state.companyMates) return []
      if(!state.clistMates) return state.companyMates
      return state.companyMates.concat(
        state.clistMates.map((e) => {
          return { ...e, username: e.name };
        })
      );
    },
    GET_COMPANYMATES: function (state) {
      return state.companyMates;
    },
    GET_CLISTMATES: function (state) {
      return state.clistMates;
    },
  },
};
