<template lang="pug">
.text-inside-brand.relative.w-full(
  class="h-[110px]"
  v-if="loaded",
  @click="$router.push({ name: 'Detalhe Histórico', params: { id: call.linkedId } })"
)
  .flex.w-full.pl-3.p-2.h-full.items-center.gap-2
    .flex.flex-col.justify-start.pt-4.h-full
      .list-user-img(style="position: relative")
        .flex(v-if="!identityContact?.unknown")
          .firstLetter(v-if="failedImg") {{ identityContact?.name[0] }}
          img.w-8.h-8.rounded.object-cover(
            v-else-if="!failedImg",
            :src="identityContact?.avatarUrl",
            @error="failedImg = true"
          )
          .track-icon.recent-call-align-icon(
            :class="mapCallType[firstStep.status.toUpperCase()]"
          )
            span.material-icons.notranslate.text-white.history-call-indicator-icon {{ 'call_' + translateCallType(firstStep) }}
        .firstLetter(v-else="failedImg") D
    .grid.items-start.w-full
      span.font-semibold.font-inter.truncate(
        class="dark:text-black dark:font-extrabold",
        v-tooltip="identityContact?.name"
      ) {{ identityContact?.name }}
      .flex.justify-end.items-start.w-fit
        span.font-inter.text-right(
          class="text-[10px] text-[#868e96] dark:text-zinc-900 dark:font-semibold"
        ) {{ activityDateFormat(firstStep) }}
      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(
        v-tooltip="identityContact?.number || callerNumber",
        class="dark:text-zinc-700"
      ) {{ identityContact?.number || callerNumber}}
      span.text-xs.font-inter.text-zinc-400.leading-5.truncate(class="dark:text-zinc-900") {{ identityContact?.organization || 'Sem organização' }}
    .flex.flex-col.justify-between.relative.h-full.p-3(
      class="max-w-[4rem]"
    )
      span.truncate.user-tag.flex.justify-start.colega-tag(
        v-if="identityContact?.endpoint"
      ) Colega
      span.truncate.user-tag.flex.justify-start(
        class="max-w-[70px]",
        v-else-if="identityContact?.category?.name",
        v-tooltip="identityContact.category.name"
      ) {{ identityContact?.category.name }}
      .items-center.justify-center.flex.h-full.mt-3(class="dark:invert")
        i.fa.fa-phone.items-center.justify-center.flex.rounded-full.transition-all.text-2xl.w-11.h-11(
          class="hover:bg-brand-gradient hover:text-white text-[#58dca5]",
          @click.stop="makeCall()",
          role="button",
          v-tooltip="'Ligar para ' + identityContact?.name || 'Contato desconhecido'"
        )
</template>

<script>
import UserOrContactMixin from '@/mixins/user-or-contact.mixin';
import eventBus from '@/main-event-bus';

export default {
  props: {
    call: {
      type: Object,
      required: true,
    },
  },
  mixins: [UserOrContactMixin],

  data() {
    return {
      failedImg: false,
      loaded: false,
      mapCallType: {
        ANSWERED: 'received',
        NOANSWER: 'missed',
        ABANDONED: 'missed',
        TIMEDOUT: 'missed',
      },
    };
  },
  methods: {
    activityDateFormat(item) {
      let timestamp = item.createdAt;
      if (typeof timestamp === 'object') timestamp = this.$moment();
      const date = this.$moment.tz(timestamp, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm');
    },
    translateCallType(item) {
      const idToSearch = this.user.id;
      const fromIds = this.call.steps.map((step) => step.fromId);

      const returnIcon = {
        ANSWERED: () => {
          return fromIds.includes(idToSearch) ? 'made' : 'received';
        },
        NOANSWER: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
        ABANDONED: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
        TIMEDOUT: () => {
          return fromIds.includes(idToSearch) ? 'missed_outgoing' : 'missed';
        },
      };

      let type = returnIcon[item?.status?.toUpperCase()];
      if (type) return type(item);
    },
    makeCall() {
      if (this.$store.getters['sip/agent']) {
        this.$store.dispatch('sip/makeCall', {
          callNum: this.identityContact.number || this.callerNumber,
          toId: this.identityContact.id,
        });
      } else this.callNumber(this.identityContact.number);
      eventBus.$emit('UX-event', {
        eventName: 'dialpad-recent-make-call',
        payload: {
          toNumber: this?.identityContact?.number || this?.callerNumber,
          toId: this?.identityContact?.id,
        },
      });
    },

    async getContact() {
      await this.setContactOrOperator({
        id: this.callerId,
        number: this.callerNumber,
      });
    },
  },
  computed: {
    firstStep() {
      return this.call.steps[0];
    },
    fromId() {
      return this.firstStep?.fromId;
    },
    isMineCall() {
      return this.firstStep?.fromId === this.user.id || this.firstStep.fromNumber === this.user.endpoint;
    },
    callerId() {
      return this.isMineCall ? this.firstStep.toId : this.fromId;
    },
    callerNumber() {
      return this.isMineCall ? this.firstStep.toNumber : this.firstStep.fromNumber;
    },
  },
  async created() {
    try {
      await this.getContact();
    } catch (error) {
    } finally {
      this.loaded = true;
    }
  },
};
</script>

<style>
.recent-call-align-icon {
  position: absolute;
  top: 60%;
  right: 65%;
}
</style>
