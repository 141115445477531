import $http from "./client";
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_NOTIFY}`;

function connectSSE({ userId }) {
  try {
    const evtSource = new EventSource(`${service}/user/live/${userId}`);
    return evtSource;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function getMostRecentNotifications({ userId }) {
  try {
    const { data } = await $http.get(`${service}/user/${userId}/recent`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function updateNotification({notificationId}){
  try {
    const { data } = await $http.put(`${service}/${notificationId}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function markAllAsSeen({ userId }) {
  try {
    const { data } = await $http.put(`${service}/user/${userId}/seen`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function markAllAsRead({ userId }) {
  try {
    const { data } = await $http.put(`${service}/user/${userId}/read`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
}

export default {
  connectSSE,
  getMostRecentNotifications,
  markAllAsSeen,
  markAllAsRead,
  updateNotification
};
