<template lang="pug">
button.px-5.py-2.bg-brand.bg-opacity-5.text-brand-400.rounded-md.transition-all.font-plus.font-bold.w-fit(
  :class='[{"!px-3 !py-1 !text-xs": sm, "cursor-not-allowed text-brand-100 !hover:bg-opacity-5": disabled, "hover:bg-opacity-10 active:scale-95": !disabled }]'
  class="group dark:invert",
  :disabled="disabled"
  @click="$emit('click')"
)
  slot
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
