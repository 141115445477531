export default {
  namespaced: true,
  state: {
    activeOverlay: false,
    context: {},
  },
  mutations: {
    setActiveOverlay: (state, payload) => {
      if (!payload) {
        state.activeOverlay = null;
        return;
      }
      if (typeof payload === "string" || payload instanceof String) {
        state.activeOverlay = payload;
        return;
      }
      if (payload.context) {
        state.context = payload.context;
      }
      state.activeOverlay = payload.name;
    },
    setContext: (state, value) => {
      state.context = value;
    },
  },
  getters: {
    activeOverlay: (state) => {
      return state.activeOverlay;
    },
    getContext: (state) => {
      return state.context;
    },
  },
};
