<template lang="pug">
.w-full.bg-neutral-200.bg-opacity-70.py-5.px-8.flex.justify-between.items-center.gap-4
  .flex.gap-4
    .flex.bg-brand-200.rounded-md.w-16.h-16
      .firsLetter.text-brand-500.text-2xl(class="dark:invert", v-if="userData.invalid") {{ firstContactNameLetter }}
      img(
        v-else,
        style="border-radius: 4px",
        :src="`${requestLinks.clist}/uploads/${userData.id}.jpg`",
        @error="userData.invalid = true; $forceUpdate()"
      )
    .flex.flex-col.items-start.py-1.justify-between
      .font-inter.text-base.text-gray-900.text-marker {{ name }}
      .font-inter.text-sm.text-gray-500.opacity-90.font-regular(
        class="dark:text-black dark:font-semibold",
        v-if="userData.organization"
      ) {{ userData.organization.name }}
      .font-inter.text-sm.text-gray-500.opacity-90.font-regular(
        v-else-if="userData.occupation"
      ) {{ userData.occupation || `Sem organização` }}
    .mb-auto.mt-2.py-1.px-3.rounded-full.flex.gap-2.bg-white.border.font-inter.border-grey.text-gray-700.text-xs(
      v-if="userData.category?.name"
    ) {{ userData.category.name }}
  .flex.flex-wrap.gap-2.justify-start.text-marker(v-if="userData.phones")
    .font-inter.text-sm.text-gray-500(v-for="email in userData.emails") {{ email.address }}
    .font-inter.text-sm.text-gray-500(v-for="phone in userData.phones") {{ formatInputValue(phone) }}
  .flex.flex-wrap.gap-2.justify-start.text-marker(v-else)
    .font-inter.text-sm.text-gray-500(v-if="userData.emails") {{ userData.emails[0].address }}
    .font-inter.text-sm.text-gray-500 {{ userData.callerNumber }}
  .flex.gap-4
    v-dropdown(placement="bottom")
      .rounded-full.bg-gray-400.w-7.material-icons.notranslate.text-white.cursor-pointer.transition(
        class="p-0.5 hover:bg-gray-600"
      ) more_horizontal
      template(#popper)
        ul.divide-y(v-close-popper)
          dropdown-item(
            @click="!userData.id?$router.push({ name: 'AddContact'}):$router.push({ name: 'EditContact', params: { id: userData.id } })",
            :label=`userData.id?'Editar Contato':'Criar Contato'`,
            icon="e3c9"
          )
          dropdown-item(
            @click="$emit('openCallback', userData)",
            label="Atribuir retorno",
            icon="e85d"
          )
          dropdown-item(
            label="Iniciar conversa",
            icon="e0b7",
            @click="$store.commit('modal/setActiveModal', { name: 'StartChat', context: { contact: userData } })"
          )
    v-dropdown(placement="bottom", v-if="userData.phones")
      .rounded-full.bg-green-300.material-icons.notranslate.text-white.cursor-pointer.transition.text-xl.h-8.w-8.grid.place-items-center(
        class="hover:bg-green-400 dark:invert dark:bg-green-400"
      ) phone
      template(#popper)
        ul.divide-y
          dropdown-item(
            v-for="(phone, index) in userData.phones",
            :label="`${formatInputValue(phone)}`",
            :key="phone?.number"
            icon="e0cd",
            @click="makeCall(phone?.number)"
          )
    v-dropdown(placement="bottom", v-else)
      .rounded-full.bg-green-300.material-icons.notranslate.text-white.cursor-pointer.transition.text-xl.h-8.w-8.grid.place-items-center(
        class="hover:bg-green-400 dark:invert dark:bg-green-400"
      ) phone
      template(#popper)
        ul.divide-y
          dropdown-item(
            :label="`${formatInputValue(userData.endpoint) || userData.number || userData.callerNumber}`",
            icon="e0cd",
            @click="userData.endpoint ? makeCall(userData.endpoint) : userData.number ? makeCall(userData.number) : makeCall(userData.callerNumber)"
          )
</template>

<script>
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import { Dropdown } from 'floating-vue';
export default {
  props: ['userData'],
  components: {
    VDropdown: Dropdown,
    DropdownItem,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    firstContactNameLetter() {
      if (!this.userData) return '?';
      if (!this.userData.name) return '?';
      return this.userData.name[0].toUpperCase();
    },
    activityId() {
      return this.$route.params.id;
    },
    name() {
      return this.userData.callerName;
    },
  },
  methods: {
    openCallback() {
      this.$emit('open-callback', this.userData);
    },
    checkNumber(number) {
      if (number != 'Não identificado') {
        return number;
      } else {
        return number;
      }
    },
    saveActualItem(itemId) {
      this.$store.commit('SET_ITEMID', itemId);
    },
    formatInputValue(number) {
      if (number?.number?.length > 4) {
        return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(number?.number, 'BR'), this.PNF.INTERNATIONAL);
      } else if (number?.number?.length === 4) {
        return 'SIP ' + number?.number;
      }
      return number?.number;
    },
    makeCall(num) {
      if (this.$store.getters['sip/agent']) this.$store.dispatch('sip/makeCall', { callNum: num });
      else this.callNumber(num);
    },
  },
  mounted() {
    this.loaded = true;
  },
};
</script>

<style>
.contact-box-right-absolute {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  width: 93%;
}
</style>
