<template lang="pug">
Dropdown(:visible="visible", @close="close")
  template(v-slot:input)
    label.text-sm.text-gray-500.font-inter Cliente
    icon-input(
      @click="open",
      label="Digite 3 letras para buscar"
      v-model="input"
    )
  template(v-slot:options)
    div(v-if="!searching") 
      li(
          v-for="(data, index) in list || clientListByNumber",
          @click="selectData(data)",
          :key="index",
          v-click-outside="close"
        ) {{ data.BusinessName || data.businessName }}
    .flex.justify-start.py-2.px-4(v-else) 
      img.h-5.w-5.mr-2.animate-spin(src="~@/assets/images/spinner.png" alt="altText")
      p.text-sm.text-zinc-400.font-extrabold Procurando

</template>

<script>
import Dropdown from "@/components/Inputs/Dropdown.vue";
import IconInput from "@/components/Inputs/IconInput.vue"
export default {
  props: ["movideskToken"],
  components: { Dropdown, IconInput },
  data() {
    return {
      debouncedInput: "",
      timeout: null,
      visible: false,
      clientListByName: [],
      clientListByNumber: [],
      searching: false,
      error: false,
    };
  },
  methods: {
    close() {
      this.visible = false;
    },
    open() {
      setTimeout(() => {
        this.visible = true;
      }, 200);
    },
    async searchClientsByLetters() {
      try {
        this.searching = true;
        const r = await this.$http.get(
          `https://api.movidesk.com/public/v1/persons?token=${this.movideskToken}&$select=id,codeReferenceAdditional,businessName,corporateName,isactive&$expand=contacts&$filter=indexof(businessName, '${this.debouncedInput}') gt -1 OR indexof(corporateName, '${this.debouncedInput}') gt -1&$orderby=businessName asc`
        );
        this.clientListByName = r.data;
        this.searching = false;
      } catch (error) {
        this.clientListByName = [];
        this.error = false;
      }
    },
    async searchClientByNumber() {
      try {
        const from = this.$store.getters["cstate/GET_CALL_PAYLOAD"].from;
        const r = await this.$http.get(
          `https://api.movidesk.com/public/v1/persons?token=${this.movideskToken}&$select=id,codeReferenceAdditional,businessName,corporateName,isactive&$expand=contacts&$filter=contacts/any(e: e/contact eq '${from}')  AND isActive eq true&$orderby=businessName asc`
        );
        this.clientListByNumber = r.data;
      } catch (error) {
        this.clientListByNumber = [];
      }
    },
    selectData(selected) {
      this.debouncedInput = selected.BusinessName || selected.businessName;
      this.visible = false;
      this.$emit("data-selected", selected);
    },
  },
  computed: {
    list() {
      return this.clientListByName
        .concat(this.clientListByNumber)
        .filter((item) => {
          if (item.BusinessName)
            return item.BusinessName.toLowerCase().includes(
              this.input.toLowerCase()
            );
          else if (item.businessName)
            return item.businessName
              .toLowerCase()
              .includes(this.input.toLowerCase());
        });
    },
    input: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.debouncedInput = val;
        this.visible = true;
        if (val.length > 2) {
          this.timeout = setTimeout(() => {
            this.searchClientsByLetters();
          }, 400);
        } else {
          this.clientListByName = [];
        }
      },
    },
  },
  created() {
    this.searchClientByNumber();
  },
};
</script>
