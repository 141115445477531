<template>
  <div class="page-content">
    <div class="step-header">
      <div class="step-title">Vincular Device SIP</div>
      <div class="step-counter-passed"></div>
      <div class="step-counter"></div>
    </div>
    <div class="line-separator"></div>
    <div class="step-subtitle">
      Para vincular seu device SIP, siga as instruções abaixo:
    </div>
    <div class="page-aligner">
      <div class="step-page">
        <div class="step-separator">
          <div class="step-horizontal">
            <div class="step-ball">1</div>
            <div class="step-margin-top">
              <span class="step-text"
                >Em seu device SIP, ligue para o número:</span
              >
              <div class="number-text">*90</div>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 64px" class="step-separator">
          <div class="step-horizontal">
            <div class="step-ball">2</div>
            <div class="step-margin-top">
              <span class="step-text"
                >Ao ouvir a mensagem solicitando o código de autorização, digite
                o código a seguir:</span
              >
              <div class="number-text">{{ code }}</div>
              <div class="progress-bar-empty">
                <div class="progress-bar-fill"></div>
              </div>
              <a style="font-size: 11px" class="step-text"
                >{{ expireText
                }}<a
                  style="font-size: 11px; font-weight: bold"
                  class="step-text"
                  >{{ timer }}</a
                ></a
              >
            </div>
          </div>
        </div>
        <div class="step-separator">
          <div class="step-horizontal">
            <div class="step-ball">3</div>
            <div class="step-margin-top">
              <span class="step-text"
                >Aguarde a mensagem de áudio de confirmação de vínculo de
                device.</span
              >
            </div>
          </div>
        </div>
        <div class="cancel-button-aligner">
          <button v-on:click="$emit('close')" class="buttoncancel">
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import io from "socket.io-client";

export default {
  data() {
    return {
      code: "????",
      socket: null,
      timer: 60,
      expireText: "Expira em ",
    };
  },
  async created() {
    this.socket = io.connect(
      `${process.env.VUE_APP_WEBSOCKET_SCHEMA}://${process.env.VUE_APP_CSTATE}`,
      {
        query: {
          token: this.accessToken,
          device: this.user.endpoint,
          room: this.user.company_id,
          userId: this.user.id
        },
        reconnect: true,
      }
    );

    this.socket.on("connect", () => {
      this.$log.info("Connected to custumer-state");
    });

    this.socket.on("enabled", (data) => {
      this.$emit("openSuccessModal");
    });

    try {
      const r = await this.$http.post(
        `${this.requestLinks.cservice}/companies/users/devices`,
        {
          type: "peer",
        }
      );
      this.code = r.data.code;
    } catch (e) {
      this.$log.info(e);
    }
    this.countDownTimer();
  },
  methods: {
    countDownTimer() {
      if (this.timer > 0) {
        setTimeout(() => {
          this.timer -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        (this.timer = ""), (this.expireText = "Expirado");
        this.$emit("openFailedModal");
      }
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.off();
      this.socket.disconnect();
    }
  },
};
</script>
<style>
.progress-bar-empty {
  margin-top: 10px;
  width: 86px;
  height: 3px;
  left: 203.84px;
  top: 343px;
  background: #e9ecef;
}
.progress-bar-fill {
  margin-top: 10px;
  width: 0px;
  height: 3px;
  left: 203.84px;
  top: 343px;
  animation-name: fillBar;
  animation-duration: 65s;
  animation-fill-mode: forwards;
  background: var(--brand-color);
}
@keyframes fillBar {
  0% {
    width: 0%;
  }
  12.5% {
    width: 12.5%;
  }
  25% {
    width: 25%;
  }
  37.5% {
    width: 37.5%;
  }
  50% {
    width: 50%;
  }
  62.5% {
    width: 62.5%;
  }
  75% {
    width: 75%;
  }
  87.5% {
    width: 87.5%;
  }
  100% {
    width: 100%;
  }
}
.cancel-button-aligner {
  display: flex;
  justify-content: center;
}
.step-separator {
  margin-bottom: 37px;
}
.step-margin-top {
  width: 447px;
  margin-top: 7px;
  height: 51px;
  margin-left: 15px;
}
.number-text {
  font-family: "Titillium Web", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  margin-top: 6px;
  color: #868e96;
}
.step-text {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 7px;
  color: #868e96;
}
.step-horizontal {
  display: inline-flex;
}
.page-aligner {
  display: flex;
  justify-content: center;
}
.step-ball {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  background: rgba(242, 108, 69, 0.1);
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  color: var(--brand-color);
}
.step-page {
  margin-top: 32px;
  width: 80%;
}
.step-subtitle {
  margin-top: 32px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}
.page-content {
  box-shadow: 0px 0px 10000px 10000px rgba(0, 0, 0, 0.8);
  position: absolute;
  z-index: 99999;
  background: #ffffff;
  width: 717px;
  height: 533px;
  padding: 32px 49px;
}
.step-header {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}
.step-counter {
  margin-left: 5px;
  width: 32px;
  height: 3px;
  background: #e9ecef;
}
.step-counter-passed {
  margin-left: 5px;
  width: 32px;
  height: 3px;
  background: var(--brand-color);
}
.line-separator {
  width: 100%;
  height: 0px;
  margin-top: 20px !important;
  border: 1px solid #dee2e6;
}
</style>
