<template lang="pug">
Overlay
  template(v-slot:content)
    .max-w-3xl.bg-white.flex.flex-col.py-4.pb-8.px-10(class="w-1/2")
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter Vincular caso
      .flex.flex-col.items-start.w-full.gap-2(v-if="loaded")
        DropdownCases(
          :model="caso",
          :attr="'name'",
          labelTitle="Caso",
          :displayAttr="'name'",
          :jsonData="casesList",
          :useId="true",
          :dataFilter="'name'",
          @data-selected="selectCase",
          @remove-filter="resetResponsible",
          :placeholder="'Selecione um caso existente'"
        )
        .w-full(v-if="!caso.id")
          .step-title.text-sm.mt-4 Ou crie um novo caso
          label.up-placeholder Título
          input.form-control.input(v-model="title")
          label.up-placeholder Descrição
          textarea.form-control(
            placeholder="Insira uma descrição",
            v-model="description"
          )
          DropdownTabInput(
            :model="responsible",
            :attr="'name'",
            labelTitle="Responsável",
            :displayAttr="'name'",
            :jsonData="responsibleList",
            :useId="true",
            :dataFilter="'name'",
            @remove-filter="resetResponsible",
            :placeholder="'Selecione um agente ou grupo responsável'"
          )
          DropdownClients(
            :model="client",
            :labelTitle="'Cliente'",
            :attr="'name'",
            :displayAttr="'name'",
            :jsonData="clientsList",
            :useId="true",
            :dataFilter="'name'",
            :placeholder="'Selecione um cliente'"
          )
        .cancel-button-aligner.mx-auto.mt-auto.gap-2
          v-button-secondary(@click="close()") Cancelar
          v-button(@click="linkCase()") Vincular
      .flex.items-center.flex-col.mt-5.gap-2(v-else)
        p Carregando...
        loading-ring
</template>

<script>
import DropdownTabInput from '@/components/Dropdown/dropdown-tab-input.vue';
import DropdownCases from '@/components/Dropdown/dropdown-cases.vue';
import Overlay from '@/components/Modals/Overlay.vue';
import DropdownClients from '@/components/Dropdown/dropdown-clients.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import VButton from '../Buttons/v-button.vue';
import CService from '@/services/cservice';
import LoadingRing from '../LoadingRing.vue';

export default {
  components: {
    DropdownTabInput,
    DropdownCases,
    DropdownClients,
    Overlay,
    VButtonSecondary,
    VButton,
    LoadingRing,
  },
  data() {
    return {
      companyUserList: [],
      clientsList: [],
      responsibleList: {
        agents: [],
        groups: [],
      },
      responsible: {
        user: '',
        id: '',
      },
      client: {
        name: '',
        id: '',
      },
      caso: {},
      title: '',
      description: '',
      loaded: false,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
    workGroups() {
      return this.$store.getters['performance/WORK_GROUPS'];
    },
    casesList() {
      const casosWithClients = this.addClientsToCases(this.$store.getters['GET_CASES']);
      const casos = casosWithClients.filter((caso) => {
        return caso.caseStatusId != 5;
      });

      if (this.context.contact.occupation) return casos;

      const sortedCases = [
        ...casos.filter(({ client }) => client?.id == this.context.contact.id),
        ...casos.filter(({ client }) => client?.id != this.context.contact.id),
      ];
      return sortedCases;
    },
    callId() {
      if (this.context.callId) return this.context.callId;
      return this.$route.params.callId || this.$route.params.id;
    },
  },
  methods: {
    selectCase(caso) {
      this.caso = caso;
    },
    async createCase() {
      const caso = {
        description: this.description,
        title: this.title,
        customerId: this.client.id,
        assignedTo: this.responsible.id,
        createdBy: this.user.id,
        caseStatusId: 3,
      };
      try {
        const res = await this.$http.post(`${this.requestLinks.cases}/cases`, caso);
        this.caso = res.data;
        this.$store.commit('SET_CASES', []);
        this.$store.dispatch('updateCases', this.$http);
      } catch (error) {}
    },
    async linkCase() {
      if (!this.caso.id) {
        await this.createCase();
      }
      try {
        if (!this.callId) return console.error('CallId does not exist');
        let newAction = {
          caseId: this.caso.id,
          actionId: this.callId.toString(),
          actionType: this.context.type ? this.context.type : 'call',
        };
        const res = await this.$http.post(`${this.requestLinks.cases}/cases-feed/link-action`, newAction);
        this.$store.commit('SET_CASES', []);
        this.$store.dispatch('updateCases', this.$http);
        this.$toast.success('Caso vinculado com sucesso!');
        this.close();
      } catch (error) {
        this.$toast.error('Erro ao vincular caso!');
      }
    },
    resetResponsible() {
      this.responsible = {
        user: '',
        id: '',
      };
    },
    addClientsToCases(casos) {
      casos.forEach((element) => {
        const client = this.clientsList.filter((client) => {
          return element.customerId == client.id;
        })[0];

        element.client = client;
      });
      return casos;
    },
    close() {
      this.$store.commit('modal/setActiveModal', '');
    },
  },
  async created() {
    await this.$store.dispatch('updateCases', this.$http);
    try {
      const resClist = await this.$http.get(`${this.requestLinks.clist}/contact`);
      this.clientsList = resClist.data;
      this.clientsList = this.clientsList.sort((a, b) => a.name.localeCompare(b.name));
      this.companyUserList = await CService.getAllUsers({ status: 'activated', withoutOpensUsers: true });
      this.responsibleList.agents = this.companyUserList;
      this.workGroups = this.workGroups.sort((a, b) => a.name.localeCompare(b.name));
      this.responsibleList.groups = this.workGroups;
      this.loaded = true;
    } catch (error) {}
  },
};
</script>

<style>
textarea.form-control {
  transition: 0s;
}
</style>
<style scoped>
.confirm-pause-modal {
  max-height: 90%;
}
</style>
