<template lang="pug">
modal(@click="close")
  modal-body
    template(#header)
      .flex.items-center.gap-6.justify-between.w-full
        h4.text-2xl.text-gray-500.font-semibold.font-plus {{ title || 'Alterar foto' }}
        .material-icons.notranslate.cursor-pointer.text-gray-500(
          @click="close"
        ) close
    template(#body)
      label.flex.border.rounded-md.items-center.justify-center.flex-col.p-4.py-20(
        v-if="!url"
        class="cursor-pointer transition-all hover:bg-brand-50 active:scale-90"
      )
        .material-icons.text-brand-400 cloud_upload
        p Selecione uma imagem
        input.hidden(type='file', accept='image/*', @change="e => image(e)")
      .flex.max-w-full.max-h-96.gap-4(v-else)
        .cropper-wrapper.max-h-96
          img(ref="cropperImage", :src="url")
        .flex.flex-col.items-center.gap-2
          b.text-xs Pré-visualização:
          .cropper-preview.overflow-hidden.rounded-md.border(style="width: 100px; height: 100px; object-fit: cover;")
          label.flex.border.rounded-md.items-center.justify-center.flex-col.p-2.w-full(
            class="cursor-pointer transition-all hover:bg-brand-50 active:scale-90"
          )
            p.text-xs Alterar foto
            input.hidden(type='file', accept='image/*', @change="e => image(e)")
    template(#footer)
      .flex.gap-2
        v-button-secondary(@click="close") Cancelar
        v-button(@click="applyImage", :disabled="!url") Confirmar
</template>

<script>
import VButtonSecondary from '@/components/Buttons/v-button-secondary.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import VButton from '@/components/Buttons/v-button.vue';
import Modal from '@/components/Modals/Modal.vue';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
export default {
  props: ['title'],
  components: {
    VButtonSecondary,
    ModalBody,
    VButton,
    Modal,
  },
  data() {
    return {
      cropper: null,
      url: null,
      file: null,
    };
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext'];
    },
  },
  methods: {
    close() {
      return this.$emit('close');
    },
    image(e) {
      if (/\.(jpe?g|png|gif)$/i.test(e.target.files[0].name)) {
        this.file = e.target.files[0];
        this.url = URL.createObjectURL(this.file);
        this.$nextTick(() => {
          if (this.cropper) {
            this.cropper.destroy();
          }
          const cropper = this.$refs.cropperImage;
          this.cropper = new Cropper(cropper, {
            aspectRatio: 1,
            viewMode: 1,
            preview: '.cropper-preview',
          });
        });
      }
    },
    applyImage() {
      if (this.file && this.cropper) {
        const canvas = this.cropper.getCroppedCanvas();
        canvas.toBlob((blob) => {
          this.$emit('apply-image', {
            blobImage: blob,
            imageUrl: this.cropper.getCroppedCanvas().toDataURL('image/png'),
          });
        });
      }
    },
  },
  beforeUnmount() {
    if (this.cropper) {
      this.cropper.destroy();
    }
  },
};
</script>
