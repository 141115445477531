<template lang="pug">
.flex.w-full.border-gray-300.rounded-md.justify-start.p-2.border.flex-col.gap-2(v-if="!isAutomaticMessage")
  .flex.flex-col.items-center.font-plus.text-sm.gap-2.font-semibold(v-if="loading")
    p Carregando informações da mensagem
    loading-ring
  .flex.flex-col.gap-2.items-start.w-full(v-else)
    .flex.w-full.gap-2.items-start(
      :class="{'cursor-pointer' : !isMostRecent}"
      @click="closed = !closed"
    )
      img.object-cover.rounded-full(
        v-if="!invalidAvatar && data.provider == 'opens'",
        :src="`https://${csAddress}/avatar/${messageOwner?.id}.png?version=${userAvatarCache}`",
        ref="img",
        :key="userAvatarCache"
        @error="invalidAvatar = true"
        class="min-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] max-h-[2.5rem]"
      )
      img.object-cover.rounded-full(
        v-else-if="!invalidAvatar",
        :src="`${requestLinks.clist}/uploads/${messageOwner?.id}.jpg?version=${userAvatarCache}`",
        ref="img",
        :key="userAvatarCache"
        @error="invalidAvatar = true"
        class="min-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] max-h-[2.5rem]"
      )
      .user-img.firstLetter.rounded-full(
        v-else-if="messageOwner?.name"
        class="min-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] max-h-[2.5rem]"
      ) {{ messageOwner?.name[0] }}
      .user-img.firstLetter.rounded-full(
        v-else-if="messageOwner?.username"
        class="min-h-[2.5rem] min-w-[2.5rem] max-w-[2.5rem] max-h-[2.5rem]"
      ) {{ messageOwner?.username[0] }}
      .flex.flex-col.items-start.w-full
        .flex.w-full.justify-between
          .flex.gap-1
            p.font-semibold {{ data.provider == 'opens' ? `${messageOwner?.username || messageOwner?.name} (${messageOwner?.email})` : `${messageOwner?.username || messageOwner?.name} (${contactChannel})`}}
            .material-icons.notranslate.cursor-help.text-base(
              v-if="data.provider == 'opens'"
              class="transition-all hover:text-gray-500"
              v-tooltip="'Agente de atendimento'"
            ) support_agent
          p.text-xs.text-gray-500 {{ `${$moment(data.createdAt).format('HH:mm')} (${$moment(data.createdAt).fromNow()})` }}
        .flex.truncate.flex-col.items-start(
          v-if="closed && !isMostRecent"
          class="max-w-[15rem]"
        )
          div.line-clamp-2.overflow-hidden.flex-1(
            :title="messageBody.visibleText"
            v-html="formatText(messageBody?.visibleText)"
          )
          .flex.gap-2.overflow-auto.max-w-full.justify-start(v-if="data?.attachments?.data")
            attachment(
              v-for="attachment in data?.attachments.data"
              :key="attachment.id"
              :file="attachment"
            )
        p.text-gray-400.text-xs(v-else) {{ data.provider == 'opens' ? 'para contato' : 'para empresa' }}
    .flex.w-full.border-t.border-t-gray-300.justify-start.flex-col.items-start(v-if="!closed || isMostRecent")
      .font-inter.text-sm.p-2.gap-4.flex.flex-col.w-full.items-start.justify-start(
        class="text-[#495057]"
      )
        div.line-break.break-anywhere(v-html="formatText(messageBody?.visibleText)")
        .flex.rounded-full.bg-zinc-400.w-fit.cursor-pointer.px-1.h-3(
          class="transition-all hover:opacity-80 active:scale-90"
          @click='displayFullMessage=!displayFullMessage'
          v-tooltip="displayFullMessage ? 'Esconder conteúdo cortado' : 'Mostrar conteúdo cortado'"
          v-if="messageBody?.fragments?.length"
        )
          p.text-zinc-800.font-bold.text-base(class="h-8") ...
        .p-2.border-l.gap-2.flex.flex-col.items-start.w-full(v-if="displayFullMessage")
          p.w-full.break-anywhere(
            v-for="(fragment, index) in messageBody?.fragments"
            :key="index" 
            :class="{'border-b pb-2' : messageBody?.fragments.length >= 2 && (index+1) != messageBody?.fragments.length}"
            v-html="formatFragment(fragment)"
          )
      .flex.gap-2.overflow-auto.justify-start.mt-2.flex-col.items-start.w-full.p-2(v-if="data?.attachments?.data")
        p.font-semibold {{ `${data?.attachments?.data?.length} anexos` }}
        .flex.gap-2.flex-wrap.justify-start
          attachment(
            v-for="attachment in data?.attachments.data"
            :key="attachment.id"
            :file="attachment"
            :hasPreview="true"
            :provider="data.provider"
          )
      .flex.gap-1.items-center.justify-center.border.rounded-full.p-1.px-3(
        v-if="!isFromHistory"
        class="transition-all cursor-pointer hover:opacity-60 active:scale-90"
        @click="showForwardEmailModal = true"
      )
        .material-icons.notranslate.text-gray-600(class="scale-x-[-1]") reply
        p.text-gray-600 Encaminhar

      email-forward-modal(
        v-if="showForwardEmailModal"
        @close="showForwardEmailModal = false"
        @forward-email="forwardEmail"
      )
.flex.flex-col.w-full.items-center.mb-0(v-else)
  .rounded.p-2.relative.bg-zinc-100
    .w-full
      .flex.justify-center.items-center.w-full
        p.text-zinc-400.text-xs(v-html="formatText(messageBody.visibleText)")
</template>

<script>
import { Tooltip } from 'floating-vue';
import jwtDecode from 'jwt-decode';
import Attachment from './attachment.vue';
import Avatar from '@/components/Avatars/avatar.vue';
import LoadingRing from '../LoadingRing.vue';
import user from '@/store/user';
import IconInput from '@/components/Inputs/IconInput.vue';
import imapService from '@/services/imap';
import chatService from '@/services/chat';
import rhService from '@/services/rship';
import contactListMixin from '@/mixins/contact-list';
import EmailForwardModal from '../Modals/EmailForwardModal.vue';
import vButtonSecondary from '../Buttons/v-button-secondary.vue';
export default {
  mixins: [contactListMixin],
  props: ['data', 'isMostRecent', 'contactChannel', 'messageIndex', 'isFromHistory'],
  components: {
    FTooltip: Tooltip,
    EmailForwardModal,
    vButtonSecondary,
    LoadingRing,
    Attachment,
    IconInput,
    Avatar,
  },
  data() {
    return {
      displayFullMessage: false,
      fullMessage: false,
      userAvatarCache: 0,
      showForwardEmailModal: false,
      loadingAttachments: true,
      loadingForward: false,
      invalidAvatar: false,
      loading: true,
      closed: true,
      forwardMessages: [],
      messageOwner: {},
      csAddress: process.env.VUE_APP_CSERVICE,
    };
  },
  watch: {
    messageOwner() {
      this.userAvatarCache++;
    },
  },
  computed: {
    emailProviders() {
      return process.env.VUE_APP_EMAIL_PROVIDERS;
    },
    isAutomaticMessage() {
      return !this.data.recipientId;
    },
    activeEmail() {
      return this.$store.getters['email/activeEmail'];
    },
    messageBody() {
      if (this.data?.messageType !== 'email')
        return {
          visibleText: this.data?.messageBody,
          fragments: [],
        };
      try {
        return JSON.parse(this.data?.messageBody);
      } catch (error) {
        return {
          visibleText: '',
          fragments: [],
        };
      }
    },
  },
  methods: {
    async forwardEmail({ toEmail, text, terminateChatWhenForward }) {
      if (this.loadingForward) return this.$toast.error('Este email já está sendo encaminhado');
      this.loadingForward = true;

      const messages = this.forwardMessages.map((message) => {
        if (this.emailProviders.includes(message.provider)) {
          return {
            ...message,
            messageBody:
              message.messageType == 'email' ? JSON.parse(message.messageBody).visibleText : message.messageBody,
            username: this.$store.getters['contactList/contactById'](message.recipientId).name,
            address: this.contactChannel,
          };
        }
        if (message.provider == 'opens') {
          return {
            ...message,
            username: this.$store.getters['usersList/userById'](message.recipientId).username,
            address: this.activeEmail.subject,
          };
        }
      });

      const { forwardEmail } = imapService;
      try {
        const { hostname } = jwtDecode(this.$cookies.get('snep7'));
        await forwardEmail({
          forwardMessages: messages.reverse(),
          fromId: this.activeEmail.subject,
          companyId: this.user.company_id,
          provider: this.data.provider,
          hostname,
          toEmail,
          text,
        });
        await this.addForwardIndicationToRh(toEmail);
        if (terminateChatWhenForward) await this.finishConversation();
        this.showForwardEmailModal = false;
        this.$toast.success('Email encaminhado com sucesso');
      } catch (error) {
        if (error?.response?.data?.message) return this.$toast.error(error.response.data.message);
        this.$toast.error('Não foi possível encaminhar este email');
      } finally {
        return (this.loadingForward = false);
      }
    },
    async addForwardIndicationToRh(toEmail) {
      try {
        await rhService.createTag({
          eventType: 'forward_email',
          companyId: this.user.company_id,
          callId: this.data.chatRoomId,
          text: `Email encaminhado para ${toEmail}`,
        });
      } catch (e) {
        this.$toast.error('Não foi possível adicionar indicação no RH');
      }
    },
    async finishConversation() {
      try {
        await chatService.terminateChat({
          roomId: this.data.chatRoomId,
          companyId: this.user.company_id,
          userId: this.user.id,
          isSilent: true,
        });
        localStorage.removeItem(`@yunique:draft-email_${this.ativeEmail?.id}`);
        this.$store.commit('chat/removeRoomById', this.activeEmail?.id);
        this.$store.commit('email/setActiveEmail', null);
        this.$router.push({ name: 'Emails' });
      } catch (e) {
        this.$toast.error('Não foi possível encerrar esta sala');
      }
    },
    async getMessageOwnerInformation() {
      this.userAvatarCache++;
      try {
        let data;
        if (this.data.provider == 'opens') {
          data = await this.$store.cache.dispatch('usersList/fetchUserById', this.data.recipientId);
        } else {
          this.activeContactId = this.data.recipientId;
          data = this.contact;
        }
        this.messageOwner = data?.data?.user?.id ? data?.data?.user : data;
      } catch (e) {
        this.loadingHasError = true;
        this.$toast.error('Não foi possível carregar as informações dessa mensagem');
      } finally {
        this.loading = false;
        this.userAvatarCache++;
      }
    },
    formatFragment(text) {
      text = text.replace(/^(&gt;)+/gm, '').trim();
      text = this.formatText(text);
      text = text.replace(
        /(Em.*?escreveu:|De: |Enviado: |Para: |Assunto:|On.*?wrote:|From: |Sent: |To: |Subject: | )/g,
        '<b>$1</b>',
      );
      return text;
    },
    formatText(text) {
      if (text.includes('\n')) {
        text = text.replace(/\n/g, '<br>');
      }
      if (text.includes('<b>') && text.includes('</b>')) {
        text = text.replace(/<b>/g, '<strong>').replace(/<\/b>/g, '</strong>');
      }
      return text;
    },
  },
  async created() {
    if (this.isAutomaticMessage) return;
    await this.getMessageOwnerInformation();
    if (!this.isFromHistory) this.forwardMessages = this.activeEmail.messages.slice(0, this.messageIndex + 1);
  },
};
</script>

<style lang="scss">
.user-img {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 4px;
  position: relative;
  top: none;
  background: var(--brand-color) 26;
  color: #fff;
}

.hidden-quote {
  blockquote {
    display: none;
  }
}

.visible-quote {
  blockquote {
    border-radius: 1rem;
    padding: 1rem;
    background-color: #a3a3a31a;
  }
}
</style>
