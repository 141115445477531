<template>
  <VuePhoneNumberInput
    :default-country-code="loadComponent(number.country)"
    @update="update"
    v-model="number.number"
    color="#AAAAAA"
  />
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import '../../assets/css/vue-phone-number-input.css';

export default {
  components: {
    VuePhoneNumberInput,
  },
  props: ['index', 'number'],
  methods: {
    update(payload) {
      try {
        if (!this.number.number) return;
        this.number.number.replace(/\s/g, '').replace('-', '');
        this.$set(this.number, 'country', payload.countryCode);
        this.$set(this.number, 'ddi', payload.countryCallingCode);
      } catch (e) {
        this.$log.error(e);
      }
    },
    loadComponent(country) {
      setTimeout(() => {
        document.getElementsByClassName('country-selector__label').item(this.index).innerHTML = '';
        document.getElementsByClassName('input-tel__input').item(this.index).placeholder = '';
        document.getElementsByClassName('input-tel__label').item(this.index).style.display = 'none';
      }, 0);
      if (!country) {
        return 'BR';
      }
      return country;
    },
  },
  created() {
    this.number.country = 'BR';
    this.number.ddi = '55';
  },
};
</script>

<style></style>
