export default {
  methods: {
    notify(message) {
      if (Notification.permission !== 'granted') return Notification.requestPermission();
      const notification = new Notification('Notificação', {
        body: message,
        icon: require('@/assets/images/opens-app-logo-for-notification.png'),
      });
      notification.onclick = function (e) {
        parent.focus();
        //window.focus(); //just in case, older browsers
        e.target.close();
      };
    },
  },
};
