<template lang="pug">
div
  v-dropdown(v-if="clientStatus" @show="extractMemberInStatus")
    i.material-icons.notranslate.text-sm.select-none(
      :class="clientStatus === 'read' ? 'text-brand-500' : clientStatus === 'failed' ? 'text-red-500' : 'text-zinc-400'"
    ) {{ clientStatus === 'failed' ? 'close' : clientStatus === 'sent' ? 'done' : 'done_all' }}
    template(#popper)
      .flex.p-4.gap-2(v-if="loadingMembersIdentity")
        loading-component.w-6.h-6(class="dark:invert")
        span.text-sm.text-gray-400() Carregando...

      .flex.flex-col.gap-4.p-4.items-start.w-72(v-else)
        .flex.items-start.gap-1.flex-col.w-full(v-if="readByIdentity?.length")
          span.text-zinc-700.font-plus.font-bold Vista por
          .flex.flex-col.w-full(v-for="item in readByIdentity")
            .flex.justify-between.items-center.w-full
              .flex.py-2.gap-2
                avatar.w-7.h-7.border-brand(
                  :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
                  :key="item.id",
                  :avatar-id="`${item.recipientId}`"
                )
                span.text-sm.text-gray-400() {{ item?.username }}
              span.text-xs.text-gray-400() {{ $moment(item.updatedAt).format('DD/MM/yyyy [-] HH:mm') }}

        .flex.items-start.gap-1.flex-col(v-if="deliveredByIdentity?.length")
          span.text-zinc-700.font-plus.font-bold Entregue para
          .flex.flex-col(v-for="item in deliveredByIdentity")
            .flex.justify-between.items-center.w-full
              .flex.py-2.gap-2
                avatar.w-7.h-7.border-brand(
                  :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
                  :key="item.id",
                  :avatar-id="`${item.recipientId}`"
                )
                span.text-sm.text-gray-400() {{ item?.username }}
              span.text-xs.text-gray-400() {{ $moment(item.updatedAt).format('DD/MM/yyyy [-] HH:mm') }}

        .flex.items-start.gap-1.flex-col(v-if="pendingMembers?.length")
          span.text-zinc-700.font-plus.font-bold Pendente
          .flex.flex-col(v-for="item in pendingMembers")
            .flex.py-2.gap-2
              avatar.w-7.h-7.border-brand(
                :src="item.provider == 'opens' ? `${requestLinks.cservice}/avatar/${item?.recipientId}.png` : `${requestLinks.clist}/uploads/${item?.recipientId}.jpg`"
                :key="item.id",
                :avatar-id="`${item.recipientId}`"
              )
              span.text-sm.text-gray-400() {{ item?.username }}

</template>

<script>
import { getContactById } from '@/services/contact-list';
import { getUserById } from '@/services/cservice';
import { defineComponent } from 'vue';
import avatar from '../Avatars/avatar.vue';
import LoadingComponent from '../LoadingComponent.vue';

export default defineComponent({
  name: 'ChatMessageStatus',
  components: {
    avatar,
    LoadingComponent,
  },
  data() {
    return {
      readByIdentity: [],
      deliveredByIdentity: [],
      loadingMembersIdentity: false,
      pendingMembers: [],
      allMembersIdentity: {},
    };
  },
  props: {
    messageStatus: {
      type: Array,
    },
    members: {
      type: Array,
      default: null,
      required: false,
    },
  },
  computed: {
    clientStatus() {
      return this.messageStatus?.find((item) => item.chatRoomMemberId === this.clientMember?.id)?.status;
    },
    clientMember() {
      return this.roomMembers?.find((item) => item.provider !== 'opens') || {};
    },
    agentMember() {
      return this.roomMembers?.find((item) => item.recipientId === this.user.id) || {};
    },
    roomMembers() {
      return this?.members || this.$store.getters['chat/activeRoom']?.room?.members;
    },
    noContentToShow() {
      return !this.readByIdentity.length && !this.deliveredByIdentity.length;
    },
  },

  methods: {
    actionTimeStamp(timestamp) {
      return moment(timestamp).tz('America/Sao_Paulo').calendar();
    },
    resetMembersIdentity() {
      this.readByIdentity = [];
      this.deliveredByIdentity = [];
      this.pendingMembers = [];
    },
    saveMemberIdentityByStatus(status, member) {
      if (status === 'read') {
        this.readByIdentity.push(member);
      } else if (status === 'delivered') {
        this.deliveredByIdentity.push(member);
      } else {
        this.pendingMembers.push(member);
      }
    },
    async extractMemberInStatus() {
      try {
        this.loadingMembersIdentity = true;
        this.resetMembersIdentity();

        await this.messageStatus.forEach(async (item) => {
          if (item.chatRoomMemberId === this.agentMember.id) return;
          let data;

          const memberAlreadyExists = this.allMembersIdentity[item.chatRoomMemberId];

          if (memberAlreadyExists) {
            data = memberAlreadyExists;
            this.saveMemberIdentityByStatus(item.status, data);
            return;
          }

          const member = this.roomMembers?.find((member) => member.id === item.chatRoomMemberId);

          let user;
          try {
            if (member.provider === 'opens') {
              user = await getUserById(member.recipientId);
            } else {
              const params = {
                associations: ['none'],
              };
              user = await getContactById(member.recipientId, { params });
            }

            data = {
              ...member,
              username: user?.username || user?.name,
            };
          } catch (error) {
            data = {
              ...member,
              username: 'Desconhecido',
            };
          }

          Object.assign(this.allMembersIdentity, { [item.chatRoomMemberId]: data });
          this.saveMemberIdentityByStatus(item.status, data);
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingMembersIdentity = false;
      }
    },
  },
});
</script>
