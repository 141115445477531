<template>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm auth-form">
      <div class="form-center">
        <div class="row">
          <img src="img/snep7-logo.png" class="logo" alt="">
        </div>
        <br>
        <auth-content></auth-content>

      </div>      
    </div>
    <div class="col-sm auth-img">
      
    </div>
  </div>
</div>
</template>
<script>
  import AuthContent from './Content.vue'

  export default {
    components: {
      AuthContent
    }, 
  }

</script>

<style lang="scss">
body{
  color: #9E9E9E;
}
.auth-form{
  background-color: #fff;
  height:100vh;
}
.auth-img{
  background-color: #CED4DA;
  height:100vh;
}
.form-center{
  margin-top: 80px;
  margin-left: 120px;
  margin-right: 120px;
}
.logo{
  margin-left: 20px;
  width: 120px;
  height: 60px;
}

h2{
  color: #000;
  font-weight: bold;
}
label{
  color: #000;
  padding-top: 20px;
}
button{
  width: 100%;
  font-weight: bold;
}
a{
  color: #9E9E9E;
}
a:hover{
  color: #495057;
}
</style>
