<template lang="pug">
.content.flex-col.min-w-max.z-0
  .header.p-5.items-center.justify-between.pt-8(v-if="isOverlay")
      .flex.gap-5
        h2.font-inter.font-normal(
          :class="isOverlay ? 'text-lg !font-bold text-zinc-700 flex justify-start items-center !font-titillium' : 'text-zinc-500'"
        ) Histórico de atividades do contato
      v-close-button(@click="close")
  .w-full
    tab(:tab-list="tabList", @tabChanged="activeTab = $event")
      template(slot="tabPanel-1")
        .w-full(v-if="!loading")
          events-history-table(:calls="calls")

      template(slot="tabPanel-2")
        .w-full(v-if="!loading")
          events-history-table(:chats="chats")
      
      template(slot="tabPanel-3")
        .w-full(v-if="!loading")
          events-history-table(:chats="emails")
    .w-full.justify-end.flex.p-6(v-if="!loading")
        span.pagination-info Itens por página:
        select.pagination-select(v-model="activityQuantity" @change="changeActivitiesTable")
          option(:value="5") 5
          option(selected :value="10") 10
          option(:value="15") 15
          option(:value="20") 20
        span.pagination-size-text(v-if="activeTab === tabsEnum.calls") {{ callsPaginationLabel }}
        span.pagination-size-text(v-if="activeTab === tabsEnum.chats") {{ chatsPaginationLabel }}
        span.pagination-size-text(v-if="activeTab === tabsEnum.emails") {{ emailsPaginationLabel }}
        span.pagination-icon.material-icons(ref="backIcon" @click="paginationBack") &#xe5cb
        span.pagination-icon.material-icons(@click="paginationForward" ref="forwardIcon") &#xe5cc
    .w-full.mt-8(v-if="loading")
      content-placeholders(v-for="item in +activityQuantity" :key="item" :rounded="true").w-full.mb-8.px-2
        content-placeholders-img.w-full.h-14
</template>
<script>
import { getAllChatsOfMember, getTotalChatsCountOfMember } from '@/services/chat';
import vCloseButton from '@/components/Buttons/v-close-button.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item.vue';
import Tab from '@/components/Tabs/index.vue';
import callReportService from '@/services/call-report';
import EventsHistoryTable from '@/components/Tables/events-history-table.vue';
import user from '@/store/user';

export default {
  title: 'Opens - Histórico do contato',
  components: {
    vCloseButton,
    DropdownItem,
    Tab,
    EventsHistoryTable,
  },

  props: {
    contactId: {
      type: String,
      required: true,
    },
    contactPhone: {
      type: String,
      required: false,
    },
    isOverlay: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      emailWhiteListedCompanies: process.env.VUE_APP_EMAIL_WHITE_LIST,
      contact: null,
      loaded: false,
      loading: false,
      tabsEnum: {
        calls: 1,
        chats: 2,
        emails: 3,
      },
      activityQuantity: 10,
      chats: [],
      chatsPaginationOffset: 0,
      chatsPaginationLimit: 10,
      chatsPaginationTotal: 0,

      calls: [],
      callsPaginationOffset: 0,
      callsPaginationLimit: 10,
      callsPaginationTotal: 0,
      activeTab: 1,

      emails: [],
      emailsPaginationOffset: 0,
      emailsPaginationLimit: 10,
      emailsPaginationTotal: 0,
    };
  },

  watch: {
    async activeTab(val) {
      if (val === this.tabsEnum.calls) {
        this.resetCallFilters();
        await this.fetchCallsWithCount();
      } else if (val === this.tabsEnum.chats) {
        this.resetChatFilters();
        await this.fetchChatsWithCount();
      } else if (val === this.tabsEnum.emails) {
        this.resetEmailFilters();
        await this.fetchEmailsWithCount();
      }
    },
    async chatsPaginationOffset(val) {
      if (this.activeTab !== this.tabsEnum.chats) return;
      await this.fetchChats();
    },
    async callsPaginationOffset(val) {
      if (this.activeTab !== this.tabsEnum.calls) return;
      await this.fetchCalls();
    },
    async emailsPaginationOffset(val) {
      if (this.activeTab !== this.tabsEnum.emails) return;
      await this.fetchEmails();
    },
    async activityQuantity(val) {
      if (this.activeTab === this.tabsEnum.calls) {
        await this.fetchCalls();
      } else if (this.activeTab === this.tabsEnum.chats) {
        await this.fetchChats();
      } else if (this.activeTab === this.tabsEnum.emails) {
        await this.fetchEmails();
      }
    },
  },

  methods: {
    close() {
      this.$store.commit('overlay/setActiveOverlay', null);
    },
    resetCallFilters() {
      this.call = [];
      this.activityQuantity = 10;
      this.callsPaginationLimit = 10;
      this.callsPaginationOffset = 0;
    },
    resetChatFilters() {
      this.chat = [];
      this.activityQuantity = 10;
      this.chatsPaginationLimit = 10;
      this.chatsPaginationOffset = 0;
    },
    resetEmailFilters() {
      this.emails = [];
      this.activityQuantity = 10;
      this.emailsPaginationLimit = 10;
      this.emailsPaginationOffset = 0;
    },
    changeActivitiesTable() {
      if (this.activeTab === this.tabsEnum.calls) {
        this.callsPaginationOffset = 0;
        this.callsPaginationLimit = parseInt(this.activityQuantity);
      } else if (this.activeTab === this.tabsEnum.chats) {
        this.chatsPaginationOffset = 0;
        this.chatsPaginationLimit = parseInt(this.activityQuantity);
      } else if (this.activeTab === this.tabsEnum.emails) {
        this.emailsPaginationOffset = 0;
        this.emailsPaginationLimit = parseInt(this.activityQuantity);
      }
    },
    async fetchCalls() {
      const calls = await callReportService.getPaginateCalls({
        limit: this.callsPaginationLimit,
        offset: this.callsPaginationOffset,
        companyId: this.user.company_id,
        createdAtLt: new Date().toISOString(),
        createdAtGt: new Date(-1).toISOString(),
        fromId: this.contact.id,
        toId: this.contact.id,
      });

      this.calls = calls;
    },
    async fetchCountPaginatedCalls() {
      const totalCalls = await callReportService.countPaginatedCalls({
        limit: this.callsPaginationLimit,
        offset: this.callsPaginationOffset,
        createdAtLt: new Date().toISOString(),
        createdAtGt: new Date(-1).toISOString(),
        companyId: this.user.company_id,
        fromId: this.contact.id,
        toId: this.contact.id,
      });

      this.callsPaginationTotal = totalCalls;
    },
    async fetchCallsWithCount() {
      try {
        this.loading = true;
        await Promise.all([this.fetchCalls(), this.fetchCountPaginatedCalls()]);
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de chamadas',
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChats() {
      try {
        this.loading = true;
        const chats = await getAllChatsOfMember({
          recipientId: this.contact.id,
          companyId: this.user.company_id,
          limit: this.activityQuantity,
          offset: this.chatsPaginationOffset,
          provider: ['yunique', 'whatsapp', 'messenger'],
        });
        this.chats = chats;
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de chats',
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchChatsCount() {
      const totalChats = await getTotalChatsCountOfMember({
        recipientId: this.contact.id,
        companyId: this.user.company_id,
        provider: ['yunique', 'whatsapp', 'messenger'],
      });
      this.chatsPaginationTotal = totalChats.count;
    },
    async fetchChatsWithCount() {
      await Promise.all([this.fetchChats(), this.fetchChatsCount()]);
    },
    async fetchEmails() {
      try {
        this.loading = true;
        const emails = await getAllChatsOfMember({
          recipientId: this.contact.id,
          companyId: this.user.company_id,
          limit: this.activityQuantity,
          offset: this.chatsPaginationOffset,
          provider: ['outlook', 'gmail'],
        });
        this.emails = emails;
      } catch (error) {
        this.$toast.open({
          type: 'error',
          message: 'Falha ao buscar histórico de emails',
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchEmailsCount() {
      const totalEmails = await getTotalChatsCountOfMember({
        recipientId: this.contact.id,
        companyId: this.user.company_id,
        provider: ['outlook', 'gmail'],
      });
      this.emailsPaginationTotal = totalEmails.count;
    },
    async fetchEmailsWithCount() {
      await Promise.all([this.fetchEmails(), this.fetchEmailsCount()]);
    },
    paginationForward() {
      if (this.activeTab === this.tabsEnum.calls) {
        if (this.callsPaginationOffset + this.callsPaginationLimit >= this.callsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.callsPaginationOffset + limit;
        this.callsPaginationLimit = limit;
        this.callsPaginationOffset = offset > this.callsPaginationTotal ? this.callsPaginationTotal : offset;
      }

      if (this.activeTab === this.tabsEnum.chats) {
        if (this.chatsPaginationOffset + this.chatsPaginationLimit >= this.chatsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.chatsPaginationOffset + limit;
        this.chatsPaginationLimit = limit;
        this.chatsPaginationOffset = offset > this.chatsPaginationTotal ? this.chatsPaginationTotal : offset;
      }

      if (this.activeTab === this.tabsEnum.emails) {
        if (this.emailsPaginationOffset + this.emailsPaginationLimit >= this.emailsPaginationTotal) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.emailsPaginationOffset + limit;
        this.emailsPaginationLimit = limit;
        this.emailsPaginationOffset = offset > this.emailsPaginationTotal ? this.emailsPaginationTotal : offset;
      }
    },
    paginationBack() {
      if (this.activeTab === this.tabsEnum.calls) {
        if (this.callsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.callsPaginationOffset - limit;
        this.callsPaginationLimit = limit;
        this.callsPaginationOffset = offset < 0 ? 0 : offset;
      }

      if (this.activeTab === this.tabsEnum.chats) {
        if (this.chatsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.chatsPaginationOffset - limit;
        this.chatsPaginationLimit = limit;
        this.chatsPaginationOffset = offset < 0 ? 0 : offset;
      }

      if (this.activeTab === this.tabsEnum.emails) {
        if (this.emailsPaginationOffset === this.tabsEnum.myCallbacks) return;
        const limit = parseInt(this.activityQuantity);
        const offset = this.emailsPaginationOffset - limit;
        this.emailsPaginationLimit = limit;
        this.emailsPaginationOffset = offset < 0 ? 0 : offset;
      }
    },
  },

  async created() {
    try {
      if (this.$props.contactId) {
        const cachedContact = this.$store.getters['contactList/contactById'](this.$props.contactId);
        if (cachedContact) {
          this.contact = cachedContact;
        } else {
          await this.$store.cache.dispatch('contactList/fetchContactById', this.$props.contactId);
          const contactResponse = this.$store.getters['contactList/contactById'](this.$props.contactId);
          this.contact = contactResponse;
        }
      }

      if (this.tabsEnum.calls === this.activeTab) {
        await this.fetchCallsWithCount();
      } else if (this.tabsEnum.chats === this.activeTab) {
        await this.fetchChatsWithCount();
      } else if (this.tabsEnum.emails === this.activeTab) {
        await this.fetchEmailsWithCount();
      }
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.loaded = true;
    }
  },

  computed: {
    isAllowedToUseEmail() {
      if (!this.emailWhiteListedCompanies) return true;
      return this.emailWhiteListedCompanies?.split(',').includes(this.user.company_id);
    },
    tabList() {
      if (this.isAllowedToUseEmail) return ['Chamadas', 'Chats', 'Emails'];
      return ['Chamadas', 'Chats'];
    },
    callsPaginationLabel() {
      const startIndex = this.callsPaginationOffset + 1;
      const endIndex = this.callsPaginationOffset + this.callsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.callsPaginationTotal}`;
    },

    chatsPaginationLabel() {
      const startIndex = this.chatsPaginationOffset + 1;
      const endIndex = this.chatsPaginationOffset + this.chatsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.chatsPaginationTotal}`;
    },

    emailsPaginationLabel() {
      const startIndex = this.emailsPaginationOffset + 1;
      const endIndex = this.emailsPaginationOffset + this.emailsPaginationLimit;
      return `${startIndex} - ${endIndex} de ${this.emailsPaginationTotal}`;
    },
  },
};
</script>
<style>
.contact-calls-content {
  position: relative;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
