import EventBus from '@/main-event-bus';

export default {
  methods: {
    askForHelp() {
      window.open('https://suporte.opens.com.br/', '_blank');
      EventBus.$emit('UX-event', { eventName: 'Asked For Help' });
    },
  },
};
