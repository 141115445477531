import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_CONFIG}`;

/**
 *
 * @param {string} companyId
 * @returns
 */
async function getAllChatOperators(companyId) {
  const { data } = await $http.get(`${service}/queue-members/aggs/${companyId}`);
  return data;
}

async function getQueueById(queueId) {
  const { data } = await $http.get(`${service}/queues/${queueId}`);
  return data;
}

async function getConfigsByProviderId(providerId, params) {
  const { data } = await $http.get(`${service}/config/provider/${providerId}`, { params });
  return data;
}

async function getRuleById(ruleId) {
  const { data } = await $http.get(`${service}/rules/${ruleId}`);
  return data;
}

async function findOrCreateQueueRule(payload) {
  const data = await $http.put(`${service}/rules/queue/find-or-create`, payload);
  return data;
}

async function getConfigById(configId) {
  const { data } = await $http.get(`${service}/config/${configId}`);
  return data;
}

async function getTemplatesByWabaId(wabaId) {
  const { data } = await $http.get(`${service}/meta/message-templates/${wabaId}`);
  return data;
}

async function getOpensTemplates({ size, from, templateName, opensProviderId }) {
  const params = {
    size,
    from,
    sortBy: 'name',
    orderBy: 'ASC',
  };

  if (templateName) params.templateName = templateName;

  const { data } = await $http.get(`${service}/meta-templates-config/provider/${opensProviderId}`, {
    params,
  });

  return data;
}

async function getAllProviders() {
  const { data } = await $http.get(`${service}/providers`);
  return data;
}

export async function getAllRules(params) {
  const { data } = await $http.get(`${service}/rules`, {
    params,
  });
  return data;
}

export async function getAllQueues() {
  const { data } = await $http.get(`${service}/queues`);
  return data;
}

export async function getMetaTemplateById(templateId) {
  const { data } = await $http.get(`${service}/meta-templates-config/${templateId}`);
  return data;
}

export async function syncOneWhatsappTemplate({ templateId, configId }) {
  const { data } = await $http.patch(`${service}/meta-templates-config/sync`, {
    templateId,
    configId,
  });
  return data;
}

export default {
  getQueueById,
  getAllQueues,
  getAllChatOperators,
  getConfigsByProviderId,
  getTemplatesByWabaId,
  getConfigById,
  getAllRules,
  getRuleById,
  getMetaTemplateById,
  findOrCreateQueueRule,
  syncOneWhatsappTemplate,
  getOpensTemplates,
  getAllProviders,
};
