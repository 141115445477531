import Onboarding from './Onboarding';
import OnboardingStepOne from '@/components/Modals/Onboarding/OnboardingStepOne';
import SoftphoneInstallOption from '@/components/Modals/Onboarding/SoftphoneInstallOption.vue';
import InstallWindows from '@/components/Modals/Onboarding/InstallWindows.vue';
import InstallMacOS from '@/components/Modals/Onboarding/InstallMacOs.vue';
import InstallMacInstructions from '@/components/Modals/Onboarding/InstallMacInstructions.vue';
import InstallLinux from '@/components/Modals/Onboarding/InstallLinux.vue';
import InstallLinuxDownload from '@/components/Modals/Onboarding/InstallLinuxDownload.vue';
import SoftPhoneConfig from '@/components/Modals/Onboarding/SoftPhoneConfig.vue';
import SoftPhoneMacConfig from '@/components/Modals/Onboarding/SoftPhoneMacConfig.vue';
import LinkDevice from '@/components/Modals/Onboarding/LinkDevice.vue';
import DeleteDevice from '@/components/Modals/DeleteDevice.vue';
import LinkTableDevice from '@/components/Modals/Onboarding/LinkTableDevice.vue';
import LinkUnregisteredTableDevice from '@/components/Modals/Onboarding/LinkUnregisteredTableDevice.vue';
import LinkSuccess from '@/components/Modals/Onboarding/LinkSuccess.vue';
import LinkFail from '@/components/Modals/Onboarding/LinkFail.vue';
import NotificationModal from '@/components/Modals/Notification';
import MovideskCreateTicketModal from '@/components/Movidesk/CreateTicket.vue';
import MovideskEditTicketModal from '@/components/Modals/EditMovideskTicket.vue';
import ConfirmPauseModal from '@/components/Modals/ConfirmPauseModal.vue';
import CompleteCallback from '@/components/Modals/CompleteCallback.vue';
import CancelCallback from '@/components/Modals/CancelCallback.vue';
import TagChat from '@/components/Modals/TagChat.vue';
import NotesChat from '@/components/Modals/NotesChat.vue';
import ChatUserTransfer from '@/components/Modals/ChatUserTransfer.vue';
import EmailUserTransfer from '@/components/Modals/EmailUserTransfer.vue';
import ChatUserInvite from '@/components/Modals/ChatUserInvite.vue';
import ChatTopicTransfer from '@/components/Modals/ChatTopicTransfer.vue';
import FullImage from '@/components/Modals/Full-Image.vue';
import PdfViewer from '@/components/Modals/Pdf-Viewer.vue';

export default {
  LinkFail,
  LinkDevice,
  DeleteDevice,
  Onboarding,
  LinkSuccess,
  NotificationModal,
  LinkTableDevice,
  OnboardingStepOne,
  LinkUnregisteredTableDevice,
  SoftphoneInstallOption,
  InstallWindows,
  InstallMacOS,
  InstallMacInstructions,
  InstallLinux,
  SoftPhoneConfig,
  EmailUserTransfer,
  SoftPhoneMacConfig,
  InstallLinuxDownload,
  MovideskCreateTicketModal,
  MovideskEditTicketModal,
  ConfirmPauseModal,
  CompleteCallback,
  CancelCallback,
  TagChat,
  NotesChat,
  ChatUserTransfer,
  ChatUserInvite,
  ChatTopicTransfer,
  FullImage,
  PdfViewer,
};
