<template lang="pug">
div
  p.my-2.text-sm.text-black.font-extrabold {{ label }}
  .flex.justify-between.relative
    p.text-gray-600 {{ value }}
    i.cursor-pointer.material-icons.notranslate.text-brand(@click="copyValue" class="dark:invert") content_copy
    transition-group.absolute.z-10.right-8(name="slide-fade")
      p.text-xs.text-gray-400(
        v-for="item in copiedToClipboard",
        :key="item"
      ) Copiado para a área de transferência
</template>

<script>
export default {
  props: ["label", "value"],
  data() {
    return {
      copiedToClipboard: [],
    };
  },
  methods: {
    copyValue() {
      navigator.clipboard.writeText(this.value);
      this.copiedToClipboard.push(Math.random().toString(36));
      setTimeout(()=>{
        this.copiedToClipboard.pop()
      }, 1100)
    },
  },
};
</script>
