<template lang="pug">
.flex.w-full.items-center.justify-between.p-2(
  class="hover:bg-gray-100 cursor-pointer transition-all"
  @click="$emit('select-contact', data)"
)
  .flex.gap-2.items-center.justify-center
    avatar.h-11.w-11(:src='`${clistUrl}/uploads/${data?.contactId}.jpg`')
    .flex.flex-col.items-start
      .flex.gap-2
        p.truncate(
          class="max-w-[10rem]"
          v-tooltip="data.contact.name"
        ) {{ data.contact.name}}
        .flex.border.bg-white.px-2.rounded-full(v-if="data?.contact?.category?.id && data?.contact?.category?.name")
          p.text-xs {{ data?.contact?.category?.name }}
      p.text-zinc-600.text-xs(v-if="data?.contact?.organization") {{ data?.contact?.organization?.name }}
  .flex.gap-2.items-center.justify-center
    .material-icons.notranslate.text-gray-400.text-sm phone
    p.text-gray-400.text-sm.truncate(
      class="max-w-[12rem]"
      v-tooltip="phoneNumber"
    ) {{ phoneNumber }}
    .flex.rounded-full.bg-brand-400.p-1.items-center.justify-center.w-6(v-if="selected")
      .material-icons.text-xs.notranslate.text-white done
</template>

<script>
import Avatar from '@/components/Avatars/avatar.vue';

export default {
  props: ['data', 'selected'],
  components: {
    Avatar,
  },
  data() {
    return {
      clistUrl: `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CLIST}`,
    };
  },
  computed: {
    phoneNumber() {
      return `+${this.data.ddi} ${this.data.number}`;
    },
  },
};
</script>
