<template lang="pug">
.w-full
  .flex.flex-col.items-center.w-full
    img.max-h-48.rounded-md.transition.cursor-pointer.object-cover(
      :src="imageUrl",
      @click="displayImage"
      @load="onImageLoaded"
      @error="onImageFailedToLoad"
      :class="{ 'hidden': loading }"
      class="hover:brightness-90 min-w-[12rem] dark:invert"
      ref="image"
    )
    p.self-start.break-words.whitespace-pre-line.font-plus.mt-1(
      class="dark:invert-0"
      :class="[ isAutomaticMessage ? '!text-zinc-400': 'text-zinc-50' ]",
      :style="{ 'max-width': $refs.image?.width + 'px' }",
      v-if="message?.caption && loadedImage"
    ) {{ message.caption }}
    .flex.flex-col(v-if="failedToLoad")
      p.font-plus.text-xs Falha ao carregar a imagem
      v-button(small, @click="reloadImage") Tentar novamente

    .flex.items-center.justify-center.w-full.w-36.h-36.bg-gray-300.rounded.animate-pulse(v-if="loading", class='dark:bg-gray-700')
      .material-icons.notranslate.text-6xl.text-zinc-400.opacity-50 landscape

</template>

<script>
import vButton from '../Buttons/v-button.vue';

export default {
  components: {
    vButton,
  },
  props: {
    message: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      loadedImage: false,
      failedToLoad: false,
      loading: true,
    };
  },

  computed: {
    isMine() {
      return this.message.recipientId === this.user.id;
    },
    imageUrl() {
      return `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_CHAT_ADAPTER}/uploads/${this.message.messageBody}`;
    },
    isAutomaticMessage() {
      return !this.message.recipientId;
    },
  },

  methods: {
    async displayImage() {
      this.$store.commit('modal/setActiveModal', {
        name: 'FullImage',
        context: `${this.imageUrl}`,
      });
    },
    reloadImage() {
      this.loading = true;
      this.loadedImage = false;
      this.failedToLoad = false;
      this.$nextTick(() => {
        this.$refs.image.src = `${this.imageUrl}?${Date.now()}`;
      });
    },
    onImageLoaded() {
      this.loadedImage = true;
      this.failedToLoad = false;
      this.loading = false;
    },
    onImageFailedToLoad() {
      this.loadedImage = false;
      this.failedToLoad = true;
      this.loading = false;
    },
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    URL.revokeObjectURL(this.imageUrl);
  },
};
</script>
