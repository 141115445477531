<template lang="pug">
.w-full.h-36.z-40.fixed.flex.justify-center.items-center.bottom-0.shadow-lg.pointer-events-none(class="dark:invert")
  .h-16.bg-zinc-800.rounded-full.flex.justify-center.items-center.cursor-pointer.px-4.pointer-events-auto(@click="toggleDialer(false)")
    p.text-white Você possui uma chamada ativa, 
        span.text-red-400 clique aqui para expandir!
    .relative
        span.material-icons.notranslate.ml-6.p-1.bg-zinc-400.text-zinc-800.rounded-full.absolute.animate-ping &#xe61d
        span.material-icons.notranslate.ml-6.p-1.bg-zinc-400.text-zinc-800.rounded-full &#xe61d
</template>

<script>
export default {
  data() {
    return {
      dialState: {
        DEFAULT: 0,
        INCOMING_CALL: 1,
        CALLING: 2,
        IN_CALL: 4,
        CALL_ENDED: 5,
      },
    };
  },
  computed: {
    currentDialState() {
      return this.$store.getters["cstate/GET_DIALER_STATE"];
    },
  },
  methods: {
    toggleDialer(status) {
      setTimeout(()=>{
        this.$store.commit("SET_COLLAPSE_DIALER", status);
      }, 50)
    },
  },
};
</script>
