<template lang="pug">
.mt-0.content
  .container-fluid.pb-6
    .pt-8.m-0.pagearea
      .header(style="align-items: flex-end; margin-bottom: 32px")
        .incall-left-aligner(style="flex-direction: column; display: flex")
          .flex.gap-2.justify-start.items-center.p-2.w-fit.transition-all(
            class="hover:bg-zinc-200 rounded-full"
            @click="$router.push({ name: 'Contatos' })",
            role="button",
          )
            i.material-icons.notranslate.text-brand-500.text-xl &#xe5c4
            span.font-inter.text-zinc-700.font-medium Voltar
          a.title(style="margin: 0px") Organizações
      .header.flex.gap-8
        .w-full
          IconInput.w-full(label="Nome da organização" :icon="'search'" v-model="organizationSearchValue")
        .flex.gap-4
          v-button.h-fit.min-w-fit(@click="addNewOrganization") Criar organização
      div(v-if="!loading").w-full.flex.flex-col.gap-4.pb-6
        md-table.tableBox.w-full(
          style="margin-top: 24px",
          v-if="organizationsList?.length > 0"
          :value="organizationsList?.slice(currentPage * limitItems - limitItems, currentPage * limitItems)", 
        )
          md-field.md-toolbar-section-end(md-clearable)
          md-table-row.contact-clickable.cursor-pointer.rounded-md(
            slot="md-table-row",
            slot-scope="{ item }",
            class="text-inside-brand" 
            @click="$router.push({ name: 'EditOrganization', params: { id: item.id } })"
          )
            md-table-cell(role="button", md-label="Nome") {{ item.name }}
            md-table-cell(role="button", md-label="Telefone Principal")
              p(:class="{'text-gray-400' : !item.phone}") {{ item.phone ? item.phone : 'Sem registro' }}
            md-table-cell(role="button", md-label="E-mail")
              p(:class="{'text-gray-400' : !item.mail}") {{ item.mail ? item.mail : 'Sem registro' }}
            md-table-cell(role="button", md-label="CNPJ")
              p(:class="{'text-gray-400' : !item.cnpj}") {{ item.cnpj ? item.cnpj : 'Sem registro' }}
            md-table-cell(role="button", md-label="Site")
              p(:class="{'text-gray-400' : !item.site}") {{ item.site ? item.site : 'Sem registro' }}
        pagination.ml-auto.mr-6(
          v-if="organizationsList?.length > 0"
          :hasMorePages="hasMorePages"
          :perPage="limitItems"
          @limit="setLimit"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
          :totalItems="organizationsList?.length"
        )
        p.mt-10(v-else) Nenhuma organização com este nome encontrada
      loading-ring.mt-10(v-else)
</template>
<script>
import VButton from '@/components/Buttons/v-button.vue';
import Pagination from '@/components/Pagination.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import cListService from '@/services/contact-list.js';
import vTable from '@/components/Tables/v-table.vue';
import LoadingRing from '@/components/LoadingRing.vue';

export default {
  title: 'Opens - Organizações',
  components: {
    VButton,
    Pagination,
    IconInput,
    vTable,
    LoadingRing,
  },
  data: () => ({
    limitItems: 10,
    currentPage: 1,
    organizations: [],
    loading: true,
    organizationSearchValue: '',
  }),
  computed: {
    hasMorePages() {
      const totalPages = Math.ceil(this.organizationsList?.length / this.limitItems);
      return this.currentPage < totalPages;
    },
    organizationsList() {
      return this.organizations.filter((organization) => {
        if (organization.name.toLowerCase().includes(this.organizationSearchValue.toLowerCase())) return true;
        return false;
      });
    },
    currentModal() {
      return this.$store.getters['modal/activeModal'];
    },
  },
  watch: {
    organizationSearchValue() {
      this.currentPage = 1;
    },
    async currentModal(val) {
      if (!val) await this.getOrganizations();
    },
  },
  methods: {
    async getOrganizations() {
      this.loading = true;
      try {
        this.organizations = await cListService.getOrganizationList();
        this.organizations = this.organizations.filter((organization) => organization.name != '');
        this.organizations = this.organizations.sort((a, b) => a.name.localeCompare(b.name));
      } catch (e) {
        if (e.response.status === 401) {
          window.location.pathname = '/auth/login';
        }
        this.$toast.open({ type: 'error', message: 'Falha ao buscar organizações' });
      }
      this.loading = false;
    },
    addNewOrganization() {
      this.$store.commit('modal/setActiveModal', 'CreateOrganization');
    },
    async onPageChange(page) {
      this.currentPage = page;
    },
    setLimit(value) {
      this.limitItems = value;
      this.resetPagination();
    },
    resetPagination() {
      this.currentPage = 1;
    },
  },
  async created() {
    await this.getOrganizations();
    this.loading = false;
  },
};
</script>
