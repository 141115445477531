<template lang="pug">
tr.callback-table-row.text-inside-brand(@click="$emit('click')" v-if="loaded")
  th.case-title(scope="row")
    .case-title-text {{ caso.title }}
    .case-description {{ caso.description ? caso.description : 'Sem descrição' }}
  td.callback-callto-content
    .callback-callto-content-aligner
      .callback-callto-user-img(v-if="failedImg || !contact?.id") {{ contact?.name[0].toUpperCase() }}
      img.callback-callto-user-img(
        v-else-if="!failedImg || contact?.id",
        :src="contact?.avatarUrl",
        @error="failedImg = true"
      )
      .callback-callto-info-aligner
        p.callback-callto-name-text {{ contact?.name }}
        p.callback-callto-number-text {{ _formatNumber(contact?.number) }}
  td.case-responsible(v-if="caso.responsible") {{ caso.responsible.username || caso.responsible.name }}
  td.case-responsible.text-gray-300.font-medium(v-else) Sem responsável
  td.case-opening-date {{ activityDateFormat() }}
  td.case-status
    v-dropdown(auto-size="min")
      Status(:icon="true", :status="statusTypes[caso.caseStatusId]")
      template(#popper)
        StatusDropdown(:options="statusTypes", @status-selected="changeStatus")
  td.case-actions.flex.items-center
    MenuIcon(:item="caso", :icon="'edit'", :text="'Editar'" @clicked="$emit('openEditOverlay', caso)")
</template>

<script>
import StatusDropdown from '@/pages/Cases/Cases.StatusDropdown.vue';
import Status from '@/pages/Cases/Cases.Status.vue';
import MenuIcon from '@/pages/Contacts/Contact.Table.Icons.vue';
import { Dropdown, hideAllPoppers } from 'floating-vue';
import ContactListMixin from '@/mixins/contact-list';

export default {
  props: ['caso'],
  mixins: [ContactListMixin],
  components: {
    StatusDropdown,
    Status,
    VDropdown: Dropdown,
    MenuIcon,
  },
  data() {
    return {
      fakeItem: { name: 'retorno' },
      client: {
        name: 'Contato Desconhecido',
        phones: ['0000000000'],
        id: 0,
      },
      loaded: false,
      responsible: { username: 'Contato desconhecido' },
      failedImg: false,
      statusTypes: { 3: 'Aberto', 4: 'Em andamento', 5: 'Fechado' },
      convertStatusToId: { Aberto: 3, 'Em andamento': 4, Fechado: 5 },
      convertStatusToClass: {
        Aberto: 'open',
        'Em andamento': 'inprogress',
        Fechado: 'closed',
      },
    };
  },
  computed: {
    companyMates() {
      return this.$store.getters['GET_COMPANYMATES'] || [];
    },
  },
  methods: {
    async changeStatus(data) {
      if (this.caso.responsible.isGroup) {
        await this.changeCaseResponsible(this.user.id);
      }
      try {
        const res = await this.$http.put(`${this.requestLinks.cases}/cases/${this.caso.id}`, {
          caseStatusId: this.convertStatusToId[data],
        });
      } catch (error) {}
      this.caso.caseStatusId = this.convertStatusToId[data];
      hideAllPoppers();
    },
    async changeCaseResponsible(id) {
      try {
        const res = await this.$http.put(`${this.requestLinks.cases}/cases/${this.caso?.id}`, {
          assignedTo: id,
        });
      } catch (error) {}
      this.caso.responsible = this.user;
      hideAllPoppers();
    },
    activityDateFormat() {
      const date = this.$moment.tz(this.caso.createdAt, this.user.timezone);
      return date.format('DD/MM/yyyy [-] HH:mm:ss');
    },
  },
  watch: {},
  async created() {
    try {
      if (this?.caso?.customerId) {
        this.activeContactId = this.caso.customerId;
      }
    } catch (error) {}
    if (this.caso.assignedTo) {
      this.responsible = this.companyMates.filter((element) => {
        return element.id == this.caso.assignedTo;
      })[0];
    }
    this.loaded = true;
  },
};
</script>

<style lang="scss" scoped>
.case-actions {
  height: 89px;
}
.case-title {
  padding-left: 1rem;
  width: 30%;
  min-width: 16rem;
}
.case-title.text {
  max-height: 1.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.case-description {
  margin-top: 0.2rem;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  max-height: 1.8rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 12px;
  color: #868e96;
}
.case-responsible {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
.case-opening-date {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #495057;
}
</style>
