export default {
  namespaced: true,
  state: {
    activeEmail: null,
  },
  mutations: {
    setActiveEmail(state, email) {
      state.activeEmail = email;
    },
    addNewTagsToActiveEmail(state, tags) {
      if (!state.activeEmail?.tags?.length) {
        state.activeEmail.tags = tags;
        return;
      }
      tags.forEach((tag) => {
        state.activeEmail.tags.unshift(tag);
      });
    },
    removeTagFromActiveEmail(state, tagId) {
      if(!state.activeEmail?.tags?.length) return;
      state.activeEmail.tags = state.activeEmail?.tags?.filter((tag) => tag.id != tagId);
    },
    addMessageToActiveRoom(state, message) {
      message.reactions ||= [];
      state.activeEmail.messages.push(message);
    },
    setInitialMessage(state, messages) {
      state.activeEmail.messages = messages;
    },
  },
  getters: {
    activeEmail(state) {
      return state.activeEmail;
    },
    activeRoomMessages(state) {
      return state.activeEmail.messages || [];
    },
  },
};
