<template lang="pug">
.h-fit.flex.p-2.items-start.justify-start
  .flex.gap-2.flex-col.items-center.justify-center(
    v-if="loadingEmojis"
  )
    p.text-sm.font-plus Carregando emojis...
    .flex
      loading-ring
  .flex.gap-1.items-start.flex-col.w-full.h-full.justify-start(
    v-if="!loadingEmojis"
  )
    p.font-plus.text-xs.text-gray-500 Categorias:
    .flex
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'smileys-emotion'}"
        v-tooltip="'Sorrisos'"
        @click="selectedEmojiCategory = 'smileys-emotion'"
      ) 😀
      p.text-2xl.rounded-md(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'people-body'}"
        v-tooltip="'Pessoas'"
        @click="selectedEmojiCategory = 'people-body'"
      ) 🦰
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'animals-nature'}"
        v-tooltip="'Animais e natureza'"
        @click="selectedEmojiCategory = 'animals-nature'"
      ) 🐶
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'food-drink'}"
        v-tooltip="'Comidas e bebidas'"
        @click="selectedEmojiCategory = 'food-drink'"
      ) ☕
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'travel-places'}"
        v-tooltip="'Viagem e lugares'"
        @click="selectedEmojiCategory = 'travel-places'"
      ) ✈️
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'activities'}"
        v-tooltip="'Atividades e esportes'"
        @click="selectedEmojiCategory = 'activities'"
      ) ⚽
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'objects'}"
        v-tooltip="'Objetos'"
        @click="selectedEmojiCategory = 'objects'"
      ) 💡
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'bg-brand-150' : selectedEmojiCategory == 'symbols'}"
        v-tooltip="'Símbolos'"
        @click="selectedEmojiCategory = 'symbols'"
      ) 🚸
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        :class="{'!bg-brand-150' : selectedEmojiCategory == 'flags'}"
        v-tooltip="'Bandeiras'"
        @click="selectedEmojiCategory = 'flags'"
      ) 🏳️
    .w-full.bg-gray-300(class="h-[1px]")
    .flex.flex-wrap.overflow-y-scroll.gap-1.items-start.justify-start.content-start.max-w-xs.max-h-80
      p.text-2xl.rounded-md.font-noto-emoji(
        class="hover:bg-slate-200 transition-all cursor-pointer active:scale-90 dark:invert"
        v-for="emoji in emojiList.filter((emoji) => emoji.group == selectedEmojiCategory)"
        :key="emoji.slug"
        @click="$emit('emojiSelected', emoji.character)"
      ) {{ emoji.character }}
</template>

<script>
import LoadingRing from '@/components/LoadingRing.vue';
import axios from 'axios';

export default {
  components: {
    LoadingRing,
  },
  data() {
    return {
      emojiList: [],
      selectedEmojiCategory: 'smileys-emotion',
      loadingEmojis: true,
    };
  },
  async created() {
    this.emojiList = await axios.get(
      'https://emoji-api.com/emojis?access_key=131deb77664078246a5a5258f695f91b5c6a8874',
    );
    this.emojiList = this.emojiList.data;
    this.loadingEmojis = false;
  },
};
</script>
