<template lang="pug">
.flex.w-full
  v-button-primary.flex.items-center.gap-2(
    class="!w-full"
    @click="handleClick"
    v-tooltip="tooltipLabel"
    size="w-full"
  )
    i.material-icons-round.notranslate {{ buttonIcon[button.type] }}
    span.text-sm.font-semibold {{ button.text }}
</template>

<script>
import { defineComponent } from 'vue';
import VButtonPrimary from '../Buttons/v-button.vue';

export default defineComponent({
  components: {
    VButtonPrimary,
  },
  data() {
    return {
      buttonIcon: {
        url: 'open_in_new',
        phone_number: 'call',
        quick_reply: 'chat',
      },
    };
  },

  computed: {
    tooltipLabel() {
      switch (this.button.type) {
        case 'url':
          return 'Abrir site em nova aba';
          break;
        case 'phone_number':
          return 'Ligar para o número: ' + this.button.value;
          break;
        default:
          return 'Botão de resposta rápida';
          break;
      }
    },
  },

  props: {
    button: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleClick() {
      if (this.button.type === 'url') {
        const page = this.button.value.replace('{{1}}', this.button.variable);
        window.open(page, '_blank');
      }
      if (this.button.type === 'phone_number') {
        this.makeCall(this.button.value);
      }
    },
    makeCall(phoneNumber) {
      if (this.$store.getters['sip/agent']) {
        this.$store.dispatch('sip/makeCall', { callNum: phoneNumber });
      } else {
        this.callNumber(phoneNumber);
      }
    },
  },
});
</script>
