export default {
  namespaced: true,
  state: {
    isModalOpen: false,
    activeModal: "",
    context: {},
  },
  mutations: {
    setActiveModal: (state, payload) => {
      if(payload.context){
        state.context = payload.context
      }
      if (!payload) {
        state.activeModal = null;
        return;
      }
      if (typeof payload === "string" || payload instanceof String) {
        state.activeModal = payload;
        return;
      }
      state.activeModal = payload.name;
    },
    setContext: (state, value) => {
      state.context = value;
    },
    setIsModalOpen: (state, value) => {
      state.isModalOpen = value;
    }
  },
  getters: {
    activeModal: (state) => {
      return state.activeModal;
    },
    getContext: (state) => {
      return state.context;
    },
    getIsModalOpen: (state) => {
      return state.isModalOpen;
    }
  },
};
