<template lang="pug">
td
  .flex.flex-col.gap-2.items-start
    p(:class='{"text-red-500": hasErrors}') {{ data }}
    p.text-xs.text-zinc-400(v-show="hasErrors") Valor inválido
    
</template>

<script>
import { PhoneNumberUtil } from 'google-libphonenumber';
const phoneNumberUtilInstance = PhoneNumberUtil.getInstance();

export default {
  data() {
    return {
      hasErrors: false,
      cpfColumn: false,
    };
  },

  watch: {
    isCpfColumn() {
      this.hasErrors = false;

      if (this.isCpfColumn) {
        this.hasErrors = false;
        this.validateCpf();
      }
    },
    isPhoneColumn() {
      this.hasErrors = false;

      if (this.isPhoneColumn) {
        this.hasErrors = false;
        this.validatePhoneNumber();
      }
    },
  },

  props: {
    data: {
      type: String,
    },
    isPhoneColumn: {
      type: Boolean,
    },
    isCpfColumn: {
      type: Boolean,
    },
    rowIndex: {
      type: Number,
    },
    cpfHasError: {
      type: Boolean,
    },
    phoneNumberHasError: {
      type: Boolean,
    },
  },

  methods: {
    validateCpf() {
      this.hasErrors = !this.data.match(/\d{11}/);

      if (!this.cpfHasError && this.hasErrors) {
        this.$emit('error', 'cpf');
      }
    },
    validatePhoneNumber() {
      try {
        const parsedPhone = phoneNumberUtilInstance.parse(this.data, 'BR');
        const isValid = phoneNumberUtilInstance.isValidNumber(parsedPhone);
        this.hasErrors = !isValid;

        if (!this.phoneNumberHasError && this.hasErrors) {
          this.$emit('error', 'phoneNumber');
        }
      } catch (error) {
        this.hasErrors = true;
      }
    },
  },
};
</script>
