<template lang="pug">
.flex.gap-3 
  .simple-input
    p.text-gray-500.font-plus.font-medium.p-1.px-2.transition(:class="{'translate-y-1/4 text-sm': upPlaceholder(), 'translate-x-0 text-xs': !upPlaceholder()}") {{ label }}
    input.absolute.w-full.h-full.outline-none.bg-transparent.appearence-none.indent-2.pt-3.font-plus.font-semibold.text-gray-800(
      :placeholder="placeholder",
      @input="handleInput",
      @focus="focus = true"
      @blur="focus = false"
      v-model="content",
      class="dark:text-gray-200"
      :type="replaceType || type",
      @keyup.enter="$emit('keyup-enter')"
      :MAXLENGTH="caract"
      :disabled="disabled"
    )
  span.material-icons.notranslate.cursor-pointer(
    v-tooltip="'Mostrar senha'"
    class="hover:text-brand-400 transition-all"
    v-if="passwordState == true"
    @click="showPassword"
    ) visibility
  span.material-icons.notranslate.cursor-pointer(
    v-tooltip="'Esconder senha'"
    class="hover:text-brand-400 transition-all"
    v-if="passwordState == false"
    @click="hidePassword"
    ) visibility_off
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    placeholder: {
      type: String,
      default: "",
    },
    type: String,
    tooltip: Object,
    caract: {
      type: Number,
      default: 1000,
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      contentSet: false,
      content: "",
      visibility: false,
      replaceType: this.type,
      focus: false,
      passwordState: null,
    };
  },
  methods: {
    upPlaceholder() {
      if (this.placeholder) return false;
      if (this.focus) return false;
      if (this.content) return false;
      return true;
    },
    handleInput(e) {
      this.$emit("input", e.target.value);
    },
    showPassword(){
      this.replaceType = 'String'
      this.passwordState = !this.passwordState
    },
    hidePassword(){
      this.replaceType = 'password'
      this.passwordState = !this.passwordState
    },
  },
  watch: {
    focus(value) {
      if (!value) {
        this.$emit("blur");
        return;
      }
      this.$emit("focus");
    },
    value(n) {
      this.content = n
    },
  },
  created() {
    this.replaceType == 'password' ? this.passwordState = true : false
    this.content = this.value;
  },
};
</script>
<style lang="scss">
input:invalid {
  border-bottom: 2px solid red !important;
}
</style>
