<template lang="pug">
.context-area-right-side(:key="`contextRightSide-${linkedid}`")
  .context-right-side-header
    .context-tracking-box
      .context-area-subtitle Rastreio de atividades
    .context-tracking-tab-box(
      @click="changeTab(enumSelectedTab.seeAll)"
      :class="(selectedTab === enumSelectedTab.seeAll ? 'tracking-tab-selected' : '')"
      )
      .context-tracking-tab-text(
        :class="(selectedTab === enumSelectedTab.seeAll ? 'tracking-tab-selected' : '')"
        ) Todas
    .context-tracking-tab-box(
      @click="changeTab(enumSelectedTab.onlyNotes)"
      :class="(selectedTab === enumSelectedTab.onlyNotes ? 'tracking-tab-selected' : '')"
      )
      .context-tracking-tab-text(
        :class="(selectedTab === enumSelectedTab.onlyNotes ? 'tracking-tab-selected' : '')"
        ) Anotações
    .context-tracking-tab-box(
      @click="changeTab(enumSelectedTab.onlyAgile)"
      :class="(selectedTab === enumSelectedTab.onlyAgile ? 'tracking-tab-selected' : '')"
      )
      .context-tracking-tab-text(
        :class="(selectedTab === enumSelectedTab.onlyAgile ? 'tracking-tab-selected' : '')"
        ) Contexto
  .scroll-activities(v-if="selectedTab === 0")
    component(
      v-for="track in activities"
      :key="track.id"
      :is="track.eventType.replace('_', '')"
      :data="track"
      @open-overlay="$emit('open-overlay','add')"
      :calldate="call.calldate"
    )
    loading-ring.h-8(v-if="loadingEvents")
    observer(@intersect="intersectedEvents" v-if="!eventsEnd")
  .scroll-activities(v-if="selectedTab === 1")
    component(
      v-for="track in activities", 
      :key="track.id",
      :is="track.eventType.replace('_', '')", 
      :data="track",
      :calldate="call.calldate"
    )
    loading-ring.h-8(v-if="loadingEvents")
    observer(@intersect="intersectedEvents" v-if="!eventsEnd")
  .scroll-activities(v-if="selectedTab === 2")
    component(
      v-for="track in activities", 
      :key="track.id",
      :is="track.eventType",
      :data="track",
      @open-overlay="$emit('open-overlay','add')"
      :calldate="call.calldate"
    )
    loading-ring.h-8(v-if="loadingEvents")
    observer(@intersect="intersectedEvents" v-if="!eventsEnd")
</template>

<script>
import ActivityType from './ContextArea.ActivityType.vue';
import rhService from '@/services/rship.js';
import io from 'socket.io-client';
import trackingTypesMixin from '@/mixins/tracking-types.mixin';
import Observer from '@/components/Observer.vue';
import LoadingRing from '@/components/LoadingRing.vue';

export default {
  props: ['callData', 'eventDetail'],
  mixins: [trackingTypesMixin],
  components: {
    ActivityType,
    Observer,
    LoadingRing,
  },
  data() {
    return {
      limit: 15,
      eventsPage: 0,
      loadingEvents: true,
      eventsEnd: false,
      eventType: null,
      selectedTab: 0,
      enumSelectedTab: {
        seeAll: 0,
        onlyNotes: 1,
        onlyAgile: 2,
      },
      activities: [],
      socket: null,
    };
  },
  computed: {
    phonenumber() {
      return this.$store.getters['rh/GET_PHONENUMBER'];
    },
    contact() {
      const contact = this.$store.getters['rh/GET_CONTACT'];
      contact.number = this.phonenumber;
      return contact;
    },
    call() {
      return this.callData ? this.callData : this.$store.getters['cstate/GET_CALL_PAYLOAD'];
    },
    linkedid() {
      return this.$route.params.callId;
    },
  },
  methods: {
    async intersectedEvents() {
      if (this.eventsEnd || this.loadingEvents) return;
      await this.getEvents();
    },
    async changeTab(tab) {
      try {
        this.selectedTab = tab;
        this.activities = [];
        this.loadingEvents = true;

        const tabToEventType = {
          [this.enumSelectedTab.seeAll]: null,
          [this.enumSelectedTab.onlyNotes]: ['note'],
          [this.enumSelectedTab.onlyAgile]: ['agile'],
        };

        this.eventType = tabToEventType[tab];

        await this.getInitialEvents();
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
    async getEvents() {
      const { getEventsById } = rhService;

      try {
        this.loadingEvents = true;

        const eventsResponse = await getEventsById(this.call.linkedid, {
          limit: this.limit,
          offset: this.eventsPage * this.limit,
          eventType: this.eventType,
        });

        this.activities = [...this.activities, ...eventsResponse];
        this.eventsPage += 1;
        this.eventsEnd = eventsResponse.length < this.limit;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
    async getInitialEvents() {
      const { getEventsById } = rhService;

      try {
        this.eventsPage = 0;

        const eventsResponse = await getEventsById(this.call.linkedid, {
          limit: this.limit,
          offset: this.eventsPage * this.limit,
          eventType: this.eventType,
        });

        this.activities = eventsResponse;
        this.eventsPage += 1;
        this.eventsEnd = eventsResponse.length < this.limit;
      } catch (error) {
        console.error(error);
      } finally {
        this.loadingEvents = false;
      }
    },
    async getAllTags() {
      const { getEventsById } = rhService;

      try {
        const tags = await getEventsById(this.call.linkedid, {
          eventType: ['tag'],
        });
        return tags;
      } catch (error) {
        console.error(error);
      }
    },
    async getProtocol() {
      const { getEventsById } = rhService;

      try {
        const protocol = await getEventsById(this.call.linkedid, {
          eventType: ['protocol'],
        });

        if (protocol.length === 0 || !protocol[0]?.identifier) return;

        this.$store.commit('rh/SET_PROTOCOL', protocol[0].identifier);
      } catch (error) {
        console.error(error);
      }
    },
    socketConfig() {
      this.socket = io(`${this.requestLinks.rhistory}`, {
        query: {
          callId: this.call.linkedid || this.call.uniqueid,
        },
        reconnect: true,
      });

      this.socket.on('rh-update', (payload) => {
        switch (payload.eventType) {
          case 'protocol':
            {
              this.$store.commit('rh/SET_PROTOCOL', payload.identifier);
            }
            break;
          case 'tag': {
            this.$store.commit('rh/ADD_TO_TAGS', {
              payload,
              linkedid: this.call.linkedid,
            });
          }
          default: {
            this.activities.unshift(payload);
          }
        }
      });
      this.socket.on('rh-delete', (payload) => {
        for (let i = 0; i < this.activities.length; i++) {
          if (this.activities[i].id === parseInt(payload)) {
            this.activities.splice(i, 1);
            this.$store.commit('rh/REMOVE_FROM_TAGS', {
              id: payload,
              linkedid: this.call.linkedid,
            });
          }
        }
      });
    },
    async initData() {
      try {
        const tags = await this.getAllTags();
        this.$emit('calldata', tags);

        await this.getProtocol();

        await this.getInitialEvents();
      } catch (e) {
        this.$log.info(e);
      } finally {
        this.$log.info('CONNECT TO RSHIP SOCKET');
        this.socketConfig();
      }
    },
  },
  created() {
    this.initData();
  },
  watch: {
    linkedid() {
      this.socket.disconnect();
      this.initData();
    },
  },
};
</script>

<style>
.context-area-right-side {
  width: calc(100% - 40%);
  margin-left: 33px;
}

.scroll-activities {
  height: 80%;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scroll-activities::-webkit-scrollbar {
  display: none;
}

.context-area-subtitle {
  font-family: 'Titillium Web', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #495057;
}

.context-tracking-tab-text {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #495057;
}

.tracking-tab-selected {
  font-weight: bold !important;
  border-color: var(--brand-color-op-1) !important;
}

.context-tracking-tab-box {
  cursor: pointer;
  padding: 8px;
  border-bottom: 2px solid #e9ecef;
  transition: 0.2s;
}

.context-tracking-tab-box:hover {
  border-color: #f0d8d1;
}

.context-tracking-box {
  padding-bottom: 8px;
  padding-left: 10px;
  border-bottom: 2px solid #e9ecef;
  width: 80%;
}

.context-right-side-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 28px;
}
</style>
