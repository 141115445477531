<template lang="pug">
div
  span
    iframe.w-screen.h-screen(
      :src="`${clientV2Url}/dashboard?authToken=${accessToken}`"
      frameborder="0"
    )
</template>

<script>
export default {
  title: 'Opens - Dashboard',
  computed: {
    clientV2Url() {
      const baseUrl = process?.env?.VUE_APP_CLIENT_V2;
      return baseUrl;
    },
  },
  methods: {
    onMessage(event) {
      if (event.data?.type === 'redirect') {
        this.$router.push(event.data?.path);
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.onMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onMessage);
  },
};
</script>

<style>
@import './_style.scss';
</style>
