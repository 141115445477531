<template lang="pug">
.flex.items-center.justify-center.-mx-2.overflow-hidden.rounded-full.border-2.border-white.bg-zinc-100
  img(v-show="imageLoaded", @error="imageLoaded = false", :src="src")
  img(
    v-show="!imageLoaded",
    src="@/assets/images/default_pfp.png"
  )
</template>

<script>
export default {
  data() {
    return {
      imageLoaded: true,
    };
  },

  props: {
    avatarId: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: ``,
    },
  },
};
</script>
