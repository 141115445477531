<template lang='pug'>
Onboarding
  template(v-slot:header)
    h4.text-2xl.text-gray-400.font-extrabold Adicione um device
    i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
  template(v-slot:content)
    .flex.flex-col.mt-12
      IconCircularBg.h-12.w-12.mb-12(:icon="'phone'")
      p.text-gray-400.text-center(class="w-4/5") Seu Telefone SIP já esta instalado e configurado?
  template(v-slot:footer)
    .flex.justify-evenly.w-full
      button.w-36.text-brand(
        class="dark:invert"
        @click="$store.commit('modal/setActiveModal', 'LinkUnregisteredTableDevice')"
      ) Não está instalado
      button.bg-orange.text-white.w-52.h-10.rounded(
        @click="$store.commit('modal/setActiveModal', 'LinkDevice')"
      ) SIM, JÁ ESTA INSTALADO
</template>



<script>
import Onboarding from "@/components/Modals/Onboarding/index.vue";
import IconCircularBg from "@/components/Icons/IconCircularBg.vue";
import OpenLinkMixin from "@/mixins/openExternalLinks.js";
export default {
  mixins: [OpenLinkMixin],
  components: {
    Onboarding,
    IconCircularBg,
  },
};
</script>