<template>
  <div class="w-full content font-inter" id="contentId">
    <ConfirmModal v-if="this.$store.getters['GET_MODAL'] === 'DeviceConfirmModal'" modalType="DisableConfirmModal">
    </ConfirmModal>
    <ConfirmModal v-if="this.$store.getters['GET_MODAL'] === 'EnableConfirmModal'" modalType="EnableConfirmModal">
    </ConfirmModal>

    <AddDeviceStep1 v-if="currentModal === 'stepDevice1Id'" id="stepDevice1Id" @close="dismissFunction"
      @openSuccessModal="openSteps('stepDeviceSuccessId')" @openFailedModal="openSteps('stepDeviceFailedId')">
    </AddDeviceStep1>
    <AddDeviceStep2 @openSuccess="openSteps('stepDeviceSuccessId')" v-if="currentModal === 'stepDevice2Id'"
      id="stepDevice2Id" @close="dismissFunction"></AddDeviceStep2>
    <AddDeviceSuccess v-if="currentModal === 'stepDeviceSuccessId'" id="stepDeviceSuccessId"
      @closeDown="dismissFunction()"></AddDeviceSuccess>
    <AddDeviceFailed v-if="currentModal === 'stepDeviceFailedId'" id="stepDeviceFailedId" @closeDown="dismissFunction"
      @openStepDevice1="openSteps('stepDevice1Id')"></AddDeviceFailed>

    <link href="https://fonts.googleapis.com/css2?family=Inter&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Montserrat&display=swap" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap" rel="stylesheet" />
    <div class="h-full container-fluid">
      <div style="h-full" class="flex items-start justify-start">
        <a class="mt-0 bg-zinc-100 pref-side-navigator relative !rounded-tl-3xl pl-4 w-56 pt-6 min-w-fit">
          <h1 class="flex items-center justify-start gap-1 text-xl font-bold text-gray-700 font-titilium">Preferências
          </h1>
          <a class="w-full p-3 mt-6 text-sm font-bold text-gray-700 bg-white border-r-4 rounded-md rounded-r-none dark:invert dark:bg-zinc-900 p-side-nav-item current font-plus border-brand text-marker"
            href="#/preferences/devices" role="button">Gerenciar ramais</a>
          <a class="w-full p-3 mt-2 text-sm text-gray-700 rounded-md rounded-r-none dark:invert dark:bg-zinc-900 p-side-nav-item hover:bg-white font-plus text-marker"
            href="#/preferences/integrations" role="button">Integrações</a>
        </a>
        <div class="flex items-start justify-start w-full m-0 page-area">
          <div style="position: absolute; width: 75%">
            <FailedModal v-if="this.$store.getters['GET_MODAL'] === 'DisableDeviceFailed'"
              modalText="Falha ao desabilitar device."></FailedModal>
            <SuccessModal v-if="this.$store.getters['GET_MODAL'] === 'DisableDeviceSuccess'"
              modalText="Device desabilitado com sucesso."></SuccessModal>
            <FailedModal v-if="this.$store.getters['GET_MODAL'] === 'EnableDeviceFailed'"
              modalText="Falha ao habilitar device."></FailedModal>
            <SuccessModal v-if="this.$store.getters['GET_MODAL'] === 'EnableDeviceSuccess'"
              modalText="Device habilitado com sucesso."></SuccessModal>
          </div>
          <div class="header">
            <div class="titles-area">
              <h1 class="title">Gerenciar ramais</h1>
              <h2 class="subtitle">Ramais SIP</h2>
              <div class="text-sm subtitle-info-text text-zinc-400">
                Conecte um ramal para começar a utilizar as funções de ligação.
              </div>
            </div>
          </div>
          <div class="w-full card mt-14">
            <div class="card-body">
              <table class="table table-striped">
                <thead class="thread-snep7">
                  <tr class="text-left">
                    <th class="padding-left">Device</th>
                    <th>Status</th>
                    <th>Web</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <Device v-for="(device, key) in devices.sip" :key="key" :device="device"
                    v-on:deleteDevice="deleteDevice"></Device>
                </tbody>
              </table>
              <div>
                <div class="no-device" style="width: 100%" v-if="!devices.sip.length">
                  <span class="info-orange-icon notranslate material-icons"> error_outline </span>
                  Você ainda não possui nenhum Ramal SIP vinculado.
                </div>
              </div>
            </div>
          </div>
          <div class="w-ful">
            <h1 class="mb-3 text-2xl font-semibold">Ajuste de audio</h1>
            <p class="mb-12 text-sm subtitle-info-text text-zinc-400">
              Controle aqui o volume dos audios do sistema, como chamadas e notificaçoes
            </p>
            <div class="flex items-center justify-start">
              <button class="w-6 h-6 mr-2 text-white rounded-full cursor-pointer material-icons bg-zinc-300"
                @click="decreaseAudioVolume(0.1)">&#xe15b</button>
              <div class="flex justify-between h-6 w-52">
                <div class="w-1/12 h-full overflow-hidden rounded cursor-pointer bg-zinc-200" v-for="item in 10"
                  :key="item" @click="setAudioVolume(item / 10)">
                  <div v-show="(item / 10) <= audioVolume" class="w-full h-full bg-brand-400 dark:invert"></div>
                </div>
              </div>
              <button class="w-6 h-6 ml-2 text-white rounded-full cursor-pointer material-icons bg-zinc-300"
                @click="increaseAudioVolume(0.1)">&#xe145</button>
            </div>
          </div>
          <div class="w-full mt-14">
            <h1 class="mb-3 text-2xl font-semibold">Som de Chamada</h1>
            <p class="mb-12 text-sm subtitle-info-text text-zinc-400">
              Selecione o som que deseja ouvir quando receber ou realizar uma chamada
            </p>
            <div class="flex flex-col justify-start gap-2">
              <div
                class="flex items-center justify-start w-full gap-4 p-4 bg-white rounded-xl"
                v-for="(ringingSound, index) of avaliablesRingingSounds"
                :key="`ringing-sound-${index}`"
              >
                <div class="flex items-center justify-start flex-1 gap-2">
                  <span class="material-icons notranslate text-brand-400">music_note</span>
                  <p class="text-sm font-semibold">{{ringingSound.title}}</p>
                </div>
                <div class="flex items-center justify-center gap-4 select-none">
                  <div class="grid w-8 h-8 text-white transition-colors rounded-full cursor-pointer place-items-center material-icons notranslate bg-zinc-300 hover:bg-zinc-400"
                    @click="() => handleTogglePlayRingingSound(index)"
                    title="Ouvir som de chamada">
                    {{ringingSound.isPlaying ? 'pause' : 'play_arrow'}}
                  </div>
                  <div
                    class="grid w-8 h-8 text-white transition-colors rounded-full cursor-pointer place-items-center material-icons notranslate"
                    @click="() => toggleRingingDefaultSound(ringingSound.isDefault, index)"
                    :class="`${ ringingSound.isDefault? 'bg-brand-400 hover:bg-brand-600': 'bg-zinc-300 hover:bg-zinc-400'}`"
                    title="Selecionar este som de chamada"
                  >check</div>
                </div>
              </div>
            </div>
          </div>
          <section class="mt-14">
            <h2 class="subtitle">Notificação de Inatividade</h2>
            <div class="flex items-center justify-start gap-4">
              <p class="text-sm subtitle-info-text text-zinc-400">
                Receber notificações quando ficar inativo por um tempo:
              </p>
              <SwitchBox class="m-0" v-model="showInactivityNotification" />
            </div>
          </section>
          <section class="mt-14">
            <h2 class="subtitle">Notificação de Mensagens</h2>
            <div class="flex items-center justify-start gap-4">
              <p class="text-sm subtitle-info-text text-zinc-400">
                Receber notificações quando receber uma mensagem:
              </p>
              <SwitchBox class="m-0" v-model="showMessagesNotification" />
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddDeviceStep1 from "./AddDevice/AddDeviceStep1.vue";
import AddDeviceStep2 from "./AddDevice/AddDeviceStep2.vue";
import AddDeviceFailed from "./AddDevice/AddDeviceFailed.vue";
import AddDeviceSuccess from "./AddDevice/AddDeviceSuccess.vue";
import ConfirmModal from "./Devices.ConfirmModal.vue";
import Device from "./Devices.device";
import SideNav from "./PrefSideNav.vue";
import ProgressModal from "@/components/Modals/ProgressModal.vue";
import FailedModal from "@/components/Modals/FailedModal.vue";
import SuccessModal from "@/components/Modals/SuccessModal.vue";
import SwitchBox from '@/components/Inputs/SwitchCheckbox.vue';
import { updateUserRingingSound, getUserRingingSound } from "@/services/cservice";

export default {
  title: "Opens - Preferências",
  components: {
    SideNav,
    Device,
    AddDeviceStep1,
    AddDeviceStep2,
    AddDeviceFailed,
    AddDeviceSuccess,
    ConfirmModal,
    ProgressModal,
    FailedModal,
    SuccessModal,
    SwitchBox,
  },
  data() {
    return {
      mobileCode: "",
      currentModal: "",
      actualDevice: {},
      inactivityTime: 20,
      showInactivityNotification: true,
      showMessagesNotification: true,
      avaliablesRingingSounds: [
        {
          title: 'Padrão',
          name: 'default',
          isPlaying: false,
          isDefault: true,
        },
        {
          title: 'Padrão 2',
          name: 'ringing2',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Celular Tocando',
          name: 'cellphone_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone de Escritório Tocando',
          name: 'office_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone Digital Tocando',
          name: 'digitalphone_ringing',
          isPlaying: false,
          isDefault: false,
        },
        {
          title: 'Telefone Tocando',
          name: 'ring_ringing',
          isPlaying: false,
          isDefault: false,
        },
      ],
    };
  },
  computed: {
    audioVolume() {
      return this.$store.getters["userPreferences/getAudioVolume"];
    },
  },
  watch: {
    inactivityTime() {
      localStorage.setItem("@yunique:inactivity-time", this.inactivityTime)
    },
    showInactivityNotification() {
      localStorage.setItem("@yunique:show-inactivity-notification", this.showInactivityNotification)
    },
    showMessagesNotification() {
      localStorage.setItem("@yunique:show-messages-notification", this.showMessagesNotification)
    },
  },
  methods: {
    async openSteps(stepId) {
      this.currentModal = stepId;
    },
    dismissFunction() {
      this.currentModal = "";
    },
    proceed(code) {
      this.mobileCode = code;
      this.openSteps("stepRamal2Id");
    },
    listenDeviceCode(code) {
      this.deviceCode = code;
    },
    async dismissFunctionAndReload() {
      this.currentModal = "";
      try {
        await this.$store.dispatch("user/requestDevices", this.$http);
      } catch (e) {
        this.$log.info(e);
      }
    },
    async deleteDevice(deviceId) {
      try {
        await this.$http.delete(
          `${this.requestLinks.cservice}/companies/users/devices/${deviceId}`
        );
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
      }
    },

    async deleteMobileDevice(deviceId) {
      try {
        await this.$http.delete(
          `${this.requestLinks.cservice}/companies/users/devices/${deviceId}`
        );
        this.$router.go(this.$router.currentRoute);
      } catch (e) {
      }
    },
    increaseAudioVolume(value) {
      const volume = Math.min(1, this.audioVolume + value);
      this.setAudioVolume(volume);
    },
    decreaseAudioVolume(value) {
      const volume = Math.max(0, this.audioVolume - value);
      this.setAudioVolume(volume);
    },
    setAudioVolume(value) {
      this.$store.commit("userPreferences/setAudioVolume", value);
    },
    async toggleRingingDefaultSound(isDefault, index) {
      if(isDefault) return;
      this.avaliablesRingingSounds.forEach((ringingSound, i) => {
        if(i === index) ringingSound.isDefault = true;
        else ringingSound.isDefault = false;
      });

      try{
        const ringingSoundName = this.avaliablesRingingSounds[index].name;
        await updateUserRingingSound(ringingSoundName, this.user.id);
        localStorage.setItem(`@yunique:ringing-sound:${this.user.id}`, ringingSoundName);
        this.$toast.success('Som de chamada padrão alterado com sucesso');
      } catch(e) {
        this.$log.error(e);
        this.$toast.error('Não foi possível alterar o som de chamada padrão');
      }
    },
    handleTogglePlayRingingSound(index) {
      const ringingSound = this.avaliablesRingingSounds[index];
      const ringingSounds = this.$store.getters['cstate/ringingSounds'];
      this.avaliablesRingingSounds.forEach(ringingSound =>{
        ringingSound.isPlaying = false;
        ringingSounds[ringingSound.name]?.pause();
      });
      const selectedRingingSound  = ringingSounds[ringingSound.name] || ringingSounds['default'];

      selectedRingingSound.addEventListener('ended', () => {
        ringingSound.isPlaying = false;
      });

      const isAudioPlaying = selectedRingingSound.paused && !selectedRingingSound.ended && selectedRingingSound.currentTime > 0;

      if(isAudioPlaying) {
        selectedRingingSound.pause();
        selectedRingingSound.currentTime = 0;
        ringingSound.isPlaying = false;
      } else {
        selectedRingingSound.volume = this.$store.getters['userPreferences/getAudioVolume'];
        selectedRingingSound.play();
        ringingSound.isPlaying = true;
      }
    }
  },
  async created() {
    const showInactivityNotification = localStorage.getItem("@yunique:show-inactivity-notification");
    const showMessagesNotification = localStorage.getItem("@yunique:show-messages-notification");

    if (showInactivityNotification === null) localStorage.setItem("@yunique:show-inactivity-notification", "true")
    else this.showInactivityNotification = showInactivityNotification === "true";

    if (showMessagesNotification === null) localStorage.setItem("@yunique:show-messages-notification", "true")
    else this.showMessagesNotification = showMessagesNotification === "true";

    this.inactivityTime = +localStorage.getItem("@yunique:inactivity-time") || 20;

    try{
      const data = await getUserRingingSound(this.user.id);
      this.avaliablesRingingSounds.forEach(ringingSound => {
        if(data.ringing_sound && ringingSound.name === data.ringing_sound) ringingSound.isDefault = true;
        else if (!data.ringing_sound && ringingSound.name === 'default') ringingSound.isDefault = true;
        else ringingSound.isDefault = false;
      });
    } catch(e) {
      this.$log.error(e);
    }
  }
};
</script>

<style>
.info-orange-icon {
  color: var(--brand-color);
  margin-right: 10px;
}

.no-device {
  padding-top: 26px;
  width: 130%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #868e96;
}

.content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.disable-button {
  margin-bottom: 7px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 14px;
  transition: 0.2s !important;
  color: var(--brand-color) !important;
}

.disable-button:hover {
  transform: scale(1.05);
}

.padding-left {
  padding-left: 30px !important;
}

.close-icon {
  width: 26px;
  height: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #adb5bd;
  border-radius: 13px;
  padding-top: 0px;
  font-size: 20px !important;
}

.close-icon:hover {
  width: 26px;
  height: 26px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8e949b;
  border-radius: 13px;
  padding-top: 0px;
  font-size: 20px !important;
  transition: 0.1s;
}

.phone-icon {
  display: inline-flex;
  align-items: flex-end;
  vertical-align: bottom;
  justify-content: center;
  width: 32px;
  color: var(--brand-color);
  font-size: 24px !important;
  margin-right: 15px;
  height: 32px;
  line-height: 30px;
  background: var(--brand-color-op-1);
  border-radius: 4px;
}

.mobile-icon {
  display: inline-flex;
  align-items: flex-end;
  vertical-align: bottom;
  justify-content: center;
  width: 32px;
  color: var(--brand-color);
  font-size: 31.5px !important;
  margin-right: 15px;
  height: 32px;
  background: var(--brand-color-op-1);
  border-radius: 4px;
}
</style>
