<template lang="pug">
label.relative.inline-block.h-6.w-12
    input.invisible(type="checkbox" v-model='content')
    span.slider.round 
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      content: false,
    };
  },
  watch: {
    value(val) {
      this.content = val
    },
    content(val){
      this.$emit("input", val);
    }
  },
  created() {
    this.content = this.value
  }
};
</script>

<style lang="scss" scoped>
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--brand-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px #fff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(23px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.hours-option-text {
  padding: 12px 30px;
  width: 88%;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #495057;
}
</style>
