<template lang='pug'>
Overlay
 template(v-slot:content)
    .modalDevice
      .modalDevice__header
        h4.step-title Excluir device
        i.material-icons(@click="$store.commit('modal/setActiveModal', '')") &#xe5cd
      .modalDevice__content
        .mt-6
        p.modalDevice__text Deseja excluir da sua conta o 
          b Device {{context.device.device}} ?
      .modalDevice__footer
        .flex.w-full
          button.buttoncancel(
            @click="$store.commit('modal/setActiveModal', '')"
          ) Cancelar
          button.bg-orange.text-white.w-48.h-10.rounded.buttonsave(
            @click="context.func"
          ) CONCLUIR
</template>



<script>
import Overlay from "@/components/Modals/Overlay.vue";
export default {
  components: {
    Overlay,
  },
  computed: {
    context() {
      return this.$store.getters['modal/getContext']
    }
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.modalDevice {
  display: flex;
  flex-direction: column;
  width: 45rem;
  padding: 30px;
  background-color: #fafafa;
  font-family: "Inter", sans-serif;

  &__header {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid rgb(218, 218, 218);
  }

  &__text {
    color: #868e96;
  }

  &__content {
    padding-bottom: 30px;
  }
}
</style>