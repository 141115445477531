<template>
  <div class="page-content">
    <link
      href="https://fonts.googleapis.com/css2?family=Inter&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap"
      rel="stylesheet"
    />
    <div class="step-header">
      <div class="step-title">Vincular Device SIP</div>
      <div class="step-counter-passed"></div>
      <div class="step-counter"></div>
    </div>
    <div class="line-separator"></div>
    <div class="progress-text">Vínculo em andamento</div>
    <div class="center-aligner">
      <svg class="progress-ring" width="120" height="120">
        <circle
          class="progress-ring"
          stroke="#E9ECEF"
          stroke-width="5"
          fill="transparent"
          r="52"
          cx="60"
          cy="60"
        />
        <circle
          class="progress-ring__circle"
          stroke="#F26C45"
          stroke-width="5"
          fill="transparent"
          r="52"
          cx="60"
          cy="60"
        />
      </svg>
    </div>
    <div class="cancel-button-aligner">
      <button v-on:click="$emit('close')" class="buttoncancel">Cancelar</button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    setTimeout(() => {
      this.$emit("openSuccess");
    }, 5000);
  },
};
</script>

<style>
.progress-ring {
  margin-top: 59px;
  margin-bottom: 50px;
}
.progress-ring__circle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 800;
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.center-aligner {
  display: flex;
  justify-content: center;
}
.progress-text {
  margin-top: 93px;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #adb5bd;
}
</style>
