<template lang="pug">
modal
  modal-body(class="w-[38rem]")
    template(v-slot:header)
      .flex.justify-between
        h4.text-2xl.text-gray-800.font-semibold.font-plus Adicionar Tag
        .material-icons.notranslate.cursor-pointer(@click="close") close
    template(v-slot:body)
      .min-h-96.h-96.flex-1.items-stretch.self-stretch(v-if="isLoading")
        loading-ring
      .flex.flex-col.justify-start.items-start.w-full.gap-3.min-h-96(v-else)
        .flex.flex-col.justify-start.items-start.border.rounded-lg.p-2.w-full(v-if="room?.tags?.length")
          span.protocol-text.mb-2 Tags já adicionadas
          .flex.flex-wrap.justify-start.max-h-96.overflow-auto
            call-tag.mr-2.my-2(
              :call-tag="tag",
              type="chat",
              :key="tag.id"
              @tag-removed="removeTag",
              v-for="tag in room.tags"
            )

        .flex.flex-col.w-full.items-start.gap-3
          span.flex.gap-1.items-center
            h5.font-plus.font-semibold.text-slate-500 Tags disponiveis
            p.material-icons.text-base.text-slate-400.cursor-pointer.select-none(v-tooltip="'Clique em uma tag para selecioná-la'") help

          .flex.gap-2.items-center.justify-center.w-full
            icon-input(
              class="w-3/5"
              label="Nome da tag"
              v-model="tagFilter"
            )
            f-dropdown.h-12(placement="bottom-start" class="w-2/5")
              button.cursor-pointer.select-none.border.transition.rounded-md.group.h-full(
                class="hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10 dark:hover:bg-inverted-brand dark:hover:bg-opacity-5 dark:active:bg-opacity-10"
                :class="{'border-brand' : selectedTagsGroupsSet.size}"
              )
                div.flex.items-center.justify-between.pr-2.pl-4
                  .font-plus.font-medium.text-sm(
                    class="group-hover:text-brand dark:invert"
                  ) {{ filterByGroupTagPlaceholder }}
                  .material-icons.notranslate.text-black(
                    class="group-hover:text-brand dark:invert"
                  ) keyboard_arrow_down
              template(#popper)
                .flex.p-2.flex-col
                  icon-input.w-48(
                    label="Nome do grupo"
                    v-model="tagsGroupFilter"
                  )
                  ul.max-h-96.w-full.flex.flex-col.justify-start.items-start
                    dropdown-item(
                      v-for="group in tagsGroups"
                      :selected="selectedTagsGroupsSet.has(group.id)"
                      @click="handleSelectTagsGroup(group)"
                      :text="group.name"
                      :key="group.id"
                    )
                    loading-ring(v-if="loadingTagsGroups")
                    observer(v-else @intersect="loadMoreTagsGroups")

          .overflow-auto.h-96.w-full
            .mb-3.text-base(v-if="selectedTagsGroups.length")
              p.inline.font-plus Filtrandos por tags dos grupos
              p.inline.font-plus.font-medium.font-bold {{ tagsGroupsActiveFiltersText }}
              .material-icons.notranslate.text-base.cursor-pointer.text-slate-500.align-bottom.ml-1(@click="resetFilters") close

            .mb-3.text-base(v-else)
              p.inline.font-plus Exibindo todas as tags

            .flex.flex-wrap.justify-start.items-start.gap-2.mb-auto.w-full(v-if="tags.length")
              selectable-tag(
                :selected="selectedTagSet.has(tag.id)"
                :tag="tag"
                @click="select(tag)"
                :key="tag.id"
                v-for="tag in tags"
              )
              loading-ring(v-if="loadingTags")
              observer(v-else @intersect="loadMoreTags")
            .w-full.text-center.h-96.flex.justify-center.items-center(v-else)
              p Nenhuma tag encontrada

    template(v-slot:footer)
      .flex.w-full.items-start.justify-center.gap-2(v-if="!isAddingTags")
        v-button-secondary(
          @click="close"
        ) Cancelar
        v-button(
          @click="addTags"
        ) Concluir
      loading-ring(v-else)
</template>

<script>
import ModalBodyNoFooter from '@/components/Modals/ModalBodyNoFooter.vue';
import ConfirmButton from '@/components/Buttons/confirm-button.vue';
import vButtonSecondary from '../Buttons/v-button-secondary.vue';
import TagInputChat from '@/components/Inputs/TagInputChat.vue';
import IconInput from '@/components/Inputs/IconInput.vue';
import ModalBody from '@/components/Modals/ModalBody.vue';
import { getAllCallTags } from '@/services/call-report';
import callTag from '../CallEventTracking/call-tag.vue';
import Overlay from '@/components/Modals/Overlay.vue';
import Modal from '@/components/Modals/Modal.vue';
import callReport from '@/services/call-report';
import Observer from '@/components/Observer.vue';
import vButton from '../Buttons/v-button.vue';
import LoadingRing from '../LoadingRing.vue';
import chatService from '@/services/chat';
import rhService from '@/services/rship';
import { Dropdown } from 'floating-vue';
import { debounce } from '@/helpers';
import SelectableTag from '@/components/Tags/SelectableTag.vue';
import DropdownItem from '@/components/Dropdown/dropdown-item-v2.vue';

export default {
  components: {
    FDropdown: Dropdown,
    ModalBodyNoFooter,
    vButtonSecondary,
    ConfirmButton,
    DropdownItem,
    TagInputChat,
    LoadingRing,
    IconInput,
    ModalBody,
    Observer,
    Overlay,
    vButton,
    callTag,
    Modal,
    SelectableTag,
    DropdownItem,
  },
  data() {
    return {
      tagsGroupFilter: '',
      tagFilter: '',
      lastTagsGroupsRequestLength: 0,
      lastTagsRequestLength: 0,
      tagsGroupsOffset: 0,
      tagsOffset: 0,
      tagsLimit: 20,
      loadingTagsGroups: false,
      isAddingTags: false,
      loadingTags: false,
      isLoading: true,
      selectedTagsGroups: [],
      selectedTags: [],
      tagsGroups: [],
      tags: [],
      room: {},
    };
  },
  watch: {
    tagFilter() {
      this.searchTags();
    },
    selectedTagsGroups() {
      this.searchTags();
    },
    tagsGroupFilter() {
      this.searchTagsGroups();
    },
  },
  computed: {
    selectedTagSet() {
      const ids = this.selectedTags.map((tag) => tag.id);
      return new Set(ids);
    },
    selectedTagsGroupsSet() {
      const ids = this.selectedTagsGroups.map((group) => group.id);
      return new Set(ids);
    },
    context() {
      return this.$store.getters['modal/getContext'];
    },
    routeId() {
      return this.$route?.params?.callId;
    },
    filterByGroupTagPlaceholder() {
      return this.selectedTagsGroups.length
        ? `${this.selectedTagsGroups.length} grupos selecionados`
        : 'Filtrar por grupo de tags';
    },
    tagsGroupsActiveFiltersText() {
      return ' ' + this.formatTagsGroupsFilter();
    },
  },
  methods: {
    searchTags: debounce(async function () {
      this.tags = [];
      this.tagsOffset = 0;
      this.lastTagsRequestLength = 0;
      await this.getTags();
    }, 500),
    searchTagsGroups: debounce(async function () {
      this.tagsGroups = [];
      this.tagsGroupsOffset = 0;
      this.lastTagsGroupsRequestLength = 0;
      await this.getTagsGroups();
      this.sortTagsGroups();
    }, 500),
    async loadMoreTags() {
      if (this.lastTagsRequestLength <= 9) return;
      this.tagsOffset = this.tagsOffset + this.tagsLimit;
      await this.getTags();
    },
    async getTags() {
      const { getTagsPaginated } = rhService;
      if (this.loadingTags) return;
      this.loadingTags = true;
      try {
        let payload = {
          companyId: this.user.company_id,
          offset: this.tagsOffset,
          orderBy: 'text',
          enable: true,
          limit: this.tagsLimit,
        };
        if (this.selectedTagsGroupsSet?.size) {
          payload.tagsGroupsIds = Array.from(this.selectedTagsGroupsSet);
          payload.withTagsGroups = true;
        }
        if (this.room?.tags) payload.noIds = this.room.tags.map((tag) => tag.tagId);
        if (this.tagFilter) payload.text = this.tagFilter;
        const data = await getTagsPaginated(payload);
        this.tags = [...this.tags, ...data.items];
        this.lastTagsRequestLength = data.items.length;
      } catch (error) {
        console.error(error);
        this.$toast.error('Ocorreu um erro ao buscar as tags.');
      } finally {
        this.loadingTags = false;
      }
    },
    async loadMoreTagsGroups() {
      if (this.lastTagsGroupsRequestLength <= 9) return;
      this.tagsGroupsOffset = this.tagsGroupsOffset + 10;
      await this.getTagsGroups();
    },
    async getTagsGroups() {
      const { getTagsGroups } = rhService;
      if (this.loadingTagsGroups) return;
      this.loadingTagsGroups = true;
      try {
        let payload = {
          companyId: this.user.company_id,
          offset: this.tagsGroupsOffset,
          limit: 10,
        };
        if (this.tagsGroupFilter) payload.name = this.tagsGroupFilter;
        const data = await getTagsGroups(payload);
        this.tagsGroups = [...this.tagsGroups, ...data.items];
        this.lastTagsGroupsRequestLength = data.items.length;
      } catch (error) {
        console.error(error);
        this.$toast.error('Ocorreu um erro ao buscar os grupos de tags.');
      } finally {
        this.loadingTagsGroups = false;
      }
    },
    handleSelectTagsGroup(group) {
      if (!this.selectedTagsGroupsSet.has(group.id)) {
        this.selectedTagsGroups.push(group);
        this.sortTagsGroups();
        return;
      }

      this.selectedTagsGroups = this.selectedTagsGroups.filter((selectedGroup) => selectedGroup.id != group.id);
      this.sortTagsGroups();
    },
    sortTagsGroups() {
      this.tagsGroups = this.tagsGroups.sort((a, b) => {
        const aIsSelected = this.selectedTagsGroupsSet.has(a.id);
        const bIsSelected = this.selectedTagsGroupsSet.has(b.id);

        if (aIsSelected && !bIsSelected) return -1;
        if (!aIsSelected && bIsSelected) return 1;

        if (!aIsSelected && !bIsSelected) {
          return a.name.localeCompare(b.name);
        }

        return 0;
      });
    },
    formatTagsGroupsFilter() {
      const tagsGroups = this.selectedTagsGroups.map((group) => group.name);

      if (tagsGroups.length === 0) return '';
      if (tagsGroups.length === 1) return tagsGroups[0];
      if (tagsGroups.length === 2) return `${tagsGroups[0]} e ${tagsGroups[1]}`;

      const lastItem = tagsGroups.pop();
      return `${tagsGroups.join(', ')} e ${lastItem}`;
    },
    resetFilters() {
      this.selectedTagsGroups = [];
      this.tagsGroupFilter = '';
      this.sortTagsGroups();
    },
    async removeTag(id) {
      this.room.tags = this.room?.tags?.filter((tag) => tag.id !== id);
      this.$store.commit('email/removeTagFromActiveEmail', id);
      this.$store.commit('chat/removeTagFromActiveChat', id);
    },
    /**
     * @typedef Tag
     * @property {string} text
     * @property {string} id
     */
    /**
     * @param {Tag} tag
     */
    select(tag) {
      if (!this.selectedTagSet.has(tag.id)) return this.selectedTags.push(tag);
      this.selectedTags = this.selectedTags.filter((addedTag) => addedTag.id !== tag.id);
    },
    close() {
      if (this.context?.mandatory) return this.$toast.warning('É necessário adicionar pelo menos uma tag.');
      this.$store.commit('modal/setActiveModal', '');
      this.$emit('close');
    },
    async addTags() {
      if (!this.selectedTags.length) return this.$toast.warning('Nenhuma tag selecionada');
      this.isAddingTags = true;
      try {
        let newTags = [];
        for (const tag of this.selectedTags) {
          if (this.context.type === 'chat') {
            const newTag = await chatService.createChatTag({
              tagId: tag.id,
              tagName: tag.text,
              companyId: this.user.company_id,
              chatRoomId: this.context.callId,
            });
            newTags.push(newTag);
            await rhService.createTag({
              eventType: 'tag',
              companyId: this.user.company_id,
              identifier: this.user.username,
              callId: this.context.callId,
              text: tag.text,
              createdBy: this.user.id,
            });
          } else {
            const newTag = await callReport.createCallTag({
              tagId: tag.id,
              tagName: tag.text,
              companyId: this.user.company_id,
              linkedId: this.context.linkedid || this.routeId,
              callId: this.context.callId,
            });
            newTags.push(newTag);
            await rhService.createTag({
              eventType: 'tag',
              companyId: this.user.company_id,
              identifier: this.user.username,
              contact: this.context.contact,
              callId: this.context.linkedid || this.routeId,
              text: tag.text,
              createdBy: this.user.id,
            });
          }
        }

        this.$emit('new-tag', newTags);
        this.$toast.success('Tags adicionadas com sucesso!');
        this.$store.commit('modal/setActiveModal', '');
        if (this.$route.name == 'Emails') this.$store.commit('email/addNewTagsToActiveEmail', newTags);
        if (this.$route.name == 'Chat') this.$store.commit('chat/addNewTagsToActiveChat', newTags);
        this.$emit('close');
      } catch (error) {
        console.error(error);
        this.$toast.error('Falha ao adicionar tags, por favor tente novamente mais tarde');
      } finally {
        this.isAddingTags = false;
      }
    },
  },
  async created() {
    if (this.context.type === 'chat') this.room = await chatService.findById(this.context.callId);
    await this.getTags();
    await this.getTagsGroups();
    this.isLoading = false;
  },
};
</script>
