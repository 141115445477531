<template lang="pug">
alert
  template(v-slot:body)
    .h-12.rounded.bg-black.bg-opacity-80.flex.justify-center.items-center.w-96 
      p.text-red-500.flex.justify-center.items-center.mr-12.text-xs Conexão perdida! Tentando restabelecer...
      span.material-icons.notranslate.animate-spin.text-white &#xe863
</template>

<script>
import alert from "@/components/Alerts/alert-template.vue";
export default {
  components: {
    alert,
  },
};
</script>
