<template lang="pug">
Overlay
  template(v-slot:content)
    .bg-white.flex.flex-col.py-4.pb-8.px-10(class="w-2/3")
      .flex.space-between.w-full.py-4.border-b.border-zinc-200
        h4.text-2xl.text-gray-500.font-semibold.font-inter Histórico de mensagens
      .flex.flex-col.justify-center.items-center.w-full
        .flex.w-full.px-2.justify-start
          v-dropdown(placement="bottom-start", auto-size="min")
            button.my-2.flex.items-center.cursor-pointer.select-none.mr-1.transition.px-2.py-1.pr-1.rounded-md.group(
              class="hover:bg-brand hover:bg-opacity-5 active:scale-95 active:bg-opacity-10"
            )
              .font-plus.font-semibold.text-xs.text-zinc-500(
                class="group-hover:text-brand"
              ) {{ filter ? translateFilter[filter] : "Filtrar por" }}
              .material-icons.notranslate.text-gray-400(class="group-hover:text-brand") keyboard_arrow_down
            template(#popper)
              ul.divide-y(v-close-popper.all)
                dropdown-item(
                  @click="filter = 'messages'",
                  label="Mensagens",
                  icon="e0b7"
                )
                dropdown-item(
                  @click="filter = 'warnings'",
                  label="Avisos",
                  icon="e0b0"
                )
                dropdown-item(@click="filter = ''", label="Tudo", icon="f06d")
          .flex.h-12.ml-auto
            .h-full.flex.justify-center.items-center.mr-4.transition.border-b-2.border-white(
              :class="{ 'font-semibold border-b-2 border-brand': displayMessages }"
            )
              .text-xs.font-inter.text-zinc-600.font-plus.cursor-pointer.transition(
                @click="activateMessagesDisplay"
              ) Mensagens
            .h-full.flex.justify-center.items-center.transition(
              :class="{ 'font-semibold  border-b-2 border-brand': displayTracking }"
            )
              .text-xs.font-inter.text-zinc-600.font-plus.cursor-pointer.transition(
                @click="activateTrackingDisplay"
              ) Rastreio de atividades
        .border.p-4.max-h-96.overflow-auto.overflow-x-hidden.w-full.relative(style="max-height: 38rem" v-if="messages.length > 0")
          div(v-show="displayMessages")
            message-card(
              v-for="message in messages",
              :key="message.id",
              :message="message"
              :members="members"
              :provider="chat.creatorProvider"
              v-if="chatProviders.includes(chat.creatorProvider)"
            )
            observer(v-if="chatProviders.includes(chat.creatorProvider)", @intersect="intersectedMessages")
            .flex.flex-col.gap-2
              email-message(
                :key="message.id"
                :data="message"
                :is-from-history="true"
                :contact-channel="members.filter((member) => member.provider != 'opens')[0].channel"
                :is-most-recent="false"
                v-for="(message, index) in messages"
                v-if="emailProviders.includes(chat.creatorProvider)"
              )
          div(v-show="displayTracking")
            component(
              v-for="(event, index) in roomEvents",
              :key="event.id",
              :is="event.eventType.replace('_', '')",
              :isChat="true",
              :data="event",
              :calldate="chat.createdAt"
            )
            observer(@intersect="intersectedEvents")
          .flex.w-full.absolute
            loading-ring.mx-auto.mb-3.h-fit.w-fit(v-if="loading")
      .flex.justify-center.items-center.mt-10.pt-4.w-full
        v-button-secondary(@click="$store.commit('modal/setActiveModal', '')") Fechar
</template>

<script>
import Overlay from '@/components/Modals/Overlay.vue';
import Observer from '../Observer.vue';
import VButtonSecondary from '../Buttons/v-button-secondary.vue';
import { Dropdown, Tooltip } from 'floating-vue';
import DropdownItem from '../Dropdown/dropdown-item.vue';
import chat from '@/services/chat';
import ChatContactData from '@/components/Banners/ChatContactData.vue';
import trackingTypesMixin from '@/mixins/tracking-types.mixin';
import { getEventsById } from '@/services/rship';
import LoadingRing from '../LoadingRing.vue';
import MessageCard from '../Chat/message-card.vue';
import EmailMessage from '@/components/Email/message.vue';

export default {
  components: {
    Overlay,
    Observer,
    EmailMessage,
    ChatContactData,
    VButtonSecondary,
    VDropdown: Dropdown,
    FTooltip: Tooltip,
    DropdownItem,
    LoadingRing,
    MessageCard,
  },
  mixins: [trackingTypesMixin],
  data() {
    return {
      displayMessages: true,
      displayTracking: false,
      rawMessages: [],
      roomEvents: [],
      filter: '',
      translateFilter: {
        warnings: 'Filtrar por avisos',
        messages: 'Filtrar por mensagens',
      },
      chat: {},
      msgPage: 0,
      msgEnd: false,
      eventsPage: 0,
      eventsEnd: false,
      loading: false,
      members: null,
    };
  },
  computed: {
    emailProviders() {
      return process.env.VUE_APP_EMAIL_PROVIDERS;
    },
    chatProviders() {
      return process.env.VUE_APP_CHAT_PROVIDERS;
    },
    context() {
      return this.$store.getters['modal/getContext'];
    },
    messages() {
      const messages = this.rawMessages;
      let currentMessage = null;
      let eventCount = 0;
      let result = messages;

      if (!this.filter) return messages;
      result = messages.filter((message) => {
        if (this.filter == 'messages') return message.provider;
        if (this.filter == 'warnings') return !message.provider;
      });
      return result;
    },
  },
  methods: {
    async intersectedMessages() {
      if (this.msgEnd) return;
      this.loading = true;
      await this.getMessages();
      this.msgPage++;
      this.loading = false;
    },
    async intersectedEvents() {
      if (this.eventsEnd) return;
      this.loading = true;
      await this.getEvents();
      this.eventsPage++;
      this.loading = false;
    },
    async getEvents() {
      try {
        const data = await getEventsById(this.context.chatRoomId, {
          limit: 15,
          offset: this.eventsPage * 15,
          order: 'asc',
        });
        this.roomEvents = [...this.roomEvents, ...data];
        if (data.length < 15) this.eventsEnd = true;
      } catch (error) {
        this.$toast.error('Falha ao carregar as novos eventos. Tente novamente!');
      }
    },
    async getMessages() {
      try {
        const data = await chat.getMessagesByRoom({
          chatRoomId: this.context.chatRoomId,
          companyId: this.user.company_id,
          limit: 15,
          offset: this.msgPage * 15,
          order: 'asc',
        });

        const messages = data;
        this.rawMessages = [...this.rawMessages, ...messages];
        if (messages.length < 15) this.msgEnd = true;
      } catch (error) {
        console.log(error);
        this.$toast.error('Falha ao carregar as mensagens');
      }
    },
    async getMembers() {
      try {
        const data = await chat.findById(this.context.chatRoomId, {
          companyId: this.user.company_id,
          includeMembers: true,
        });

        this.members = data?.members;
      } catch (error) {
        console.log(error);
        this.$toast.error('Falha ao carregar os membros');
      }
    },
    activateMessagesDisplay() {
      this.displayMessages = true;
      this.displayTracking = false;
    },
    activateTrackingDisplay() {
      this.displayMessages = false;
      this.displayTracking = true;
    },
  },
  async created() {
    try {
      this.chat = await chat.findById(this.context.chatRoomId);
      await this.getMessages();
      await this.getMembers();
      this.msgPage++;
      await this.getEvents();
      this.eventsPage++;
    } catch (error) {
      this.$toast.error('Falha ao carregar histórico. Tente novamente!');
    }
  },
};
</script>

<style></style>
