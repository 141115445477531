<template lang="pug">
.event-detail
    LeftSide.max-w-md(class="lg:max-w-xl" :callData="callData", :eventDetail="true")
      template(v-slot:audio-player)
        .input-box(v-if="callAudio != undefined && !this.$store.getters['performance/IS_SUPER']")
        label.text Gravação da chamada
        audio-player(:source="callAudio")
    RightSide(@calldata="setActivities", :eventDetail="true", :callData="callData")
</template>
<script>
import LeftSide from '@/pages/ContextArea/ContextArea.LeftSide';
import RightSide from '@/pages/ContextArea/ContextArea.RightSide';

export default {
  props: ['callData'],
  components: {
    LeftSide,
    RightSide,
  },
  data() {
    return {
      activities: [],
      tags: [],
      callAudio: undefined,
    };
  },
  methods: {
    setActivities(payload) {
      this.activities = payload;
      for (const element of this.activities) {
        if (element.eventType === 'tag') {
          this.tags.push(element);
        }
      }
    },
    async getAudio() {
      try {
        const res = await this.$http.get(`${this.requestLinks.records}/record/${this.callData.callid}`, {
          responseType: 'JSON',
        });
        this.callAudio = res.data.record;
      } catch (error) {
        this.callAudio = undefined;
        if (error.response.status == 404) {
          this.$toast.error('Não foi possível localizar o aúdio');
        } else if (error.response.status == 401) {
          this.$toast.error('Autorização para carregar aúdio negada');
        } else {
          this.$toast.error(error);
        }
      }
    },
  },
};
</script>
<style></style>
