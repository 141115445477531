import $http from './client';
const service = `${process.env.VUE_APP_SCHEMA}://${process.env.VUE_APP_INTEGRATIONS}`;

async function verifyMovidesk({ companyId }) {
  try {
    const result = await $http.get(`${service}/integration/${companyId}/partner/movidesk`);
    return result.data;
  } catch (e) {
    return Promise.reject(e);
  }
}

async function executeAction({ actionName, partnerName }, payload) {
  try {
    const { data } = await $http.post(`${service}/action/execute/partner/${partnerName}/action/${actionName}`, payload);
    return data;
  } catch (error) {
    return Promise.reject(e);
  }
}

async function getAgents(token) {
  try {
    let list = [];
    await $http
      .get(
        `https://api.movidesk.com/public/v1/persons?token=${token}&$filter=(personType eq 1 OR personType eq 4) AND (profileType eq 3 OR profileType eq 1)&$orderby=businessName asc`,
      )
      .then((res) => {
        let agents = res.data;
        let teamsAndAgents = {};
        let agentsRepeat = {};
        Object.keys(agents).map(function (i) {
          agentsRepeat[agents[i].id] = 0;
          Object.keys(agents[i].teams).map(function (t) {
            if (typeof teamsAndAgents[agents[i].teams[t]] == 'undefined') {
              teamsAndAgents[agents[i].teams[t]] = [];
              teamsAndAgents[agents[i].teams[t]].push({
                id: agents[i].teams[t],
                businessName: agents[i].teams[t],
                team: true,
              });
              agentsRepeat[agents[i].id]++;
              teamsAndAgents[agents[i].teams[t]].push({
                id: agents[i].id,
                businessName: agents[i].businessName,
                team: false,
                teams: agents[i].teams,
                repeat: agentsRepeat[agents[i].id],
              });
            } else {
              agentsRepeat[agents[i].id]++;
              teamsAndAgents[agents[i].teams[t]].push({
                id: agents[i].id,
                businessName: agents[i].businessName,
                team: false,
                teams: agents[i].teams,
                repeat: agentsRepeat[agents[i].id],
              });
            }
          });
        });
        Object.keys(teamsAndAgents).map(function (i) {
          Object.keys(teamsAndAgents[i]).map(function (x) {
            list.push(teamsAndAgents[i][x]);
          });
        });
      })
      .catch((e) => {
        return callback({ message: 'Invalid token' });
      });
    return list;
  } catch (error) {
    return Promise.reject(e);
  }
}

/**
 * @param {object} params
 * @param {string} params.hook - Webhook name
 * @param {string} params.companyId - Company ID
 * @param {object} params.payload - Payload to send to webhook
 *
 * @returns {Promise<object>}
 */
export async function executeAllActionByHook({ hook, companyId, payload }) {
  await $http.post(
    `${service}/action/execute-all`,
    { data: payload },
    {
      params: {
        companyId,
        hook,
      },
    },
  );
}

export default {
  verifyMovidesk,
  executeAction,
  getAgents,
  executeAllActionByHook,
};
